import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import uuidv4 from 'uuid/v4';
import { withReduxForm, TextField } from 'utilsModule/form/reduxFormMui';
import { normalize, isNumeric } from 'utilsModule/form';
import { Back } from 'utilsModule/components';
import Logo from 'appModule/logo/logoNoText';
import HeaderComponent from 'appModule/main/layouts/Header';

const styles = {
  pageContainer: {
    height: '84vh',
    width: '100vw',
    margin: 0,
    backgroundColor: '#fff',
    paddingBottom: 100
  },
  formContainer: {
    // width:606,
  },
  banner: {
    textAlign: 'center',
  },
  back: {
    height: '16vh',
    padding: 20,
    backgroundColor: '#fff',
  },
  title : {
    color: '#212121',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 19,
  },
  subTitle: {
    color: '#212121',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '19px',
  },
};

const digitInputs = {};

const layout = {
  header: {
    itemProps: {
      style: {
        marginBottom: 42,
      },
    },
    containerProps: {
      justify: 'center',
    },
    components: [
      {
        id: uuidv4(),
        name: 'banner',
        component: ({ title, subtitle }) => (
          <Grid container style={{ textAlign: 'center' }}>
            <Grid item xs={12}><Logo /></Grid>
            <Grid item xs={12} style={{marginBottom:16}}><Typography variant='h4' styles={styles.title} style={{letterSpacing:0.5}}>{title}</Typography></Grid>
            <Grid item xs={12}><Typography variant='body1' styles={styles.subTitle} style={{letterSpacing:0.5}}>{subtitle}</Typography></Grid>
          </Grid>
        ),
        colSpans: { xs: 12 },
        inject: ({ runtimeProps: { header: { banner } } }) => banner,
      },
    ],
  },
  body: {
    sections: [
      {
        id: uuidv4(),
        name: 'otp',
        rows: [
          {
            id: uuidv4(),
            containerProps: {
              justify: 'center',
              spacing: 1,
            },
            cols: [
              {
                name: 'digit0',
                component: TextField,
                normalize: normalize.toNumber,
                colSpans: { xs: 1 },
                autoFocus: true,
                inputProps: {
                  style: {
                    textAlign: 'center',
                    backgroundColor: '#FAFAFA',
                    height:52,
                    padding:0
                  },
                  maxLength: 1,
                  onClick: e => e.target.select(),
                  onFocus: e => e.target.select(),
                },
                // eslint-disable-next-line
                inputRef: node => digitInputs.digit0 = node,
                inject: ({ submit }) => ({
                  onChange: (event, newValue) =>
                    // eslint-disable-next-line
                    (isNumeric(newValue) ?
                      digitInputs.digit1.value &&
                        digitInputs.digit2.value &&
                        digitInputs.digit3.value &&
                        digitInputs.digit4.value &&
                        digitInputs.digit5.value ?
                        setTimeout(submit, 0) :
                        digitInputs.digit1.focus()
                      : null),
                }),
              },
              {
                name: 'digit1',
                component: TextField,
                normalize: normalize.toNumber,
                colSpans: { xs: 1 },
                inputProps: {
                  style: {
                    textAlign: 'center',
                    backgroundColor: '#FAFAFA',
                    height:52,
                    padding:0
                  },
                  maxLength: 1,
                  onClick: e => e.target.select(),
                  onFocus: e => e.target.select(),
                  onKeyDown: ({ key }) => key === 'Backspace' && digitInputs.digit1.value === '' && digitInputs.digit0.focus(),
                },
                // eslint-disable-next-line
                inputRef: node => digitInputs.digit1 = node,
                inject: ({ submit }) => ({
                  onChange: (event, newValue) =>
                    // eslint-disable-next-line
                    (isNumeric(newValue) ?
                      digitInputs.digit0.value &&
                        digitInputs.digit2.value &&
                        digitInputs.digit3.value &&
                        digitInputs.digit4.value &&
                        digitInputs.digit5.value ?
                        setTimeout(submit, 0) :
                        digitInputs.digit2.focus()
                      : null),
                }),
              },
              {
                name: 'digit2',
                component: TextField,
                normalize: normalize.toNumber,
                colSpans: { xs: 1 },
                inputProps: {
                  style: {
                    textAlign: 'center',
                    backgroundColor: '#FAFAFA',
                    height:52,
                    padding:0
                  },
                  maxLength: 1,
                  onClick: e => e.target.select(),
                  onFocus: e => e.target.select(),
                  onKeyDown: ({ key }) => key === 'Backspace' && digitInputs.digit2.value === '' && digitInputs.digit1.focus(),
                },
                // eslint-disable-next-line
                inputRef: node => digitInputs.digit2 = node,
                inject: ({ submit }) => ({
                  onChange: (event, newValue) =>
                    // eslint-disable-next-line
                    (isNumeric(newValue) ?
                      digitInputs.digit0.value &&
                        digitInputs.digit1.value &&
                        digitInputs.digit3.value &&
                        digitInputs.digit4.value &&
                        digitInputs.digit5.value ?
                        setTimeout(submit, 0) :
                        digitInputs.digit3.focus()
                      : null),
                }),
              },
              {
                name: 'digit3',
                component: TextField,
                normalize: normalize.toNumber,
                colSpans: { xs: 1 },
                inputProps: {
                  style: {
                    textAlign: 'center',
                    backgroundColor: '#FAFAFA',
                    height:52,
                    padding:0
                  },
                  maxLength: 1,
                  onClick: e => e.target.select(),
                  onFocus: e => e.target.select(),
                  onKeyDown: ({ key }) => key === 'Backspace' && digitInputs.digit3.value === '' && digitInputs.digit2.focus(),
                },
                // eslint-disable-next-line
                inputRef: node => digitInputs.digit3 = node,
                inject: ({ submit }) => ({
                  onChange: (event, newValue) =>
                    // eslint-disable-next-line
                    (isNumeric(newValue) ?
                      digitInputs.digit0.value &&
                        digitInputs.digit1.value &&
                        digitInputs.digit2.value &&
                        digitInputs.digit4.value &&
                        digitInputs.digit5.value ?
                        setTimeout(submit, 0) :
                        digitInputs.digit4.focus()
                      : null),
                }),
              },
              {
                name: 'digit4',
                component: TextField,
                normalize: normalize.toNumber,
                colSpans: { xs: 1 },
                inputProps: {
                  style: {
                    textAlign: 'center',
                    backgroundColor: '#FAFAFA',
                    height:52,
                    padding:0
                  },
                  maxLength: 1,
                  onClick: e => e.target.select(),
                  onFocus: e => e.target.select(),
                  onKeyDown: ({ key }) => key === 'Backspace' && digitInputs.digit4.value === '' && digitInputs.digit3.focus(),
                },
                // eslint-disable-next-line
                inputRef: node => digitInputs.digit4 = node,
                inject: ({ submit }) => ({
                  onChange: (event, newValue) =>
                    // eslint-disable-next-line
                    (isNumeric(newValue) ?
                      digitInputs.digit0.value &&
                        digitInputs.digit1.value &&
                        digitInputs.digit2.value &&
                        digitInputs.digit3.value &&
                        digitInputs.digit5.value ?
                        setTimeout(submit, 0) :
                        digitInputs.digit5.focus()
                      : null),
                }),
              },
              {
                name: 'digit5',
                component: TextField,
                normalize: normalize.toNumber,
                colSpans: { xs: 1 },
                inputProps: {
                  style: {
                    textAlign: 'center',
                    backgroundColor: '#FAFAFA',
                    height:52,
                    padding:0,
                  },
                  maxLength: 1,
                  onClick: e => e.target.select(),
                  onFocus: e => e.target.select(),
                  onKeyDown: ({ key }) => key === 'Backspace' && digitInputs.digit5.value === '' && digitInputs.digit4.focus(),
                },
                // eslint-disable-next-line
                inputRef: node => digitInputs.digit5 = node,
                inject: ({ submit }) => ({
                  onChange: (event, newValue) =>
                    isNumeric(newValue) &&
                    digitInputs.digit0.value &&
                    digitInputs.digit1.value &&
                    digitInputs.digit2.value &&
                    digitInputs.digit3.value &&
                    digitInputs.digit4.value &&
                    // NOTE: submit in the next tick so that digit's value is captured by redux-form
                    setTimeout(submit, 0),
                }),
              },
            ],
          },
        ],
      },
    ],
  },
  footer: {
    itemProps: {
      style: {
        marginTop: 40,
      },
    },
    containerProps: {
      justify: 'center',
    },
    components: [
      {
        id: uuidv4(),
        name: 'OTPInfo',
        colSpans: {
          xs: 6,
        },
        component: ({ submitting, sendOTP, onSubmit }) =>
          (
          <Grid container direction='column'>
            <Grid key={uuidv4()} item style={{ paddingBottom: '15px' }}>
              <Button type='submit' variant='contained' color='primary' className='milo-btn-submit-medium' style={{ width: '100%' }} disabled={submitting} onClick={onSubmit} >Next</Button>
            </Grid>
            <Grid key={uuidv4()} item >
              <Button variant="contained" color='primary' className='milo-btn-submit-medium-outline' style={{ width: '100%' }} disabled={submitting} onClick={sendOTP}>Resend OTP</Button>
            </Grid>
          </Grid>
          ),
        inject: ({
          submitting,
          onSendOTP: sendOTP,
          onSubmit,
        }) => ({
          submitting,
          sendOTP,
          onSubmit,
        }),
      },
    ],
  },
};

const validate = ({
  otp: {
    digit0, digit1, digit2, digit3, digit4, digit5,
  } = {},
}) => {
  const errors = { otp: {} };
  digit0 === '' && (errors.otp.digit0 = 'Oops');
  digit1 === '' && (errors.otp.digit1 = 'Oops');
  digit2 === '' && (errors.otp.digit2 = 'Oops');
  digit3 === '' && (errors.otp.digit3 = 'Oops');
  digit4 === '' && (errors.otp.digit4 = 'Oops');
  digit5 === '' && (errors.otp.digit5 = 'Oops');
  return errors;
};

const config = {
  form: 'verifyOTP',
  validate,
};

@withStyles(styles, { index: 1 })
@withReduxForm({ layout, config })
class ForgetPasswordVerify extends PureComponent {
  static propTypes = {
    /* container */
    verifyOTP: PropTypes.func.isRequired,
    sendOTP: PropTypes.func.isRequired,
    /* withStyles */
    classes: PropTypes.object,
    /* createReduxForm */
    Form: PropTypes.func.isRequired,
  };

  render() {
    const {
      classes,
      Form, initialValues, runtimeProps,
      verifyOTP, sendOTP, changePassword,
    } = this.props;


    return (
      <React.Fragment>
        <HeaderComponent isOnBoard={true}  hideComponents={{isDisclaimer:true}}/>
        <div style={{paddingTop: 50}}>
          <Back
            className={classes.back}
            // label={changePassword ? 'Back' : 'Log in'}
            backToWebsite={!changePassword}
          />
          <Grid container className={classes.pageContainer} alignItems='center'>
            <Grid item xs />
            <Grid item xs={5}>
              <Form
                autocomplete='off'
                runtimeProps={runtimeProps}
                initialValues={initialValues}
                className={classes.formContainer}
                onSubmit={verifyOTP}
                onSendOTP={sendOTP}
              />
            </Grid>
            <Grid item xs />
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default ForgetPasswordVerify;
