/* eslint-disable consistent-return */
/* eslint-disable object-curly-newline */
import React from 'react';
import moment from 'moment-timezone';
import { withStyles } from '@material-ui/styles';
const footer = require('../../../../public/hb_powered_by_int.png');

const styles = theme => ({
    container: {
        backgroundColor: 'white',
        width: '21cm',
        height: '29.7cm',
        overflow: 'hidden',
    },
    metadataTitle: {
        width: '40%',
        fontSize: 14,
    },
    metadataValue: {
        width: '60%',
        fontSize: 14,
    },
    tdHeader: {
        border: '1px solid black',
        borderCollapse: 'collapse',
        textAlign: 'left',
        fontSize: 12,
        fontWeight: 'bold',
        padding: 8,
    },
    td: {
        border: '1px solid black',
        borderCollapse: 'collapse',
        textAlign: 'left',
        fontSize: 12,
        padding: 8,
    },
    tdTotal: {
        border: '1px solid black',
        borderCollapse: 'collapse',
        fontWeight: 'bold',
        fontSize: 12,
        padding: 8,
    },
    metadataSpace: {
        height: 10,
    },
});

@withStyles(styles, { index: 1 })
class TimeSpentReportComponent extends React.Component {
    render = () => {
        const { classes } = this.props;
        return ( <
            div className = { classes.container } >
            <
            div style = {
                { padding: 20 } } > { this._header() } <
            div style = {
                { height: 20 } } > < /div> { this._table() } { this._footer() } <
            /div> <
            /div>
        );
    }

    _header = () => {
        const { reportMetaData, classes } = this.props;
        if (!reportMetaData) return;
        return ( <
            div style = {
                {
                    width: '100%',
                }
            } >
            <
            div style = {
                { display: 'flex', justifyContent: 'center', alignItems: 'center' } } >
            <
            img src = { reportMetaData.hospitalLogo }
            style = {
                { width: 110, height: 110, marginTop: 12, marginBottom: 12 } }
            /> <
            /div> <
            div style = {
                { width: '100%', textAlign: 'center', fontSize: 20, fontWeight: 'bold', fontFamily: 'Roboto', marginTop: 12, marginBottom: 12 } } >
            Time Spent Report <
            /div> <
            table style = {
                { width: '100%' } } >
            <
            tr >
            <
            td className = { classes.metadataTitle } > Patient Name < /td> <
            td className = { classes.metadataValue } >: { reportMetaData.patient } < /td> <
            /tr> <
            tr >
            <
            td className = { classes.metadataTitle } > Patient ID and ID No. < /td> <
            td className = { classes.metadataValue } >: { reportMetaData.idType } { reportMetaData.idNo } < /td> <
            /tr> <
            tr >
            <
            td className = { classes.metadataSpace } > < /td> <
            td className = { classes.metadataSpace } > < /td> <
            /tr> <
            tr >
            <
            td className = { classes.metadataTitle } > Provider Name < /td> <
            td className = { classes.metadataValue } >: { reportMetaData.provider } < /td> <
            /tr> <
            tr >
            <
            td className = { classes.metadataTitle } > Hospital / Clinic Name < /td> <
            td className = { classes.metadataValue } >: { reportMetaData.hospital } < /td> <
            /tr> <
            tr >
            <
            td className = { classes.metadataTitle } > Program Name < /td> <
            td className = { classes.metadataValue } >: { reportMetaData.program } < /td> <
            /tr> <
            tr >
            <
            td className = { classes.metadataTitle } > Period of Report(Date) < /td> <
            td className = { classes.metadataValue } >: { reportMetaData.periodReport } < /td> <
            /tr> <
            tr >
            <
            td className = { classes.metadataSpace } > < /td> <
            td className = { classes.metadataSpace } > < /td> <
            /tr> <
            tr >
            <
            td className = { classes.metadataTitle } > Date and Time of Report < /td> <
            td className = { classes.metadataValue } >: { reportMetaData.dateOfReport } < /td> <
            /tr> <
            tr >
            <
            td className = { classes.metadataTitle } > Downloaded By < /td> <
            td className = { classes.metadataValue } >: { reportMetaData.downloadedBy } < /td> <
            /tr> <
            /table>

            <
            /div>
        );
    }

    _table = () => {
        const { notes, classes, pages, totalPages } = this.props;
        return ( <
            div style = {
                { height: '609px' } } >
            <
            table style = {
                { borderCollapse: 'collapse', width: '100%' } } >
            <
            thead >
            <
            td className = { classes.tdHeader } > Start Date / Time < /td> <
            td className = { classes.tdHeader } > End Date / Time < /td> <
            td className = { classes.tdHeader } > Name of Provider < /td> <
            td className = { classes.tdHeader } > Category < /td> <
            td className = { classes.tdHeader } > Duration < /td> <
            /thead> {
                notes.map(n => {
                    return this._tableCell(n);
                })
            } {
                this._totalCell()
            } <
            /table> <
            /div>
        );
    }

    _totalCell = () => {
        const { classes, totalHours, totalMinutes, page, totalPages } = this.props;
        if (+page !== +totalPages) {
            return;
        }
        return ( <
            tr >
            <
            td className = { classes.td }
            style = {
                { borderRight: 'none' } } >
            <
            /td> <
            td className = { classes.td }
            style = {
                { borderLeft: 'none', borderRight: 'none' } } >
            <
            /td> <
            td className = { classes.td }
            style = {
                { borderLeft: 'none', borderRight: 'none' } } >
            <
            /td> <
            td className = { classes.tdTotal }
            style = {
                { borderRight: 'none', borderLeft: 'none' } } >
            Total Time Spent:
            <
            /td> <
            td className = { classes.tdTotal }
            style = {
                { borderLeft: 'none' } } > { totalHours ? `${totalHours} hrs` : '' } { totalMinutes ? `${totalMinutes} mins` : '' } <
            /td> <
            /tr>
        );
    }

    _tableCell = (data) => {
        const { classes, reportMetaData } = this.props;
        return ( <
            tr >
            <
            td className = { classes.td } > { moment(data.startTime).tz(reportMetaData.timezone).format('DD MMM YYYY, HH:mm') } <
            /td> <
            td className = { classes.td } > { moment(data.endTime).tz(reportMetaData.timezone).format('DD MMM YYYY, HH:mm') } <
            /td> <
            td className = { classes.td } > { reportMetaData.provider } < /td> <
            td className = { classes.td } > { data.category } < /td> <
            td className = { classes.td } > { data.duration } < /td> <
            /tr>
        );
    }

    _footer = () => {
        const { page, totalPages } = this.props;
        return ( <
            div >
            <
            div style = {
                { flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' } } >
            <
            div style = {
                { flex: 1 } } >
            <
            img src = { footer }
            style = {
                { width: '50%', float: 'right', marginRight: '-30px' } }
            /> <
            /div> <
            div style = {
                { flex: 1 } } >
            <
            span style = {
                { float: 'right', fontSize: 12 } } > Page { page }
            of { totalPages } < /span> <
            /div> <
            /div> <
            /div>
        );
    }
}

export default TimeSpentReportComponent;