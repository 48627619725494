import React from 'react';
import CollapsibleCardWithStatusChartVitals from '../../component/ReportData/CollapsibleCardWithStatusChartVitals';
import { withStyles } from '@material-ui/styles';
import * as _ from 'lodash';
import ReportPage from 'appModule/reporting/component/ReportPDF/ReportPage';
import { VITAL_NAME_MAPPING } from 'dataModule/constants/vital';
import ReportDataViewTable from 'appModule/reporting/component/ReportPDF/ReportDataView/ReportDataViewTable';
import ReportDataViewTableHeader from 'appModule/reporting/component/ReportPDF/ReportDataView/ReportDataViewTableHeader';
import ReportDataViewTableCell from 'appModule/reporting/component/ReportPDF/ReportDataView/ReportDataViewTableCell';

const styles = {
  collapsibleCardGrid: {
    backgroundColor: '#212121',
    fontSize: '24px',
  },
  description: {
    marginTop: '5px',
    fontSize: '12px',
    letterSpacing: '0.08px',
    marginBottom: '5px',
    color: '#212121',
  },
  paper: {
    borderRadius: '5px',
    width: '820px',
  },
  patientProgramLabel: {
    fontSize: '12px',
    color: '#9E9E9E',
  },
  patientProgramGrid: {
    cursor: 'pointer',
    width: '9px',
    height: '15px',
    float: 'right',
    borderRadius: '14px',
    opacity: 1,
    marginRight: '3px',
  },
  patientProgramGrid2: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  patientProgramGridContent: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  patientProgramDetails: {
    fontSize: '14px',
  },
  reportInformationTitle1: {
    textAlign: 'right',
    fontSize: '12px',
    color: '#f44336',
  },
  reportInformationTitle2: {
    textAlign: 'right',
    fontSize: '12px',
    color: '#9e9e9e',
    float: 'right',
  },
  reportInformationPickerGrid: {
    justifyContent: 'center',
    alignContent: 'center',
    width: '100%',
  },
  advancePropsTypograph: {
    height: '100px',
    alignItems: 'center',
    display: 'flex',
  },
  cardLabel: {
    fontFamily: 'Roboto',
    fontWeight: 'medium',
    fontSize: '12px',
    color: '#FFFFFF',
    paddingTop: 16,
  },
  cardDetail: {
    fontFamily: 'Roboto',
    fontSize: '21px',
    color: '#FFFFFF',
  },
  vitalDiv: {
    borderLeft: '1px solid',
    height: '100px',
    marginTop: 3,
  },
  careTaskLegend: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '12px',
  },
  careTaskLegendCategoryDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  careTaskLegendCategoryLabel: {
    color: '#212121',
    fontWeight: '400',
    fontSize: '14px',
    marginLeft: '6px',
  },
  careTaskSummaryCardPaper: {
    display: 'flex',
    borderRadius: 3,
    marginLeft: 16,
    marginRight: 16,
    marginBottom: 20,
    boxShadow: 'none',
  },
  careTaskInfoTyphograph: {
    fontSize: '12px',
  },
  careTaskInfoGrid: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '10px',
  },
  careTaskInfoGrid2: {
    justifyContent: 'space-between',
  },
  careTaskToolTipCard: {
    height: 'auto',
    width: '275px',
    marginBottom: '10px',
    marginRight: '10px',
    padding: '12px',
    borderRadius: '8px',
  },
  selectionGridThreshold: {
    cursor: 'pointer',
    width: '9px',
    height: '15px',
    float: 'right',
    borderRadius: '14px',
    opacity: 1,
    marginRight: '3px',
  },
  refreshData: {
    fontSize: '12px',
    color: '#FFFFFF',
  },
  fabBtn: {
    height: '10px',
    width: '38px',
    float: 'right',
    backgroundColor: 'white',
    boxShadow: 'none',
  },
  iconClose: {
    color: '#4c4b4b',
    fontSize: '20px',
  },
  menuItemTypograph: {
    paddingLeft: '10px',
    textAlign: 'left',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0px',
    color: '#474747',
    opacity: 1,
  },
  menuItemTypographData: {
    paddingLeft: '10px',
    textAlign: 'left',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0px',
    color: '#474747',
    opacity: 1,
  },
  dataViewSelect: {
    paddingLeft: '16px',
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  paperBoxShadow: {
    boxShadow: '0px 0px 0px 1px rgb(0 0 0 / 10%), 0px 1px 1px 1px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%)',
  },
  statusInfoBoxShadow: {
    boxShadow: '0px 2px 11px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
  avatarTitle: {
    fontSize: '24px',
  },
  dialogAction: {
    justifyContent: 'space-between',
    paddingLeft: '25px',
    paddingRight: '25px',
    paddingBottom: '25px',
  },
  vitalCardTooltip: {
    height: 'auto',
    width: '275px',
    marginBottom: '10px',
    marginRight: '10px',
    padding: '12px',
    borderRadius: '8px',
  },
  container: {
    backgroundColor: 'white',
    width: '21cm',
    height: '29.7cm',
    overflow: 'hidden',
  },
  metadataTitle: {
    width: '40%',
    fontSize: 14,
  },
  metadataValue: {
    width: '60%',
    fontSize: 14,
  },
  tdHeader: {
    border: '1px solid black',
    borderCollapse: 'collapse',
    textAlign: 'left',
    fontSize: 12,
    fontWeight: 'bold',
    padding: 8,
  },
  td: {
    border: '1px solid black',
    borderCollapse: 'collapse',
    textAlign: 'left',
    fontSize: 12,
    padding: 8,
  },
  tdTotal: {
    border: '1px solid black',
    borderCollapse: 'collapse',
    fontWeight: 'bold',
    fontSize: 12,
    padding: 8,
  },
  metadataSpace: {
    height: 10,
  },
  dataViewTitle: {
    padding: '15px',
    backgroundColor: 'black',
    color: 'white',
    fontSize: '18px',
  },
};

@withStyles(styles, { index: 1 })
class ReportingPDF extends React.Component {
  state = {
    vitalsData: {
      key: null,
      summaryData: {
        status: true,
        totalTasks: 0,
        criticallyLow: 0,
        low: 0,
        criticallyHigh: 0,
        high: 0,
        missed: 0,
        normal: 0,
        average: {
          measurementAverage: null,
          unit: '',
        },
        type: 'OVERALL',
      },
      enrolledProgramData: [],
      chartData: [],
      loading: false,
      selectedData: null,
      selectedEnrolledProgramme: [],
      selectedView: 'LINE_CHART',
      dataView: [],
    },
    selectedEnrolledProgram: [],
    modules: [],
    informationData: {
      title: '',
      startDate: '',
      endDate: '',
      updatedAt: null,
    },
    loading: true,
    reportData: null,
    selectedView: 'LINE_CHART',
  };

  async componentDidMount() {
    await this.getReportData();
  }

  getReportData() {
    const { API_URL } = window.MILO_CONFIG;
    const { reportId, 'session-token': sessionToken, vitalType } = this.props.location.query;
    fetch(`${API_URL}/reports/${reportId}?session-token=${sessionToken}`)
      .then((res) => res.json())
      .then((json) => json.value)
      .then((response) => {
        const { report } = response;

        const module = _.find(report.modules, { key: 'vitals' });
        const component = _.find(module.components, { key: vitalType });
        const careTaskTypes = _.find(module.filters, { key: 'type_of_care_task' });
        const thresholdTypes = _.find(module.filters, { key: 'threshold_type' });
        const timeTaken = _.find(module.filters, { key: 'time_taken' });

        const modules = report.modules.map((module) => {
          return {
            ...module,
            components: module.components.map((component) => {
              return {
                checked: true,
                key: component.key,
                loading: false,
                name: component.name,
                chartType: component.chartType,
              };
            }),
          };
        });

        this.setState(
          {
            informationData: {
              ...this.state.informationData,
              title: report.name,
              startDate: report.dateStart,
              endDate: report.dateEnd,
              updatedAt: report.updatedAt,
            },
            modules,
            reportData: report,
            module,
            component,
            careTaskTypes,
            thresholdTypes,
            timeTaken,
            selectedView: component.chartType,
          },
          async () => {
            await this.getEnrolledPrograms();
          },
        );
      });
  }

  getEnrolledPrograms = async () => {
    const { API_URL } = window.MILO_CONFIG;
    const { 'session-token': sessionToken, vitalType } = this.props.location.query;
    const { reportData } = this.state;

    fetch(`${API_URL}/reports/patient/overall/enrolledPrograms`, {
      method: 'POST',
      headers: {
        'session-token': sessionToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        dateStart: reportData.dateStart,
        dateEnd: reportData.dateEnd,
        returnSelectedEps: 'true',
        selectedEpIds: reportData.enrolledPrograms,
        page_no: 1,
        page_size: 10,
        timezone: 'Asia/Singapore',
      }),
    })
      .then((res) => res.json())
      .then((json) => json.value)
      .then((response) => {
        const { thresholdEpId } = this.state.component;
        const { selectedEnrolledPrograms = [] } = response;
        const selectedData = selectedEnrolledPrograms.find((ep) => ep.id == thresholdEpId);
        this.setState(
          {
            selectedEnrolledProgram: selectedEnrolledPrograms,
            vitalsData: {
              ...this.state.vitalsData,
              key: vitalType,
              selectedData,
              selectedEnrolledProgramme: selectedEnrolledPrograms,
            },
          },
          async () => {
            await this.getSummary();
          },
        );
      });
  };

  getSummary = async () => {
    const { 'session-token': sessionToken, vitalType } = this.props.location.query;
    const { reportData, careTaskTypes, thresholdTypes, timeTaken } = this.state;

    const { API_URL } = window.MILO_CONFIG;
    fetch(`${API_URL}/reports/provider/patient/overall/vitals/summary`, {
      method: 'POST',
      headers: {
        'session-token': sessionToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        dateStart: reportData.dateStart,
        dateEnd: reportData.dateEnd,
        epIds: reportData.enrolledPrograms,
        patientIds: reportData.patient.ids,
        thresholdTypes: thresholdTypes.selected,
        careTaskTypes: careTaskTypes.selected,
        timeTaken: timeTaken.selected,
        vitalType: vitalType,
      }),
    })
      .then((res) => res.json())
      .then((json) => json.value)
      .then((summaryData) => {
        this.setState(
          {
            vitalsData: {
              ...this.state.vitalsData,
              summaryData,
            },
            loading: false,
          },
          async () => await this.getChartData(),
        );
      });
  };

  getChartData = async () => {
    const { 'session-token': sessionToken, vitalType } = this.props.location.query;
    const { API_URL } = window.MILO_CONFIG;
    const { thresholdEpId } = this.state.component;
    const { selectedEnrolledProgram, reportData, careTaskTypes, thresholdTypes, timeTaken, selectedView } = this.state;
    const ep = _.find(selectedEnrolledProgram, { id: +thresholdEpId });

    let vitalThreshold = null;
    ep.activities.map((actv) => {
      const { threshold, vitalsDataForActivity } = actv;
      const vitalDefNames = vitalsDataForActivity.vitalDefs.map((vdad) => vdad.vitalDef.name);
      if (vitalDefNames.indexOf(vitalType) != -1) {
        vitalThreshold = threshold;
      }
    });

    let encodedThreshold = vitalThreshold;

    if (vitalType === 'BG') {
      encodedThreshold = {
        bg_pre: vitalThreshold.bg_pre.range,
        bg_post: vitalThreshold.bg_post.range,
      };
    }

    fetch(`${API_URL}/reports/provider/patient/overall/vitals/chart`, {
      method: 'POST',
      headers: {
        'session-token': sessionToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        dateStart: reportData.dateStart,
        dateEnd: reportData.dateEnd,
        epIds: reportData.enrolledPrograms,
        patientIds: reportData.patient.ids,
        thresholdTypes: thresholdTypes.selected,
        careTaskTypes: careTaskTypes.selected,
        threshold: encodedThreshold,
        timeTaken: timeTaken.selected,
        vitalType: vitalType,
        chartType: selectedView,
      }),
    })
      .then((res) => res.json())
      .then((json) => json.value)
      .then((chartData) => {
        this.setState(
          {
            vitalsData: {
              ...this.state.vitalsData,
              chartData: chartData.lineChartData || chartData.dataView,
            },
            loading: false,
          },
          () => console.log(this.state),
        );
      });
  };

  renderLineChart = () => {
    const { classes, isEmbedded } = this.props;
    const { page = 1, totalPages = 10 } = this.props.location.query;
    return (
      <ReportPage isEmbedded={isEmbedded} page={page} totalPages={totalPages}>
        <CollapsibleCardWithStatusChartVitals
          isEmbedded={isEmbedded}
          mode={'view'}
          pdf={true}
          status={'pristine'}
          title="Vitals"
          step={3}
          classes={classes}
          data={[this.state.vitalsData]}
          selectedEnrolledProgram={this.state.selectedEnrolledProgram}
          modules={this.state.modules}
          refresh={null}
          informationData={this.state.informationData}
          changeVitalViewType={null}
          getLatestOTT={null}
        />
      </ReportPage>
    );
  };

  renderDataView = () => {
    const { vitalType } = this.props.location.query;
    const { isEmbedded } = this.props;
    const { page = 1, totalPages = 10 } = this.props.location.query;
    const dataViewTitle = VITAL_NAME_MAPPING[vitalType];
    const titles = ['Month', 'Measurement Average'];
    const tableData = this.state.vitalsData.chartData.map((data) => {
      const measurementAverageCheck =
        data.average.measurementAverage === 'N.A.' ? 'N.A.' : `${data.average.measurementAverage} ${data.average.unit}`;
      const bpMeasurementAverageCheck =
        data.average.sysAverage && data.average.diaAverage === 'N.A.'
          ? 'N.A./N.A.'
          : `${data.average.sysAverage}/${data.average.diaAverage} ${data.average.unit}`;
      return [data.reportDate.display, vitalType === 'BP' ? bpMeasurementAverageCheck : measurementAverageCheck];
    });
    return (
      <ReportPage isEmbedded={isEmbedded} page={page} totalPages={totalPages}>
        <ReportDataViewTable dataViewTitle={dataViewTitle}>
          <ReportDataViewTableHeader titles={titles} />
          <ReportDataViewTableCell tableData={tableData} />
        </ReportDataViewTable>
      </ReportPage>
    );
  };

  render = () => {
    const { selectedView, loading } = this.state;
    if (loading) return <div>Loading...</div>;

    if (!selectedView) return null;
    if (selectedView === 'LINE_CHART') {
      return this.renderLineChart();
    }

    if (selectedView == 'DATA_VIEW') {
      return this.renderDataView();
    }

    return 'no';
  };
}

export default ReportingPDF;
