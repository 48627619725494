import React from 'react';
import CryptoJS from 'crypto-js';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { withResource, resourceTypes } from 'dataModule/store/resources';
import { actionCreators as messageActionCreators } from 'appModule/message/ducks/message';
import { Loading } from 'utilsModule/components';
import SetNewPassword from '../components/SetNewPassword';
import i from 'jspdf';

@withResource([{ resourceType: resourceTypes.USERS }])
@connect(null, { notify: messageActionCreators.show })
@withRouter
class SetNewPasswordContainer extends React.Component {
  static propTypes = {
    actionCreators: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  shouldComponentUpdate(nextProps) {
    return !nextProps.data.status.loading;
  }

  handleCreate = ({ encryptPassword }) => {
    const {
      actionCreators,
      notify,
      location: {
        state: {
          changePassword = false,
        } = {},
      } = {},
    } = this.props;
    const verifyPhone = sessionStorage.getItem('verifyPhone');

    // Encrypt the password before sending to the BE
    const cipherText = CryptoJS.AES.encrypt(encryptPassword, '@2ecret4uth2907!');
    const password = cipherText.toString();

    return new Promise((resolve, reject) =>
      actionCreators[resourceTypes.USERS].ajax({
        cargo: { method: 'resetPassword', input: { content: { phone: verifyPhone, password, isChangePassword: changePassword } } },
        onSuccess: ({ data: registerAuthData }) => {
          if (this.mounted) {
            sessionStorage.clear();
            resolve(registerAuthData);

            // if (changePassword) {
              // notify({ message: 'Your password has been updated successfully and you will be logged out automatically. Please use your new password to login again.', type: 'success' });
            // } else {
              notify({ message: 'Account password has been updated successfully.', type: 'success', customClass : ['expanded'] });
            // }
            setTimeout(() => {
              window.location.href = 'https://www.healthbeats.co';
            }, 3000);
          }
        },
        onFailure: (error) => {
          if (this.mounted) {
            const { error: { message, code } } = error;
            if (code == '2000105') {
              reject(new SubmissionError({
                encryptPassword: message,
                confirmPassword: message,
                _error: 'New password is already used before.',
              }));
              notify({ message: 'The New Password you entered has been used before. You are allowed to reuse a password only if it is beyond the 5th change in your password history. Please choose a different password.', type: 'error', customClass : ['expanded'] });
            } else if (code == '2000106') {
              reject(new SubmissionError({
                encryptPassword: message,
                confirmPassword: message,
                _error: 'New password is the same as current password.',
              }));
              notify({
                message:
                  'The New Password you have entered cannot be the same as your current password. Please choose a different password.',
                type: 'error',
                customClass : ['expanded']
              });
            } else {
              reject(new SubmissionError({
                _error: message,
              }));
              notify({
                message: (!_.isEmpty(message)) ? message : 'Failed',
                type: 'error'
              });
            }
          }
        },
      }));
  }

  render() {
    const { data, location: { state: { changePassword = false } = {} } = {} } = this.props;

    // First render
    if (data.status.loading) return <Loading />;

    if (!this.inited) {
      this.initialValues = {};

      this.runtimeProps = {
        header: {
          banner: {
            title: 'Set New Password',
            subtitle1: 'Setup a new password for your HealthBeats® account.',
            subtitle2: 'Password should have at least 8 characters, include at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 symbol.',
          },
        },
      };
      this.inited = true;
    }

    return (
      <SetNewPassword
        initialValues={this.initialValues}
        runtimeProps={this.runtimeProps}
        handleCreate={this.handleCreate}
        handleResend={this.handleResend}
        changePassword={changePassword}
      />
    );
  }
}

export default SetNewPasswordContainer;
