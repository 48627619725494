/* eslint-disable */
/* Constants */

/* Action Creators */

/* Reducer */

/* Libs */

/*
**************************************************
  Action Types
**************************************************
*/
const prefix = "program/enroll/vitals/selectBar";

const actionTypes = {
  VIEW_VITAL: Symbol(`${prefix}/VIEW_VITAL`),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  viewVital: payload => ({ type: actionTypes.VIEW_VITAL, payload }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/
const epics = [];

export { epics };

/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
  active: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.VIEW_VITAL: {
      const { vital } = action.payload;
      return { ...state, active: vital };
    }
    default: {
      return state;
    }
  }
};
