/* eslint-disable */
import get from 'lodash/get';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/catch';

/* Libs */
import DM from 'dataModule';
import { push } from 'react-router-redux';
/*
**************************************************
  Action Types
**************************************************
*/
const prefix = 'SKULOWSTOCKTHRESHOLD';

const actionTypes = {
  LIST: Symbol(`${prefix}/LIST`),
  LIST_SUCCESS: Symbol(`${prefix}/LIST_SUCCESS`),

  EDIT: Symbol(`${prefix}/EDIT`),
  EDIT_SUCCESS: Symbol(`${prefix}/EDIT_SUCCESS`),
  EDIT_FAILURE: Symbol(`${prefix}/EDIT_FAILURE`),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  listSkuLowStockThreshold: payload => ({ type: actionTypes.LIST, payload }),
  listSkuLowStockThresholdSuccess: payload => ({ type: actionTypes.LIST_SUCCESS, payload }),

  editSkuLowStockThreshold: payload => ({ type: actionTypes.EDIT, payload }),
  editSuccess: payload => ({ type: actionTypes.EDIT_SUCCESS, payload }),
  editFailure: payload => ({ type: actionTypes.EDIT_FAILURE, payload }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/
const epics = [
  // List all SKUs of a particular warehouse/clinic
  action$ => action$.ofType(actionTypes.LIST)
    .mergeMap(({ payload }) =>
      Observable.fromPromise(DM.skuLowStockThreshold.listSkuLowStockThreshold(payload))
        .map(res => {
          return actionCreators.listSkuLowStockThresholdSuccess({
            rows: get(res, 'value.skus', []),
          });
        })
        .catch(error => {
          return actionCreators.listSkuLowStockThresholdSuccess({ rows: [], error });
        }),
    ),

  // create or edit sku low stock threshold
  action$ => action$.ofType(actionTypes.EDIT)
    .mergeMap(({ payload: { info, onEditSuccess, onEditFailure } }) => {
      return Observable.fromPromise(DM.skuLowStockThreshold.editSkuLowStockThreshold(info))
        .map((res) => {
          onEditSuccess(res.value);
          return actionCreators.editSuccess({ res });
        })
        .catch((error) => {
          onEditFailure(error);
          return Observable.of(actionCreators.editFailure({ error: error.message }));
        });
    }),
];

export { epics };

/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
  list: {
    rows: [],
  },
  edit: {
    info: null,
    result: {
      ok: null,
      info: null,
      error: null,
    },
  },
};

export default (state = initState, action) => {
  switch (action.type) {

    case actionTypes.LIST: {
      return { ...state, list: { rows: [] } };
    }
    case actionTypes.LIST_SUCCESS: {
      const { rows } = action.payload;
      return { ...state, list: { rows } };
    }

    case actionTypes.EDIT: {
      const { info } = action.payload;
      return { ...state, edit: { info } };
    }
    case actionTypes.EDIT_SUCCESS: {
      const { info } = action.payload;
      return { ...state, edit: { result: { ok: true, info } } };
    }
    case actionTypes.EDIT_FAILURE: {
      const { error } = action.payload;
      return { ...state, edit: { result: { ok: false, error } } };
    }

    default: {
      return state;
    }
  }
};
