import { combineReducers } from 'redux';
import skuLowStockThreshold, { epics as skuLowStockThresholdEpics } from './skuLowStockThreshold';
import deviceWarehouseList, { epics as deviceWarehouseListEpics } from './deviceWarehouseList';
import consumableWarehouseList, { epics as consumableWarehouseListEpics } from './consumableWarehouseList';
import deviceAvailableList, { epics as deviceAvailableListEpics } from './deviceAvailableList';

export const epics = [
  ...skuLowStockThresholdEpics,
  ...deviceWarehouseListEpics,
  ...consumableWarehouseListEpics,
  ...deviceAvailableListEpics,
];

export default combineReducers({
  skuLowStockThreshold,
  deviceWarehouseList,
  consumableWarehouseList,
  deviceAvailableList,
});
