/* eslint-disable max-len */
import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import BrandLogo from 'appModule/authLayout/components/BrandLogo';
import { ButtonBase } from '@material-ui/core';
import { getters as usersGetters } from 'dataModule/store/resources/users';
import { ROLES, ORG_TYPES } from 'dataModule/constants/profile';
import { PersonOutline } from 'utilsModule/material-ui-icons';
import globalSidebar from 'utilsModule/globalSidebar';
import uuidv4 from 'uuid/v4';

const superAdminMenu = globalSidebar.superAdminMenus;
const groupAdminMenu = globalSidebar.groupAdminMenus;
const clusterAdminMenu = globalSidebar.clusterAdminMenus;
const hospitalAdminMenu = globalSidebar.hospitalAdminMenus;
const providerMenu = globalSidebar.providerMenus;

@connect(state => ({
  uiPermission: usersGetters.getUiPermission({ root: true })(state),
  role: usersGetters.getLoggedInRole({ root: true })(state),
  organisation: usersGetters.getLoggedInOrganization({ root: true })(state),
  groupOrganisation: usersGetters.getLoggedInGroupOrganization({ root: true })(state),
}))
export default class HomeButton extends React.Component {
  onClick(path) {
    const { history } = this.props;
    history.push(path);
  }

  render() {
    const {
      role,
      organisation: { type = '' } = {},
      uiPermission = [],
      groupOrganisation: { isCovidOrg },
    } = this.props;
    const getSidebarAccess = uiPermission.filter(i => i.category === 'SIDEBAR' && i.access === 'ALLOW');

    let curMenu = [];

    const isCovidMenu = isCovidOrg
      ? providerMenu.concat({
        id: uuidv4(),
        icon: <PersonOutline />,
        label: 'Discharge',
        path: 'discharge-covid',
        resource: 'DISCHARGE',
      })
      : providerMenu;

    switch (true) {
      case role === ROLES.SUPERADMIN: curMenu = superAdminMenu; break;
      case role === ROLES.ADMIN && type === ORG_TYPES.GROUP: curMenu = groupAdminMenu; break;
      case role === ROLES.ADMIN && type === ORG_TYPES.CLUSTER: curMenu = clusterAdminMenu; break;
      case role === ROLES.ADMIN && type === ORG_TYPES.HOSPITAL_CLINIC: curMenu = hospitalAdminMenu; break;
      case [ROLES.DOCTOR, ROLES.NURSE, ROLES.CARE_MANAGER, ROLES.PHYSIOTHERAPIST, ROLES.DIETICIAN, ROLES.OTHERS].includes(role): curMenu = isCovidMenu; break;
      default: curMenu = [];
    }

    if (role !== ROLES.SUPERADMIN) curMenu = curMenu.filter(i => getSidebarAccess.map(a => a.resource).includes(i.resource) || i.path === 'discharge-covid');
    const homePath = R.pathOr('', [0, 'path'], curMenu);
    const path = `/app/wsb/${homePath}`;

    return (
      <ButtonBase onClick={() => this.onClick(path)} disableRipple>
        <BrandLogo width={180} height={60} />
      </ButtonBase>
    );
  }
}
