import React from 'react';
import { withResource, resourceTypes } from 'dataModule/store/resources';
import { connect } from 'react-redux';
import { fetchGoThumbSignedUrl } from 'utilsModule/gothumb';
import * as R from 'ramda';
import AdherenceReportComponent from '../components/AdherenceReport';
import AvatarImg from 'dataModule/assets/img/avatar.png';

@withResource([
  {
    resourceType: resourceTypes.PROGRAMS,
    method: 'getAdherencePdfDetail',
  },
])
@connect((state) => ({
  currentOrganization: R.path(['resources', 'users', 'retrieveCurrent', 'organization'], state),
}))
class AdherenceReportContainer extends React.Component {
  state = {
    adherences: [],
    reportMetaData: null,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const {
      actionCreators,
      location: {
        query: { take, page, providerId, month, year, currentDate, 'session-token': sessionToken },
      },
    } = this.props;
    actionCreators[resourceTypes.PROGRAMS].ajax({
      cargo: {
        method: 'getAdherencePdfDetail',
        input: {
          params: {
            queries: [
              { name: 'take', value: take },
              { name: 'page', value: page },
              { name: 'month', value: month },
              { name: 'year', value: year },
              { name: 'providerId', value: providerId },
              { name: 'session-token', value: sessionToken },
            ],
          },
        },
      },
      onSuccess: async (data) => {
        const {
          data: {
            getAdherencePdfDetail: { value },
          },
        } = data;
        if (value.adherences) {
          const { adherences, provider } = value;
          provider.reportDate = currentDate;
          let goThumbUrl = AvatarImg;
          if (provider.hospitalLogo) {
            try {
              goThumbUrl = await fetchGoThumbSignedUrl(provider.hospitalLogo, sessionToken);
            } catch (err) {
              console.log(err);
            }
          }
          this.setState({
            adherences,
            reportMetaData: {
              ...provider,
              hospitalLogo: goThumbUrl,
            },
          });
        }
      },
      onFailure: ({ error }) => {
        console.log(error);
      },
    });
  };

  render = () => {
    const {
      location: {
        query: { take, page, totalPages },
      },
    } = this.props;
    const { adherences, reportMetaData } = this.state;
    const pageStartFrom = page * take - take + 1;
    return (
      <AdherenceReportComponent
        adherences={adherences}
        reportMetaData={reportMetaData}
        page={page}
        totalPages={totalPages}
        pageStartFrom={pageStartFrom}
      />
    );
  };
}

export default AdherenceReportContainer;
