import React from 'react';
import CollapsibleCardWithStatusChartAlerts from '../../component/ReportData/CollapsibleCardWithStatusChartAlerts';
import { withStyles } from '@material-ui/styles';
import ReportPage from 'appModule/reporting/component/ReportPDF/ReportPage';
import ReportDataViewTable from 'appModule/reporting/component/ReportPDF/ReportDataView/ReportDataViewTable';
import ReportDataViewTableHeader from 'appModule/reporting/component/ReportPDF/ReportDataView/ReportDataViewTableHeader';
import ReportDataViewTableCell from 'appModule/reporting/component/ReportPDF/ReportDataView/ReportDataViewTableCell';

const styles = {
  collapsibleCardGrid: {
    backgroundColor: '#212121',
    fontSize: '24px',
  },
  description: {
    marginTop: '5px',
    fontSize: '12px',
    letterSpacing: '0.08px',
    marginBottom: '5px',
    color: '#212121',
  },
  paper: {
    borderRadius: '5px',
    width: '820px',
  },
  patientProgramLabel: {
    fontSize: '12px',
    color: '#9E9E9E',
  },
  patientProgramGrid: {
    cursor: 'pointer',
    width: '9px',
    height: '15px',
    float: 'right',
    borderRadius: '14px',
    opacity: 1,
    marginRight: '3px',
  },
  patientProgramGrid2: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  patientProgramGridContent: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  patientProgramDetails: {
    fontSize: '14px',
  },
  reportInformationTitle1: {
    textAlign: 'right',
    fontSize: '12px',
    color: '#f44336',
  },
  reportInformationTitle2: {
    textAlign: 'right',
    fontSize: '12px',
    color: '#9e9e9e',
    float: 'right',
  },
  reportInformationPickerGrid: {
    justifyContent: 'center',
    alignContent: 'center',
    width: '100%',
  },
  advancePropsTypograph: {
    height: '100px',
    alignItems: 'center',
    display: 'flex',
  },
  cardLabel: {
    fontFamily: 'Roboto',
    fontWeight: 'medium',
    fontSize: '12px',
    color: '#FFFFFF',
    paddingTop: 16,
  },
  cardDetail: {
    fontFamily: 'Roboto',
    fontSize: '21px',
    color: '#FFFFFF',
  },
  vitalDiv: {
    borderLeft: '1px solid',
    height: '100px',
    marginTop: 3,
  },
  careTaskLegend: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '12px',
  },
  careTaskLegendCategoryDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  careTaskLegendCategoryLabel: {
    color: '#212121',
    fontWeight: '400',
    fontSize: '14px',
    marginLeft: '6px',
  },
  careTaskSummaryCardPaper: {
    display: 'flex',
    borderRadius: 3,
    marginLeft: 16,
    marginRight: 16,
    marginBottom: 20,
    boxShadow: 'none',
  },
  careTaskInfoTyphograph: {
    fontSize: '12px',
  },
  careTaskInfoGrid: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '10px',
  },
  careTaskInfoGrid2: {
    justifyContent: 'space-between',
  },
  careTaskToolTipCard: {
    height: 'auto',
    width: '275px',
    marginBottom: '10px',
    marginRight: '10px',
    padding: '12px',
    borderRadius: '8px',
  },
  selectionGridThreshold: {
    cursor: 'pointer',
    width: '9px',
    height: '15px',
    float: 'right',
    borderRadius: '14px',
    opacity: 1,
    marginRight: '3px',
  },
  refreshData: {
    fontSize: '12px',
    color: '#FFFFFF',
  },
  fabBtn: {
    height: '10px',
    width: '38px',
    float: 'right',
    backgroundColor: 'white',
    boxShadow: 'none',
  },
  iconClose: {
    color: '#4c4b4b',
    fontSize: '20px',
  },
  menuItemTypograph: {
    paddingLeft: '10px',
    textAlign: 'left',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0px',
    color: '#474747',
    opacity: 1,
  },
  menuItemTypographData: {
    paddingLeft: '10px',
    textAlign: 'left',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0px',
    color: '#474747',
    opacity: 1,
  },
  dataViewSelect: {
    paddingLeft: '16px',
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  paperBoxShadow: {
    boxShadow: '0px 0px 0px 1px rgb(0 0 0 / 10%), 0px 1px 1px 1px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%)',
  },
  statusInfoBoxShadow: {
    boxShadow: '0px 2px 11px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
  avatarTitle: {
    fontSize: '24px',
  },
  dialogAction: {
    justifyContent: 'space-between',
    paddingLeft: '25px',
    paddingRight: '25px',
    paddingBottom: '25px',
  },
  vitalCardTooltip: {
    height: 'auto',
    width: '275px',
    marginBottom: '10px',
    marginRight: '10px',
    padding: '12px',
    borderRadius: '8px',
  },
  container: {
    backgroundColor: 'white',
    overflow: 'hidden',
  },
  metadataTitle: {
    width: '40%',
    fontSize: 14,
  },
  metadataValue: {
    width: '60%',
    fontSize: 14,
  },
  tdHeader: {
    border: '1px solid black',
    borderCollapse: 'collapse',
    textAlign: 'left',
    fontSize: 12,
    fontWeight: 'bold',
    padding: 8,
  },
  td: {
    border: '1px solid black',
    borderCollapse: 'collapse',
    textAlign: 'left',
    fontSize: 12,
  },
  tdTotal: {
    border: '1px solid black',
    borderCollapse: 'collapse',
    fontWeight: 'bold',
    fontSize: 12,
    padding: 8,
  },
  metadataSpace: {
    height: 10,
  },
  dataViewTitle: {
    padding: '15px',
    backgroundColor: 'black',
    color: 'white',
    fontSize: '18px',
  },
};

@withStyles(styles, { index: 1 })
class IndividualReportingAlertsChart extends React.Component {
  state = {
    alertsData: {
      summaryData: {
        status: true,
        totalAlerts: 0,
        criticallyLow: 0,
        low: 0,
        criticallyHigh: 0,
        high: 0,
        missed: 0,
        type: 'INDIVIDUAL',
      },
      enrolledProgramData: [],
      chartData: [],
      loading: false,
      selectedView: 'LINE_CHART',
      selectedData: null,
      dataView: [],
      selectedEnrolledProgramme: [],
    },
    loading: true,
  };

  async componentDidMount() {
    const { chartType } = this.props.location.query;
    this.setState(
      {
        alertsData: {
          ...this.state.alertsData,
          selectedView: chartType,
        },
      },
      async () => {
        await this.getEnrolledProgram();
      },
    );
  }

  getEnrolledProgram = async () => {
    const { 'session-token': sessionToken, patientId, dateStart, dateEnd, epIds } = this.props.location.query;
    const selectedEpIds = epIds.map((epId) => `selectedEpIds[]=${epId}`).join('&');
    const { API_URL } = window.MILO_CONFIG;
    fetch(
      `${API_URL}/reports/patient/${patientId}/enrolledPrograms?page_no=1&page_size=1&dateStart=${dateStart}&dateEnd=${dateEnd}&timezone=Asia/Singapore&returnSelectedEps=true&${selectedEpIds}&session-token=${sessionToken}`,
      { method: 'GET' },
    )
      .then((res) => res.json())
      .then((json) => json.value)
      .then((epData) => {
        this.setState(
          {
            alertsData: {
              ...this.state.alertsData,
              selectedEnrolledProgramme: epData.selectedEnrolledPrograms,
            },
          },
          async () => this.getSummary(),
        );
      });
  };

  getSummary = async () => {
    const {
      'session-token': sessionToken,
      patientId,
      dateStart,
      dateEnd,
      epIds,
      vitalTypes,
      careTaskTypes,
      alertTypes,
      timeTaken,
    } = this.props.location.query;
    const { API_URL } = window.MILO_CONFIG;

    const epIdsParam = epIds.map((epId) => `epIds[]=${epId}`).join('&');
    const alertTypesParam = alertTypes.map((alertType) => `alertTypes[]=${alertType}`).join('&');
    const vitalTypesParam = vitalTypes.map((vitalType) => `vitalTypes[]=${vitalType}`).join('&');
    const careTaskTypesParam = careTaskTypes.map((careTaskType) => `careTaskTypes[]=${careTaskType}`).join('&');
    // const timeTakenParam = timeTaken.map(tTaken => `timeTaken[]=${tTaken}`).join('&');

    fetch(
      `${API_URL}/reports/provider/patient/${patientId}/alerts/thresholdSummary?${epIdsParam}&dateStart=${dateStart}&dateEnd=${dateEnd}&${alertTypesParam}&${careTaskTypesParam}&${vitalTypesParam}&session-token=${sessionToken}`,
      { method: 'GET' },
    )
      .then((res) => res.json())
      .then((json) => json.value)
      .then((summaryData) => {
        this.setState(
          {
            alertsData: {
              ...this.state.alertsData,
              summaryData,
            },
          },
          async () => this.getChartData(),
        );
      });
  };

  getChartData = async () => {
    const {
      'session-token': sessionToken,
      patientId,
      dateStart,
      dateEnd,
      epIds,
      vitalTypes,
      careTaskTypes,
      alertTypes,
      timeTaken,
      chartType,
    } = this.props.location.query;
    const { API_URL } = window.MILO_CONFIG;

    const epIdsParam = epIds.map((epId) => `epIds[]=${epId}`).join('&');
    const alertTypesParam = alertTypes.map((alertType) => `alertTypes[]=${alertType}`).join('&');
    const vitalTypesParam = vitalTypes.map((vitalType) => `vitalTypes[]=${vitalType}`).join('&');
    const careTaskTypesParam = careTaskTypes.map((careTaskType) => `careTaskTypes[]=${careTaskType}`).join('&');
    // const timeTakenParam = timeTaken.map(tTaken => `timeTaken[]=${tTaken}`).join('&');

    fetch(
      `${API_URL}/reports/provider/patient/${patientId}/alerts/thresholdSummary/chart?${epIdsParam}&dateStart=${dateStart}&dateEnd=${dateEnd}&${alertTypesParam}&${careTaskTypesParam}&${vitalTypesParam}&session-token=${sessionToken}&chartType=${chartType}`,
      { method: 'GET' },
    )
      .then((res) => res.json())
      .then((json) => json.value)
      .then((chartData) => {
        this.setState({
          alertsData: {
            ...this.state.alertsData,
            chartData: chartData.lineChartData || chartData.dataView,
          },
          loading: false,
        });
      });
  };

  renderDataView = () => {
    const { isEmbedded } = this.props;
    const { page = 1, totalPages = 10 } = this.props.location.query;
    const titles = ['Month', 'Total Alerts'];
    const tableData = this.state.alertsData.chartData.map((data) => {
      return [data.reportDate.display, data.totalAlerts];
    });
    return (
      <ReportPage isEmbedded={isEmbedded} page={page} totalPages={totalPages}>
        <ReportDataViewTable dataViewTitle={'Threshold Alerts'}>
          <ReportDataViewTableHeader titles={titles} />
          <ReportDataViewTableCell reportType="INDIVIDUAL" tableData={tableData} />
        </ReportDataViewTable>
      </ReportPage>
    );
  };

  renderLineChart = () => {
    const { classes, isEmbedded } = this.props;
    const { page = 1, totalPages = 10 } = this.props.location.query;
    return (
      <ReportPage isEmbedded={isEmbedded} page={page} totalPages={totalPages}>
        <CollapsibleCardWithStatusChartAlerts
          isEmbedded={isEmbedded}
          mode={'view'}
          pdf={true}
          status={'pristine'}
          title="Alerts"
          step={5}
          classes={classes}
          data={this.state.alertsData}
          refresh={null}
          changeType={null}
        />
      </ReportPage>
    );
  };

  render = () => {
    const {
      alertsData: { selectedView },
      loading,
    } = this.state;

    if (!selectedView || loading) return <div>Loading...</div>;
    if (selectedView === 'LINE_CHART') {
      return this.renderLineChart();
    }

    if (selectedView == 'DATA_VIEW') {
      return this.renderDataView();
    }

    return null;
  };
}

export default IndividualReportingAlertsChart;
