/* Constants */

/* Action Creators */

/* Epics */
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/catch';

import DM from 'dataModule';

/* Reducer */
/*
**************************************************
  Action Types
**************************************************
*/

const actionTypes = {
  FETCH_LATEST_MEASUREMENTS: Symbol('FETCH_LATEST_MEASUREMENTS'),
  FETCH_LATEST_MEASUREMENTS_SUCCESS: Symbol('FETCH_LATEST_MEASUREMENTS_SUCCESS'),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  fetchLatestMeasurements: (payload) => ({ type: actionTypes.FETCH_LATEST_MEASUREMENTS, payload }),
  fetchLatestMeasurementsSuccess: (payload) => ({ type: actionTypes.FETCH_LATEST_MEASUREMENTS_SUCCESS, payload }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/

const epics = [
  (action$) =>
    action$
      .ofType(actionTypes.FETCH_LATEST_MEASUREMENTS)
      .mergeMap(({ payload }) =>
        Observable.fromPromise(DM.measurement.fetchList(payload)).map((result) =>
          actionCreators.fetchLatestMeasurementsSuccess({ result }),
        ),
      ),
];

export { epics };
/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
  loading: false,
  latestMeasurements: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LATEST_MEASUREMENTS_SUCCESS: {
      const { result } = action.payload;
      return { ...state, latestMeasurements: { ...result }, loading: true };
    }
    default: {
      return state;
    }
  }
};
