/* eslint-disable */
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/catch';
import * as R from 'ramda';
import DM from 'dataModule';

/*
**************************************************
  Action Types
**************************************************
*/
const prefix = 'CONSUMABLE';

const actionTypes = {
  CONSUMABLE_AVAILABLE_LIST: Symbol(`${prefix}/CONSUMABLE_AVAILABLE_LIST`),
  CONSUMABLE_AVAILABLE_LIST_SUCCESS: Symbol(`${prefix}/CONSUMABLE_AVAILABLE_LIST_SUCCESS`),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  consumableAvailableList: payload => ({ type: actionTypes.CONSUMABLE_AVAILABLE_LIST, payload }),
  consumableAvailableListSuccess: payload => ({ type: actionTypes.CONSUMABLE_AVAILABLE_LIST_SUCCESS, payload }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/
const epics = [
  // List available consumables based on warehouseId
  action$ =>
    action$.ofType(actionTypes.CONSUMABLE_AVAILABLE_LIST).mergeMap(({ payload }) =>
      DM.consumables
        .consumableAvailableList(payload)
        .map(res => {
          return actionCreators.consumableAvailableListSuccess({
            availableConsumables: R.pathOr([], ['value', 'consumables'], res),
          });
        })
        .catch(error => {
          return Observable.of(actionCreators.consumableAvailableListSuccess({ availableConsumables: [], error }));
        }),
    ),
];

export { epics };

/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
  availableConsumables: []
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.CONSUMABLE_AVAILABLE_LIST: {
      return { ...state, availableConsumables: [] };
    }
    case actionTypes.CONSUMABLE_AVAILABLE_LIST_SUCCESS: {
      const { availableConsumables } = action.payload;
      return { ...state, availableConsumables };
    }

    default: {
      return state;
    }
  }
};
