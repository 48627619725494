/* eslint-disable */
/* Constants */

/* Action Creators */

/* Reducer */

/* Libs */

/*
**************************************************
  Action Types
**************************************************
*/
const prefix = "program/create/vitals/vitalList";

const actionTypes = {
  INIT: Symbol(`${prefix}/INIT`),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  init: payload => ({ type: actionTypes.INIT, payload }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/
const epics = [];

export { epics };

/*
**************************************************
  Reducer
**************************************************
*/
const initState = [
  {
    def: { name: 'BP', label: 'Blood Pressure' },
    dims: [
      { name: 'systolic', label: 'Systolic (mmHg)' },
      { name: 'diastolic', label: 'Diastolic (mmHg)' },
    ],
  },
  {
    def: { name: 'BG', label: 'Blood Glucose' },
    dims: [
      { name: 'beforeMeal', label: 'Before Meal (mmol/L)' },
      { name: 'afterMeal', label: 'After Meal (mmol/L)' },
    ],
  },
  {
    def: { name: 'PO', label: 'SPO2' },
    dims: [
      { name: 'oxygenLevel', label: 'Oxygen Absortion (%)' },
    ],
  },
  {
    def: { name: 'HS', label: 'Weight' },
    dims: [
      { name: 'weightChange', label: 'Weight Change (kg/day)' },
    ],
  },
  {
    def: { name: 'HR', label: 'Heart Rate' },
    dims: [
      { name: 'heartRate', label: 'Heart Rate (bpm)' },
    ],
  },
];

export default (state = initState, action) => {
  switch (action.type) {
    default: {
      return state;
    }
  }
};
