import React from 'react';
const footer = require('../../../../../public/hb_powered_by_int.png');

const ReportFooter = (props) => {
  const { page = null, totalPages = null } = props;
  if (!page || !totalPages) {
    return null;
  }
  return (
    <div style={{ marginRight: 20, marginBottom: 20 }}>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
        <div style={{ flex: 1 }}>
          <img src={footer} style={{ width: '40%', float: 'right', marginRight: '-30px' }} />
        </div>
        <div style={{ flex: 1 }}>
          <span style={{ float: 'right', fontSize: 12 }}>
            Pages {page} of {totalPages}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ReportFooter;
