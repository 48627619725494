import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import SettingContainer from './containers/SettingContainer';
import { withRouter } from 'react-router';

@withRouter
class Container extends React.Component {
  static displayName = 'GlobalSetting';

  render() {
    return <SettingContainer />;
  }
}

export default Container;
