/* eslint-disable */
import get from 'lodash/get';
import { assocPath, compose, findIndex, lensPath, merge, path, prop, propEq, set } from 'ramda';
/* Constants */

/* Action Creators */

/* Epics */
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/catch';

// import apolloClient from 'libModule/gqlClient';
/* Reducer */

/* Libs */
// import update from 'immutability-helper';
import DM from 'dataModule';
import { push } from 'react-router-redux';
import * as R from 'ramda';
/*
**************************************************
  Action Types
**************************************************
*/

const actionTypes = {
  FETCH_LIST: Symbol(`FETCH_LIST`),
  FETCH_LIST_SUCCESS: Symbol(`FETCH_LIST_SUCCESS`),
  FETCH_LIST_FAILURE: Symbol(`FETCH_LIST_FAILURE`),
  MARK_AS_ERROR: Symbol(`MARK_AS_ERROR`),
  UPDATE_NOTE: Symbol('UPDATE_NOTE'),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  fetchList: ({ relatedId, type }) => ({
    type: actionTypes.FETCH_LIST,
    payload: {
      relatedId,
      type,
      page: 1,
      pageSize: 9e6,
    },
  }),
  fetchListSuccess: payload => ({ type: actionTypes.FETCH_LIST_SUCCESS, payload }),
  fetchListFailure: payload => ({ type: actionTypes.FETCH_LIST_FAILURE, payload }),
  markAsError: payload => ({ type: actionTypes.MARK_AS_ERROR, payload }),
  updateNote: payload => ({ type: actionTypes.UPDATE_NOTE, payload }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/
const epics = [
  // fetchList
  action$ =>
    action$.ofType(actionTypes.FETCH_LIST).mergeMap(({ payload }) =>
      Observable.fromPromise(DM.note.fetchList(payload))
        .map(({ value: { data } }) =>
          actionCreators.fetchListSuccess({ data, relatedId: payload.relatedId }),
        )
        .catch(error => Observable.of(actionCreators.fetchListFailure({ error }))),
    ),
  action$ =>
    action$.ofType(actionTypes.MARK_AS_ERROR).mergeMap(
      ({ payload: { id, errorMsg } }) =>
        Observable.fromPromise(DM.note.markAsError({ id, errorMessage: errorMsg })).map(
          ({ value }) => actionCreators.updateNote({ value }),
        ),
      // .catch(error => Observable.of(actionCreators.fetchListFailure({ error }))),
    ),
];

export { epics };
/*
**************************************************
  Reducer
**************************************************
*/
const initState = {};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LIST: {
      const { relatedId } = action.payload;
      return assocPath([relatedId, 'loading'], true, state);
    }
    case actionTypes.FETCH_LIST_SUCCESS: {
      const { data, relatedId } = action.payload;
      return merge(state, {
        [relatedId]: {
          loading: false,
          list: data,
        },
      });
    }
    case actionTypes.UPDATE_NOTE: {
      const { value } = action.payload;
      const { relatedId } = value;
      const idx = compose(
        findIndex(propEq('_id', value._id)),
        path([relatedId, 'list']),
      )(state);
      return set(lensPath([relatedId, 'list', idx]), value, state);
    }
    default: {
      return state;
    }
  }
};
