import * as R from 'ramda';
import React from 'react';
import { Paper, Grid, Button, Typography } from '@material-ui/core';
import Logo from 'appModule/logo';
import { fetchGoThumbSignedUrl } from 'utilsModule/gothumb';
import pdfImage from 'appModule/discharge/assets/pdf.png';
import FormSuccess from '../assets/FormSuccess.png';

const style = {
  containerWrapper: {
    backgroundColor: '#fff',
    padding: '0px',
    height: '60px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'sticky',
    top: 0,
    background: 'inherit',
    zIndex: 100,
    webkitBoxShadow: '0 3px 5px rgba(57, 63, 72, 0.3)',
    mozBoxShadow: '0 3px 5px rgba(57, 63, 72, 0.3)',
    boxShadow: '0 3px 5px rgba(57, 63, 72, 0.3)',
  },
  logo: { marginLeft: 20 },
  sticky: {
    position: 'sticky',
    bottom: 0,
    background: 'inherit',
    zIndex: 9,
  },
  description: {
    fontFamily: 'Roboto, Helvetica ,Arial , sans-serif',
    color: '#212121',
  },
  pdfContent: {
    justifyContent: 'flex-start',
    paddingTop: '6px',
    paddingBottom: '6px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #F0F0F0',
    color: 'white',
    borderRadius: '8px',
    marginTop: '30px',
    width: '40%',
    height: '80px',
  },
};

class RegistrationCompleteComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      invoiceFormUri: R.path(['location', 'state', 'invoiceFormUri'], this.props),
      invoiceNo: R.path(['location', 'state', 'invoiceNo'], this.props),
    };
  }

  backToHome = () => { window.location.href = 'https://www.healthbeats.co'; }

  renderFooter = () => (
      <Grid container style={{ paddingTop: '90px' }} justify="center">
        <Grid item xs={6} style={{ textAlign: 'center', minWidth: '100%' }}>
          <Button
            variant='contained'
            style={{
                 width: '180px',
                 color: 'white',
                 backgroundColor: '#ff5100',
                 borderRadius: '20px',
                 textTransform: 'capitalize',
             }}
            onClick={this.backToHome}>
            Back to home
          </Button>
        </Grid>
      </Grid>
  );

  handleViewClick = (invoiceFormUri) => {
    fetchGoThumbSignedUrl(invoiceFormUri).then((signedUrl) => {
      const buttonElement = document.createElement('a');
      buttonElement.setAttribute('href', signedUrl);
      buttonElement.setAttribute('target', '_blank');
      buttonElement.style.display = 'none';
      document.body.appendChild(buttonElement);
      buttonElement.click();
      buttonElement.remove();
    });
  };

  renderPDFContent = (invoiceName, invoiceFormUri) => (
    <Grid justify="center" align="center">
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ height: '80px' }}>
        <Button
          style={R.merge(style.pdfContent, { textTransform: 'none' })}
          onClick={() => this.handleViewClick(invoiceFormUri)}>
          <Typography>
            <img
              src={pdfImage}
              style={{
                marginRight: '20px',
                width: '48px',
                height: '48px',
                borderRadius: '8px',
              }}
            />
            <Typography
               variant='subtitle1'
               style={{
                  fontSize: '16px',
                  display: 'unset',
                  position: 'absolute',
                  paddingTop: '10px',
                 }}>{invoiceName}.pdf</Typography>
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );

  render() {
    const { location: { state: { form } = {} } = {} } = this.props;
    const { invoiceFormUri, invoiceNo } = this.state;
    const invoiceName = `${invoiceNo}`;
    // console.log('%cRegistration Complete', 'color: cyan; font-size: 14px;', this.props);

    return (
      <Paper style={{ boxShadow: 'unset' }}>
       <div style={style.containerWrapper}>
          <Logo width={180} height={60} style={style.logo} />
        </div>
        <Grid
          container
          style={{ height: '100vh', width: '100vw' }}>
          <Grid item xs={6} style={{ textAlign: 'center', minWidth: '100%', marginTop: '75px' }}>
            <Typography
              variant="title"
              style={R.merge(style.description, {
                fontSize: '32px',
                paddingTop: '80px',
                fontWeight: 500,
              })}>
              Registration is completed!
            </Typography>
            <Typography
            variant='subtitle1'
            style={R.merge(style.description, {
                fontSize: '16px',
                marginBottom: '10px',
                marginTop: '30px',
                letterSpacing: '0.08px',
              })}
            >
            { form === 'GROUP' ?
            <span>A verification e-mail has been sent to both primary and secondary contacts.<br /> Please click on the link to commence using the account.</span>
            :
            'A verification e-mail has been sent to you, please verify your e-mail by clicking on the link.'
            }
            </Typography>
            <Typography>
            <img
              src={FormSuccess}
              style={{
                width: '280px',
                height: '200px',
                borderRadius: '8px',
              }}
            />
          </Typography>
            {form === 'GROUP' && this.renderPDFContent(invoiceName, invoiceFormUri)}
            {this.renderFooter()}
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default RegistrationCompleteComponent;
