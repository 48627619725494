import React from 'react';
import { withStyles } from '@material-ui/styles';


const styles = {
  collapsibleCardGrid: {
    backgroundColor: '#212121',
    fontSize: '24px'
  },
  description: {
    marginTop: '5px',
    fontSize: '12px',
    letterSpacing: '0.08px',
    marginBottom: '5px',
    color: '#212121',
  },
  paper: {
    borderRadius: '5px',
    width: '820px',
  },
  patientProgramLabel: {
    fontSize: '12px',
    color: '#9E9E9E'
  },
  patientProgramGrid: {
    cursor: 'pointer',
    width: '9px',
    height: '15px',
    float: 'right',
    borderRadius: '14px',
    opacity: 1,
    marginRight: '3px',
  },
  patientProgramGrid2: {
    paddingTop: '0px',
    paddingBottom: '0px'
  },
  patientProgramGridContent: {
    paddingLeft: '16px',
    paddingRight: '16px'
  },
  patientProgramDetails: {
    fontSize: '14px',
  },
  reportInformationTitle1: {
    textAlign: 'right',
    fontSize: '12px',
    color: '#f44336'
  },
  reportInformationTitle2: {
    textAlign: 'right',
    fontSize: '12px',
    color: '#9e9e9e',
    float: 'right'
  },
  reportInformationPickerGrid: {
    justifyContent: 'center',
    alignContent: 'center',
    width: '100%'
  },
  advancePropsTypograph: {
    height: '100px',
    alignItems: 'center',
    display: 'flex'
  },
  cardLabel: {
    fontFamily: 'Roboto',
    fontWeight: 'medium',
    fontSize: '12px',
    color: '#FFFFFF',
    paddingTop: 16,
  },
  cardDetail: {
    fontFamily: 'Roboto',
    fontSize: '21px',
    color: '#FFFFFF'
  },
  vitalDiv: {
    borderLeft: '1px solid',
    height: '100px',
    marginTop: 3,
  },
  careTaskLegend: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '12px'
  },
  careTaskLegendCategoryDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  careTaskLegendCategoryLabel: {
    color: '#212121',
    fontWeight: '400',
    fontSize: '14px',
    marginLeft: '6px',
  },
  careTaskSummaryCardPaper: {
    display: 'flex',
    borderRadius: 3,
    marginLeft: 16,
    marginRight: 16,
    marginBottom: 20,
    boxShadow: 'none',
  },
  careTaskInfoTyphograph: {
    fontSize: '12px'
  },
  careTaskInfoGrid: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '10px'
  },
  careTaskInfoGrid2: {
    justifyContent: 'space-between'
  },
  careTaskToolTipCard: {
    height: 'auto',
    width: '275px',
    marginBottom: '10px',
    marginRight: '10px',
    padding: '12px',
    borderRadius: '8px'
  },
  selectionGridThreshold: {
    cursor: 'pointer',
    width: '9px',
    height: '15px',
    float: 'right',
    borderRadius: '14px',
    opacity: 1,
    marginRight: '3px',
  },
  refreshData: {
    fontSize: '12px',
    color: '#FFFFFF'
  },
  fabBtn: {
    height: '10px',
    width: '38px',
    float: 'right',
    backgroundColor: 'white',
    boxShadow: 'none',
  },
  iconClose: {
    color: '#4c4b4b',
    fontSize: '20px'
  },
  menuItemTypograph: {
    paddingLeft: '10px',
    textAlign: 'left',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0px',
    color: '#474747',
    opacity: 1

  },
  menuItemTypographData: {
    paddingLeft: '10px',
    textAlign: 'left',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0px',
    color: '#474747',
    opacity: 1
  },
  dataViewSelect: {
    paddingLeft: '16px',
    paddingTop: '16px',
    paddingBottom: '16px'
  },
  paperBoxShadow: {
    boxShadow: '0px 0px 0px 1px rgb(0 0 0 / 10%), 0px 1px 1px 1px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%)'
  },
  statusInfoBoxShadow: {
    boxShadow: '0px 2px 11px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
  },
  avatarTitle: {
    fontSize: '24px',
  },
  dialogAction: {
    justifyContent: 'space-between',
    paddingLeft: '25px',
    paddingRight: '25px',
    paddingBottom: '25px'
  },
  vitalCardTooltip: {
    height: 'auto',
    width: '275px',
    marginBottom: '10px',
    marginRight: '10px',
    padding: '12px',
    borderRadius: '8px'
  },
  container: {
    backgroundColor: 'white',
    width: '21cm',
    height: '29.7cm',
    overflow: 'hidden',
  },
  metadataTitle: {
    width: '40%',
    fontSize: 14,
  },
  metadataValue: {
    width: '60%',
    fontSize: 14,
  },
  tdHeader: {
    border: '1px solid black',
    borderCollapse: 'collapse',
    textAlign: 'left',
    fontSize: 12,
    fontWeight: 'bold',
    padding: 8,
  },
  td: {
    border: '1px solid black',
    borderCollapse: 'collapse',
    textAlign: 'left',
    fontSize: 12,
    padding: 8,
  },
  tdTotal: {
    border: '1px solid black',
    borderCollapse: 'collapse',
    fontWeight: 'bold',
    fontSize: 12,
    padding: 8,
  },
  metadataSpace: {
    height: 10,
  },
  dataViewTitle: {
    padding: '15px',
    backgroundColor: 'black',
    color: 'white',
    fontSize: '18px',
  }
};

@withStyles(styles, { index: 1 })
class ReportDataViewTable extends React.Component {

  render() {
    const { dataViewTitle, classes, children } = this.props;

    return (
      <React.Fragment>
        <div className={classes.dataViewTitle}>{dataViewTitle}</div>
        <div style={{ flex: 1 }}>
          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            {children}
          </table>
        </div>
      </React.Fragment>
    );
  }
}

export default ReportDataViewTable;
