import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
  Divider,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import HeroBanner from '../assets/HeroBanner.png';

@withStyles({
  paper: {
  },
  title: {
    fontSize: '24px',
    height: '28px',
    width: '260px',
    opacity: 1,
    letterSpacing: '0px',
  },
  subtitle: {
    fontSize: '14px',
    letterSpacing: '0.07px',
    width: '488px',
    height: '9px',
    paddingBottom: '17px',
    paddingTop: '10px',
  },
  caption: {
    fontSize: '12px',
    letterSpacing: '0.06px',
    top: '1333px',
  },
  desc: {
    fontSize: '14px',
    letterSpacing: '0.07px',
    height: '16px',
    width: '135px',
  },
})

class DialogInformation extends React.PureComponent {
  static propTypes = {};

  render() {
    const {
      open, onClose, classes, pricingPlan,
    } = this.props;

    return (
      <Dialog
        open={open}
        onBackdropClick={() => onClose()}
        classes={{ paper: classes.paper }}
        maxWidth="sm">
        <DialogTitle style={{ paddingBottom: '10px' }}>
          <Typography variant='subtitle1' className={classes.title}>
          How do we charge?
          </Typography>
          <Typography variant='subtitle1' className={classes.subtitle}>
          We charge simply a monthly fee for the business.
        </Typography>
        </DialogTitle>
        <Grid>
        <Typography>
            <img
              src={HeroBanner}
              style={{
                height: '177px',
                width: '100%',
                paddingTop: '15px',
                paddingBottom: '15px',
              }}
            />
          </Typography>
        </Grid>
        <DialogContent style={{ marginTop: '-15px' }}>
          <Grid container style={{ paddingTop: '12px' }}>
            <Grid item xs={6}>
              <Typography variant="caption" className={classes.caption}>Number of Users Monitored</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption" className={classes.caption}>Fixed Monthly</Typography>
            </Grid>
          </Grid>
          {pricingPlan.map(item =>
          // eslint-disable-next-line react/jsx-key
          <Grid container style={{ paddingTop: '0px', height: '56px' }}>
            <Grid item xs={6}>
              <Typography variant='subtitle1' className={classes.desc}>{item.label}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle1' className={classes.desc}>{item.priceMonthly}</Typography>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: '5px', paddingBottom: '5px' }}><Divider /></Grid>
          </Grid>)}
        </DialogContent>
      </Dialog>
    );
  }
}

export default DialogInformation;
