import React from 'react';
import { withRouter } from 'react-router';
import { Loading } from 'utilsModule/components';
import EdfConverterComponent from '../components/EdfConverterComponent';


@withRouter
class EdfConverterContainer extends React.Component {
  render() {
    return (
      <EdfConverterComponent  />
    );
  }
}

export default EdfConverterContainer;
