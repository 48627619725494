import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import uuidv4 from 'uuid/v4';
import { withReduxForm, ShowHidePasswordTextField } from 'utilsModule/form/reduxFormMui';
import Logo from 'appModule/logo/logoNoText';
import { Back } from 'utilsModule/components';
import HeaderComponent from 'appModule/main/layouts/Header';
import { isEmpty } from 'lodash';

const styles = {
  pageContainer: {
    height: '84vh',
    width: '100vw',
    margin: 0,
    backgroundColor: '#fff',
    paddingBottom: 100
  },
  formContainer: {
    // width:606,
  },
  banner: {
    textAlign: 'center',
  },
  back: {
    height: '16vh',
    padding: 20,
    backgroundColor: '#fff',
  },
  title : {
    color: '#212121',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 19,
  },
  subTitle: {
    color: '#212121',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '19px',
  },
};

const layout = {
  header: {
    containerProps: {
      justify: 'center',
    },
    components: [
      {
        id: uuidv4(),
        name: 'banner',
        component: ({ title, subtitle1, subtitle2 }) => (
          <Grid container style={{ textAlign: 'center' }}>
            <Grid item xs={12}><Logo /></Grid>
            <Grid item xs={12} style={{marginBottom:16}}><Typography variant='h4' styles={styles.title} style={{letterSpacing:0.5}}>{title}</Typography></Grid>
            <Grid item xs={12}><Typography variant='body1' styles={styles.subTitle} style={{letterSpacing:0.5, marginBottom:16}}>{subtitle1}</Typography></Grid>
            <Grid item xs={12}><Typography variant='body1' styles={styles.subTitle} style={{letterSpacing:0.5}}>{subtitle2}</Typography></Grid>
          </Grid>
        ),
        colSpans: { xs: 12 },
        inject: ({ runtimeProps: { header: { banner } } }) => banner,
      },
    ],
  },
  body: {
    sections: [
      {
        id: uuidv4(),
        containerProps: {
          overall: {
            spacing: 0,
          },
          body: {
            spacing: 0,
          },
        },
        rows: [
          {
            id: uuidv4(),
            containerProps: {
              justify: 'center',
              spacing: 0,
            },
            cols: [
              {
                name: 'encryptPassword',
                label: 'New Password',
                required: true,
                component: ShowHidePasswordTextField,
                colSpans: { xs: 6 },
              },
            ],
          },
          {
            id: uuidv4(),
            containerProps: {
              justify: 'center',
              spacing: 0,
              style: { marginTop: '25px' },
            },
            cols: [
              {
                name: 'confirmPassword',
                label: 'Confirm New Password',
                required: true,
                component: ShowHidePasswordTextField,
                colSpans: { xs: 6 },
              },
            ],
          },
        ],
      },
    ],
  },
  footer: {
    itemProps: {
      style: {
        marginTop: 40,
      },
    },
    containerProps: {
      justify: 'center',
    },
    components: [
      {
        id: uuidv4(),
        component: Button,
        type: 'submit',
        children: 'Submit',
        variant: 'contained',
        color: 'primary',
        style: { width: '100%' },
        className: 'milo-btn-submit-medium',
        colSpans: { xs: 6 },
        inject: ({ submitting }) => ({ disabled: submitting }),
      },
    ],
  },
};

const validate = ({ encryptPassword, confirmPassword }) => {
  const result = {};
  if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/g.test(encryptPassword)) {
    result.encryptPassword = 'Password should have at least 8 characters, include at least 1\nuppercase letter, 1 lowercase letter, 1 number and 1 symbol.';
  }

  if(confirmPassword !== encryptPassword && !isEmpty(confirmPassword)) {
    result.confirmPassword = 'The two passwords do not match.';
  }
  
  if(isEmpty(confirmPassword)) {
    result.confirmPassword = 'Confirm New Password is required';
  }
  
  return result;
};

const config = {
  form: 'setPassword',
  validate,
};

@withStyles(styles, { index: 1 })
@withReduxForm({ layout, config })
class SetNewPassword extends PureComponent {
  static propTypes = {
    /* container */
    handleCreate: PropTypes.func.isRequired,
    /* withStyles */
    classes: PropTypes.object,
    /* createReduxForm */
    Form: PropTypes.func.isRequired,
  };

  render() {
    const {
      classes,
      Form, initialValues, runtimeProps,
      handleCreate, changePassword,
    } = this.props;

    return (
      <React.Fragment>
        <HeaderComponent isOnBoard={true}  hideComponents={{isDisclaimer: true}}/>
        <div style={{paddingTop: 50}}>
          <Back
            className={classes.back}
            // label={changePassword ? 'Back' : 'Log in'}
            backToWebsite={!changePassword}
          />
          <Grid container className={classes.pageContainer} alignItems='center'>
            <Grid item xs />
            <Grid item xs={5} style={{ marginTop: '-65px' }}>
              <Form
                runtimeProps={runtimeProps}
                initialValues={initialValues}
                className={classes.formContainer}
                onSubmit={handleCreate}
              />
            </Grid>
            <Grid item xs />
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default SetNewPassword;
