import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ForgetPassword, ForgetPasswordVerify, SetNewPassword } from '../containers';

class ForgetPasswordLayout extends React.Component {
  static propTypes = {};

  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route path={`${match.path}/verifymobile`} component={ForgetPassword} />
        <Route path={`${match.path}/otp`} component={ForgetPasswordVerify} />
        <Route path={`${match.path}/setnewpassword`} component={SetNewPassword} />
        <Route component={ForgetPassword} />
      </Switch>
    );
  }
}

export default ForgetPasswordLayout;
