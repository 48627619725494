// Imports
import React from 'react';

// NOTE: Import to put here instead of webpack.entry before index.js, otherwise
// click events of some buttons (Vital Type) will be somehow unhooked
import 'react-hot-loader/patch';
/* Libs */
import { PureComponent } from 'react';
import { Provider } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { withResourcesGetters } from 'dataModule/store/resources';
/* Components */
import ForgetPassword from 'appModule/forgetPassword';
import PrivilegedApp from 'appModule/main';
import Auth from 'appModule/auth';
import Onboard from 'appModule/onboard';
import Message from 'appModule/message';
import Policies from 'appModule/doc';
import PatientInvoice from 'appModule/billing/containers/PatientInvoice';
import OrgInvoice from 'appModule/billing/containers/OrgInvoice';
import CRFormReadOnly from 'appModule/forms/changeRequest/containers/CRFormReadOnly';
import CovidBusinessCreate from 'appModule/covid-19/containers/CovidBusinessCreate';
import CovidIndividualCreate from 'appModule/covid-19/containers/CovidIndividualCreate';
import SelectCountry from 'appModule/covid-19/containers/SelectCountry';
import RegistrationComplete from 'appModule/covid-19/components/RegistrationComplete';
import TimeSpentReport from 'appModule/pdf/containers/TimeSpentReport';
import AdherenceReport from 'appModule/pdf/containers/AdherenceReport';
import PatientVitalReport from 'appModule/pdf/containers/PatientVitalReport';

/* Styles */
import StylePreview from 'appModule/stylePreview';
import { MuiThemeProvider } from '@material-ui/core/styles';
import 'stylesModule/index.scss';
import 'stylesModule/theme.scss';
/* i18n */
import i18n from 'appModule/i18n';
import { I18nextProvider } from 'react-i18next';
import EdfConverterContainer from './edf-converter/containers/EdfConverterContainer';
import ReportingMetaData from './reporting/container/IndividualParticipantReportingPdf/ReportingMetaData';
import ReportingCaretaskChart from './reporting/container/IndividualParticipantReportingPdf/ReportingCaretaskChart';
import ReportingVitalsChart from './reporting/container/IndividualParticipantReportingPdf/ReportingVitalsChart';
import ReportingAlertsChart from './reporting/container/IndividualParticipantReportingPdf/ReportingAlertsChart';
import ReportingDisclaimerPage1 from './reporting/container/IndividualParticipantReportingPdf/ReportingDisclaimerPage1';
import ReportingDisclaimerPage2 from './reporting/container/IndividualParticipantReportingPdf/ReportingDisclaimerPage2';
import OverallReportingMetaData from './reporting/container/OverallPartricipantReportingPdf/ReportingMetaData';
import OverallReportingVitalsChart from './reporting/container/OverallPartricipantReportingPdf/ReportingVitalsChart';
import OverallReportingCaretaskChart from './reporting/container/OverallPartricipantReportingPdf/ReportingCaretaskChart';
import OverallReportingAlertsChart from './reporting/container/OverallPartricipantReportingPdf/ReportingAlertsChart';
import OverallReportingDisclaimerPage1 from './reporting/container/OverallPartricipantReportingPdf/ReportingDisclaimerPage1';

// TODO: Use pararms or query for /login & remove /otp
// FIXME: Fix /onboard/:token which returns 404
@withResourcesGetters
class App extends PureComponent {
  render() {
    const { theme, store, history } = this.props;

    return (
      <I18nextProvider i18n={i18n}>
        <MuiThemeProvider theme={theme}>
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <React.Fragment>
                <Switch>
                  {/*
                    **Disabled the covid routes**
                    <Route path="/covid-19-select-country" component={SelectCountry} />
                    <Route path="/covid-19-business-reg" component={CovidBusinessCreate} />
                    <Route path="/covid-19-individual-reg" component={CovidIndividualCreate} />
                    <Route path="/covid-19-reg-success" component={RegistrationComplete} />
                  */}
                  <Route path="/forgetpassword" component={ForgetPassword} />
                  <Route path="/changepassword" component={ForgetPassword} />
                  <Route path="/auth" component={Auth} />
                  <Route path="/onboard" component={Onboard} />
                  <Route path="/invoice/patient" component={PatientInvoice} />
                  <Route path="/invoice/organization" component={OrgInvoice} />
                  <Route path="/pdf/time-spent-report" component={TimeSpentReport} />
                  <Route path="/pdf/adherence-report" component={AdherenceReport} />
                  <Route path="/pdf/patient-vital-report" component={PatientVitalReport} />

                  {/* individual patient report pdf */}
                  <Route path="/pdf/meta-data/report" component={ReportingMetaData} />
                  <Route path="/pdf/vitals/report" component={ReportingVitalsChart} />
                  <Route path="/pdf/care-task/report" component={ReportingCaretaskChart} />
                  <Route path="/pdf/alerts/report" component={ReportingAlertsChart} />
                  <Route path="/pdf/disclaimer-page-1" component={ReportingDisclaimerPage1} />
                  <Route path="/pdf/disclaimer-page-2" component={ReportingDisclaimerPage2} />

                  {/* overall participant report pdf */}
                  <Route path="/report/overall/meta-data/pdf" component={OverallReportingMetaData} />
                  <Route path="/report/overall/vitals/pdf" component={OverallReportingVitalsChart} />
                  <Route path="/report/overall/care-tasks/pdf" component={OverallReportingCaretaskChart} />
                  <Route path="/report/overall/alerts/pdf" component={OverallReportingAlertsChart} />
                  <Route path="/report/overall/disclaimer-page-1" component={OverallReportingDisclaimerPage1} />

                  <Route path="/system/crform" component={CRFormReadOnly} />
                  <Route path="/app" component={PrivilegedApp} />
                  <Route path="/policies/:type" component={Policies} />
                  <Route path="/edf-converter" component={EdfConverterContainer} />
                  <Route path="/styles" component={StylePreview} />
                  <Redirect to="/auth" />
                </Switch>
                <Route component={Message} />
              </React.Fragment>
            </ConnectedRouter>
          </Provider>
        </MuiThemeProvider>
      </I18nextProvider>
    );
  }
}

export default App;
