import React from 'react';
import SettingContainer from './containers/SettingContainer';
import { withRouter } from 'react-router';

@withRouter
class Container extends React.Component {
  static displayName = 'GlobalNotification';

  render() {
    return <SettingContainer />;
  }
}

export default Container;
