/* Constants */
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/catch';

import DM from 'dataModule';
import { actionCreators as actionCreatorsForList } from './list';

/*
**************************************************
  Action Types
**************************************************
*/

const actionTypes = {
  CREATE: Symbol('CREATE'),
  CREATE_SUCCESS: Symbol('CREATE_SUCCESS'),
  CREATE_FAILURE: Symbol('CREATE_FAILURE'),
  // CHANGE_SELECT: Symbol(`${prefix}/CHANGE_SELECT`),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  create: payload => ({ type: actionTypes.CREATE, payload }),
  createSuccess: payload => ({ type: actionTypes.CREATE_SUCCESS, payload }),
  createFailure: payload => ({ type: actionTypes.CREATE_FAILURE, payload }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/
const epics = [
  // register provider
  action$ =>
    action$.ofType(actionTypes.CREATE).mergeMap(({ payload: { info } }) =>
      Observable.fromPromise(DM.note.create(info))
        .map(({ code, value }) => {
          if (code < 0) {
            return actionCreators.createFailure({ err: code });
          }
          return actionCreators.createSuccess({ value });
        })
        .catch(err => actionCreators.createFailure({ err }))),

  action$ =>
    action$
      .ofType(actionTypes.CREATE_SUCCESS)
      .map(({ payload: { value } }) =>
        actionCreatorsForList.fetchList({ relatedId: value.relatedId })),
];

export { epics };
