/* eslint-disable */
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/catch';

/*
**************************************************
  Action Types
**************************************************
*/
const prefix = 'CONSUMABLE';

const actionTypes = {
  ADD_CONSUMABLES: Symbol(`${prefix}/ADD_CONSUMABLES`),
  REMOVE_CONSUMABLES: Symbol(`${prefix}/REMOVE_CONSUMABLES`),
  RESET_CONSUMABLES: Symbol(`${prefix}/RESET_CONSUMABLES`),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  addConsumables: payload => ({ type: actionTypes.ADD_CONSUMABLES, payload }),
  removeConsumables: payload => ({ type: actionTypes.REMOVE_CONSUMABLES, payload }),
  resetConsumables: () => ({ type: actionTypes.RESET_CONSUMABLES }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/
const epics = [];
export { epics };

/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
  consumables: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.ADD_CONSUMABLES: {
      const { selection } = action.payload;
      const newAddedConsumables = state.consumables.concat(selection);
      return { ...state, consumables: newAddedConsumables };
    }
    case actionTypes.REMOVE_CONSUMABLES: {
      const { consumableId } = action.payload;
      const newAddedConsumables = state.consumables.filter(con => con != consumableId);
      return { ...state, consumables: newAddedConsumables };
    }
    case actionTypes.RESET_CONSUMABLES: {
      return initState;
    }

    default: {
      return state;
    }
  }
};
