import * as ChatEngineCore from 'chat-engine';
import * as R from 'ramda';
import Chat from './Chat';

class ChatCore {
  ChatEngine;
  channels;
  constructor({ key, user, rooms }) {
    this.key = key;
    this.user = user;
    this.rooms = rooms;
    this.chats = [];
    this.ChatEngineReady = false;
  }

  initChatEngine({ userStateChange }) {
    this.ChatEngine = ChatEngineCore.create({
      publishKey: this.key.publishKey,
      subscribeKey: this.key.subscribeKey,
    });
    return new Promise((resolve, reject) => {
      try {
        const { profile, demographics } = this.user;
        this.ChatEngine.connect(
          profile.id,
          {
            type: profile.type,
            avatar: profile.profile_photo,
            nickName: `${demographics.name.firstName} ${demographics.name.lastName}`,
            status: 'online',
          },
        );
        this.ChatEngine.on('$.ready', () => {
          this.ChatEngineReady = true;
          resolve(this);
        });
        this.ChatEngine.on('$.state', ({ user, state }) => {
          if (userStateChange) {
            userStateChange({ uuid: user.uuid, ...state });
          }
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  online() {
    const { profile, demographics } = this.user;

    if (this.ChatEngineReady) {
      this.ChatEngine.me.update({
        type: profile.type,
        avatar: profile.profile_photo,
        nickName: `${demographics.name.firstName} ${demographics.name.lastName}`,
        status: 'online',
      });
    }
  }

  offline() {
    const { profile, demographics } = this.user;

    if (this.ChatEngineReady) {
      this.ChatEngine.me.update({
        type: profile.type,
        avatar: profile.profile_photo,
        nickName: `${demographics.name.firstName} ${demographics.name.lastName}`,
        status: 'offline',
      });
    }
  }

  connectAllChannels() {
    this.chats = this.rooms.map(room => new Chat({ room, ChatEngine: this.ChatEngine }));
    return this.chats.map(chat => chat.connectChannels());
  }

  registerMessageEventForAll(fn) {
    this.chats.forEach((chat) => {
      chat.registerMessageEvent(fn);
    });
  }

  sendMessageToChat({
    eventType = 'text', msgType = 'text', content, epId,
  }) {
    const chat = this.findChat({ epId });
    chat.sendMessage({
      eventType,
      msgType,
      content,
    });
  }
  connectOneChat(room) {
    const chat = new Chat({ room, ChatEngine: this.ChatEngine });
    this.chats.push(chat);
    return chat.connectChannels();
  }

  findChat({ epId }) {
    if (!epId) return null;
    const fn = R.propEq('epId', epId);
    return R.find(fn)(this.chats);
  }
  close() {
    this.chats.forEach((chat) => {
      chat.close();
    });
    this.offline();
    this.ChatEngine.disconnect();
  }
}

export default ChatCore;
