/* Epics */
import { compose, find, isNil, path, pathOr, propEq } from 'ramda';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/filter';

/* Libs */
import DM from 'dataModule';

/*
**************************************************
  Action Types
**************************************************
*/
const actionTypes = {
  FETCH_PATIENT: Symbol('FETCH_PATIENT'),
  SET_PATIENT_ID: Symbol('SET_PATIENT_ID'),
  SET_PROFILE_CARD: Symbol('FETCH_PROFLE_CARD'),
  FETCH_PATIENT_FALURE: Symbol('FETCH_PATIENT_FALURE'),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  fetchPatient: patientId => ({ type: actionTypes.FETCH_PATIENT, patientId }),
  setProfileCard: profile => ({ type: actionTypes.SET_PROFILE_CARD, payload: profile }),
  setPatientId: patientId => ({ type: actionTypes.SET_PATIENT_ID, patientId }),
  fetchPatientProfileFailure: error => ({ type: actionTypes.FETCH_PATIENT_FALURE, error }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/
const epics = [
  action$ =>
    action$.ofType(actionTypes.FETCH_PATIENT).mergeMap(({ patientId }) =>
      Observable.fromPromise(DM.patient.provider.fetchPatientProfile({ patientId }))
        .map(ret => actionCreators.setProfileCard(pathOr({}, ['patient'], ret)))
        .catch(error => Observable.of(actionCreators.fetchPatientProfileFailure({ error })))),
  (action$, store) =>
    action$.ofType(actionTypes.SET_PATIENT_ID).map(({ patientId }) => {
      const patient = compose(find(propEq('id', patientId)), path(['patient', 'list', 'list']))(store.getState());
      return isNil(patient)
        ? actionCreators.fetchPatient(patientId)
        : actionCreators.setProfileCard(patient);
    }),
];

export { epics };
/*
**************************************************
  Reducer
**************************************************
*/
const initState = {};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.SET_PROFILE_CARD: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};
