/* eslint-disable function-paren-newline */
import Loadable from 'react-loadable';
import { Loading } from 'utilsModule/components';

export default Loadable({
  loader: () => import(
    /* webpackChunkName: 'app' */
    /* webpackPrefetch: true */
    '../layouts',
  ),
  loading: Loading,
});
