import { httpGet, httpPost } from './utils';

const admin = {
  fetchAdminProgramList: httpGet,
  createAdminProgram: httpPost,
  saveDraftAdminProgram: httpPost,
};

const provider = {
  fetchAvailableProgramList: httpGet,
  fetchActiveBaseProgramList: httpGet,
  fetchEnrolledProgramListNoPagination: httpGet,
  fetchDischargedEnrolledProgramListNoPagination: httpGet,
  enrollProgram: httpPost,
  consentContractNo: httpGet,
  loanContractNo: httpGet,
};

const api = {
  admin,
  provider,
};

export default api;
