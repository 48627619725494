import React from 'react';
import CollapsibleCardWithStatusChartCareTask from '../../component/ReportData/CollapsibleCardWithStatusChartCareTask';
import { withStyles } from '@material-ui/styles';
import ReportPage from 'appModule/reporting/component/ReportPDF/ReportPage';
import ReportDataViewTable from 'appModule/reporting/component/ReportPDF/ReportDataView/ReportDataViewTable';
import ReportDataViewTableHeader from 'appModule/reporting/component/ReportPDF/ReportDataView/ReportDataViewTableHeader';
import ReportDataViewTableCell from 'appModule/reporting/component/ReportPDF/ReportDataView/ReportDataViewTableCell';

const styles = {
  collapsibleCardGrid: {
    backgroundColor: '#212121',
    fontSize: '24px',
  },
  description: {
    marginTop: '5px',
    fontSize: '12px',
    letterSpacing: '0.08px',
    marginBottom: '5px',
    color: '#212121',
  },
  paper: {
    borderRadius: '5px',
    width: '820px',
  },
  patientProgramLabel: {
    fontSize: '12px',
    color: '#9E9E9E',
  },
  patientProgramGrid: {
    cursor: 'pointer',
    width: '9px',
    height: '15px',
    float: 'right',
    borderRadius: '14px',
    opacity: 1,
    marginRight: '3px',
  },
  patientProgramGrid2: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  patientProgramGridContent: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  patientProgramDetails: {
    fontSize: '14px',
  },
  reportInformationTitle1: {
    textAlign: 'right',
    fontSize: '12px',
    color: '#f44336',
  },
  reportInformationTitle2: {
    textAlign: 'right',
    fontSize: '12px',
    color: '#9e9e9e',
    float: 'right',
  },
  reportInformationPickerGrid: {
    justifyContent: 'center',
    alignContent: 'center',
    width: '100%',
  },
  advancePropsTypograph: {
    height: '100px',
    alignItems: 'center',
    display: 'flex',
  },
  cardLabel: {
    fontFamily: 'Roboto',
    fontWeight: 'medium',
    fontSize: '12px',
    color: '#FFFFFF',
    paddingTop: 16,
  },
  cardDetail: {
    fontFamily: 'Roboto',
    fontSize: '21px',
    color: '#FFFFFF',
  },
  vitalDiv: {
    borderLeft: '1px solid',
    height: '100px',
    marginTop: 3,
  },
  careTaskLegend: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '12px',
  },
  careTaskLegendCategoryDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  careTaskLegendCategoryLabel: {
    color: '#212121',
    fontWeight: '400',
    fontSize: '14px',
    marginLeft: '6px',
  },
  careTaskSummaryCardPaper: {
    display: 'flex',
    borderRadius: 3,
    marginLeft: 16,
    marginRight: 16,
    marginBottom: 20,
    boxShadow: 'none',
  },
  careTaskInfoTyphograph: {
    fontSize: '12px',
  },
  careTaskInfoGrid: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '10px',
  },
  careTaskInfoGrid2: {
    justifyContent: 'space-between',
  },
  careTaskToolTipCard: {
    height: 'auto',
    width: '275px',
    marginBottom: '10px',
    marginRight: '10px',
    padding: '12px',
    borderRadius: '8px',
  },
  selectionGridThreshold: {
    cursor: 'pointer',
    width: '9px',
    height: '15px',
    float: 'right',
    borderRadius: '14px',
    opacity: 1,
    marginRight: '3px',
  },
  refreshData: {
    fontSize: '12px',
    color: '#FFFFFF',
  },
  fabBtn: {
    height: '10px',
    width: '38px',
    float: 'right',
    backgroundColor: 'white',
    boxShadow: 'none',
  },
  iconClose: {
    color: '#4c4b4b',
    fontSize: '20px',
  },
  menuItemTypograph: {
    paddingLeft: '10px',
    textAlign: 'left',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0px',
    color: '#474747',
    opacity: 1,
  },
  menuItemTypographData: {
    paddingLeft: '10px',
    textAlign: 'left',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0px',
    color: '#474747',
    opacity: 1,
  },
  dataViewSelect: {
    paddingLeft: '16px',
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  paperBoxShadow: {
    boxShadow: '0px 0px 0px 1px rgb(0 0 0 / 10%), 0px 1px 1px 1px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%)',
  },
  statusInfoBoxShadow: {
    boxShadow: '0px 2px 11px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
  avatarTitle: {
    fontSize: '24px',
  },
  dialogAction: {
    justifyContent: 'space-between',
    paddingLeft: '25px',
    paddingRight: '25px',
    paddingBottom: '25px',
  },
  vitalCardTooltip: {
    height: 'auto',
    width: '275px',
    marginBottom: '10px',
    marginRight: '10px',
    padding: '12px',
    borderRadius: '8px',
  },
  container: {
    backgroundColor: 'white',
    overflow: 'hidden',
  },
  metadataTitle: {
    width: '40%',
    fontSize: 14,
  },
  metadataValue: {
    width: '60%',
    fontSize: 14,
  },
  tdHeader: {
    border: '1px solid black',
    borderCollapse: 'collapse',
    textAlign: 'left',
    fontSize: 12,
    fontWeight: 'bold',
    padding: 8,
  },
  td: {
    border: '1px solid black',
    borderCollapse: 'collapse',
    textAlign: 'left',
    fontSize: 12,
  },
  tdTotal: {
    border: '1px solid black',
    borderCollapse: 'collapse',
    fontWeight: 'bold',
    fontSize: 12,
    padding: 8,
  },
  metadataSpace: {
    height: 10,
  },
  dataViewTitle: {
    padding: '15px',
    backgroundColor: 'black',
    color: 'white',
    fontSize: '18px',
  },
};

@withStyles(styles, { index: 1 })
class IndividualReportingCaretaskChart extends React.Component {
  state = {
    careTaskData: {
      summaryData: {},
      chartData: [],
      dataView: [],
      loading: false,
      selectedView: null,
      selectedData: null,
      selectedEnrolledProgramme: [],
    },
  };

  async componentDidMount() {
    const { chartType } = this.props.location.query;
    this.setState(
      {
        careTaskData: {
          ...this.state.careTaskData,
          selectedView: chartType,
        },
      },
      async () => {
        await this.getSummary();
        await this.getChartData();
      },
    );
  }

  getSummary = async () => {
    const {
      'session-token': sessionToken,
      patientId,
      dateStart,
      dateEnd,
      epIds,
      vitalTypes,
      careTaskTypes,
    } = this.props.location.query;
    const { API_URL } = window.MILO_CONFIG;

    const epIdsParam = epIds.map((epId) => `epIds[]=${epId}`).join('&');
    const vitalTypesParam = vitalTypes.map((vitalType) => `vitalTypes[]=${vitalType}`).join('&');
    const careTaskTypesParam = careTaskTypes.map((careTaskType) => `careTaskTypes[]=${careTaskType}`).join('&');

    fetch(
      `${API_URL}/reports/provider/patient/${patientId}/careTask/adherence/summary?${epIdsParam}&dateStart=${dateStart}&dateEnd=${dateEnd}&${careTaskTypesParam}&${vitalTypesParam}&session-token=${sessionToken}`,
      { method: 'GET' },
    )
      .then((res) => res.json())
      .then((json) => json.value)
      .then((summaryData) => {
        this.setState({
          careTaskData: {
            ...this.state.careTaskData,
            summaryData,
          },
        });
      });
  };

  getChartData = async () => {
    const {
      'session-token': sessionToken,
      patientId,
      dateStart,
      dateEnd,
      timezone,
      epIds,
      vitalTypes,
      careTaskTypes,
      chartType,
    } = this.props.location.query;
    const { API_URL } = window.MILO_CONFIG;

    const epIdsParam = epIds.map((epId) => `epIds[]=${epId}`).join('&');
    const vitalTypesParam = vitalTypes.map((vitalType) => `vitalTypes[]=${vitalType}`).join('&');
    const careTaskTypesParam = careTaskTypes.map((careTaskType) => `careTaskTypes[]=${careTaskType}`).join('&');

    fetch(
      `${API_URL}/reports/provider/patient/${patientId}/careTask/adherence/chart?${epIdsParam}&dateStart=${dateStart}&dateEnd=${dateEnd}&${careTaskTypesParam}&${vitalTypesParam}&chartType=${chartType}&session-token=${sessionToken}`,
      { method: 'GET' },
    )
      .then((res) => res.json())
      .then((json) => json.value)
      .then((chartData) => {
        this.setState({
          careTaskData: {
            ...this.state.careTaskData,
            chartData: chartData.lineChartData || chartData.dataView,
          },
        });
      });
  };

  _table = () => {
    const { chartData } = this.state.careTaskData;
    const { classes } = this.props;
    return (
      <div style={{ height: '609px' }}>
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
          <thead>
            <td className={classes.tdHeader}>Month</td>
            <td className={classes.tdHeader}>Adherence</td>
          </thead>
          {chartData.map((data) => {
            return this._tableCell(data);
          })}
        </table>
      </div>
    );
  };

  renderLineChart = () => {
    const { classes, isEmbedded } = this.props;
    const { page = 1, totalPages = 10 } = this.props.location.query;
    return (
      <ReportPage isEmbedded={isEmbedded} page={page} totalPages={totalPages}>
        <CollapsibleCardWithStatusChartCareTask
          isEmbedded={isEmbedded}
          mode={'view'}
          pdf={true}
          status={'pristine'}
          title="Care Tasks"
          step={4}
          classes={classes}
          data={this.state.careTaskData}
          refresh={null}
          changeType={null}
        />
      </ReportPage>
    );
  };

  renderDataView = () => {
    const { isEmbedded } = this.props;
    const { page = 1, totalPages = 10 } = this.props.location.query;
    const titles = ['Month', 'Adherence'];
    const tableData = this.state.careTaskData.chartData.map((data) => {
      return [data.reportDate.display, data.adherence && `${data.adherence}%`];
    });
    return (
      <ReportPage isEmbedded={isEmbedded} page={page} totalPages={totalPages}>
        <ReportDataViewTable dataViewTitle={'Monthly Adherence'}>
          <ReportDataViewTableHeader titles={titles} />
          <ReportDataViewTableCell tableData={tableData} />
        </ReportDataViewTable>
      </ReportPage>
    );
  };

  render = () => {
    const {
      careTaskData: { selectedView },
    } = this.state;
    if (!selectedView) return null;
    if (selectedView === 'LINE_CHART') {
      return this.renderLineChart();
    }

    if (selectedView == 'DATA_VIEW') {
      return this.renderDataView();
    }

    return null;
  };
}

export default IndividualReportingCaretaskChart;
