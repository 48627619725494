import React from 'react';

const ReportDataViewTableHeader = (props) => {
  const { titles } = props;
  return (
    <thead>
      {
        titles.map(title => {
          return (
            <td
              key={title}
              style={{
                border: '1px solid black',
                borderCollapse: 'collapse',
                textAlign: 'left',
                fontSize: 12,
                fontWeight: 'bold',
                padding: 8,
              }}>{title}</td>
          );
        })
      }
    </thead>
  );
};

export default ReportDataViewTableHeader;
