/* eslint-disable max-len */
import React from 'react';
import * as R from 'ramda';
import update from 'immutability-helper';
import { replace, size } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { formValueSelector, change, SubmissionError } from 'redux-form';
import { memoize, switchProp } from 'utilsModule';
import { withIPLocation } from 'utilsModule/ipLocation';
import { withResource, resourceTypes, gettersOf } from 'dataModule/store/resources';
import { actionCreators as messageActionCreators } from 'appModule/message/ducks/message';
import { Loading } from 'utilsModule/components';
import CovidIndividualCreate from '../components/CovidIndividualCreate';
import CovidUtil from '../utils/CovidUtil';

@withIPLocation()
@withResource([
  { resourceType: resourceTypes.COUNTRIES, method: 'retrieveiHealthCountries', options: { runOnDidMount: true } },
  { resourceType: resourceTypes.COUNTRIES, method: 'retrieveMany', options: { runOnDidMount: true } },
  { resourceType: resourceTypes.CONFIGS, method: 'retrieveStates', options: { runOnDidMount: true } },
  { resourceType: resourceTypes.CONFIGS, method: 'retrieveCities', options: { runOnDidMount: true } },
  { resourceType: resourceTypes.CONFIGS, method: 'retrieveCitiesByState' },
  { resourceType: resourceTypes.CONFIGS, method: 'retrieveCountries', options: { runOnDidMount: true } },
  { resourceType: resourceTypes.USERS, method: 'create' },
])
@connect(
  state => ({
    selectedCountry: formValueSelector('createCovidIndividual')(state, 'basicInfo.country'),
    stateOptions: formValueSelector('createCovidIndividual')(state, 'basicInfo.state.options'),
    addr1: formValueSelector('createCovidIndividual')(state, 'basicInfo.addr1'),
    addr2: formValueSelector('createCovidIndividual')(state, 'basicInfo.addr2'),
    postalCode: formValueSelector('createCovidIndividual')(state, 'basicInfo.postalCode'),
    country: formValueSelector('createCovidIndividual')(state, 'basicInfo.country'),
    state: formValueSelector('createCovidIndividual')(state, 'basicInfo.state'),
    city: formValueSelector('createCovidIndividual')(state, 'basicInfo.city'),
    cardNumber: formValueSelector('createCovidIndividual')(state, 'paymentDetails.cardNumber'),
    cardExpiry: formValueSelector('createCovidIndividual')(state, 'paymentDetails.cardExpiry'),
    currentActiveField: R.path(['form', 'createCovidIndividual', 'active'], state),
  }),
  {
    notify: messageActionCreators.show,
    changeField: change,
  },
)
@withRouter
class CovidIndividualCreateContainer extends React.Component {
  state = {
    loading: false,
  }
  componentDidMount() {
    // Load Stripe library only in this component
    this.loadStripe();
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  loadStripe = () => {
    if (!window.document.getElementById('stripe-script')) {
      const s = window.document.createElement('script');
      s.id = 'stripe-script';
      s.type = 'text/javascript';
      s.src = 'https://js.stripe.com/v2/';
      s.onload = () => {
        window.Stripe.setPublishableKey(window.MILO_CONFIG.STRIPE_PUBLISHABLE_KEY || '');
      };
      window.document.body.appendChild(s);
    }
  }

  shouldComponentUpdate(nextProps) {
    const { data } = nextProps;
    const donotUpdate = data.status.loading;
    return !donotUpdate;
  }

  handleCardNumberChange = (value) => {
    const { changeField } = this.props;
    const filterValue = replace(value, / /g, '');
    const cardNumberLength = size(filterValue);
    const spaceCount = Math.floor((cardNumberLength - 1) / 4);
    let modifyValue = filterValue.slice(0, cardNumberLength > 4 ? 4 : cardNumberLength);

    for (let i = 0; i < spaceCount; i += 1) {
      modifyValue = `${modifyValue} ${filterValue.slice(
        (i + 1) * 4,
        cardNumberLength < (i + 2) * 4 ? cardNumberLength : (i + 2) * 4,
      )}`;
    }
    setTimeout(() => changeField('createCovidIndividual', 'paymentDetails.cardNumber', modifyValue), 1);
  }

  handleCardExpiryChange = (value) => {
    const { cardExpiry = '', changeField } = this.props;
    const filterValue = replace(value, /\//g, '');
    const cardNumberLength = size(filterValue);
    let modifyValue = filterValue;

    if (cardNumberLength === 2 && size(cardExpiry) >= 3) {
      modifyValue = filterValue;
    } else if (cardNumberLength >= 2) {
      modifyValue = `${filterValue.slice(0, 2)}/${filterValue.slice(2, size(filterValue))}`;
    }
    setTimeout(() => changeField('createCovidIndividual', 'paymentDetails.cardExpiry', modifyValue), 1);
  }

  handleCreate = async (values) => {
    this.setState({ loading: true });
    const { actionCreators, notify, history } = this.props;

    // 1. Call Stripe CreateToken API to tokenize credit card
    const cloneValues = R.clone(values);
    const { paymentDetails: { cardNumber, cardExpiry, cardCVV } } = cloneValues;

    // const fakeCardExpiry = '11/25';
    // const stripeTestCard = {
    //   number: '4242424242424242',
    //   expMonth: R.split('/', fakeCardExpiry)[0],
    //   expYear: R.split('/', fakeCardExpiry)[1],
    //   cvc: '123',
    // };

    // For testing, please use any card provided by Stripe
    // https://stripe.com/docs/testing
    const creditCardPayload = {
      number: cardNumber,
      expMonth: R.split('/', cardExpiry)[0],
      expYear: R.split('/', cardExpiry)[1],
      cvc: cardCVV,
    };

    const stripeCallBack = (status, response) => {
      if (status === 200) {
        // 2. Prepare payload and call Account Create API
        const addCountryCode = mobileValue => ({
          countryCode: this.runtimeProps.body.shared.countryCode,
          value: mobileValue,
        });
        const { id: cardToken } = response;
        const cargo = {
          method: 'createCovid',
          input: {
            content: R.pipe(
              R.assocPath(['basicInfo', 'idType', 'value'], 'PATIENT'),
              R.assocPath(['paymentDetails', 'creditCardToken'], cardToken),
              v => update(v, {
                basicInfo: {
                  mobile: addCountryCode,
                  homeNumber: addCountryCode,
                },
              }),
            )(values),
          },
        };

        // console.log('%crender Organisation Create Cargo', 'font-size: 12px; color: #00b3b3', cargo);

        return new Promise((resolve, reject) =>
          actionCreators[resourceTypes.USERS].ajax({
            cargo,
            onSuccess: ({ data: result }) => {
              if (this.mounted) {
                resolve(result);
                const param = {
                  form: 'INDIVIDUAL',
                };
                history.push('/covid-19-reg-success', param);
              }
            },
            onFailure: ({ error }) => {
              this.setState({ loading: false });
              if (this.mounted) {
                reject(new SubmissionError({ _error: JSON.stringify(error) }));
                notify({
                  message: process.env.DEBUG
                    ? `Create: ${JSON.stringify(error, ['code', 'message'])}`
                    : 'Failed to create organisation',
                  type: 'error',
                });
              }
            },
          }));
      }
      notify({ message: 'There has been an error verifying your credit card. Please try again or use another card.', type: 'error' });
      this.setState({ loading: false });
    };
    window.Stripe.card.createToken(creditCardPayload, stripeCallBack.bind(this));
  }

  getInitialCountryStateCity = (selectedCountry) => {
    const {
      data,
      actionCreators,
      changeField,
      notify,
    } = this.props;

    const countries = gettersOf(resourceTypes.CONFIGS).getCountries()(data);
    const { iso = 'SG' } = countries.find(c => c.value === selectedCountry);

    changeField('createCovidIndividual', 'basicInfo.mobile.countryCode', {
      options: R.map(({ iso, countryCode: countryCodeOptions, flag }) => ({
        id: iso, value: countryCodeOptions, label: `+${countryCodeOptions}`, iso, flag,
      }))(countries),
      value: switchProp('countryCode', 'iso', iso, countries),
    });

    changeField('createCovidIndividual', 'basicInfo.homeNumber.countryCode', {
      options: R.map(({ iso, countryCode: countryCodeOptions, flag }) => ({
        id: iso, value: countryCodeOptions, label: `+${countryCodeOptions}`, iso, flag,
      }))(countries),
      value: switchProp('countryCode', 'iso', iso, countries),
    });
    actionCreators[resourceTypes.CONFIGS].ajax({
      cargo: {
        method: 'retrieveStates',
        input: {
          params: {
            queries: [
              { name: 'country', value: selectedCountry },
            ],
          },
        },
      },
      onSuccess: ({ cargo: { method }, data: result }) => {
        if (result[method].list.length === 0) {
          actionCreators[resourceTypes.CONFIGS].ajax({
            cargo: {
              method: 'retrieveCities',
              input: {
                params: {
                  queries: [
                    { name: 'country', value: selectedCountry },
                  ],
                },
              },
            },
            onSuccess: ({ cargo: { method: retrieveCities }, data: cities }) => {
              changeField('createCovidIndividual', 'basicInfo.city', {
                options: cities[retrieveCities].list,
                value: R.pathOr('', [retrieveCities, 'list', 0, 'value'], cities),
              });
            },
            onFailure: ({ error }) => {
              const reportedError = JSON.stringify(error, ['code', 'message']);
              notify({ message: `Couldn't get cities of ${selectedCountry}.\nReason: ${reportedError}`, type: 'error' });
              changeField('createCovidIndividual', 'basicInfo.city', { options: [] });
            },
          });
        } else {
          changeField('createCovidIndividual', 'basicInfo.state', {
            options: result[method].list,
            value: R.pathOr('', [method, 'list', 0, 'value'], result),
          });

          // Workaround as changeField does not trigger onChange method of city
          actionCreators[resourceTypes.CONFIGS].ajax({
            cargo: {
              method: 'retrieveCitiesByState',
              input: {
                params: {
                  queries: [
                    { name: 'state', value: R.pathOr('', [method, 'list', 0, 'value'], result) },
                  ],
                },
              },
            },
            onSuccess: ({ cargo: { method: retrieveCitiesByState }, data: cities }) => {
              changeField('createCovidIndividual', 'basicInfo.city', {
                options: cities[retrieveCitiesByState].list,
                value: R.pathOr('', [retrieveCitiesByState, 'list', 0, 'value'], cities),
              });
            },
            onFailure: ({ error }) => {
              const reportedError = JSON.stringify(error, ['code', 'message']);
              notify({ message: `Couldn't get cities of ${R.pathOr('', [method, 'list', 0, 'value'], result)}.\nReason: ${reportedError}`, type: 'error' });
              changeField('createCovidIndividual', 'basicInfo.city', { options: [] });
            },
          });
        }
      },
      onFailure: ({ error: { code, message } }) => {
        notify({ message: `Couldn't get cities of ${selectedCountry}.\nReason: ${code} - ${message}`, type: 'error' });
        changeField('createCovidIndividual', 'basicInfo.city', { options: [] });
      },
    });
  }

  getInitialValues = memoize((countryList) => {
    const { data, getCountryCodeByIp } = this.props;
    const state = gettersOf(resourceTypes.CONFIGS).getStates()(data);
    const city = gettersOf(resourceTypes.CONFIGS).getCities()(data);
    const cityOptions = R.map(R.pick(['id', 'value', 'label']), city);
    const countries = gettersOf(resourceTypes.CONFIGS).getCountries()(data);

    const { location: { query: { country = '' } = {} } = {} } = this.props;
    this.getInitialCountryStateCity(country);

    return {
      basicInfo: {
        email: '',
        mobile: {
          countryCode: {
            options: R.map(({ iso, countryCode, flag }) => ({
              id: iso, value: countryCode, label: `+${countryCode}`, iso, flag,
            }))(countries),
            value: getCountryCodeByIp().toString(),
          },
        },
        firstName: '',
        lastName: '',
        dob: '',
        gender: {
          options: [
            { id: 'Male', value: 'Male', label: 'Male' },
            { id: 'Female', value: 'Female', label: 'Female' },
            { id: 'Unspecified', value: 'Unspecified', label: 'Unspecified' },
            { id: 'Unknown', value: 'Unknown', label: 'Unknown' },
            { id: 'Others', value: 'Others', label: 'Others' },
          ],
        },
        homeNumber: {
          countryCode: {
            options: R.map(({ iso, countryCode, flag }) => ({
              id: iso, value: countryCode, label: `+${countryCode}`, iso, flag,
            }))(countries),
            value: getCountryCodeByIp().toString(),
          },
        },
        addr1: '',
        country: {
          options: R.map(R.pick(['id', 'value', 'label']), countryList),
          value: country,
        },
        state: {
          options: R.map(R.pick(['id', 'value', 'label']), state),
        },
        city: {
          options: cityOptions,
        },
        addr2: '',
        postalCode: '',
      },
      paymentDetails: {
        paymentAmount: 'USD 0.99 / month',
      },
      acceptance: false,
    };
  });

  render() {
    const {
      data, actionCreators,
      notify, changeField,
      selectedCountry, stateOptions, cardNumber,
    } = this.props;

    // console.log('%crender CovidGroupCreateContainer', 'font-size: 12px; color: #00b3b3', this.props);

    // First render
    if (data.status.loading) return <Loading />;

    // Sanitize data
    const iHealthCountryList = gettersOf(resourceTypes.COUNTRIES).getStatus('retrieveiHealthCountries')(data, false).success
      ? gettersOf(resourceTypes.COUNTRIES).getiHealthCountries(data, false)
      : [];
    const commonCountryList = gettersOf(resourceTypes.COUNTRIES).getStatus('retrieveMany')(data, false).success
      ? gettersOf(resourceTypes.COUNTRIES).getCountries(data, false)
      : [];
    const countryList = R.map(({ id, name }) => ({
      id, value: name, label: name, countryCode: R.pipe(R.find(R.propEq('value', name)), R.prop('countryCode'))(commonCountryList),
    }), iHealthCountryList);
    const countryCode = R.prop('countryCode', R.find(R.propEq('value', R.prop('value', selectedCountry)), commonCountryList));

    this.initialValues = this.getInitialValues(countryList);
    const creditCardType = CovidUtil.getCreditCardType(replace(cardNumber, / /g, ''));

    if (!this.inited) {
      this.runtimeProps = {
        body: {
          shared: {
            countryCode,
          },
          basicInfo: {
            state: {
              onChange: (event, { value: stateValue }) =>
                actionCreators[resourceTypes.CONFIGS].ajax({
                  cargo: {
                    method: 'retrieveCitiesByState',
                    input: {
                      params: {
                        queries: [
                          { name: 'state', value: stateValue },
                        ],
                      },
                    },
                  },
                  onSuccess: ({ cargo: { method }, data: result }) => {
                    changeField('createCovidIndividual', 'basicInfo.city', {
                      options: result[method].list,
                      value: R.pathOr('', [method, 'list', 0, 'value'], result),
                    });
                  },
                  onFailure: ({ error }) => {
                    const reportedError = JSON.stringify(error, ['code', 'message']);
                    notify({ message: `Couldn't get cities of ${stateValue}.\nReason: ${reportedError}`, type: 'error' });
                    changeField('createCovidIndividual', 'basicInfo.city', { options: [] });
                  },
                }),
            },
          },
          paymentDetails: {
            cardExpiry: {
              onChange: (event, value) => this.handleCardExpiryChange(value),
            },
            cardNumber: {
              onChange: (event, value) => this.handleCardNumberChange(value),
              creditCardType,
            },
          },
        },
      };

      this.inited = true;
    }

    this.runtimeProps = update(this.runtimeProps, {
      body: {
        shared: {
          countryCode: { $set: countryCode },
        },
        basicInfo: {
          state: {
            $merge: {
              disableState: selectedCountry && selectedCountry.value === '',
              notApplicable: selectedCountry && selectedCountry.value !== '' && stateOptions && stateOptions.length <= 0,
            },
          },
        },
        paymentDetails: {
          cardNumber: { $merge: { creditCardType } },
        },
      },
    });

    return (
      <CovidIndividualCreate
        handleEdit={this.handleEdit}
        initialValues={this.initialValues}
        runtimeProps={this.runtimeProps}
        handleCreate={this.handleCreate}
        handleCancel={this.handleCancel}
        loading={this.state.loading}
      />
    );
  }
}

export default CovidIndividualCreateContainer;
