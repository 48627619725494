import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  typography: {
    // Headline 1
    h1: {
      fontSize: 96,
      fontWeight: 300,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.17,
      letterSpacing: -0.14,
      color: '#212121',
    },
    // Headline 2
    h2: {
      fontSize: 60,
      fontWeight: 300,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.18,
      letterSpacing: -0.3,
      color: '#212121',
    },
    // Headline 3
    h3: {
      fontSize: 48,
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.19,
      letterSpacing: 0,
      color: '#212121',
    },
    // Page Header
    h4: {
      fontSize: 34,
      fontWeight: 500,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.18,
      letterSpacing: 0.02,
      color: '#212121',
    },
    // Table Header
    h5: {
      fontSize: 24,
      fontWeight: 500,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.17,
      letterSpacing: 0.01,
      color: '#212121',
    },
    // Section Header
    h6: {
      fontSize: 20,
      fontWeight: 500,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.19,
      letterSpacing: 0,
      color: '#212121',
    },
    // Side Menu
    subtitle1: {
      fontSize: 16,
      fontWeight: 500,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.19,
      letterSpacing: 0,
      color: '#212121',
    },
    // Reading Style 1
    subtitle2: {
      fontSize: 14,
      fontWeight: 500,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.14,
      letterSpacing: 0,
      color: '#212121',
    },
    // Reading Style 2
    body1: {
      fontSize: 16,
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.19,
      letterSpacing: 0.01,
      color: '#212121',
    },
    // Button
    body2: {
      fontSize: 14,
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.14,
      letterSpacing: 0,
      color: '#212121',
    },
    // Button
    button: {
      textTransform: 'none',
      fontSize: 14,
      fontWeight: 500,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.14,
      letterSpacing: 0.02,
      color: '#212121',
    },
    // Label
    caption: {
      fontSize: 12,
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.17,
      letterSpacing: 0,
      color: '#9e9e9e',
    },
    // Overline
    overline: {
      fontSize: 10,
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1,
      letterSpacing: 0.6,
      color: '#212121',
    },
  },
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: 20,
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: '#3FD762',
        },
      },
    },
  },
});
