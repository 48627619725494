// eslint-disable-next-line no-inline-style
import React from 'react';
import { withResource, resourceTypes } from 'dataModule/store/resources';
import { connect } from 'react-redux';
import { fetchGoThumbSignedUrl } from 'utilsModule/gothumb';
import { actionCreators } from '../../measurements/ducks/list';
import PatientVitalReportComponent from '../components/PatientVitalReport';

@withResource([
  {
    resourceType: resourceTypes.PROGRAMS,
    method: 'retrieveListPDFPatientVitalReport',
  },
])
@connect(
  (state) => ({}),
  { ...actionCreators },
)
class PatientVitalReportContainer extends React.Component {
  state = {
    data: null,
    reportMetaData: null,
  };

  componentDidMount() {
    this.fetchVital();
  }

  fetchVital = () => {
    const {
      actionCreators,
      location: {
        query: { from, to, epId, take, page, vitalGroupId },
      },
    } = this.props;
    const sessionToken = this.props.location.query['session-token'];
    actionCreators[resourceTypes.PROGRAMS].ajax({
      cargo: {
        method: 'retrieveListPDFPatientVitalReport',
        input: {
          params: {
            queries: [
              { name: 'epId', value: +epId },
              { name: 'take', value: take },
              { name: 'page', value: page },
              { name: 'from', value: +from },
              { name: 'to', value: +to },
              { name: 'type', value: 'list-pdf' },
              { name: 'session-token', value: sessionToken },
              { name: 'vitalGroupId', value: +vitalGroupId },
            ],
          },
        },
      },
      onSuccess: async (data) => {
        const {
          data: {
            retrieveListPDFPatientVitalReport: { value },
          },
        } = data;
        if (value) {
          const { reportMetaData, data } = value;
          let goThumbUrl = null;
          if (reportMetaData.hospitalLogo !== null) {
            goThumbUrl = await fetchGoThumbSignedUrl(reportMetaData.hospitalLogo, sessionToken);
          }
          this.setState({
            data,
            reportMetaData: {
              ...reportMetaData,
              hospitalLogo: goThumbUrl,
            },
          });
        }
      },
      onFailure: ({ error }) => {
        console.log(error);
      },
    });
  };

  render = () => {
    const { data, reportMetaData } = this.state;
    const {
      location: {
        query: { page, totalPages, vitalGroupId },
      },
    } = this.props;

    if (!data) {
      return null;
    }
    return (
      <PatientVitalReportComponent
        {...this.props}
        vitalGroupId={vitalGroupId}
        data={data}
        reportMetaData={reportMetaData}
        page={page}
        totalPages={totalPages}
      />
    );
  };
}

export default PatientVitalReportContainer;
