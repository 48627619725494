import React from 'react';
import CryptoJS from 'crypto-js';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { withResource, resourceTypes, gettersOf } from 'dataModule/store/resources';
import { actionCreators as messageActionCreators } from 'appModule/message/ducks/message';
import { Loading } from 'utilsModule/components';
import { withIPLocation } from 'utilsModule/ipLocation';
import { updateEnvConfig } from 'utilsModule';
import { Login, ReverifyLogin } from '../components';
import { OTP_TYPES } from './OTP';

const LOGIN_TYPES = {
  AUTH: 'AUTH',
  ONBOARD: 'ONBOARD',
};

@withIPLocation()
@withResource([
  {
    resourceType: resourceTypes.CONFIGS,
    method: 'retrieveLoginCountryCodes',
    input: {
      params: {
        queries: [
          { name: 'global', value: window.MILO_CONFIG.ENABLE_GLOBAL_TABLE.toString() },
        ],
      },
    },
    options: { runOnDidMount: true },
  },
  { resourceType: resourceTypes.USERS },
])
@connect(null, { notify: messageActionCreators.show })
@withRouter
class LoginContainer extends React.Component {
  static propTypes = {
    actionCreators: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  forgetPassword = () => {
    this.props.history.push('/forgetpassword/verifymobile/');
  }

  componentDidUpdate() {
    /* NOTE: Notify failures of withResource */
    const {
      data: {
        status: { loading, success, error },
      },
      notify,
    } = this.props;
    !this.notified && !loading && !success && notify({ message: error, type: 'error' });
    this.notified = true;
  }

  // eslint-disable-next-line
  shouldComponentUpdate(nextProps) {
    return !nextProps.data.status.loading;
  }

  invokeRequest2FA = (LOGIN_URL, mobileCC, mobileNo, encryptPassword, resolve, reject) => {
    let method;
    let credentials;
    let next;

    const {
      match: { params: { type = LOGIN_TYPES.AUTH } = {} },
      location: { state: { referrer: entryLocation } = {} },
      data, actionCreators, history, notify,
    } = this.props;

    // Encrypt the password before sending to the BE
    const cipherText = CryptoJS.AES.encrypt(encryptPassword, '@2ecret4uth2907!');
    const password = cipherText.toString();


    switch (type) {
      case LOGIN_TYPES.AUTH: {
        method = 'request2FA';
        credentials = {
          loginId: `${mobileCC} ${mobileNo}`,
          password,
        };
        next = (request2FAData) => {
          if (LOGIN_URL) {
            window.location.href = `${LOGIN_URL}/auth/otp/${OTP_TYPES.AUTH_TFA}?token=${request2FAData.request2FA.token}&phone=${credentials.loginId}`;
          } else {
            history.push(`/auth/otp/${OTP_TYPES.AUTH_TFA}`, { ...credentials, entryLocation });
          }
        };
        break;
      }
      case LOGIN_TYPES.ONBOARD: {
        method = 'request2FAForOnboard';
        credentials = {
          invitationToken: gettersOf(resourceTypes.USERS).getInvitationToken()(data),
          username: `${mobileCC} ${mobileNo}`,
          password,
        };
        next = (request2FAForOnboardData) => {
          history.push(`/auth/otp/${OTP_TYPES.ONBOARD_TFA}`, credentials);
        };
        break;
      }
      default:
        if (process.env.DEBUG) throw new TypeError(`Unknown Login type ${type}`);
    }

    actionCreators[resourceTypes.USERS].ajax({
      cargo: {
        method,
        input: { content: credentials },
      },
      onSuccess: ({ data: result }) => {
        if (this.mounted) {
          resolve(result);
          next(result);
        }
      },
      onFailure: ({ error }) => {
        if (this.mounted) {
          const reportedError = JSON.stringify(error, ['message']);
          reject(new SubmissionError({ _error: reportedError }));
          notify({ message: error.message, type: 'error' });
        }
      },
    });
  }

  signIn = ({
    mobile: {
      countryCode: { value: mobileCC } = {},
      value: mobileNo,
    } = {},
    encryptPassword,
  }) => {
    const { actionCreators, notify } = this.props;
    const { ENABLE_GLOBAL_TABLE } = window.MILO_CONFIG;

    if (ENABLE_GLOBAL_TABLE) {
      return new Promise((resolve, reject) => {
        actionCreators[resourceTypes.USERS].ajax({
          cargo: {
            method: 'getServerMapping',
            input: { content: { loginId: `${mobileCC} ${mobileNo}` } },
          },
          onSuccess: ({ data: { getServerMapping: { status, error, API_URL, GOTHUMB_URL, LOGIN_URL } } }) => {
            if (status) {
              updateEnvConfig(API_URL, GOTHUMB_URL);
              this.invokeRequest2FA(LOGIN_URL, mobileCC, mobileNo, encryptPassword, resolve, reject);
            } else {
                reject(new SubmissionError({ _error: error }));
                notify({ message: error, type: 'error' });
            }
          },
        });
      });
    }
    return new Promise((resolve, reject) =>
      this.invokeRequest2FA(null, mobileCC, mobileNo, encryptPassword, resolve, reject));
  };

  render() {
    const { data, match, getCountryCodeByIp } = this.props;

    // console.log('%crender LoginContainer', 'font-size: 12px; color: #00b3b3', this.state, this.props);

    // First render
    if (data.status.loading) return <Loading />;

    // Sanitize data
    const countries = gettersOf(resourceTypes.CONFIGS).getLoginCountryCodes()(data);

    if (!this.inited) {
      this.initialValues = {
        mobile: {
          countryCode: {
            options: R.map(({ iso, countryCode, flag }) => ({
              id: iso, value: countryCode, label: `+${countryCode}`, iso, flag,
            }))(countries),
            value: getCountryCodeByIp(),
          },
        },
      };

      this.runtimeProps = {};
      this.inited = true;
    }

    let isOnBoard = false;
    if(match.url === '/auth/login/ONBOARD') {
      isOnBoard = true;
      return (<ReverifyLogin
        initialValues={this.initialValues}
        runtimeProps={this.runtimeProps}
        signIn={this.signIn}
        forgetPassword={this.forgetPassword}
        isOnBoard={isOnBoard}
      />);
    }

    return (
      <Login
        initialValues={this.initialValues}
        runtimeProps={this.runtimeProps}
        signIn={this.signIn}
        forgetPassword={this.forgetPassword}
        isOnBoard={isOnBoard}
      />
    );
  }
}

export { LOGIN_TYPES };
export default LoginContainer;
