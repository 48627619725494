import { path } from 'ramda';

/*
**************************************************
  Action Types
**************************************************
*/
const prefix = 'FEEDBACK';

const actionTypes = {
  GET_FEEBACKS: Symbol(`${prefix}/GET_FEEBACKS`),
  GET_FEEBACKS_SUCCESS: Symbol(`${prefix}/GET_FEEBACKS_SUCCESS`),
  GET_FEEDBACKS_FAILED: Symbol(`${prefix}/GET_FEEDBACKS_FAILED`),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  getFeedbacks: payload => ({ type: actionTypes.GET_FEEBACKS, payload }),
  getFeedbacksSuccess: payload => ({ type: actionTypes.GET_FEEBACKS_SUCCESS, payload }),
  getFeedbacksFaled: payload => ({ type: actionTypes.GET_FEEDBACKS_FAILED, payload }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/
const epics = [];

export { epics };

/*
**************************************************
  State Getters
**************************************************
*/
const getState = path(['feedback']);

const getters = {
  getState,
};

export { getters };

/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
  list: [],
  attemptGettingFeedbacks: false
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.getFeedbacks: {
      return {
        ...state,
        attemptGettingFeedbacks: true
      };
    }
    case actionTypes.getFeedbacksSuccess: {
      const { payload } = action;
      return {
        ...state,
        list: payload,
        attemptGettingFeedbacks: false
      };
    }
    default: {
      return state;
    }
  }
};
