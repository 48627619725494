import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    ns: ['common', 'validation'],
    defaultNS: 'common',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already escapes value
    },
    backend: {
      loadPath: '{{lng}}/{{lng}}.{{ns}}.json',
      ajax: (url, options, callback, data) =>
        import(/* webpackChunkName: '[request]' */ `dataModule/assets/i18n/locales/${url}`)
          .then(({ default: locale }) => callback(JSON.stringify(locale), { status: '200' }))
          .catch((error) => {
            console.warn(
              `%cUnable to import locale ${url}`,
              'font-size: 12px; color: lightcoral',
              error,
            );
            callback(null, { status: '404' });
          }),
    },
    detection: {
      // order and from where user language should be detected
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: [],
    },
  });

export default i18n;
