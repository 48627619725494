import { combineReducers } from 'redux';
import list, { epics as listEpics } from './list';

export const epics = [
  ...listEpics,
];

export default combineReducers({
  list,
});
