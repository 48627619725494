import * as R from 'ramda';
import moment from 'moment-timezone';

class Chat {
  constructor({ room, ChatEngine }) {
    this.ChatEngine = ChatEngine;
    this.room = room;
    this.epId = `${room.epId}`;
    this.roomId = `${room._id}`;
    this.channels = room.channels || [];
    this.channelLen = this.channels.length;
    this.chats = [];
    this.isConnected = false;
    this.unreadMessages = [];
    this.messages = [];
    this.hasMoreHistory = true;
    this.timePeriods = [];
    this.search = null;
    this.isSearching = false;
    this.onlineUsers = [];
  }

  connectChannels() {
    const channelNum = this.channelLen;
    let connectedTimes = 0;
    return new Promise((resolve, reject) => {
      try {
        if (this.channelLen === 0) {
          resolve();
        }
        this.channels.forEach((channel) => {
          const chat = new this.ChatEngine.Chat(channel);
          chat.on('$.connected', () => {
            connectedTimes += 1;
            this.chats.push(chat);
            if (connectedTimes === channelNum) {
              this.isConnected = true;
              resolve(this);
            }
          });
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  registerEvent({ receiveMsg, online, offline }) {
    if (this.isConnected) {
      this.chats[0]
        .on('message.*', (msg) => {
          const result = this.receiveMessage({
            message: msg,
            epId: this.epId,
          });
          receiveMsg(result);
        })
        .on('$.online.*', ({ user, chat }) => {
          // console.log('room=>$.online', user.uuid, user.state);
          online({ uuid: user.uuid, newState: user.state, epId: this.epId });
        })
        .on('$.offline.*', ({ user, chat }) => {
          // console.log('room=>$.offline', user.uuid, user.state);
          offline({ uuid: user.uuid, newState: user.state, epId: this.epId });
        });
    }
  }
  receiveMessage({ message, epId, isHistory }) {
    const formattedMessage = this.formattedOnlineMessage(message);

    if (isHistory) {
      this.messages.unshift(formattedMessage);
    } else {
      this.messages.push(formattedMessage);
    }

    return {
      message: formattedMessage,
      epId,
    };
  }

  sendMessage({ eventType = 'text', msgType = 'text', content }) {
    this.chats[0].emit(`message.${eventType}`, {
      type: msgType,
      content,
      timestamp: new Date().getTime(),
      roomId: `${this.epId}`,
    });
  }

  formattedOnlineMessage(message) {
    const curDay = moment(Number(message.timetoken.slice(0, message.timetoken.length - 4))).format('D MMM gggg');
    const SP = 'Some People';
    let sender = message.sender || {};
    if (!R.is(Object, sender)) {
      const uuid = sender;
      sender = {
        uuid,
        state: {
          nickName: SP,
        },
      };
    }

    if (!sender.uuid) {
      sender = {
        uuid: SP,
        state: {
          nickName: SP,
        },
      };
    } else {
      sender = R.pick(['uuid', 'state'], sender);
      const user = R.path([sender.uuid], this.users);
      if (!sender.state.nickName || sender.state.nickName === SP) {
        if (user) {
          sender.state = {
            nickName: `${user.name.firstName} ${user.name.lastName}`,
            avatar: user.profile_photo,
          };
        } else {
          sender.state = {
            nickName: SP,
          };
        }
      }

      if (sender.state.nickName && sender.state.nickName !== SP) {
        if (user) {
          sender.state = {
            nickName: `${user.name.firstName} ${user.name.lastName}`,
            avatar: user.profile_photo,
            status: 'online',
            type: user.type,
          };
        } else {
          sender.state = {
            nickName: SP,
          };
        }
      }
    }

    return {
      ...message.data,
      sender,
      timetoken: message.timetoken,
      curDay,
    };
  }

  setTimePeriods(timePeriods) {
    if (!(timePeriods && timePeriods.length)) return;
    this.timePeriods = timePeriods;
  }

  setUsers({ members }) {
    if (!R.is(Array, members) || members.length === 0) return;
    this.users = {};
    members.forEach((item) => {
      this.users[item.id] = item;
    });
  }

  getValue() {
    return R.omit(['chats', 'ChatEngine'], this);
  }

  close() {
    this.chats.forEach((c) => {
      c.off('*', (e) => {
        // console.log('off,*', e);
      });
      c.leave();
    });
  }

  searchHistory({ cb }) {
    // first search  return count*11
    // next search  return (pages+1)*count
    if (!this.isConnected) return;
    const letMessageShowDate = (messages) => {
      if (!messages || messages.length <= 0) return [];
      return messages.map((msg, i) => Object.assign(msg, { showDate: i === 0 }));
    };
    let messages = [];
    const pages = 4;
    const count = 10;
    const searchDone = (msgs) => {
      this.isSearching = false;
      messages = [];
      cb({
        messages: msgs,
        hasMoreHistory: this.hasMoreHistory,
        epId: this.epId,
      });
    };

    if (!this.search) {
      this.isSearching = true;

      this.searchTimes = 1;
      this.search = this.chats[0]
        .search({
          limit: 50000,
          //   start: 15289571963320000,
          //   end: 15289572133620000,
          pages,
          count,
        })
        .on('message.*', (message) => {
          const result = this.receiveMessage({
            message,
            epId: this.epId,
            isHistory: true,
          });
          messages = [result.message, ...messages];
        })
        .on('$.search.page.request', () => {
          // console.log('$.search.page.request');
        })
        .on('$.search.page.response', () => {
          // console.log('$.search.page.response===' + responseTimes);
        })
        .on('$.search.pause', () => {
          searchDone(letMessageShowDate(messages));
          // console.log('$.search.pause');
        })
        .on('$.search.finish', () => {
          // console.log('we have all our results!');
          this.hasMoreHistory = false;
          searchDone(letMessageShowDate(messages));
        });
    } else if (this.hasMoreHistory) {
      this.isSearching = true;
      this.searchTimes += 1;
      this.search.next();
    }
  }
}

export default Chat;
