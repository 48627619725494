import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Paper,
  Typography,
  Button,
  Avatar as MuiAvatar,
  Divider,
  Grid,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Tooltip from '@material-ui/core/Tooltip';
import * as R from 'ramda';
import uuidv4 from 'uuid/v4';
import {
  withReduxForm,
  TextField,
  Select,
  TypeSelect,
  Phone,
  DatePicker,
  CheckboxRightLabelGroup,
} from 'utilsModule/form/reduxFormMui';
import { replace } from 'lodash';
import { InfoOutline } from 'utilsModule/material-ui-icons';
import { validators, decideStatusFromMeta } from 'utilsModule/form';
import { Back, TransparentLoading } from 'utilsModule/components';
import BrandLogo from 'appModule/authLayout/components/BrandLogo';
import CovidUtil from '../utils/CovidUtil';

const styles = {
  containerWrapper: {
    backgroundColor: '#fff',
    padding: '0px',
    height: '60px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'sticky',
    top: 0,
    background: 'inherit',
    zIndex: 100,
    webkitBoxShadow: '0 3px 5px rgba(57, 63, 72, 0.3)',
    mozBoxShadow: '0 3px 5px rgba(57, 63, 72, 0.3)',
    boxShadow: '0 3px 5px rgba(57, 63, 72, 0.3)',
  },
  brandLogo: { marginLeft: 20 },
  fullWidthContainer: {
    background: 'white',
  },
  formContainer: {
    maxWidth: 1000,
    margin: '0 auto',
    backgroundColor: '#fff',
    borderRadius: 10,
  },
  back: {
    padding: 20,
    position: 'sticky',
    top: 60,
    background: 'white',
    zIndex: 9,
  },
  steps: {
    color: '#FFFFFF',
    fontSize: '24px',
  },
  blueBox: {
    padding: '6px',
    backgroundColor: '#F8FCFF',
    border: '1px solid #1783FF',
    color: '#1783FF',
    fontSize: '12px',
    borderRadius: '5px',
    letterSpacing: '0.07px',
    marginBottom: '10px',
  },
  boxInfoIcon: {
    paddingLeft: '8px',
    marginTop: '3px',
  },
  boxInfoMessage: {
    display: 'inherit',
    fontSize: '14px',
    letterSpacing: '0.07px',
    marginLeft: '-40px',
  },
  adornmentIconInfo: {
    width: '20px',
    height: '20px',
    color: 'rgba(0, 0, 0, 0.54)',
    position: 'absolute',
    marginLeft: '15px',
    marginTop: '-5px',
  },
};

const InfoTooltip = withStyles(() => ({
  tooltip: {
    background: '#212121 0% 0% no-repeat padding-box',
    boxShadow: '0px 4px 16px #00000052',
    borderRadius: '8px',
    minWidth: 200,
    width: '270px',
    color: 'white',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    padding: 10,
  },
}))(Tooltip);

@withStyles(() => ({
  card: {
    width: '100%',
    borderRadius: '10px',
  },
  cardHeader: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}))
class CollapsibleCard extends React.PureComponent {
  state = {
    expanded: true,
  };

  render() {
    const {
      classes, cardProps, cardHeaderProps, withDivider = true, children,
    } = this.props;

    return (
      <Card className={classes.card} {...cardProps}>
        <CardHeader className={classes.cardHeader} {...cardHeaderProps} />
        {withDivider && <Divider style={{ marginBottom: 5 }} />}
        <CardContent className={classes.cardContent}>{children}</CardContent>
      </Card>
    );
  }
}

class CollapsibleCardWithStatus extends React.PureComponent {
  render() {
    const {
      status, title, step, children, ...props
    } = this.props;

    const avatarChoices = {
      pristine: {
        content: (() => {
          switch (step) {
            case 1:
              return (
                <Typography variant='body1' style={styles.steps}>
                  1
                </Typography>
              );
            case 2:
              return (
                <Typography variant='body1' style={styles.steps}>
                  2
                </Typography>
              );
            default:
              if (process.env.DEBUG) throw new TypeError(`Unexpected step ${step}`);
          }
        })(),
        style: { backgroundColor: '#212121', color: '#FFFFFF' },
      },
      dirty: {
        content: step,
        style: { backgroundColor: '#212121', fontSize: '24px' },
      },
      touchedValid: {
        content: step,
        style: { backgroundColor: '#212121', fontSize: '24px' },
      },
      visiblyInvalid: {
        content: step,
        style: { backgroundColor: '#212121', fontSize: '24px' },
      },
    };

    return (
      <CollapsibleCard
        cardHeaderProps={{
          avatar: (
            <MuiAvatar aria-label={title} style={avatarChoices[status].style}>
              {avatarChoices[status].content}
            </MuiAvatar>
          ),
          title: (
            <Typography variant="h5" component="h3" style={{ fontSize: '24px' }}>
              {title}
            </Typography>
          ),
        }}
        collapseProps={{ timeout: 'auto' }}
        children={children}
        {...props}
      />
    );
  }
}

const layout = {
  header: {
    components: [
      {
        id: uuidv4(),
        name: 'banner',
        component: () => (
          <Paper elevation={0} style={{ background: 'inherit' }}>
            <Typography variant='h5'>Sign-up Form by Individual</Typography>
            <Typography variant='body1'><span><sup>*</sup> Mandatory details</span></Typography>
          </Paper>
        ),
      },
    ],
    itemProps: {
      style: {
        position: 'sticky',
        top: 60,
        background: 'inherit',
        zIndex: 9,
        paddingTop: '10px',
        paddingBottom: '15px',
      },
    },
  },
  body: {
    sections: [
      {
        id: uuidv4(),
        name: 'basicInfo',
        title: 'Basic Information',
        step: 1,
        containerProps: {
          body: {
            spacing: 2,
          },
        },
        component: ({ meta, ...rest }) => (
          <CollapsibleCardWithStatus status={decideStatusFromMeta(meta)} {...rest} />
        ),
        style: { marginTop: 5 },
        rows: [
          {
            id: uuidv4(),
            cols: [
              {
                name: 'email',
                label: 'Account Registered Email',
                component: TextField,
                required: true,
              },
              {
                name: 'mobile',
                label: 'Account Registered Mobile Number',
                component: Phone,
                required: true,
              },
            ],
          },
          {
            id: uuidv4(),
            cols: [
              {
                name: 'firstName',
                label: 'First Name',
                component: TextField,
                required: true,
              },
              {
                name: 'lastName',
                label: 'Last Name',
                component: TextField,
                required: true,
              },
            ],
          },
          {
            id: uuidv4(),
            cols: [
              {
                name: 'dob',
                label: 'Date of Birth',
                component: DatePicker,
                disableFuture: true,
                required: true,
                additionalStyle: { marginTop: 'auto', paddingLeft: '8px' },
                colSpans: { xs: 3 },
              },
              {
                name: 'gender',
                label: 'Gender',
                component: Select,
                required: true,
                colSpans: { xs: 3 },
              },
              {
                name: 'homeNumber',
                label: 'Home Number ',
                component: Phone,
                colSpans: { xs: 6 },
              },
            ],
          },
          {
            id: uuidv4(),
            cols: [
              {
                name: 'addr1',
                label: 'Address Line 1',
                component: TextField,
                required: true,
                colSpans: { xs: 6 },
              },
              {
                name: 'country',
                label: 'Country',
                component: TypeSelect,
                required: true,
                disabled: true,
                inject: ({
                  updateisusa,
                }) => ({ updateisusa }),
              },
              {
                name: 'state',
                label: 'State',
                component: TypeSelect,
                required: false,
                inject: ({
                  runtimeProps: {
                    body: {
                      basicInfo: {
                        state: { onChange, disableState, notApplicable },
                      },
                    },
                  },
                }) => ({ onChange, notApplicable, disabled: disableState }),
              },
            ],
          },
          {
            id: uuidv4(),
            containerProps: { justify: 'flex-start' },
            cols: [
              {
                name: 'addr2',
                label: 'Address Line 2',
                component: TextField,
                colSpans: { xs: 6 },
              },
              {
                name: 'city',
                label: 'City/Town',
                component: TypeSelect,
                required: true,
              },
              {
                name: 'postalCode',
                label: 'Postal Code',
                component: TextField,
                required: true,
                colSpans: { xs: 3 },
                inject: ({ isusa }) => ({ isusa }),
              },
            ],
          },
        ],
      },
      {
        id: uuidv4(),
        name: 'paymentDetails',
        title: 'Payment Details',
        step: 2,
        containerProps: {
          body: {
            spacing: 2,
          },
        },
        component: ({ meta, ...rest }) => (
          <CollapsibleCardWithStatus status={decideStatusFromMeta(meta)} {...rest} />
        ),
        style: { marginTop: 5 },
        rows: [
          {
            id: uuidv4(),
            cols: [
              {
                name: 'infoMessage',
                component: ({ withStyleClasses }) => {
                  return (
                    <Typography variant='h2' className={withStyleClasses.blueBox}>
                      <Grid container direction="row" justify="flex-start" alignItems="center">
                        <Grid item xs={1} className={withStyleClasses.boxInfoIcon}><InfoOutline style={{ marginLeft: '0px' }} /></Grid>
                        <Grid item xs={11} className={withStyleClasses.boxInfoMessage}>
                          <Typography variant='body1' style={{ color: '#1783FF', fontSize: '14px' }}>
                           Congratulations! Your subscription till last day of this month is FREE! By clicking on the “Submit” button, we will proceed to process monthly subscription fees from next month onwards.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  );
                },
                disabled: true,
                colSpans: { xs: 12 },
                inject: ({ withStyleClasses }) => ({ withStyleClasses }),
              },
            ],
          },
          {
            id: uuidv4(),
            cols: [
              {
                name: 'paymentAmount',
                label: 'Payment Amount',
                component: TextField,
                disabled: true,
                colSpans: { xs: 6 },
                fullWidth: false,
              },
              {
                name: 'cardHolderName',
                label: 'Card Holder Name',
                component: TextField,
                required: true,
                colSpans: { xs: 6 },
              },
            ],
          },
          {
            id: uuidv4(),
            cols: [
              {
                name: 'cardNumber',
                label: 'Card Number',
                component: ({ onChange, creditCardType, ...props }) => {
                  const imageUri = CovidUtil.getCreditCardImage(creditCardType);
                  return (
                    <TextField
                      adornment={{
                        position: 'start',
                        component: imageUri && (
                          <img
                            src={imageUri}
                            style={{
                              width: '40px',
                              height: '25px',
                              marginBottom: '5px',
                            }}
                          />
                        ),
                      }}
                      onChange={onChange}
                      {...props}
                    />
                  );
                },
                required: true,
                colSpans: { xs: 6 },
                inputProps: {
                  maxLength: 19,
                },
                inject: ({
                  runtimeProps: {
                    body: {
                      paymentDetails: {
                        cardNumber: { onChange, creditCardType },
                      },
                    },
                  },
                }) => ({ onChange, creditCardType }),
              },
              {
                name: 'cardExpiry',
                label: 'Expiry',
                component: TextField,
                required: true,
                colSpans: { xs: 3 },
                inputProps: {
                  maxLength: 5,
                },
                inject: ({
                  runtimeProps: {
                    body: {
                      paymentDetails: {
                        cardExpiry: { onChange },
                      },
                    },
                  },
                }) => ({ onChange }),
              },
              {
                name: 'cardCVV',
                label: 'CVV',
                component: props => (
                  <TextField
                    {...props}
                    adornmentLabel= {{
                      positionAdornmentLabel: 'end',
                      componentAdornmentLabel: (
                        <InfoTooltip
                          placement="top-start"
                          title={
                            <React.Fragment>
                              <Typography variant='h5' style={{ fontSize: '18px', lineHeight: 1.5 }} color="inherit">
                                VISA/MASTERCARD
                              </Typography>
                              <Typography variant='body1' style={{ fontSize: '16px', lineHeight: 1.5 }} color="inherit">
                                A 3-digit number in reverse italics on the back of your credit card.
                              </Typography>
                              {/* <p /> */}
                              <Typography variant='h5' style={{ fontSize: '18px', lineHeight: 1.5, marginTop: '20px' }} color="inherit">
                                AMERICAN EXPRESS
                              </Typography>
                              <Typography variant='body1' style={{ fontSize: '16px', lineHeight: 1.5 }} color="inherit">
                                A 4-digit number on the front, just above your credit card number.
                              </Typography>
                            </React.Fragment>
                          }>
                          <InfoOutline style={styles.adornmentIconInfo} />
                        </InfoTooltip>
                      ),
                    }}
                  />
                ),
                required: true,
                colSpans: { xs: 3 },
                inputProps: {
                  maxLength: 4,
                },
              },
            ],
          },
        ],
      },
    ],
  },
  footer: {
    components: [
      {
        id: uuidv4(),
        name: 'acceptance',
        component: CheckboxRightLabelGroup,
        label: (
          <Typography variant='caption' style={{ fontSize: '14px' }}>
           Yes, I/We accept and agree to be bound by the terms and conditions of{' '}
            <Link target='_blank' to='/policies/services-agreement-individual' style={{ color: '#2c7df6', textDecoration: 'underline', cursor: 'pointer' }}>
              HealthBeats Services Agreement
            </Link>
            .
          </Typography>
        ),
        required: true,
        style: { marginLeft: '10px' },
        colSpans: { xs: 12 },
      },
      {
        id: uuidv4(),
        component: Button,
        type: 'button',
        children: '',
        style: { visibility: 'hidden' },
        variant: 'contained',
        colSpans: { xs: 3 },
      },
      {
        id: uuidv4(),
        component: Button,
        type: 'submit',
        children: 'Submit',
        className: 'milo-btn-orange',
        variant: 'contained',
        style: { width: '100%' },
        colSpans: { xs: 3 },
        inject: ({
          pristine,
          submitting,
        }) => ({
          disabled: pristine || submitting,
        }),
      },
    ],
    itemProps: {
      style: {
        position: 'sticky',
        bottom: 0,
        background: 'inherit',
        zIndex: 9,
        paddingBottom: 10,
        paddingTop: '15px',
      },
    },
    divider: true,
  },
};

const fieldGroups = R.pipe(
  R.map(({ name, rows }) => ({
    name: name || 'root',
    fields: R.flatten(R.map(R.prop('cols'), rows)),
  })),
  R.reduceBy((acc, { fields }) => acc.concat(fields), [], R.prop('name')),
  R.unless(R.prop('root'), R.assoc('root', [])),
)(layout.body.sections);

// console.log('%cfieldGroups', 'font-size: 12px; color: #00b3b3', fieldGroups);

/* eslint-disable */
const validate = values => {
  const errors = {};
  const getFieldValue = (groupName, fieldName) =>
    R.prop(fieldName, groupName === 'root' ? values : values[groupName]);
  const setFieldError = (groupName, fieldName, error) =>
    ((groupName === 'root' ? errors : (errors[groupName] = errors[groupName] || {}))[
      fieldName
    ] = error);
  const attachValueToField = groupName => field =>
    R.assoc(
      'value',
      R.contains(field.component, [TypeSelect])
        ? R.prop('value', getFieldValue(groupName, field.name))
        : getFieldValue(groupName, field.name),
      field,
    );
  const fieldGroupsWithValue = R.mapObjIndexed(
    (groupFields, groupName) => R.map(attachValueToField(groupName), groupFields),
    fieldGroups,
  );

  const requiredFieldGroupsWithValue = R.map(R.filter(({ required }) => required), fieldGroupsWithValue);

  const emailFieldsWithValue = R.map(
    R.filter(
      R.pipe(
        R.prop('name'),
      R.test(/email/i),
    ),
    ),
    fieldGroupsWithValue,
  );

  // console.log('%cfields', 'font-size: 12px; color: #00b3b3', fieldGroupsWithValue);
  // console.log('%crequiredFields', 'font-size: 12px; color: #00b3b3', requiredFieldGroupsWithValue);
  // console.log('%cemailFields', 'font-size: 12px; color: #00b3b3', emailFieldsWithValue);

  if (values && values.paymentDetails) {
    if (!errors.paymentDetails) errors.paymentDetails = {};
    const { cardExpiry } = values.paymentDetails;
    const filterValue = replace(cardExpiry, /\//g, '');
    const invalidExpiryError = 'Invalid Expiry';
    if (isNaN(filterValue)) errors.paymentDetails.cardExpiry = invalidExpiryError;
  }

  /* Validate required fields */
  R.mapObjIndexed(
    (groupFields, groupName) =>
      R.map(
        ({ name, label, value }) =>
          !value && setFieldError(groupName, name, `${label} is required`),
        groupFields,
      ),
    requiredFieldGroupsWithValue,
  );
  const requiredPhoneFieldGroupsWithValue = R.map(R.filter(R.pipe(R.prop('component'), R.identical(Phone))), requiredFieldGroupsWithValue);
  R.mapObjIndexed((groupFields, groupName) =>
    R.map(({ name, value: { countryCode: { value: countryCode } = {}, value } = {} }) => {
          !countryCode && (errors[groupName] = R.assocPath([name, 'countryCode'], 'Oops', errors[groupName]));
          !value && (errors[groupName] = R.assocPath([name, 'value'], 'phone number is required', errors[groupName]));
        }, groupFields), requiredPhoneFieldGroupsWithValue);

  if (values.acceptance === false) {
    errors['acceptance'] = 'Acceptance is required';
  }

  /* Validate email format */
  R.mapObjIndexed(
    (groupFields, groupName) =>
      R.map(
        ({ name, value }) =>
          !validators.email(value) && setFieldError(groupName, name, 'Invalid email address'),
        groupFields,
      ),
    emailFieldsWithValue,
  );

  // console.log('%cerrors', 'font-size: 12px; color: #00b3b3', errors);

  return errors;
};
/* eslint-enable */

const config = {
  form: 'createCovidIndividual',
  validate,
  enableReinitialize: true,
};

@withStyles(styles, { index: 1 })
@withReduxForm({ layout, config })
class CovidIndividualCreate extends React.Component {
  static propTypes = {
    /* container */
    handleCreate: PropTypes.func.isRequired,
    /* withStyles */
    classes: PropTypes.object,
    /* createReduxForm */
    Form: PropTypes.func.isRequired,
  };

  state = {
    isusa: R.prop('value', R.prop('country', R.prop('basicInfo', R.prop('initialValues', this.props)))) === 'United States',
  }

  updateisusa = (value) => {
    this.setState({ isusa: value === 'United States' });
  }

  render() {
    const {
      classes,
      Form,
      initialValues,
      runtimeProps,
      handleCreate,
      loading,
    } = this.props;

    const { isusa } = this.state;

    const newIsUsa = R.prop('value', R.prop('country', R.prop('basicInfo', initialValues))) === 'United States';
    if (isusa !== newIsUsa && R.prop('mode', R.prop('shared', runtimeProps)) === 'view') {
      this.setState({ isusa: newIsUsa });
    }

    return (
      <React.Fragment>
        {loading && (<TransparentLoading />)}
        <div className={classes.containerWrapper}>
          <BrandLogo width={180} height={60} className={classes.brandLogo} />
        </div>
        <Back className={classes.back} label="" path="/covid-19-select-country?type=individual" />
        <div className={classes.fullWidthContainer}>
          <Form
            updateisusa={this.updateisusa}
            isusa={isusa}
            initialValues={initialValues}
            runtimeProps={runtimeProps}
            className={classes.formContainer}
            onSubmit={handleCreate}
            withStyleClasses={classes}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default CovidIndividualCreate;
