/* Constants */

/* Action Creators */

/* Epics */
import { Observable } from 'rxjs/Observable';
import { replace } from 'react-router-redux';
import { any, path, eqBy, propSatisfies } from 'ramda';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/catch';

import DM from 'dataModule';
import { actionCreators as vitalsActionCreator } from './list';

const MAX_PAGE_SIZE = 1e6;
/* Reducer */
/*
**************************************************
  Action Types
**************************************************
*/

const actionTypes = {
  FETCH_PROGRAMS: Symbol('FETCH_PROGRAMS'),
  SET_CURRENT: Symbol('SET_CURRENT'),
  SET_LIST: Symbol('SET_LIST'),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  fetchPrograms: ({ patientId }) => ({ type: actionTypes.FETCH_PROGRAMS, payload: patientId }),
  setCurrent: ({ progId }) => ({ type: actionTypes.SET_CURRENT, payload: progId }),
  setList: ({ list }) => ({ type: actionTypes.SET_LIST, payload: list }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/

const epics = [
  (action$, store) =>
    action$.ofType(actionTypes.FETCH_PROGRAMS).mergeMap(({ payload }) =>
      Observable.fromPromise(DM.program.provider.fetchEnrolledProgramListNoPagination({
        patientId: payload,
        pageSize: MAX_PAGE_SIZE,
      }))
        .mergeMap((result) => {
          const id = path(['router', 'location', 'query', 'id'], store.getState());
          const isProgIdValid = any(propSatisfies(eqBy(Number, id), 'id'))(result.list);
          return result.list.length
            ? [
              actionCreators.setList({ list: result.list }),
              actionCreators.setCurrent({
                progId: isProgIdValid ? id : path(['list', 0, 'id'], result),
              }),
            ]
            : [
              actionCreators.setList({ list: [] }),
              // ZOHO-4 Need to clear store otherwise showing old data
              vitalsActionCreator.setList({ list: [] }),
            ];
        })
        .catch(error => Observable.of({ error }))),
  action$ =>
    action$
      .ofType(actionTypes.SET_CURRENT)
      .map(({ payload }) => replace({ search: `?id=${payload}` })),
];

export { epics };
/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
  loading: false,
  list: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROGRAMS: {
      return { ...state, loading: true };
    }
    case actionTypes.SET_LIST: {
      return { list: action.payload, loading: false };
    }
    default: {
      return state;
    }
  }
};
