/* eslint-disable max-len */
import {
  Card,
  CardContent,
  CardHeader,
  Divider
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';

@withStyles(() => ({
  card: {
    width: '100%',
    borderRadius: '10px',
  },
  cardHeader: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}))
class CollapsibleCard extends React.PureComponent {
  state = {
    expanded: true,
  };

  render() {
    const { classes, cardProps, cardHeaderProps, withDivider = true, children, noPaddingLeftRight = false } = this.props;
    const additionalStyle = {};
    if (noPaddingLeftRight) {
      additionalStyle.paddingLeft = 0;
      additionalStyle.paddingRight = 0;
      additionalStyle.paddingTop = 0;
    }

    return (
      <Card className={classes.card} {...cardProps}>
        {cardHeaderProps ? <CardHeader className={classes.cardHeader} {...cardHeaderProps}/> : null}
        {withDivider && <Divider style={{ marginBottom: 5 }} />}
        <CardContent className={classes.cardContent} style={{ ...additionalStyle }}>{children}</CardContent>
      </Card>
    );
  }
}
export default CollapsibleCard;
