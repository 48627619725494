import { combineReducers } from 'redux';
import vitalList, { epics as vitalListEpics } from './vitalList';
import deviceList, { epics as deviceListEpics } from './deviceList';
import selectBar, { epics as selectBarEpics } from './selectBar';
import selectBarEnroll, { epics as selectBarEnrollEpics } from './selectBarEnroll';

export const epics = [
  ...vitalListEpics,
  ...deviceListEpics,
  ...selectBarEpics,
  ...selectBarEnrollEpics,
];

export default combineReducers({
  vitalList,
  deviceList,
  selectBar,
  selectBarEnroll,
});
