import { withStyles } from '@material-ui/styles';
import ReportPage from 'appModule/reporting/component/ReportPDF/ReportPage';
import React from 'react';
const footer = require('../../../../../public/hb_powered_by_int.png');

const styles = () => ({
  container: {
    backgroundColor: 'white',
    width: '21cm',
    height: '29.7cm',
    overflow: 'hidden',
  },
  metadataTitle: {
    width: '40%',
    fontSize: 12,
  },
  metadataValue: {
    width: '60%',
    fontSize: 12,
  },
  metadataSpace: {
    height: 5,
  },
  disclaimerContainer: {},
});

@withStyles(styles, { index: 1 })
export default class ReportingDisclaimerPage1 extends React.Component {
  render() {
    const { page = 1, totalPages = 10 } = this.props.location.query;
    return (
      <ReportPage page={page} totalPages={totalPages}>
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <h2 style={{ marginTop: '130px' }}>Disclaimer</h2>
          {/* Sub Title */}
          <div style={{ marginTop: '5px', textAlign: 'left' }}>
            <p style={{ lineHeight: '25px' }}>
              HealthBeats® Offline Module now allows Participants to continue taking their vitals measurements when they
              are offline, and for these data to be synchronized when they get back online. This report is generated
              based on data available to HealthBeats® platform as at the date and time the report is downloaded. As some
              data may not be updated when a Participant is offline, this may result in the same report downloaded at
              different date and time to generate different results. For more information on the terms and conditions of
              the HealthBeats® Offline Module, please refer to our Terms of Use of HealthBeats®.
            </p>
          </div>
        </div>
      </ReportPage>
    );
  }
}
