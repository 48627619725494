/* Epics */
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

/* Libs */
import DM from 'dataModule';

import { actionTypes as actionTypesEnroll } from './enroll';

/*
**************************************************
  Action Types
**************************************************
*/
const prefix = 'program/enroll/addonBaseList';

const actionTypes = {
  INIT: Symbol(`${prefix}/INIT`),
  INIT_SUCCESS: Symbol(`${prefix}/INIT_SUCCESS`),
  INIT_FAILURE: Symbol(`${prefix}/INIT_FAILURE`),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  init: payload => ({ type: actionTypes.INIT, payload }),
  initSuccess: payload => ({ type: actionTypes.INIT_SUCCESS, payload }),
  initFailure: payload => ({ type: actionTypes.INIT_FAILURE, payload }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/
const epics = [
  // init
  action$ => action$.ofType(actionTypesEnroll.SELECT_BASE)
    .mergeMap(({ payload }) =>
      Observable.fromPromise(DM.program.provider.fetchActiveBaseProgramList(payload))
        .map(({ list }) => actionCreators.initSuccess({ list }))
        .catch(error => Observable.of(actionCreators.initFailure({ error })))),
];

export { epics };
/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
  list: null,
  loading: true,
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT: {
      return { ...state, loading: true };
    }
    case actionTypes.INIT_SUCCESS: {
      const { list } = action.payload;
      // console.log('%clist', 'font-size: 12px; color: #00b3b3', list);
      return { ...state, list, loading: false };
    }
    default: {
      return state;
    }
  }
};
