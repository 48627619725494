import { httpGet, httpPost, httpGetObservable } from './utils';

const provider = {
  fetchPatientList: httpGet,
  fetchRegister: httpPost,
  fetchPatientProfile: httpGet,
};

const api = {
  provider,
  patientMinList: url => httpGetObservable(url),
};

export default api;
