import React from 'react';
import { withResource, resourceTypes } from 'dataModule/store/resources';
import { connect } from 'react-redux';
import { fetchGoThumbSignedUrl } from 'utilsModule/gothumb';
import * as R from 'ramda';
import TimeSpentReportComponent from '../components/TimeSpentReport';

const mapState = ({ measurements: { list, programs } }, { programId }) => {
  const prog = find(propEq('id', Number(programId)), programs.list);
  return { list, prog };
};

@withResource([
  {
    resourceType: resourceTypes.PROGRAMS,
    method: 'programTimeSpentReport',
  },
])
@connect((state) => ({
  currentOrganization: R.path(['resources', 'users', 'retrieveCurrent', 'organization'], state),
}))
class TimeSpentReportContainer extends React.Component {
  state = {
    notes: [],
    reportMetaData: null,
  };

  componentDidMount() {
    this.fetchNotes();
  }

  fetchNotes = () => {
    const {
      actionCreators,
      location: {
        query: { from, to, epId, take, page, pagesToRender },
      },
    } = this.props;
    const sessionToken = this.props.location.query['session-token'];
    actionCreators[resourceTypes.PROGRAMS].ajax({
      cargo: {
        method: 'programTimeSpentReport',
        input: {
          params: {
            queries: [
              { name: 'epId', value: epId },
              { name: 'take', value: take },
              { name: 'page', value: page },
              { name: 'from', value: +from },
              { name: 'to', value: +to },
              { name: 'type', value: 'list-pdf' },
              { name: 'session-token', value: sessionToken },
            ],
          },
        },
      },
      onSuccess: async (data) => {
        const {
          data: {
            programTimeSpentReport: { value },
          },
        } = data;
        if (value.notes) {
          const { notes, reportMetaData, totalHours, totalMinutes } = value;
          const goThumbUrl = await fetchGoThumbSignedUrl(reportMetaData.hospitalLogo, sessionToken);
          this.setState({
            notes,
            reportMetaData: {
              ...reportMetaData,
              hospitalLogo: goThumbUrl,
            },
            totalHours,
            totalMinutes,
          });
        }
      },
      onFailure: ({ error }) => {
        console.log(error);
      },
    });
  };

  render = () => {
    const {
      location: {
        query: { page, totalPages },
      },
    } = this.props;
    const { notes, reportMetaData, totalHours, totalMinutes } = this.state;
    return (
      <TimeSpentReportComponent
        notes={notes}
        reportMetaData={reportMetaData}
        page={page}
        totalPages={totalPages}
        totalHours={totalHours}
        totalMinutes={totalMinutes}
      />
    );
  };
}

export default TimeSpentReportContainer;
