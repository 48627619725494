import React from 'react';
import { withStyles } from '@material-ui/styles';
const footer = require('../../../../../public/hb_powered_by_int.png');
import { fetchGoThumbSignedUrl } from 'utilsModule/gothumb';
import ReportPage from 'appModule/reporting/component/ReportPDF/ReportPage';
import OverallReportingVitalsChart from './ReportingVitalsChart';
import OverallReportingCaretaskChart from './ReportingCaretaskChart';
import OverallReportingAlertsChart from './ReportingAlertsChart';

const styles = () => ({
  container: {
    backgroundColor: 'white',
    width: '21cm',
    height: '29.7cm',
    overflow: 'hidden',
  },
  metadataTitle: {
    width: '40%',
    fontSize: 12,
  },
  metadataValue: {
    width: '60%',
    fontSize: 12,
  },
  metadataSpace: {
    height: 5,
  },
});

@withStyles(styles, { index: 1 })
export default class ReportingMetaData extends React.Component {
  state = {
    loading: true,
    metaData: null,
    module: null,
  };

  async componentDidMount() {
    await this.fetchReportMetaData();
  }

  async fetchReportMetaData() {
    const { 'session-token': sessionToken, reportId, module } = this.props.location.query;

    this.setState({
      module,
    });

    const { API_URL } = window.MILO_CONFIG;
    fetch(`${API_URL}/reports/${reportId}/metaData?session-token=${sessionToken}`, { method: 'GET' })
      .then((res) => res.json())
      .then((json) => json.value)
      .then(async (metaData) => {
        const goThumbUrl = await fetchGoThumbSignedUrl(metaData.hospitalLogo, sessionToken);
        this.setState({
          loading: false,
          metaData: {
            ...metaData,
            hospitalLogo: goThumbUrl,
          },
        });
      });
  }

  _header = () => {
    const { metaData } = this.state;
    const { classes } = this.props;
    if (!metaData) return;
    return (
      <div
        style={{
          width: '100%',
        }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={metaData.hospitalLogo} style={{ width: 110, height: 110, marginTop: 12, marginBottom: 12 }} />{' '}
        </div>{' '}
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            fontSize: 20,
            fontWeight: 'bold',
            fontFamily: 'Roboto',
            marginTop: 12,
            marginBottom: 12,
          }}>
          {metaData.reportName}{' '}
        </div>{' '}
        <table style={{ width: '100%' }}>
          <tr>
            <td className={classes.metadataTitle}> Provider Name </td>{' '}
            <td className={classes.metadataValue}>
              : {metaData.provider.firstName} {metaData.provider.lastName}{' '}
            </td>{' '}
          </tr>{' '}
          <tr>
            <td className={classes.metadataTitle}> Hospital / Clinic Name </td>{' '}
            <td className={classes.metadataValue}>: {metaData.hospitalName} </td>{' '}
          </tr>{' '}
          <tr>
            <td className={classes.metadataTitle}> Report Name </td>{' '}
            <td className={classes.metadataValue}>: {metaData.reportName} </td>{' '}
          </tr>{' '}
          <tr>
            <td className={classes.metadataTitle}> Selected Health Conditions </td>{' '}
            <td className={classes.metadataValue}>: {metaData.healthConditions.join(', ')} </td>{' '}
          </tr>{' '}
          <tr>
            <td className={classes.metadataTitle}> Period of Report(Date) </td>{' '}
            <td className={classes.metadataValue}>: {metaData.period} </td>{' '}
          </tr>{' '}
          <tr>
            <td className={classes.metadataSpace} /> <td className={classes.metadataSpace} />{' '}
          </tr>{' '}
          <tr>
            <td className={classes.metadataTitle}> Date and Time of Report </td>{' '}
            <td className={classes.metadataValue}>: {metaData.dateAndTime} </td>{' '}
          </tr>{' '}
          <tr>
            <td className={classes.metadataTitle}> Downloaded By </td>{' '}
            <td className={classes.metadataValue}>: {metaData.downloadedBy} </td>{' '}
          </tr>{' '}
          <tr>
            <td className={classes.metadataTitle}> </td> <td className={classes.metadataValue}> </td>{' '}
          </tr>{' '}
          {/* <tr >
                                <td className={classes.metadataTitle}>Participant Names</td>
                                <td className={classes.metadataValue}>: {' '}
                                  {
                                    metaData.patients.map((patient, index) => {
                                      return (
                                        <span key={patient.id}>
                                          {patient.firstName} {patient.lastName}
                                          {index === metaData.patients.length - 1 ? '' : ','}
                                        </span>
                                      );
                                    })
                                  }
                                </td>
                              </tr> */}{' '}
        </table>{' '}
      </div>
    );
  };

  render() {
    const { loading, metaData, module } = this.state;
    if (loading || !metaData) return <div> Loading... </div>;
    const { page = 1, totalPages = 10 } = this.props.location.query;

    return (
      <ReportPage page={page} totalPages={totalPages}>
        {this._header()} {module === 'vitals' && <OverallReportingVitalsChart {...this.props} isEmbedded={true} />}
        {module === 'care_tasks' && <OverallReportingCaretaskChart {...this.props} isEmbedded={true} />}
        {module === 'alerts' && <OverallReportingAlertsChart {...this.props} isEmbedded={true} />}
      </ReportPage>
    );
  }
}
