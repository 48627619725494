import React from 'react';
import PropTypes from 'prop-types';
import CryptoJS from 'crypto-js';
import { withRouter } from 'react-router';
import { SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { withResource, resourceTypes, gettersOf } from 'dataModule/store/resources';
import { actionCreators as messageActionCreators } from 'appModule/message/ducks/message';
import { Loading } from 'utilsModule/components';
import { withIPLocation } from 'utilsModule/ipLocation';
import { updateEnvConfig } from 'utilsModule';
import { ForgetPassword } from '../components';
import ChangePassword from '../components/ChangePassword';

@withIPLocation()
@withResource([
  {
    resourceType: resourceTypes.CONFIGS,
    method: 'retrieveLoginCountryCodes',
    input: {
      params: {
        queries: [
          { name: 'global', value: window.MILO_CONFIG.ENABLE_GLOBAL_TABLE.toString() },
        ],
      },
    },
    options: { runOnDidMount: true },
  },
  { resourceType: resourceTypes.USERS },
])
@connect(null, { notify: messageActionCreators.show })
@withRouter
class ForgetPasswordContainer extends React.Component {
  static propTypes = {
    actionCreators: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }
  componentDidUpdate() {
    /* NOTE: Notify failures of withResource */
    const {
      data: {
        status: { loading, success, error },
      },
      notify,
    } = this.props;
    !this.notified && !loading && !success && notify({ message: error, type: 'error' });
    this.notified = true;
  }

  // eslint-disable-next-line
  shouldComponentUpdate(nextProps) {
    return !nextProps.data.status.loading;
  }

  invokeForgetPassword = (mobileCC, mobileNo, resolve, reject) => {
    const {
      actionCreators, notify, history, location: { state: { changePassword = false } = {} } = {},
    } = this.props;

    actionCreators[resourceTypes.USERS].ajax({
      cargo: { method: 'forgetPassword', input: { content: { phone: `${mobileCC} ${mobileNo}` } } },
      onSuccess: ({ data: result }) => {
        if (this.mounted) {
          resolve(result);
          sessionStorage.setItem('verifyPhone', `${mobileCC} ${mobileNo}`);
          if (changePassword) {
            history.push('/changepassword/otp', { changePassword: true });
          } else {
            history.push('/forgetpassword/otp');
          }
        }
      },
      onFailure: ({ error: { message } }) => {
        if (this.mounted) {
          reject(new SubmissionError({ _error: message }));
          notify({ message, type: 'error' });
        }
      },
    });
  }

  forgetPassword = ({ mobile: { countryCode: { value: mobileCC } = {}, value: mobileNo } }) => {
    const { actionCreators, notify } = this.props;

    const { ENABLE_GLOBAL_TABLE } = window.MILO_CONFIG;
    if (ENABLE_GLOBAL_TABLE) {
      return new Promise((resolve, reject) => {
        actionCreators[resourceTypes.USERS].ajax({
          cargo: {
            method: 'getServerMapping',
            input: { content: { loginId: `${mobileCC} ${mobileNo}` } },
          },
          onSuccess: ({ data: { getServerMapping: { API_URL, GOTHUMB_URL } } }) => {
            updateEnvConfig(API_URL, GOTHUMB_URL);
            this.invokeForgetPassword(mobileCC, mobileNo, resolve, reject);
          },
          onFailure: ({ error }) => {
            if (this.mounted) {
              const reportedError = JSON.stringify(error, ['message']);
              reject(new SubmissionError({ _error: reportedError }));
              notify({
                message: 'Your mobile number is not registered in the system, please enter the correct mobile number and ensure country code is correct.',
                type: 'error',
              });
            }
          },
        });
      });
    }
    return new Promise((resolve, reject) =>
      this.invokeForgetPassword(mobileCC, mobileNo, resolve, reject));
  }

  doChangePassword = async (data) => {
    const { notify } = this.props;
    const { oldPassword } = data;
    const cipherText = CryptoJS.AES.encrypt(oldPassword, '@2ecret4uth2907!');
    const currPassword = cipherText.toString();
    const {  history } = this.props;
    const sessionToken= sessionStorage.getItem('session-token');
      const { API_URL } = window.MILO_CONFIG;
    const requestData = {
      password: currPassword
    };
    const headers = { 'Content-Type': 'application/json', 'session-token': sessionToken };
    return fetch(`${API_URL}/account/verifyPassword`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        const { value = false } = data;
        if (value) {
          history.push('/changepassword/setnewpassword', { changePassword: true });
        } else {
          notify({
            message: 'Please ensure you enter a valid current password and try again.',
            type: 'error',
            customClass: ['expanded']
          });
          throw new SubmissionError({ oldPassword: 'Incorrect Current Password', _error: 'Current Password Verification Failed!' });
        }
      });
  }

  render() {
    const {
      data,
      getCountryCodeByIp,
      location: {
        state: { changePassword = false }
        = {},
      } = {},
    } = this.props;

    // First render
    if (data.status.loading) return <Loading />;

    // Sanitize data
    const countries = gettersOf(resourceTypes.CONFIGS).getLoginCountryCodes()(data);


    if (!this.inited) {
      this.initialValues = {
        mobile: {
          countryCode: {
            options: R.map(({ iso, countryCode, flag }) => ({
              id: iso, value: countryCode, label: `+${countryCode}`, iso, flag,
            }))(countries),
            value: getCountryCodeByIp(),
          },
        },
      };

      const forgetPasswordBanner = {
        title: 'Verify Account',
        subtitle: 'Enter the mobile number you have used to register HealthBeats®.',
      };

      const changePasswordBanner = { title: 'Verify Current Password', subtitle: 'Enter your current password that you are using for your account.' };

      this.runtimeProps = {
        header: {
          banner: changePassword ? changePasswordBanner : forgetPasswordBanner,
        },
      };
      this.inited = true;
    }

    // Change Password
    if (changePassword) {
      return <ChangePassword runtimeProps={this.runtimeProps} doChangePassword={this.doChangePassword} changePassword={changePassword} />;
    }

    // Forgot Password
    return (
      <ForgetPassword
        initialValues={this.initialValues}
        runtimeProps={this.runtimeProps}
        forgetPassword={this.forgetPassword}
        changePassword={changePassword}
      />
    );
  }
}

export default ForgetPasswordContainer;
