import React, { PureComponent } from 'react';
import moment from 'moment';
import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  LineMarkSeries,
  Hint,
  MarkSeries,
  CustomSVGSeries,
  LineSeries,
  HorizontalGridLines,
  AreaSeries,
  LabelSeries,
} from 'hb-react-vis';
import { VITAL_DISPLAY_MAPPING } from 'dataModule/constants/vital';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { addIndex, all, find, isEmpty, isNil, map, merge, prop, toLower } from 'ramda';
import { Paper, Typography, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { normalizeVitalsDecimals } from '../../../utils/vital';
import commonUtil from 'utilsModule/common';
import { getRangeDisplay } from '../utils/dateAggregationUtil';

const styles = () => ({
  circle: {
    height: '10px',
    width: '10px',
    backgroundColor: '#A7E1D2',
    borderRadius: '50%',
    display: 'inline-block',
  },
  diamond: {
    height: '11px',
    width: '11px',
    backgroundColor: '#C4A7E1',
    clipPath: 'polygon(50% 0, 100% 50%, 50% 100%, 0 50%)',
    display: 'inline-block',
  },
  star: {
    height: '14px',
    width: '14px',
    backgroundColor: '#414EC1',
    clipPath:
      'polygon(50% -8%, 34.5% 37.8%, -10% 38.8%, 28% 62.1%, 16.1% 106.6%, 50% 80.3%, 85.9% 108.6%, 71% 63.1%, 108% 38.8%, 63.5% 36.8%)',
    display: 'inline-block',
  },
  typographyChart: {
    fontSize: '12px',
    paddingLeft: 8,
    color: 'grey',
  },
  typography3: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: '400',
    paddingBottom: 10,
    color: '#9E9E9E',
  },
  typography4: {
    fontFamily: 'Roboto',
    color: 'white',
    fontWeight: '500',
    fontSize: '14px',
  },
  typography5: {
    fontFamily: 'Roboto',
    fontSize: '34px',
    fontWeight: '700',
    color: 'white',
  },
  threshold: {
    padding: 4,
    borderRadius: 20,
    marginBottom: -10,
    marginLeft: -6,
  },
  thresholdText: {
    fontFamily: 'Roboto',
    fontSize: '11px',
    fontWeight: 'medium',
    color: '#fff',
    paddingLeft: 4,
    paddingRight: 4,
  },
  dateDisplayOnCard: {
    position: 'relative',
    borderRadius: '8px',
    boxShadow: '0px 20px 40px #00000029',
    marginLeft: '15px',
    marginRight: '15px',
  },
  gridDailyAverageOnCard: {
    flexDirection: 'column',
    marginRight: '-50px',
    position: 'relative',
    marginTop: -7,
  },
  gridDailyAverageOnCard2: {
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  gridListReading: {
    flexDirection: 'column',
    width: '300px',
  },
  paperDailyAverageOnCard: {
    height: 110,
    width: 120,
    paddingTop: 15,
    paddingBottom: 15,
    display: 'flex',
    borderRadius: 10,
    marginRight: 10,
    marginBottom: 10,
  },
  verticalLine: {
    borderLeft: '1px solid',
    height: '36px',
    marginTop: 3,
    marginLeft: 60,
    marginRight: 15,
  },
});

const colorMap = ['#12939A', '#55299A', '#1A3177'];
const markColorMap = ['#A7E1D2', '#C4A7E1'];

const dynamicColor = (thresholdData, number, markFill = false, verticalLine = false, statusIndicator) => {
  if (thresholdData !== null || number !== null) {
    const cHigh = !isNil(thresholdData.CH.y) && thresholdData.CH.y;
    const cLow = !isNil(thresholdData.CL.y) && thresholdData.CL.y;
    const high = thresholdData.H.y;
    const low = thresholdData.L.y;
    // Critical High & Critical Low
    if ((cHigh && number >= cHigh) || (cLow && number <= cLow)) {
      // eslint-disable-next-line no-nested-ternary
      return markFill ? (verticalLine ? '#E17777' : '#D72B07') : statusIndicator ? '#D34431' : '#CC3333';
    }
    // High & Low
    if (
      (number >= high && number < cHigh) ||
      (number <= low && number > cLow) ||
      (number >= high && cHigh === false) ||
      (number <= low && cLow === false)
    ) {
      // eslint-disable-next-line no-nested-ternary
      return markFill ? (verticalLine ? '#F9B03A' : '#FBAF39') : statusIndicator ? '#FE7F22' : '#FF8330';
    }
    // Normal
    if (number > low && number < high) {
      // eslint-disable-next-line no-nested-ternary
      return markFill ? (verticalLine ? '#77E192' : '#1AC606') : statusIndicator ? '#34BF7B' : '#33CC99';
    }
  }
  // Missed
  // eslint-disable-next-line no-nested-ternary
  return markFill && verticalLine ? '#ABABAA' : statusIndicator ? '#465C66' : 'linear-gradient(#D0CFCF, #B3ACAB)';
};

const getYAxis = (yValue) => (typeof !isNil(yValue) && yValue ? yValue : null);

const returnValue = ({
  xAxis,
  vitalLabel,
  valueQuantity = {},
  thresholdValue: {
    interpretation: { text },
  },
  initialXAxis,
}) => {
  return {
    x: xAxis,
    y: valueQuantity.value ? parseFloat(valueQuantity.value) : valueQuantity.value,
    value: valueQuantity.value ? parseFloat(valueQuantity.value) : valueQuantity.value,
    unit: valueQuantity.unit,
    threshold: JSON.parse(text)
      .map((t) => ({ x: initialXAxis, y: getYAxis(t) }))
      .reverse(),
    vitalName: VITAL_DISPLAY_MAPPING[toLower(vitalLabel)],
  };
};

const getTheNewestData = (value) =>
  value.length > 0 &&
  value.reduce(
    (acc, cur, idx) =>
      (cur.effectiveTime
        ? moment(cur.effectiveTime.utcValue)
            .tz(cur.effectiveTime.timezone)
            .format()
        : cur.issued) >
        (acc.effectiveTime
          ? moment(acc.effectiveTime.utcValue)
              .tz(acc.effectiveTime.timezone)
              .format()
          : acc.issued) && idx
        ? cur
        : acc,
  );

const makeData = ({ vitalDims, data, dateRange }) =>
  addIndex(map)((v, i) => {
    let { label } = v;
    if (v.name === 'sys') label = 'Systolic';
    else if (v.name === 'dia') label = 'Diastolic';
    const newData = data.map((m) => {
      let value = m.average.measurementAverage;
      if (v.name === 'sys') value = m.average.sysAverage;
      else if (v.name === 'dia') value = m.average.diaAverage;
      return {
        ...m,
        yValue: value,
        valueQuantity: {
          value,
          system: '',
          unit: m.average.unit,
        },
      };
    });
    const latestThreshold = getTheNewestData(newData);
    const value = newData.map((d, index) => {
      const xAxis = index + 1;
      return merge(
        {
          ...d,
          effectiveTime: !isNil(d.effectiveTime) && d.effectiveTime,
          type: typeof d.type !== 'undefined' ? d.type : 'scheduled',
          issued: +new Date(d.issued),
          label,
        },
        isNil(d.component)
          ? returnValue(
              merge(
                {
                  initialXAxis: dateRange[0],
                  vitalLabel: v.label,
                  xAxis,
                  thresholdValue: latestThreshold,
                },
                d,
                i,
              ),
            )
          : returnValue(
              merge(
                {
                  xAxis,
                  initialXAxis: dateRange[0],
                  vitalLabel: v.label,
                  thresholdValue: find((c) => c.code.text === v.name, latestThreshold.component),
                },
                find((c) => c.code.text === v.name, d.component),
              ),
              i,
            ),
      );
    });

    const newValue = [];

    value.map((item) => {
      newValue.push({
        ...item,
        value: item.yValue,
        y: item.yValue,
      });
    });

    const listReadings = value.map((item) => ({
      ...item,
    }));

    return { label, name: v.name, value: newValue.length === 0 ? value : newValue, listReadings };
  }, vitalDims);

const getThreshold = (data, dateRange, isMissedData = false) =>
  data.map((d) => {
    const { text } = d.interpretation || d;
    let threshold;
    Array.isArray(JSON.parse(text))
      ? (threshold = JSON.parse(text)
          .map((t) => ({ x: dateRange, y: getYAxis(t) }))
          .reverse())
      : (threshold = JSON.parse(text)
          .bg_post.map((t) => ({ x: dateRange, y: getYAxis(t) }))
          .reverse());
    return isMissedData
      ? threshold
      : {
          CH: threshold[0],
          H: threshold[1],
          L: threshold[2],
          CL: threshold[3],
        };
  });

const vitalBackgroundColor = (value) => {
  const latestThreshold = getTheNewestData(value.data);
  return isNil(latestThreshold.component)
    ? getThreshold([latestThreshold.interpretation], value.dateRange[0])
    : getThreshold(latestThreshold.component, value.dateRange[0]);
};

@withStyles(styles, { index: 1 })
class ReportVitalChart extends PureComponent {
  state = {
    hovered: false,
    hovered2: false,
    index: null,
    index2: null,
    taskType: null,
    hoveredNode: null,
    chartIndex: 0,
  };

  handleMouseOver = this.handleMouseOver.bind(this);
  handleMouseOverThreshold = this.handleMouseOverThreshold.bind(this);

  onValueMouseOver = (v) => {
    // return;
    this.setState({ hovered: v });
  };

  onValueMouseOut = () => {
    // return;
    this.setState({ hovered: false });
  };

  onValueMouseOverThreshold = (v) => {
    this.setState({ hovered2: v });
  };

  onValueMouseOutThreshold = () => {
    this.setState({ hovered2: false });
  };

  handleMouseOver(index, taskType) {
    this.setState({ index, taskType });
  }

  handleMouseOverThreshold(index2) {
    this.setState({ index2 });
  }

  getAdditionalValue = (data, largestNumber) => {
    if (data.name == 'hs') {
      return 10;
    } else if (data.name == 'bg') {
      return largestNumber > 30 ? 20 : 1;
    }

    return 10;
  };

  getFillCollor = (index, markColor) => {
    if (index === 0) {
      // critically high
      return '#CC3333';
    } else if (index === 1) {
      // high
      return '#FF8330';
    } else if (index === 2) {
      // low
      return '#FF8330';
    } else if (index === 3) {
      // critically low
      return '#CC3333';
    }

    return markColor;
  };

  render() {
    const { hovered, hovered2, index, index2 } = this.state;
    const { classes, dateRange, vitalDims, xTicks, pdf = false, title, isEmbedded = false, unit } = this.props;
    const isBloodOxygenDevice = toLower(vitalDims[0].label) === 'spo2';
    const chartData = makeData(this.props);
    const missedData = [];
    const areaArr = vitalBackgroundColor(this.props);
    const yDomain = all(isEmpty, map(prop('value'), chartData)) && missedData.length === 0 ? { yDomain: [0, 5] } : {};

    return (
      <div style={{ width: '100%', padding: 10 }}>
        {chartData.map((c, i) => {
          let { data } = this.props;
          data = data.map((m) => {
            let value = m.average.measurementAverage;
            if (c.name === 'sys') value = m.average.sysAverage;
            else if (c.name === 'dia') value = m.average.diaAverage;
            return {
              ...m,
              yValue: value,
              valueQuantity: {
                value,
                system: '',
                unit: m.average.unit,
              },
            };
          });
          let lastItem = false;
          if (i + 1 === chartData.length) lastItem = true;
          const newData = {
            ...c,
            value: c.value.map((v) => ({
              ...v,
              style: { fill: dynamicColor(areaArr[i], v.y, true) },
            })),
          };
          const newMarkData =
            c.value.length !== 0 ? c.value[0].threshold : missedData.length > 0 && missedData[0].threshold[i];

          const fixTitle = title === 'Blood Pressure' ? c.label : title;
          return (
            <div key={i}>
              <Typography className={classes.typographyChart}>
                {fixTitle} {unit ? `(${unit})` : null}
              </Typography>
              <LineMarkChart
                isBloodOxygenDevice={isBloodOxygenDevice}
                dateRange={dateRange}
                parentIndex={i}
                oriChartData={chartData}
                data={newData}
                yDomain={yDomain}
                markData={newMarkData}
                areaData={areaArr}
                indexed={index}
                indexedThreshold={index2}
                stroke={colorMap[i]}
                markColor={markColorMap[i]}
                state={hovered}
                state2={hovered2}
                lastItem={lastItem}
                classes={classes}
                handleMouseOver={this.handleMouseOver}
                onValueMouseOver={this.onValueMouseOver}
                onValueMouseOut={this.onValueMouseOut}
                handleMouseOverThreshold={this.handleMouseOverThreshold}
                onValueMouseOverThreshold={this.onValueMouseOverThreshold}
                onValueMouseOutThreshold={this.onValueMouseOutThreshold}
                getAdditionalValue={this.getAdditionalValue}
                xTicks={xTicks}
                pdf={pdf}
                isEmbedded={isEmbedded}
                isDiastolic={c.name === 'dia'}
                getFillCollor={this.getFillCollor}
                title={title}
                fixTitle={fixTitle}
              />
            </div>
          );
        })}
      </div>
    );
  }
}

function LineMarkChart({
  isBloodOxygenDevice,
  dateRange,
  parentIndex,
  oriChartData,
  data,
  yDomain,
  markData,
  areaData,
  indexed,
  indexedThreshold,
  stroke,
  markColor,
  state,
  state2,
  lastItem,
  classes,
  handleMouseOver,
  handleMouseOverThreshold,
  onValueMouseOver,
  onValueMouseOut,
  onValueMouseOverThreshold,
  onValueMouseOutThreshold,
  getAdditionalValue,
  xTicks,
  pdf = false,
  isEmbedded = false,
  isDiastolic,
  getFillCollor,
  title,
  fixTitle,
}) {
  // handling Sys XAxis
  // lastItem = true;
  const yTicks = [0];

  // find bigest number
  const getYs = data.value.map((m) => (m.y !== 'N.A.' ? m.y : 0));
  let largestNumber = Math.max(...getYs);
  if (markData.length > 0) {
    const getLargestThreshold = Math.max(...markData.map((m) => m.y));
    if (largestNumber < getLargestThreshold) {
      largestNumber = getLargestThreshold;
    }
  }

  const additionalValue = getAdditionalValue(data, largestNumber);
  let yTicsLastValue = yTicks[yTicks.length - 1];
  while (yTicsLastValue + additionalValue <= largestNumber) {
    yTicsLastValue += additionalValue;
    const getValueBeetween = data.value.find(
      (f) => f.yValue !== 'N.A.' && f.yValue >= yTicks[yTicks.length - 1] && f.yValue <= yTicsLastValue,
    );
    if (getValueBeetween) {
      const yTicsBeforeValue = yTicsLastValue - additionalValue;
      if (!yTicks.includes(yTicsBeforeValue)) yTicks.push(yTicsBeforeValue);
      yTicks.push(yTicsLastValue);
    }
  }

  yTicsLastValue += additionalValue;
  const getValueBeetween = data.value.find(
    (f) => f.yValue !== 'N.A.' && f.yValue >= yTicks[yTicks.length - 1] && f.yValue <= yTicsLastValue,
  );
  if (getValueBeetween) {
    const yTicsBeforeValue = yTicsLastValue - additionalValue;
    if (!yTicks.includes(yTicsBeforeValue)) yTicks.push(yTicsBeforeValue);
  }
  yTicks.push(yTicsLastValue);

  if (yTicks.length > 0) {
    yDomain = [yTicks[0], yTicks[yTicks.length - 1]];
  }

  const lineMarkSeriesData = [];
  let indexLineMarkSeriesData = 0;
  data.value.forEach((item, index) => {
    if (item.y !== 'N.A.') {
      if (index > 0) {
        if (data.value[index - 1].y === 'N.A.') {
          indexLineMarkSeriesData++;
        }
      }

      if (!lineMarkSeriesData[indexLineMarkSeriesData]) lineMarkSeriesData[indexLineMarkSeriesData] = [];
      lineMarkSeriesData[indexLineMarkSeriesData].push(item);
    }
  });

  const xAxisData = data.value;
  if (data.value) data.value = data.value.filter((f) => f.y !== 'N.A.');
  if (oriChartData.value) oriChartData.value = oriChartData.value.filter((f) => f.y !== 'N.A.');
  if (oriChartData.listReadings) oriChartData.listReadings = oriChartData.listReadings.filter((f) => f.y !== 'N.A.');
  // let missedReadingData;
  let maxYAxis = data.value.length !== 0 && data.value.reduce((acc, cur, idx) => (cur.y > acc.y && idx ? cur : acc));
  if (largestNumber < yDomain[yDomain.length - 1]) {
    maxYAxis = {
      ...maxYAxis,
      y: yDomain[yDomain.length - 1],
      yValue: yDomain[yDomain.length - 1],
    };
  }
  const isMoreThanOneData = oriChartData.length > 1;

  const oneTimeTaskData = data.value.filter((d) => d.type === 'one-time');
  const scheduledTaskData = data.value;
  const labelSeriesData = data.value.map((m) => ({
    ...m,
    color: m.style.fill,
    label: `${m.label === 'Hearth Rate' && m.y ? normalizeVitalsDecimals(parseFloat(m.y), title) : m.y ? m.y : '0'}`,
    style: { fontSize: 14, fontWeight: 'bold', textAnchor: 'middle' },
    yOffset: -10,
  }));

  const thresholdType = (key) => {
    if (key === 'CH') {
      return 'Critically High';
    } else if (key === 'H') {
      return 'High';
    } else if (key === 'L') {
      return 'Low';
    }
    return 'Critically Low';
  };

  const dateDisplay = (() => {
    if (!state) {
      return '';
    }
    const { reportDate, nextReportDate } = state;
    const {
      aggregation: { unit, step },
    } = reportDate;

    return getRangeDisplay({
      unit,
      reportDate,
      nextReportDate,
      step,
    });
  })();

  return (
    <FlexibleWidthXYPlot
      height={isMoreThanOneData ? 181 : pdf && isEmbedded ? 232 : 362}
      xDomain={dateRange}
      yDomain={yDomain}
      onMouseLeave={() => {
        // handleMouseOver(null);
        handleMouseOverThreshold(null);
        // onValueMouseOut();
        onValueMouseOutThreshold();
      }}
      onMouseDown={() => {
        handleMouseOver(null);
        onValueMouseOut();
      }}>
      {/** Background color for high reading */}
      <AreaSeries
        color={'#FDFDF2'}
        data={[
          {
            x: dateRange[0],
            // eslint-disable-next-line no-nested-ternary
            y: !isNil(areaData[parentIndex].CH.y)
              ? areaData[parentIndex].CH.y
              : // eslint-disable-next-line no-nested-ternary
                maxYAxis && areaData[parentIndex].H.y > maxYAxis.y
                ? areaData[parentIndex].H.y + 10
                : maxYAxis
                  ? maxYAxis.y
                  : areaData[parentIndex].H.y + 10,
            y0: areaData[parentIndex].H.y,
          },
          {
            x: dateRange[1],
            // eslint-disable-next-line no-nested-ternary
            y: !isNil(areaData[parentIndex].CH.y)
              ? areaData[parentIndex].CH.y
              : // eslint-disable-next-line no-nested-ternary
                maxYAxis && areaData[parentIndex].H.y > maxYAxis.y
                ? areaData[parentIndex].H.y + 10
                : maxYAxis
                  ? maxYAxis.y
                  : areaData[parentIndex].H.y + 10,
            y0: areaData[parentIndex].H.y,
          },
        ]}
      />
      {/** Background color for low reading */}
      <AreaSeries
        color={'#FDFDF2'}
        data={[
          {
            x: dateRange[0],
            y: areaData[parentIndex].L.y,
            // eslint-disable-next-line no-nested-ternary
            y0: !isNil(areaData[parentIndex].CL.y) ? areaData[parentIndex].CL.y : isBloodOxygenDevice ? 50 : 0,
          },
          {
            x: dateRange[1],
            y: areaData[parentIndex].L.y,
            // eslint-disable-next-line no-nested-ternary
            y0: !isNil(areaData[parentIndex].CL.y) ? areaData[parentIndex].CL.y : isBloodOxygenDevice ? 50 : 0,
          },
        ]}
      />
      {/** Background color for critical high reading */}
      {!isNil(areaData[parentIndex].CH.y) && (
        <AreaSeries
          color={'#FFF9FC'}
          data={[
            {
              x: dateRange[0],
              y: maxYAxis.y > areaData[parentIndex].CH.y ? maxYAxis.y : areaData[parentIndex].CH.y,
              y0: areaData[parentIndex].CH.y,
            },
            {
              x: dateRange[1],
              y: maxYAxis.y > areaData[parentIndex].CH.y ? maxYAxis.y : areaData[parentIndex].CH.y,
              y0: areaData[parentIndex].CH.y,
            },
          ]}
        />
      )}
      {/** Background color for critical low reading */}
      {!isNil(areaData[parentIndex].CL.y) && (
        <AreaSeries
          color={'#FFF9FC'}
          data={[
            { x: dateRange[0], y: areaData[parentIndex].CL.y, y0: isBloodOxygenDevice ? 50 : 0 },
            { x: dateRange[1], y: areaData[parentIndex].CL.y, y0: isBloodOxygenDevice ? 50 : 0 },
          ]}
        />
      )}
      {
        <XAxis
          tickValues={xTicks}
          tickFormat={(v) => {
            const index = v - 1;
            const item = xAxisData[index] || {};
            return item.display;
          }}
          style={{
            text: {
              fontSize: pdf ? 9 : 11,
            },
          }}
        />
      }
      <YAxis tickValues={yTicks} tickFormat={(v) => (v ? v : '')} />
      <HorizontalGridLines tickValues={yTicks} />
      {/** Threshold Mark Component */}
      <CustomSVGSeries
        customComponent={lastItem && isMoreThanOneData && isDiastolic ? 'diamond' : 'circle'}
        data={
          markData &&
          markData.map((m, markIndex) => ({
            ...m,
            y: lastItem && isMoreThanOneData ? m.y + 2.7 : m.y,
            size: lastItem && isMoreThanOneData ? 9 : 7,
            style: {
              stroke: !isNil(m.y) ? markColor : 'none',
              strokeWidth: !isNil(m.y) ? 2 : 'none',
              fill: !isNil(m.y) ? getFillCollor(markIndex, markColor) : 'none',
            },
          }))
        }
        onNearestXY={(datapoint, { index }) => {
          handleMouseOverThreshold(index);
          onValueMouseOverThreshold(datapoint);
        }}
      />
      {/** The main component of the chart */}
      {lineMarkSeriesData.map((item, idx) => (
        <LineMarkSeries
          key={idx}
          data={item}
          strokeWidth={2}
          size={12}
          fill="none"
          lineStyle={{ stroke: markColor }}
          markStyle={{ stroke: 'none' }}
          onValueMouseOver={(datapoint) => {
            const index = scheduledTaskData.findIndex((f) => f.x === datapoint.x && f.y === datapoint.y);
            handleMouseOver(index, datapoint.type);
            onValueMouseOver(datapoint);
          }}
        />
      ))}
      <LabelSeries animation allowOffsetToBeReversed style={{ color: 'red' }} data={labelSeriesData} />
      <MarkSeries
        data={oneTimeTaskData}
        strokeWidth={2}
        size={12}
        stroke="none"
        fill="none"
        onValueMouseOver={(datapoint) => {
          const index = oneTimeTaskData.findIndex((f) => f.x === datapoint.x && f.y === datapoint.y);
          handleMouseOver(index, datapoint.type);
          onValueMouseOver(datapoint);
        }}
      />
      {/** Custom mark for yAxis data */}
      <CustomSVGSeries
        customComponent={lastItem && isMoreThanOneData && isDiastolic ? 'diamond' : 'circle'}
        data={scheduledTaskData.map((m) => ({
          ...m,
          y: lastItem && isMoreThanOneData ? m.y + 5 : m.y,
          size: lastItem && isMoreThanOneData ? 13 : 10,
        }))}
        style={{ stroke: markColor, strokeWidth: 2 }}
      />
      {/** One time task */}
      <CustomSVGSeries
        customComponent={'star'}
        data={oneTimeTaskData.map((m) => ({
          ...m,
          // y: lastItem && isMoreThanOneData ? m.y + 5 : m.y,
          size: 9,
        }))}
        style={{ stroke: '#414EC1', strokeWidth: 1 }}
      />
      {/** vertical line */}
      {indexed === null ? null : (
        <LineSeries
          data={[
            { x: state.x, y: isBloodOxygenDevice ? 50 : 0 },
            {
              x: state.x,
              // eslint-disable-next-line no-nested-ternary
              y: !isNil(areaData[parentIndex].CH.y)
                ? maxYAxis && maxYAxis.y > areaData[parentIndex].CH.y
                  ? maxYAxis.y
                  : areaData[parentIndex].CH.y
                : // eslint-disable-next-line no-nested-ternary
                  maxYAxis && areaData[parentIndex].H.y > maxYAxis.y
                  ? areaData[parentIndex].H.y + 10
                  : maxYAxis
                    ? maxYAxis.y
                    : areaData[parentIndex].H.y + 10,
            },
          ]}
          opacity={0.2}
          stroke="black"
        />
      )}
      {/** horizontal line of threshold */}
      {indexedThreshold === null ? null : (
        <LineSeries
          data={[
            {
              x: dateRange[0],
              y: !isNil(markData[indexedThreshold]) && markData[indexedThreshold].y,
            },
            {
              x: dateRange[1],
              y: !isNil(markData[indexedThreshold]) && markData[indexedThreshold].y,
            },
          ]}
          opacity={0.5}
          strokeStyle="dashed"
          stroke={
            isNil(markData[indexedThreshold]) || isNil(markData[indexedThreshold].y)
              ? 'none'
              : getFillCollor(indexedThreshold, markColor)
          }
        />
      )}
      {/** This component will be used when the data point is clicked */}
      {state2 &&
        markData[indexedThreshold].y !== null && (
          <Hint value={markData[indexedThreshold]} align={{ vertical: 'top' }}>
            <Grid className={classes.threshold} style={{ backgroundColor: getFillCollor(indexedThreshold, markColor) }}>
              <Typography className={classes.thresholdText}>
                {normalizeVitalsDecimals(markData[indexedThreshold].y, title, false)}:{' '}
                {thresholdType(Object.keys(areaData[parentIndex])[indexedThreshold])}
              </Typography>
            </Grid>
          </Hint>
        )}
      {state &&
        lastItem && (
          <Hint value={{ x: state.x, y: state.y }} align={{ vertical: 'auto', horizontal: 'auto' }}>
            <Grid style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <Card
                className={classes.dateDisplayOnCard}
                style={{
                  marginTop: !isMoreThanOneData ? '-10px' : !isNil(areaData[parentIndex].CH.y) ? '-215px' : '-225px',
                }}>
                <CardHeader
                  style={{ height: '10px' }}
                  title={
                    <Grid>
                      <Grid container direction="row" style={{ paddingTop: '12px' }}>
                        <Typography style={{ fontWeight: '500', fontSize: '14px' }}>{state && dateDisplay}</Typography>
                      </Grid>
                      <div style={{ borderBottom: '1px solid #F5F5F5', paddingTop: '10px' }} />
                    </Grid>
                  }
                />
                <CardContent style={{ width: '280px' }}>
                  <Grid container>
                    <Grid className={classes.gridDailyAverageOnCard}>
                      <Typography className={classes.typography3}>Measurement Average</Typography>
                      <Grid container direction="row">
                        {oriChartData.map((o, i) => {
                          const dailyAvg = o.value.filter((f) => f.x === state.x && f.type === state.type);
                          let unit = '';
                          const getUnit = dailyAvg.find((f) => f.unit || f.valueQuantity.unit);
                          if (getUnit) unit = getUnit.unit ? getUnit.unit : getUnit.valueQuantity.unit;
                          return (
                            <Paper
                              key={i}
                              elevation={1}
                              className={classes.paperDailyAverageOnCard}
                              style={{
                                background: dynamicColor(
                                  dailyAvg.length > 0 ? areaData[i] : null,
                                  dailyAvg.length > 0 ? dailyAvg[0].y : null,
                                ),
                                marginLeft: !isMoreThanOneData && 8,
                                width: !isMoreThanOneData ? 265 : 135,
                                boxShadow:
                                  '0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%)',
                              }}>
                              <Grid container className={classes.gridDailyAverageOnCard2}>
                                <Typography className={classes.typography4}>
                                  {o.label === 'Temperature'
                                    ? 'Body Temperature'
                                    : fixTitle === 'Diastolic'
                                      ? o.label
                                      : fixTitle === 'Temperature'
                                        ? 'Body Temperature'
                                        : fixTitle}
                                </Typography>
                                <Typography className={classes.typography5}>
                                  {/* {dailyAvg.length > 0 ? normalizeVitalsDecimals(dailyAvg[0].y, title) : ''} */}
                                  {dailyAvg.length > 0 ? dailyAvg[0].y : ''}
                                </Typography>
                                <Typography className={classes.typography4}>{unit}</Typography>
                              </Grid>
                            </Paper>
                          );
                        })}
                      </Grid>
                    </Grid>
                    <Grid
                      className={classes.gridListReading}
                      style={{
                        marginTop: -7,
                        maxHeight: '80px',
                        overflowX: 'scroll',
                      }}>
                      {state &&
                        state.vitalChartEnrolledProgramData.length > 1 &&
                        state.vitalChartEnrolledProgramData.map((epListPopup, listIndex) => {
                          let threshold = '';
                          if (epListPopup.average.measurementAverage) {
                            threshold = `${epListPopup.average.measurementAverage} ${epListPopup.average.unit}`;
                          } else {
                            threshold = `${epListPopup.average.sysAverage}/${epListPopup.average.diaAverage} ${
                              epListPopup.average.unit
                            }`;
                          }
                          return (
                            <Grid
                              container
                              direction="row"
                              style={{ justifyContent: 'space-between', paddingTop: '12px' }}
                              key={listIndex}>
                              <Grid>
                                <Typography style={{ fontSize: '12px', fontWeight: '400' }}>
                                  {commonUtil.truncateWithEllipsis(epListPopup.epTitle, 30)}
                                </Typography>
                              </Grid>
                              <Grid>
                                <Typography style={{ fontSize: '12px', fontWeight: '400' }}>{threshold}</Typography>
                              </Grid>
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Hint>
        )}
    </FlexibleWidthXYPlot>
  );
}

export default ReportVitalChart;
