import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import uuidv4 from 'uuid/v4';
import { withReduxForm, Select } from 'utilsModule/form/reduxFormMui';
import { Back } from 'utilsModule/components';
import Logo from 'appModule/logo/logoNoText';
import HeaderComponent from 'appModule/main/layouts/Header';

const styles = {
  pageContainer: {
    height: '84vh',
    width: '100vw',
    margin: 0,
    background: '#fff',
    paddingBottom: 100
  },
  back: {
    height: '16vh',
    padding: 20,
    backgroundColor: '#fff',
  },
  banner: {
    textAlign: 'center',
  },
  title: {
    marginTop: '15px',
  },
  subTitle: {
    fontSize: '15px',
    lineHeight: 1.2,
    letterSpacing: '0.5px',
    whiteSpace: 'pre-line',
    textAlign: 'center',
    marginTop: '15px',
  },
};

const layout = {
  header: {
    containerProps: {
      justify: 'center',
    },
    components: [
      {
        id: uuidv4(),
        name: 'banner',
        component: ({ title, subtitle }) => (
          <Grid container style={styles.banner}>
            <Grid item xs={12}><Logo /></Grid>
            <Grid item xs={12}><Typography variant='h4' style={styles.title}>{title}</Typography></Grid>
            <Grid item xs={12}><Typography variant='body1' style={styles.subTitle}>{subtitle}</Typography></Grid>
          </Grid>
        ),
        colSpans: { xs: 12 },
        inject: ({ runtimeProps: { header: { banner } } }) => banner,
      },
    ],
  },
  body: {
    sections: [
      {
        id: uuidv4(),
        rows: [
          {
            id: uuidv4(),
            cols: [
              {
                name: 'profile',
                label: 'Profiles',
                component: Select,
                required: true,
              },
            ],
          },
        ],
      },
    ],
  },
  footer: {
    itemProps: {
      style: {
        marginTop: 50,
      },
    },
    containerProps: {
      justify: 'center',
    },
    components: [
      {
        id: uuidv4(),
        component: Button,
        type: 'submit',
        children: 'Enter',
        variant: 'contained',
        color: 'primary',
        style: { width: '100%' },
        className: 'milo-btn-submit-medium',
        colSpans: { xs: 6 },
        inject: ({ submitting }) => ({ disabled: submitting }),
      },
    ],
  },
};

const validate = ({ profile: { value } = {} }) => ({ profile: value ? '' : 'Please select a profile' });

const config = {
  form: 'selectProfile',
  validate,
};

@withStyles(styles, { index: 1 })
@withReduxForm({ layout, config })
class SelectProfile extends PureComponent {
  static propTypes = {
    /* container */
    selectProfile: PropTypes.func.isRequired,
    /* withStyles */
    classes: PropTypes.object,
    /* createReduxForm */
    Form: PropTypes.func.isRequired,
  };

  render() {
    const {
      classes,
      Form, initialValues, runtimeProps, selectProfile,
    } = this.props;

    // console.log('%crender SelectProfile', 'font-size: 12px; color: #00b3b3', this.props);

    return (
      <React.Fragment>
        <HeaderComponent isOnBoard={true} />
        <div style={{paddingTop: 50}}>
          <Back className={classes.back} label='Log In' backToWebsite={true} path='/auth' />
          <Grid container className={classes.pageContainer} alignItems='center'>
            <Grid item xs />
            <Grid item xs={4}>
              <Form
                runtimeProps={runtimeProps}
                initialValues={initialValues}
                onSubmit={({ profile: { value } = {} }) => selectProfile(value)}
                sections={[]}
              />
            </Grid>
            <Grid item xs />
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default SelectProfile;
