import { combineReducers } from 'redux';
import list, { epics as listEpics } from './list';
import room, { epics as roomEpics } from './room';
import chats, { epics as chatsEpics } from './chats';

export const epics = [...listEpics, ...roomEpics, ...chatsEpics];

export default combineReducers({
  list,
  chats,
  room,
});
