import * as moment from 'moment';

export function getRangeDisplay(params) {
    const { unit, reportDate, nextReportDate, step } = params;

    /**
     * MONTH
     */
    if (unit === 'MONTH') {
        const start = moment(reportDate.date).format('MMM YYYY');
        const display = `${start}`;
        return display;
    }

    /**
     * DAY
     */
    if (unit === 'DAY') {
        if (step === 1) {
            const start = moment(reportDate.date).format('DD MMM YYYY');
            const display = `${start}`;
            return display;
        }

        if (step === 2) {
            const start = moment(reportDate.date).format('DD MMM YYYY');
            const end = nextReportDate ?
                moment(nextReportDate.date)
                .subtract(1, 'd')
                .format('DD MMM YYYY') :
                moment(reportDate.date)
                .endOf('M')
                .format('DD MMM YYYY');
            const display = `${start} - ${end}`;
            return display;
        }

        if (step === 7) {
            const start = moment(reportDate.date).format('DD MMM YYYY');
            const end = nextReportDate ?
                moment(nextReportDate.date)
                .subtract(1, 'd')
                .format('DD MMM YYYY') :
                moment(reportDate.date)
                .endOf('M')
                .format('DD MMM YYYY');
            const display = `${start} - ${end}`;
            return display;
        }
    }

    /**
     * HOUR
     */
    if (unit === 'HOUR') {
        if (step === 1) {
            const start = moment(reportDate.date)
                .startOf('D')
                .add(reportDate.hour, 'H')
                .format('DD MMM YYYY, HH:mm');
            const end = moment(reportDate.date)
                .startOf('D')
                .add(reportDate.hour, 'H')
                .add(59, 'm')
                .format('DD MMM YYYY, HH:mm');
            const display = `${start} - ${end}`;
            return display;
        }

        if (step === 3) {
            const start = moment(reportDate.date)
                .startOf('D')
                .add(reportDate.hour, 'H')
                .format('DD MMM YYYY, HH:mm');
            const end = moment(reportDate.date)
                .startOf('D')
                .add(reportDate.hour, 'H')
                .add(3, 'h')
                .subtract(1, 'm')
                .format('DD MMM YYYY, HH:mm');
            const display = `${start} - ${end}`;
            return display;
        }

        if (step === 6) {
            const start = moment(reportDate.date)
                .startOf('D')
                .add(reportDate.hour, 'H')
                .format('DD MMM YYYY, HH:mm');
            const end = moment(reportDate.date)
                .startOf('D')
                .add(reportDate.hour, 'H')
                .add(6, 'h')
                .subtract(1, 'm')
                .format('DD MMM YYYY, HH:mm');
            const display = `${start} - ${end}`;
            return display;
        }

        if (step === 12) {
            const start = moment(reportDate.date)
                .startOf('D')
                .add(reportDate.hour, 'H')
                .format('DD MMM YYYY, HH:mm');
            const end = moment(reportDate.date)
                .startOf('D')
                .add(reportDate.hour, 'H')
                .add(12, 'h')
                .subtract(1, 'm')
                .format('DD MMM YYYY, HH:mm');
            const display = `${start} - ${end}`;
            return display;
        }
    }
    return '';
}