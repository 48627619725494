import VISA from '../assets/visa_s.png';
import MASTER from '../assets/mastercard_s.png';
import AMEX from '../assets/amex_s.png';

const getCreditCardType = (number) => {
  // visa
  let re = new RegExp('^4');
  if (number.match(re) != null) return 'VISA';

  // Mastercard
  // Updated for Mastercard 2017 BINs expansion
  //  if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number))
  //     return "MASTERCARD";
  if (/^(5[1-5]|2(22[1-9]|2[3-9]|[3-6]|7[0-1]|720))/.test(number)) return 'MASTERCARD';

  // AMEX
  re = new RegExp('^3[47]');
  if (number.match(re) != null) return 'AMEX';

  // Discover
  re = new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)');
  if (number.match(re) != null) return 'DISCOVER';

  // Diners
  re = new RegExp('^36');
  if (number.match(re) != null) return 'DINERS';

  // Diners - Carte Blanche
  re = new RegExp('^30[0-5]');
  if (number.match(re) != null) return 'DINERS - Carte Blanche';

  // JCB
  re = new RegExp('^35(2[89]|[3-8][0-9])');
  if (number.match(re) != null) return 'JCB';

  // Visa Electron
  re = new RegExp('^(4026|417500|4508|4844|491(3|7))');
  if (number.match(re) != null) return 'VISA Electron';

  return '';
};

const getCreditCardImage = (cardBrand) => {
  switch (cardBrand) {
    case 'VISA':
      return VISA;
    case 'MASTERCARD':
      return MASTER;
    case 'AMEX':
      return AMEX;
    default:
      return null;
  }
};

export default { getCreditCardType, getCreditCardImage };
