import React from 'react';
import { withStyles } from '@material-ui/styles';
import SwipeableViews from 'react-swipeable-views';
import {
  Grid, Button, Dialog, DialogTitle, DialogActions, DialogContent, List, ListItem, ListItemText, ListItemIcon, IconButton, Tab,
  Tabs, Box, AppBar
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import logo from 'dataModule/assets/img/HealthBeatsDialogLogo.svg';
import helpAndSupport from 'dataModule/assets/img/customerServiceSupport/helpAndSupport.svg';
import serviceContact from 'dataModule/assets/img/customerServiceSupport/serviceContact.svg';
import serviceEmail from 'dataModule/assets/img/customerServiceSupport/serviceEmail.svg';
import serviceLocation from 'dataModule/assets/img/customerServiceSupport/serviceLocation.svg';

const styles = theme => ({
  dialogPaper: {
    minHeight: '600px',
    minWidth: '654px',
    borderRadius: '5px'
  },
  topHeader: {
    margin: 0,
    padding: 2,
    height: 64,
    backgroundColor: '#fff',
  },
  primaryColor: {
    color: '#ff5100',
  },
  listItem: {
    height: 'auto !important',
  },
  dialogLogo: {
    maxWidth: '40px'
  },
  dialogTitle: {
    color: '#212121',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '19px',
    letterSpacing: '0.15000000596046448px',
  },
  dialogCloseButton: {
    position: 'relative',
    left: '10px',
    color: 'black',
    maxHeight: '40px',
    maxWidth: '40px'
  },
  dialogDisplayFlex: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: '40px',
    textIndent: 0
  },
  dialogListItem: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '19px',
    color: '#212121',
    letterSpacing: '0.5px'
  },
  link: {
    marginLeft: 30,
  },
  icon: {
    marginRight: 8,
  },
  button: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '1.25px',
    textAlign: 'center',
    minWidth: 161,
  },
  tabRoot: {
    [theme.breakpoints.up('md')]: {
      minWidth: 80,
    },
    backgroundColor: '#fff',
    opacity: 'unset',
    height: 60,
    '&:hover': {
      color: '#2979ff',
      opacity: 1,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  tabLabelContainer: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(),
    },
  },
  label: {
    textTransform: 'capitalize',
    color: '#212121',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: 1.2,
    fontStretch: 'normal',
    letterSpacing: '0.5px',
    '&:hover': {
      color: '#212121',
    },
  },
  indicator: { backgroundColor: '#ff5100' },
});

class TabPanel extends React.Component {
  render() {
    const { children, value, index, ...other } = this.props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
}

@withStyles(styles, { index: 1 })
export default class DialogCustomerSupportContactComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
    };
  }

  handleClose = async () => {
    this.props.toggledialogCustomerSupportContact(false);
  }

  handleChange = (event, newValue) => {
    this.setState({ selectedTab: newValue });
  };

  handleChangeIndex = (index) => {
    this.setState({ selectedTab: index });
  };

  a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  render() {
    const {
      open, classes: { dialogPaper, dialogLogo, dialogDisplayFlex, button, dialogTitle, dialogCloseButton, dialogListItem, indicator, tabRoot, tabLabelContainer, listItem, label },
    } = this.props;

    return (
      <Dialog
        open={open}
        classes={{ paper: dialogPaper }}
        aria-labelledby='ihealth-customer-care-info'
      >
        <DialogTitle id='ihealth-customer-care-info' style={{ padding: '12px 16px' }}>
          <Grid container direction="row" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid item className={dialogDisplayFlex}>
              <img src={logo} className={dialogLogo} />
              <span style={{ width: '16px' }} />
              <Typography variant="body" className={dialogTitle}>
                HealthBeats® Customer Service Support
              </Typography>
            </Grid>
            <Grid item className={dialogDisplayFlex}>
              <IconButton aria-label="close" className={dialogCloseButton} onClick={this.handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ padding: '0' }}>

          <AppBar position="static" color="default">
            <Tabs
              value={this.state.selectedTab}
              onChange={this.handleChange}
              classes={{ indicator: indicator, backgroundColor: '#E0E0E0' }}
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label={
                <Typography variant='subtitle1' className={label} style={{ color: this.state.selectedTab === 0 ? '#ff5100' : '#9E9E9E' }}>
                  Singapore
                </Typography>
              } {...this.a11yProps(0)} classes={{
                root: tabRoot,
                wrapper: tabLabelContainer,
              }} />
              <Tab label={
                <Typography variant='subtitle1' className={label} style={{ color: this.state.selectedTab === 1 ? '#ff5100' : '#9E9E9E' }}>
                  Australia
                </Typography>
              } {...this.a11yProps(1)} classes={{
                root: tabRoot,
                wrapper: tabLabelContainer,
              }} />
              <Tab label={
                <Typography variant='subtitle1' className={label} style={{ color: this.state.selectedTab === 2 ? '#ff5100' : '#9E9E9E' }}>
                  United States
                </Typography>
              } {...this.a11yProps(2)} classes={{
                root: tabRoot,
                wrapper: tabLabelContainer,
              }} />
            </Tabs>
          </AppBar>

          <SwipeableViews
            index={this.state.selectedTab}
            onChangeIndex={this.handleChangeIndex}
          >
            <TabPanel value={this.state.selectedTab} index={0} >
              <List>
                <ListItem className={listItem}>
                  <ListItemIcon style={{ color: '#212121', paddingBottom: 20 }}>
                    <img src={helpAndSupport} />
                  </ListItemIcon>
                  <ListItemText
                    style={{ whiteSpace: 'break-spaces', paddingBottom: 20 }}
                    primary={
                      <React.Fragment>
                        <Typography variant='body1' className={dialogListItem}>
                          Raise a service ticket through <a href="https://healthbeats.co/support">HealthBeats® Support Portal</a>
                        </Typography>
                      </React.Fragment>
                    } secondary={<Typography variant='body1' className={dialogListItem}>We will respond to you within one working day.</Typography>} />
                </ListItem>
                <ListItem className={listItem}>
                  <ListItemIcon style={{ color: '#212121', paddingBottom: 20 }}>
                    <img src={serviceEmail} />
                  </ListItemIcon>
                  <ListItemText style={{ whiteSpace: 'break-spaces', paddingBottom: 20 }} primary={
                    <React.Fragment>
                      <Typography variant='body1' className={dialogListItem}>
                        <a href="mailto:support@healthbeats.co">support@healthbeats.co</a>
                      </Typography>
                    </React.Fragment>
                  } />
                </ListItem>
                <ListItem className={listItem}>
                  <ListItemIcon style={{ color: '#212121', paddingBottom: 20 }}>
                    <img src={serviceContact} />
                  </ListItemIcon>
                  <ListItemText
                    style={{ whiteSpace: 'break-spaces', paddingBottom: 20 }}
                    primary={<Typography variant='body1' className={dialogListItem}>+65 6957 4900</Typography>} />
                </ListItem>
                <ListItem className={listItem}>
                  <ListItemIcon style={{ color: '#212121', paddingBottom: 20 }}>
                    <img src={serviceLocation} />
                  </ListItemIcon>
                  <ListItemText
                    style={{ whiteSpace: 'break-spaces', paddingBottom: 20 }}
                    primary={<Typography variant='body1' className={dialogListItem}>19 Jalan Kilang Barat, Acetech Centre #03-07, Singapore 159361</Typography>} />
                </ListItem>
              </List>
            </TabPanel>
            <TabPanel value={this.state.selectedTab} index={1} >
              <List>
                <ListItem className={listItem}>
                  <ListItemIcon style={{ color: '#212121', paddingBottom: 20 }}>
                    <img src={helpAndSupport} />
                  </ListItemIcon>
                  <ListItemText
                    style={{ whiteSpace: 'break-spaces', paddingBottom: 20 }}
                    primary={
                      <React.Fragment>
                        <Typography variant='body1' className={dialogListItem}>
                          Raise a service ticket through <a href="https://healthbeats.co/support">HealthBeats® Support Portal</a>
                        </Typography>
                      </React.Fragment>
                    } secondary={<Typography variant='body1' className={dialogListItem}>We will respond to you within one working day</Typography>} />
                </ListItem>
                <ListItem className={listItem}>
                  <ListItemIcon style={{ color: '#212121', paddingBottom: 20 }}>
                    <img src={serviceEmail} />
                  </ListItemIcon>
                  <ListItemText style={{ whiteSpace: 'break-spaces', paddingBottom: 20 }} primary={
                    <React.Fragment>
                      <Typography variant='body1' className={dialogListItem}>
                        <a href="mailto:support@healthbeats.co">support@healthbeats.co</a>
                      </Typography>
                    </React.Fragment>
                  } />
                </ListItem>
                <ListItem className={listItem}>
                  <ListItemIcon style={{ color: '#212121', paddingBottom: 20 }}>
                    <img src={serviceContact} />
                  </ListItemIcon>
                  <ListItemText
                    style={{ whiteSpace: 'break-spaces', paddingBottom: 20 }}
                    primary={<Typography variant='body1' className={dialogListItem}>+61 2 7250 0351</Typography>} />
                </ListItem>
                <ListItem className={listItem}>
                  <ListItemIcon style={{ color: '#212121', paddingBottom: 20 }}>
                    <img src={serviceLocation} />
                  </ListItemIcon>
                  <ListItemText
                    style={{ whiteSpace: 'break-spaces', paddingBottom: 20 }}
                    primary={<Typography variant='body1' className={dialogListItem}>903, 50 Clarence St. Sydney NSW 2000 Australia</Typography>} />
                </ListItem>
              </List>
            </TabPanel>
            <TabPanel value={this.state.selectedTab} index={2} >
              <List>
                <ListItem className={listItem}>
                  <ListItemIcon style={{ color: '#212121', paddingBottom: 20 }}>
                    <img src={helpAndSupport} />
                  </ListItemIcon>
                  <ListItemText
                    style={{ whiteSpace: 'break-spaces', paddingBottom: 20 }}
                    primary={
                      <React.Fragment>
                        <Typography variant='body1' className={dialogListItem}>
                          Raise a service ticket through <a href="https://healthbeats.co/support">HealthBeats® Support Portal</a>
                        </Typography>
                      </React.Fragment>
                    } secondary={<Typography variant='body1' className={dialogListItem}>We will respond to you within one working day</Typography>} />
                </ListItem>
                <ListItem className={listItem}>
                  <ListItemIcon style={{ color: '#212121', paddingBottom: 20 }}>
                    <img src={serviceEmail} />
                  </ListItemIcon>
                  <ListItemText style={{ whiteSpace: 'break-spaces', paddingBottom: 20 }} primary={
                    <React.Fragment>
                      <Typography variant='body1' className={dialogListItem}>
                        <a href="mailto:support@healthbeats.co">support@healthbeats.co</a>
                      </Typography>
                    </React.Fragment>
                  } />
                </ListItem>
                <ListItem className={listItem}>
                  <ListItemIcon style={{ color: '#212121', paddingBottom: 20 }}>
                    <img src={serviceLocation} />
                  </ListItemIcon>
                  <ListItemText
                    style={{ whiteSpace: 'break-spaces', paddingBottom: 20 }}
                    primary={<Typography variant='body1' className={dialogListItem}>651 N Broad St. Suite 205 #403 Middletown Delaware 19709, USA</Typography>} />
                </ListItem>
              </List>
            </TabPanel>
          </SwipeableViews>

        </DialogContent>
        <DialogActions style={{ padding: '12px' }}>
          <Button className={['milo-btn-orange', button]} variant='contained' onClick={this.handleClose}> Ok</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

