import { combineReducers } from 'redux';
import create, { epics as createEpics } from './create';
import apList, { epics as apListEpics } from './apList';
import epList, { epics as epListEpics } from './epList';
import enroll, { epics as enrollEpics } from './enroll';
import availableList, { epics as availableListEpics } from './availableList';
import addonBaseList, {epics as addonBaseListEpics} from './addonBaseList';

export const epics = [
  ...createEpics,
  ...apListEpics,
  ...epListEpics,
  ...enrollEpics,
  ...availableListEpics,
  ...addonBaseListEpics
];

export default combineReducers({
  create,
  apList,
  epList,
  enroll,
  availableList,
  addonBaseList
});
