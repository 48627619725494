/* eslint-disable max-len */
import {
  Avatar as MuiAvatar,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import {
  FlexibleWidthXYPlot,
  Hint,
  HorizontalGridLines,
  LabelSeries,
  LineMarkSeries,
  XAxis,
  YAxis,
} from 'hb-react-vis';
import * as R from 'ramda';
import React from 'react';
import { MiloTable } from 'utilsModule/components';
import { Assignment } from 'utilsModule/material-ui-icons';
import { setColourByRate } from '../../../adherence/utils/utils';
import CollapsibleCard from './CollapsibleCard';
import commonUtil from 'utilsModule/common';
import { InfoOutline } from 'utilsModule/material-ui-icons';
import EmptyStateReportChart from './../../assets/EmptyStateReportChart.png';
import { getRangeDisplay } from 'appModule/reporting/utils/dateAggregationUtil';

const chartList = [
  { value: 'LINE_CHART', name: 'line', label: 'Line Chart' },
  { value: 'DATA_VIEW', name: 'dataTable', label: 'Data Table' },
];

const styles = {
  collapsibleCardGrid: {
    backgroundColor: '#212121',
    fontSize: '24px',
  },
  cardLabel: {
    fontFamily: 'Roboto',
    fontWeight: 'medium',
    fontSize: '12px',
    color: '#FFFFFF',
    paddingTop: 16,
  },
  cardDetail: {
    fontFamily: 'Roboto',
    fontSize: '21px',
    color: '#FFFFFF',
  },
  vitalDiv: {
    borderLeft: '1px solid',
    height: '120px',
    marginTop: 3,
  },
  vitalDiv2: {
    borderBottom: '1px solid',
    height: '20px',
    marginTop: 3,
  },
  careTaskLegend: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '12px',
  },
  careTaskLegendCategoryDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  careTaskLegendCategoryLabel: {
    color: '#212121',
    fontWeight: '400',
    fontSize: '14px',
    marginLeft: '6px',
  },
  careTaskSummaryCardPaper: {
    display: 'flex',
    borderRadius: 3,
    marginLeft: 16,
    marginRight: 16,
    marginBottom: 20,
    boxShadow: 'none',
  },
  careTaskInfoTyphograph: {
    fontSize: '12px',
  },
  careTaskInfoGrid2: {
    justifyContent: 'center',
  },
  careTaskToolTipCard: {
    height: 'auto',
    width: '275px',
    marginBottom: '10px',
    marginRight: '10px',
    padding: '12px',
    borderRadius: '8px',
  },
  refreshData: {
    fontSize: '12px',
    color: '#FFFFFF',
  },
  menuItemTypograph: {
    paddingLeft: '10px',
    textAlign: 'left',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0px',
    color: '#474747',
    opacity: 1,
  },
  menuItemTypographData: {
    paddingLeft: '10px',
    textAlign: 'left',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0px',
    color: '#474747',
    opacity: 1,
  },
  dataViewSelect: {
    paddingLeft: '16px',
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  paperBoxShadow: {
    boxShadow: '0px 0px 0px 1px rgb(0 0 0 / 10%), 0px 1px 1px 1px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%)',
  },
  avatarTitle: {
    fontSize: '24px',
  },
};

const monthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const careTaskChart = createMuiTheme({
  overrides: {
    'rv-xy-plot': {
      width: '100%',
    },
  },
});

const selectStyle = createMuiTheme({
  overrides: {
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: 'transparent !important',
        },
        '&:after': {
          borderBottom: 'transparent !important',
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: 'transparent !important',
        },
      },
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      borderColor: 'white',
      position: 'relative',
      backgroundColor: 'white',
      border: '1px solid #ced4da',
      fontSize: 16,
      display: 'block',
      width: '100%',
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
    MuiSelect: {
      selectMenu: {
        height: 'auto',
      },
      root: {
        paddingRight: '0px !important',
      },
    },
    MuiInputBase: {
      root: {
        display: 'block',
        height: '30px',
      },
    },
    MuiTypography: {
      root: {
        fontSize: '14px',
      },
    },
  },
});

export default class CollapsibleCardWithStatusChartCareTask extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hoveredNode: null,
      pageSize: 10,
      currentPage: 0,
      totalCount: 0,
      listDataTable: [],
      list: [],
      pageSizeProgram: 10,
      currentPageProgram: 0,
      totalCountProgram: 0,
      listDataProgram: [],
      listProgram: [],
    };

    this.programId = 0;

    this.changeCurrentPage = (currentPage) => {
      const { list, totalCount } = this.paginate(currentPage);
      this.setState({ list, totalCount, currentPage });
    };

    this.changeCurrentPageProgram = (currentPageProgram) => {
      const { listProgram, totalCountProgram } = this.paginateProgram(currentPageProgram);
      this.setState({ listProgram, totalCountProgram, currentPageProgram });
    };
  }

  componentDidMount() {
    this.changeCurrentPage(0);
    this.changeCurrentPageProgram(0);
  }

  UNSAFE_componentWillReceiveProps({ data }) {
    const { data: oldData } = this.props;

    // handle change data view
    if (data.dataView !== oldData.dataView) {
      const listDataTable = data.dataView.map((m) => {
        const {
          reportDate: { display },
          adherence,
        } = m;
        return {
          date: display,
          adherenceRate: adherence,
        };
      });
      this.setState({ listDataTable, totalCount: listDataTable.length }, () => this.changeCurrentPage(0));
    }

    // handle enrolledProgramData change
    if (data.enrolledProgramData !== oldData.enrolledProgramData) {
      const listDataProgram = data.selectedEnrolledProgramme.map((m) => {
        let rate = 0;
        let totalTasks = 0;
        const getEPAlerts = data.enrolledProgramData.find((f) => f.epId === m.id);
        if (getEPAlerts) {
          rate = `${getEPAlerts.adherence || 0}%`;
          totalTasks = `${getEPAlerts.totalTasks || 0}`;
        }
        return {
          name: m.title,
          rate,
          totalTasks,
          value: m.id,
          label: m.title,
        };
      });
      this.setState({ listDataProgram, totalCountProgram: listDataProgram.length }, () =>
        this.changeCurrentPageProgram(0),
      );
    }
  }

  paginate = (currentPage) => {
    const { pageSize } = this.state;
    const { data } = this.props;

    const listDataTable = data.dataView.map((m) => {
      const {
        reportDate: { display },
        adherence,
      } = m;
      return {
        date: display,
        adherenceRate: adherence,
      };
    });

    const list =
      listDataTable.length > pageSize
        ? listDataTable.slice(currentPage * pageSize, currentPage * pageSize + pageSize)
        : listDataTable;
    return { list, listDataTable, totalCount: listDataTable.length };
  };

  paginateProgram = (currentPageProgram) => {
    const { pageSizeProgram } = this.state;
    const { data } = this.props;

    const listDataProgram = data.selectedEnrolledProgramme.map((m) => {
      let rate = 0;
      let totalTasks = 0;
      const getEPAlerts = data.enrolledProgramData.find((f) => f.epId === m.id);
      if (getEPAlerts) {
        rate = `${getEPAlerts.adherence || 0}%`;
        totalTasks = `${getEPAlerts.totalTasks || 0}`;
      }
      return {
        name: m.title,
        rate,
        totalTasks,
        value: m.id,
        label: m.title,
      };
    });

    const listProgram =
      listDataProgram.length > pageSizeProgram
        ? listDataProgram.slice(
            currentPageProgram * pageSizeProgram,
            currentPageProgram * pageSizeProgram + pageSizeProgram,
          )
        : listDataProgram;

    return { listProgram, listDataProgram, totalCountProgram: listDataProgram.length };
  };

  renderLegend() {
    return (
      <Grid container direction="row" style={{ justifyContent: 'center' }}>
        <Grid item style={{ marginRight: '12px' }}>
          {this.renderLegendCategory('0%', setColourByRate(0))}
        </Grid>
        <Grid style={styles.careTaskLegend}>{this.renderLegendCategory('0.1% - 25.0%', setColourByRate(25))}</Grid>
        <Grid style={styles.careTaskLegend}>{this.renderLegendCategory('25.1% - 50.0%', setColourByRate(50))}</Grid>
        <Grid style={styles.careTaskLegend}>{this.renderLegendCategory('50.1% - 75.0%', setColourByRate(75))}</Grid>
        <Grid style={styles.careTaskLegendCategoryDiv}>
          {this.renderLegendCategory('75.1% - 100.0%', setColourByRate(100))}
        </Grid>
      </Grid>
    );
  }

  renderLegendCategory(labelText, colour) {
    return (
      <div style={styles.careTaskLegendCategoryDiv}>
        <svg height="17px" width="17px" viewBox="0 0 24 24">
          <path
            d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z"
            fill={colour}
            stroke="white"
            strokeWidth={2}
          />
        </svg>
        <Typography variant="body2" style={styles.careTaskLegendCategoryLabel}>
          {labelText}
        </Typography>
      </div>
    );
  }

  renderSummaryCard({
    completedOneTimeTasks,
    completedScheduledTasks,
    missedOneTimeTasks,
    missedScheduledTasks,
    overallAdherenceRate,
    totalTasks,
  }) {
    const dynamicColor = (thresholdData, number, markFill = false, verticalLine = false, statusIndicator) => {
      if (thresholdData !== null || number !== null) {
        const cHigh = !R.isNil(thresholdData.CH.y) && thresholdData.CH.y;
        const cLow = !R.isNil(thresholdData.CL.y) && thresholdData.CL.y;
        const high = thresholdData.H.y;
        const low = thresholdData.L.y;
        // Critical High & Critical Low
        if ((cHigh && number >= cHigh) || (cLow && number <= cLow)) {
          // eslint-disable-next-line no-nested-ternary
          return markFill
            ? verticalLine
              ? '#E17777'
              : '#D72B07'
            : statusIndicator
              ? '#D34431'
              : 'linear-gradient(#FF6A4A, #B82403)';
        }
        // High & Low
        if (
          (number >= high && number < cHigh) ||
          (number <= low && number > cLow) ||
          (number >= high && cHigh === false) ||
          (number <= low && cLow === false)
        ) {
          // eslint-disable-next-line no-nested-ternary
          return markFill
            ? verticalLine
              ? '#F9B03A'
              : '#FBAF39'
            : statusIndicator
              ? '#FE7F22'
              : 'linear-gradient(#FEB52C, #FF6400)';
        }
        // Normal
        if (number > low && number < high) {
          // eslint-disable-next-line no-nested-ternary
          return markFill
            ? verticalLine
              ? '#77E192'
              : '#1AC606'
            : statusIndicator
              ? '#34BF7B'
              : 'linear-gradient(#0FDA68, #32B66C)';
        }
      }
      // Missed
      // eslint-disable-next-line no-nested-ternary
      return markFill && verticalLine ? '#ABABAA' : statusIndicator ? '#465C66' : 'linear-gradient(#D0CFCF, #B3ACAB)';
    };

    const firstChartAverageData = null;
    return (
      <Paper elevation={1} style={R.merge(styles.careTaskSummaryCardPaper, { background: '#212121' })}>
        <Grid container direction="row" alignItems="center">
          <div direction="column" style={{ paddingLeft: '2%', width: '32%' }}>
            <Typography style={styles.cardLabel}>Total No. of Tasks</Typography>
            <Typography style={styles.cardDetail}>{totalTasks}</Typography>
            <div style={R.merge(styles.vitalDiv2, { color: 'white' })} />
            <Typography style={styles.cardLabel}>Overall Adherence Rate</Typography>
            <Typography style={R.merge(styles.cardDetail, { paddingBottom: 16 })}>
              {overallAdherenceRate && typeof overallAdherenceRate === 'number'
                ? `${overallAdherenceRate}%`
                : overallAdherenceRate}
            </Typography>
          </div>
          <div style={R.merge(styles.vitalDiv, { color: dynamicColor(null, firstChartAverageData, true, true) })} />
          <div direction="column" style={{ width: '32%' }}>
            <Typography style={R.merge(styles.cardLabel, { paddingLeft: 16 })}>Completed Scheduled Tasks</Typography>
            <Typography style={R.merge(styles.cardDetail, { paddingLeft: 16 })}>{completedScheduledTasks}</Typography>
            <div style={R.merge(styles.vitalDiv2, { color: 'white' })} />
            <Typography style={R.merge(styles.cardLabel, { paddingLeft: 16 })}>Missed Scheduled Tasks</Typography>
            <Typography style={R.merge(styles.cardDetail, { paddingBottom: 16, paddingLeft: 16 })}>
              {missedScheduledTasks}
            </Typography>
          </div>
          <div style={R.merge(styles.vitalDiv, { color: dynamicColor(null, firstChartAverageData, true, true) })} />
          <div direction="column" style={{ width: '30.5%' }}>
            <Typography style={R.merge(styles.cardLabel, { paddingLeft: 16 })}>Completed One-Time Tasks</Typography>
            <Typography style={R.merge(styles.cardDetail, { paddingLeft: 16 })}>{completedOneTimeTasks}</Typography>
            <div style={R.merge(styles.vitalDiv2, { color: 'white' })} />
            <Typography style={R.merge(styles.cardLabel, { paddingLeft: 16 })}>Missed One-Time Tasks</Typography>
            <Typography style={R.merge(styles.cardDetail, { paddingBottom: 16, paddingLeft: 16 })}>
              {missedOneTimeTasks}
            </Typography>
          </div>
        </Grid>
      </Paper>
    );
  }

  renderNoData() {
    return (
      <Grid
        style={{
          borderRadius: '0px 0px 5px 5px',
          paddingLeft: '24px',
          paddingTop: '40px',
          paddingBottom: '40px',
          height: '350px',
          paddingRight: '16px',
          flexDirection: 'column',
        }}>
        {' '}
        <Typography style={{ textAlign: 'center' }}>
          <img src={EmptyStateReportChart} style={{ width: '200px' }} />
        </Typography>
        <Typography style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>No data available</Typography>
        <Typography style={{ textAlign: 'center', color: '#757575', fontSize: '14px' }}>
          Select a different date range or filter(s)
        </Typography>
      </Grid>
    );
  }

  formatTickLabel(v, transformedData) {
    const index = v - 1;
    const item = transformedData[index];
    return item.display;
  }

  renderCareTaskInfo(y, data) {
    const { enrolledProgramAdherence = [] } = data;

    return (
      <Grid
        container
        direction="column"
        style={{
          marginTop: '16px',
        }}>
        {enrolledProgramAdherence.map((epAdherence) => {
          return (
            <Grid item style={{ paddingBottom: '10px' }} key={epAdherence.epTitle}>
              <Grid container direction="row" style={styles.careTaskInfoGrid2}>
                <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <svg height="17px" width="17px" viewBox="0 0 24 24">
                    <path
                      d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z"
                      fill={setColourByRate(epAdherence.adherence)}
                      stroke="white"
                      strokeWidth={2}
                    />
                  </svg>
                  <Typography
                    variant="body2"
                    style={{ ...styles.careTaskInfoTyphograph, marginLeft: 6, color: '#212121' }}>
                    {commonUtil.truncateWithEllipsis(epAdherence.epTitle, 30)}
                  </Typography>
                </div>
                <div style={{ flexGrow: 1, justifyContent: 'flex-end' }}>
                  <Typography
                    variant="body2"
                    style={{ ...styles.careTaskInfoTyphograph, textAlign: 'right', color: '#212121' }}>
                    {`${epAdherence.adherence}%`}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  }

  renderTooltip(transformedData) {
    const { hoveredNode } = this.state;
    const popUp = document.getElementById('pop-up');
    if (popUp) {
      popUp.scrollTo(0, 0);
    }

    const dateDisplay = (params) => {
      const { reportDate, nextReportDate } = params;
      const {
        aggregation: { unit, step },
      } = reportDate;

      return getRangeDisplay({ unit, reportDate, nextReportDate, step });
    };

    return (
      <Hint
        value={
          { x: hoveredNode.x, y: hoveredNode.y } // align={{ horizontal: 'left' }}
        }
        style={{
          height: '210px',
          width: '275px',
          borderRadius: '8px',
          paddingRight: 0,
          backgroundColor: 'white',
          boxShadow:
            '0px 2px 11px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        }}>
        <Grid
          container
          style={{ borderRadius: '8px', overflowY: 'scroll', height: '100%', width: '100%', padding: '12px' }}
          onMouseLeave={() => this.setState({ hoveredNode: null })}
          onMouseEnter={() => this.popupTimeout && clearTimeout(this.popupTimeout)}
          id="pop-up">
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ fontSize: '14px', fontWeight: '500', marginBottom: '6px', color: '#212121' }}>
              {dateDisplay({
                reportDate: transformedData[hoveredNode.x - 1].reportDate,
                nextReportDate: transformedData[hoveredNode.x - 1].nextReportDate,
              })}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ borderBottom: '1px solid rgb(245, 245, 245)' }} />
          <Grid item xs={12}>
            <Typography
              variant="body1"
              style={{ fontSize: '32px', fontWeight: '700', marginBottom: '6px', color: '#212121' }}>
              {transformedData[hoveredNode.x - 1].taskCount && `${transformedData[hoveredNode.x - 1].label}%`}
            </Typography>
          </Grid>
          {/** Progress bar to visualise adherence rate */}
          <Grid item xs={12}>
            {this.renderCareTaskInfo(hoveredNode.y, transformedData[hoveredNode.x - 1])}
          </Grid>
        </Grid>
      </Hint>
    );
  }

  noAvailableData() {
    return (
      <Grid style={{ padding: '16px 16px' }}>
        <Typography
          variant="h2"
          style={{
            paddingTop: '6px',
            paddingBottom: '6px',
            backgroundColor: '#FF95000D',
            border: '1px solid #FF950059',
            color: '#FF9500',
            fontSize: '12px',
            borderRadius: '5px',
            letterSpacing: '0.07px',
          }}>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <Grid item xs={1}>
              <InfoOutline
                style={{
                  paddingLeft: '8px',
                  marginTop: '3px',
                }}
              />
            </Grid>
            <Grid
              item
              xs={11}
              style={{
                display: 'inherit',
                fontSize: '14px',
                letterSpacing: '0.07px',
                fontWeight: 'normal',
                marginLeft: '-20px',
              }}>
              No Result for Care Tasks
            </Grid>
          </Grid>
        </Typography>
      </Grid>
    );
  }

  render() {
    const {
      title,
      data: {
        enrolledProgramData,
        chartData,
        summaryData,
        loading,
        selectedView,
        selectedData,
        selectedEnrolledProgramme = [],
      } = {},
      refresh,
      changeType,
      mode,
      pdf,
      reportType,
      selectedEnrolledProgramAll = [],
      isEmbedded = false,
    } = this.props;
    const { hoveredNode, list, listProgram } = this.state;
    const transformedData = [];
    let index = 0;
    for (const item of chartData) {
      const y = item.adherence || 0;
      const label = `${item.adherence}`;
      transformedData.push({
        x: index + 1,
        y,
        month: item.reportDate.month,
        year: item.reportDate.year,
        display: item.reportDate.display,
        color: setColourByRate(y),
        label,
        style: { fontSize: 14, textAnchor: 'middle', fontWeight: 'bold' },
        yOffset: -10,
        enrolledProgramAdherence: item.enrolledProgramAdherence,
        taskCount: item.taskCount,
        reportDate: item.reportDate,
        nextReportDate: item.nextReportDate,
      });
      index++;
    }

    this.yTicks = [0, 25, 50, 75, 100];
    this.yDomain = [0, 100];

    const programmeList = [];

    for (const item of selectedEnrolledProgramAll) {
      let rate = 0;
      let totalTasks = 0;
      const getEPAlerts = enrolledProgramData.find((f) => f.epId === item.id);
      if (getEPAlerts) {
        rate = `${getEPAlerts.adherence || 0}%`;
        totalTasks = `${getEPAlerts.totalTasks || 0}`;
      }
      programmeList.push({
        name: item.title,
        rate,
        totalTasks,
        value: item.id,
        label: item.title,
      });
    }

    let hasData = false;
    if (summaryData.totalTasks > 0) hasData = true;

    // Make domain 1 point larger than data set so last data point will not hit edge of graph
    const xDomain = [0, transformedData.length + 1];
    const xTicks = [];
    for (let i = 0; i < transformedData.length; i++) {
      xTicks.push(i + 1);
    }

    const cardHeaderProps = {
      avatar: (
        <MuiAvatar aria-label={title} style={{ ...styles.collapsibleCardGrid, position: 'static' }}>
          <Assignment />
        </MuiAvatar>
      ),
      title: (
        <Typography variant="h5" component="h3" style={styles.avatarTitle}>
          {title}
        </Typography>
      ),
      action:
        mode === 'view' ? null : (
          <Grid style={{ padding: 15 }}>
            <Button
              variant="contained"
              className="milo-btn-blue-2"
              style={{ fontSize: '12px' }}
              onClick={() => refresh()}>
              <Typography variant="body1" style={styles.refreshData}>
                Refresh data
              </Typography>
            </Button>
          </Grid>
        ),
    };

    const lineMarkSeriesData = [];
    let indexLineMarkSeriesData = 0;
    transformedData.forEach((item, index) => {
      if (item.y !== 'N.A.') {
        if (index > 0) {
          if (transformedData[index - 1].y === 'N.A.') {
            indexLineMarkSeriesData++;
          }
        }

        if (!lineMarkSeriesData[indexLineMarkSeriesData]) lineMarkSeriesData[indexLineMarkSeriesData] = [];
        lineMarkSeriesData[indexLineMarkSeriesData].push(item);
      }
    });

    return (
      <CollapsibleCard
        cardHeaderProps={!pdf ? cardHeaderProps : null}
        collapseProps={{ timeout: 'auto' }}
        noPaddingLeftRight={true}
        withDivider={false}
        // {...props}
      >
        <Card
          style={{
            background: '#212121',
            width: '100%',
            height: '56px',
            borderRadius: 0,
          }}>
          <CardContent>
            <Grid container>
              <Grid item xs={10}>
                <Typography
                  variant="body1"
                  style={{
                    color: '#fff',
                    fontSize: '18px',
                    marginTop: '2px',
                  }}>
                  Adherence Rate
                </Typography>
              </Grid>
              {!pdf && (
                <ThemeProvider theme={selectStyle}>
                  <Grid item xs={2} style={{ borderRadius: '4px', backgroundColor: 'white' }}>
                    <Select
                      disabled={mode === 'view' ? true : false}
                      value={selectedView}
                      placeholder="Line Chart"
                      onChange={(e) => changeType(e.target.value, selectedData)}>
                      {chartList.map((p) => (
                        <MenuItem value={p.value} key={p.value}>
                          <Typography style={styles.menuItemTypograph}>{p.label}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </ThemeProvider>
              )}
            </Grid>
          </CardContent>
        </Card>

        {selectedView === 'LINE_CHART' ? (
          <div style={{ width: '100%' }}>
            <Grid item xs={12} style={{ marginTop: 20 }}>
              {this.renderSummaryCard(summaryData)}
            </Grid>
            <Grid>
              <Typography
                style={{
                  fontSize: '12px',
                  paddingLeft: 18,
                  color: 'grey',
                }}>
                Adherence Rate (%)
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20, marginLeft: '10px', marginRight: '10px' }}>
              <Paper style={{ boxShadow: 'none' }}>
                {loading && (
                  <CircularProgress
                    style={{
                      width: '80px',
                      height: '80px',
                      position: 'absolute',
                      left: '42.5%',
                      zIndex: '9',
                      padding: '80px',
                      color: '#ff5000',
                    }}
                  />
                )}
                {hasData ? (
                  <ThemeProvider theme={careTaskChart}>
                    <FlexibleWidthXYPlot height={isEmbedded ? 232 : 350} yDomain={this.yDomain} xDomain={xDomain}>
                      <HorizontalGridLines tickValues={this.yTicks} />
                      <XAxis
                        tickValues={xTicks}
                        tickFormat={(v) => this.formatTickLabel(v, transformedData)}
                        style={{
                          text: {
                            fontSize: pdf ? 9 : 11,
                          },
                        }}
                      />
                      <YAxis tickValues={this.yTicks} tickFormat={(v) => `${v}`} />

                      {lineMarkSeriesData.map((item, idx) => (
                        <LineMarkSeries
                          key={idx}
                          animation={false}
                          colorType="literal"
                          data={item}
                          size={8}
                          strokeWidth={2}
                          stroke={'#B2DFDB'}
                          onValueMouseOver={(node) => this.setState({ hoveredNode: node })}
                          onValueMouseOut={() => {
                            this.popupTimeout = setTimeout(() => this.setState({ hoveredNode: null }), 100);
                          }}
                        />
                      ))}
                      {lineMarkSeriesData.map((item, idx) => (
                        <LabelSeries key={idx} animation allowOffsetToBeReversed data={item} />
                      ))}
                      {hoveredNode && this.renderTooltip(transformedData)}
                    </FlexibleWidthXYPlot>
                  </ThemeProvider>
                ) : (
                  !loading && this.renderNoData()
                )}
              </Paper>
            </Grid>
            <Grid item xs={12}>
              {this.renderLegend()}
            </Grid>
          </div>
        ) : (
          <div style={{ width: '100%' }}>
            {loading && (
              <CircularProgress
                style={{
                  width: '80px',
                  height: '80px',
                  position: 'absolute',
                  left: '37.5%',
                  zIndex: '9',
                  padding: '130px',
                  color: '#ff5000',
                }}
              />
            )}{' '}
            <MiloTable
              header={{
                components: [
                  {
                    id: 0,
                    component: () => (
                      <Grid style={styles.dataViewSelect}>
                        {programmeList.length > 0 && (
                          <Select
                            value={selectedData ? selectedData.id : null}
                            // placeholder='Enrolled Programme'
                            onChange={(e) => {
                              const curSelectedProgramme = selectedEnrolledProgramme.find(
                                (f) => f.id === e.target.value,
                              );
                              changeType(selectedView, curSelectedProgramme);
                            }}>
                            {programmeList.map((p) => (
                              <MenuItem value={p.value} key={p.value}>
                                <Typography style={styles.menuItemTypographData}>{p.label}</Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </Grid>
                    ),
                  },
                ],
              }}
              paperStyle={R.merge(styles.paperBoxShadow, { borderRadius: 5, margin: '24px', padding: 0 })}
              showHeaderDivider={true}
              content={{
                rows: list,
                columns: [
                  {
                    name: 'date',
                    title: 'Date',
                    getCellValue: (item) => {
                      return (
                        <div>
                          <span>{item.date}</span>
                        </div>
                      );
                    },
                  },
                  {
                    name: 'adherenceRate',
                    title: 'Adherence Rate',
                    getCellValue: (item) => {
                      return <span>{item.adherenceRate}</span>;
                    },
                  },
                ],
                columnExtensions: [{ columnName: 'date', width: 400 }, { columnName: 'adherenceRate', width: null }],
              }}
              options={{
                pagination: {
                  currentPage: this.state.currentPage,
                  pageSize: this.state.pageSize,
                  totalCount: this.state.totalCount,
                  handleCurrentPageChange: this.changeCurrentPage,
                },
              }}
              advanced={{
                tableProps: {
                  messages: {
                    noData: !loading ? this.renderNoData() : <Grid style={{ height: '200px' }} />,
                  },
                },
              }}
            />
          </div>
        )}

        {pdf ? null : (
          <Grid style={{ padding: 20 }}>
            {reportType === 'INDIVDUAL_PARTCIPANT' && (
              <Card style={R.merge(styles.paperBoxShadow, { paddingBottom: '16px' })}>
                <CardContent style={{ padding: 0 }}>
                  <Grid
                    container
                    direction="row"
                    style={{ paddingTop: 16, paddingBottom: 16, paddingLeft: 24, paddingRight: 24 }}>
                    <Grid item md={6}>
                      <Typography variant="caption">Programme</Typography>
                    </Grid>
                    <Grid item md={3}>
                      <Typography variant="caption">No. of Tasks</Typography>
                    </Grid>
                    <Grid item md={3}>
                      <Typography variant="caption">Adherence Rate</Typography>
                    </Grid>
                  </Grid>
                  {programmeList.length >= 0 &&
                    programmeList.map((item, index) => {
                      return (
                        <div key={index}>
                          <Grid
                            container
                            direction="row"
                            style={{ paddingTop: 16, paddingBottom: 16, paddingLeft: 24, paddingRight: 24 }}>
                            <Grid item md={6}>
                              <Typography variant="body1">{commonUtil.truncateWithEllipsis(item.name)}</Typography>
                            </Grid>
                            <Grid item md={3}>
                              <Typography variant="body1">{item.totalTasks}</Typography>
                            </Grid>
                            <Grid item md={3}>
                              <Typography variant="body1">{item.rate}</Typography>
                            </Grid>
                          </Grid>
                          {index < programmeList.length - 1 && <Divider style={{ marginBottom: 5 }} />}
                        </div>
                      );
                    })}
                  {programmeList.length === 0 && this.noAvailableData()}
                </CardContent>
              </Card>
            )}

            {reportType === 'OVERALL_PARTICIPANT' &&
              listProgram.length === 0 && (
                <Card style={R.merge(styles.paperBoxShadow, { paddingBottom: '16px', margin: '24px' })}>
                  <CardContent style={{ padding: 0 }}>
                    <Grid
                      container
                      direction="row"
                      style={{ paddingTop: 16, paddingBottom: 16, paddingLeft: 24, paddingRight: 24 }}>
                      <Grid item md={6}>
                        <Typography variant="caption">Programme</Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography variant="caption">No. of Tasks</Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography variant="caption">Adherence Rate</Typography>
                      </Grid>
                    </Grid>
                    <Grid style={{ padding: '16px 16px' }}>{this.noAvailableData()}</Grid>
                  </CardContent>
                </Card>
              )}

            {reportType === 'OVERALL_PARTICIPANT' &&
              listProgram.length > 0 && (
                <MiloTable
                  paperStyle={R.merge(styles.paperBoxShadow, { borderRadius: 5, margin: '24px', padding: 0 })}
                  content={{
                    rows: listProgram,
                    columns: [
                      {
                        name: 'name',
                        title: 'Programme',
                        getCellValue: (item) => {
                          return (
                            <div>
                              <span>{commonUtil.truncateWithEllipsis(item.name)}</span>
                            </div>
                          );
                        },
                      },
                      {
                        name: 'totalTasks',
                        title: 'No. of Tasks',
                        getCellValue: (item) => {
                          return <span>{item.totalTasks}</span>;
                        },
                      },
                      {
                        name: 'rate',
                        title: 'Adherence Rate',
                        getCellValue: (item) => {
                          return <span>{item.rate}</span>;
                        },
                      },
                    ],
                    columnExtensions: [
                      { columnName: 'name', width: 400 },
                      { columnName: 'totalTasks', width: null },
                      { columnName: 'rate', width: null },
                    ],
                  }}
                  options={{
                    pagination: {
                      currentPage: this.state.currentPageProgram,
                      pageSize: this.state.pageSizeProgram,
                      totalCount: this.state.totalCountProgram,
                      handleCurrentPageChange: this.changeCurrentPageProgram,
                    },
                  }}
                  advanced={{
                    tableProps: {
                      messages: {
                        noData: !loading ? this.renderNoData() : <Grid style={{ height: '200px' }} />,
                      },
                    },
                  }}
                />
              )}
          </Grid>
        )}
      </CollapsibleCard>
    );
  }
}
