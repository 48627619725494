/* eslint-disable react/react-in-jsx-scope */
import * as R from 'ramda';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import { Manager, Target, Popper } from 'react-popper';
import { withStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import AvatarImg from 'dataModule/assets/img/avatar.png';
import { ImageAvatar } from 'utilsModule/components';
import { getters as usersGetters } from 'dataModule/store/resources/users';
import { ONLINE, OFFLINE, actionCreators } from '../ducks';
import { actionCreators as messageActionCreators } from 'appModule/message/ducks/message';
import { withResource, resourceTypes } from 'dataModule/store/resources';
import Panel from '../components/Panel';

const styles = {
  userAvatar: {
    backgroundColor: '#fff',
    border: '1px solid #f5f5f0',
    marginLeft: 25,
    cursor: 'pointer',
  },
  userAvatarPanel: {
    backgroundColor: '#fff',
    border: '1px solid #f5f5f0',
    cursor: 'pointer',
  },
  bigAvatar: {
    width: 40,
    height: 40,
  },
  popper: {
    width: 350,
    // The popper's right edge aligns with the avatar icon, which is 30px from the right edge of the screen
    // To achieve a right margin of 10px, we shift the popper by 20px to the right.
    marginTop: '15px',
    marginRight: '-25px',
  },
  root: {
    display: 'flex',
  },
  popperClose: {
    pointerEvents: 'none',
  },
};

@withResource([
  {
    resourceType: resourceTypes.USERS,
    method: 'logoutAccount',
    options: { runOnDidMount: false,},
  },
  {
    resourceType: resourceTypes.USERS,
    method: 'logoutAccount',
    options: { runOnDidMount: false,},
  },
])
@connect(
  state => ({
    profilePhoto: usersGetters.getProfilePhoto(state),
    currentSession: R.path(['resources', 'users', 'retrieveCurrent'], state),
    open: R.path(['globalSetting', 'open'], state),
    chatStatus: R.path(['globalSetting', 'chatStatus'], state),
    updatedUser: R.path(['resources', 'users', 'updateProfile', 'provider'], state),
  }),
  { ...actionCreators,
    notify: messageActionCreators.show,},
)
@withStyles(styles, { index: 1 })
@withRouter
class SettingContainer extends Component {
  handleClose = () => {
    const { open, panelClose } = this.props;
    if (open) {
      panelClose();
    }
  };

  handleOpen = () => {
    const { open, panelOpen, panelClose } = this.props;
    if (open) {
      panelClose();
    } else {
      panelOpen();
    }
  };

  handleOnline = () => {
    const { changeChatsStatus } = this.props;
    changeChatsStatus({ status: ONLINE });
    this.handleClose();
  };

  handleOffline = () => {
    const { changeChatsStatus } = this.props;
    changeChatsStatus({ status: OFFLINE });
    this.handleClose();
  };

  handleEdit = () => {
    const {
      currentSession: {
        demographics: { id },
      },
      history,
    } = this.props;
    history.push(`/app/global/${id}/profile`);
    this.handleClose('edit');
  };

  onSignOut = async () => {
    const {notify, actionCreators } = this.props;
    try {
      // Revoke Session
      await new Promise((resolve, reject) => { 
        actionCreators[resourceTypes.USERS].ajax({
          cargo: { method:'revokeSession'}, 
          onSuccess: ({data: result}) => {
            sessionStorage.clear();
            window.location.href = 'https://www.healthbeats.co';
          },
          onFailure: ({error}) => {
            reject(error);
          }
        });
      }).catch((error) => {
        throw new TypeError(error.message); 
      });

    } catch (error) {
      notify({
        message: 'Failed to logout, please try again',
        type: 'error',
      });
      console.log(error);
    }
    
  };

  render() {
    const {
      updatedUser: { firstName = null, lastName = null, identity: { avatar: updatedProfilePhoto = null } = {} } = {},
      profilePhoto,
      currentSession,
      classes: { userAvatar, userAvatarPanel, bigAvatar, popperClose },
      open,
      classes,
      chatStatus,
    } = this.props;

    // This is to ensure the Panel's avatar and name is showing the updated values (if it exist)
    // Otherwise, user need to manually refresh the page to refetch the currentSession
    const finalCurrentSession = {
      organization: { name: currentSession.organization.name },
      profile: {
        name: {
          firstName: firstName || currentSession.profile.name.firstName,
          lastName: lastName || currentSession.profile.name.lastName,
        },
        type: currentSession.profile.type,
      },
    };

    // Find globally a className of 'globalNotificationClassName'
    // This is to prevent the overlapping between the notification and profile pop-ups
    const isGlobalNotificationSelected = document.querySelector('.globalNotificationClassName');
    if (isGlobalNotificationSelected !== null) {
      isGlobalNotificationSelected.addEventListener('click', () => {
        this.handleClose();
      });
    }

    let latestProfilePhoto = null;
    if (updatedProfilePhoto === "") {
      latestProfilePhoto = "";
    } else if (updatedProfilePhoto && updatedProfilePhoto !== "") {
      latestProfilePhoto = updatedProfilePhoto;
    } else {
      latestProfilePhoto = profilePhoto;
    }

    return (
      <div className={classes.root}>
        <Manager>
          <Target>
            <div className='globalProfileClassName'>
              <ImageAvatar
                className={classNames(userAvatar, bigAvatar)}
                src={latestProfilePhoto}
                defaultUrl={AvatarImg}
                onClick={e => {
                  this.handleOpen();
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                }}
              />
            </div>
          </Target>
          <Popper
            placement="bottom-end"
            eventsEnabled={open}
            style={styles.popper}
            className={classNames({ [popperClose]: !open })}
          >
            <ClickAwayListener onClickAway={this.handleClose}>
              <Grow in={open} id="menu-list">
                <Paper elevation={10}>
                  <Panel
                    edit={this.handleEdit}
                    currentUser={finalCurrentSession}
                    style={classNames(userAvatarPanel, bigAvatar)}
                    profilePhoto={latestProfilePhoto}
                    defaultUrl={AvatarImg}
                    online={this.handleOnline}
                    offline={this.handleOffline}
                    status={chatStatus}
                    onSignOut={this.onSignOut}
                  />
                </Paper>
              </Grow>
            </ClickAwayListener>
          </Popper>
        </Manager>
      </div>
    );
  }
}

export default SettingContainer;
