import { withStyles } from '@material-ui/styles';
import ReportPage from 'appModule/reporting/component/ReportPDF/ReportPage';
import React from 'react';
const footer = require('../../../../../public/hb_powered_by_int.png');

const styles = () => ({
  container: {
    backgroundColor: 'white',
    width: '21cm',
    height: '29.7cm',
    overflow: 'hidden',
  },
  metadataTitle: {
    width: '40%',
    fontSize: 12,
  },
  metadataValue: {
    width: '60%',
    fontSize: 12,
  },
  metadataSpace: {
    height: 5,
  },
  disclaimerContainer: {},
});

@withStyles(styles, { index: 1 })
export default class ReportingDisclaimerPage1 extends React.Component {
  render() {
    const { page = 1, totalPages = 10 } = this.props.location.query;

    return (
      <ReportPage page={page} totalPages={totalPages}>
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          {/* Title */}
          <div style={{ marginTop: '50px', textAlign: 'center' }}>
            <h2>Appendix 1</h2>
          </div>

          {/* Sub Title */}
          <div style={{ marginTop: '30px', textAlign: 'left' }}>
            <h4>Disclaimer Title for HealthBeats® Reporting</h4>

            <h5>3. Generation of Report</h5>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi porta eros elit, quis pulvinar risus
              placerat ac. Vivamus nec ante pharetra justo volutpat ullamcorper blandit vitae velit. Vestibulum viverra
              tincidunt nibh, vel scelerisque tellus volutpat sed. Aliquam eget lacus sit amet nisi vulputate gravida ac
              at purus. Aenean laoreet felis et leo suscipit, vitae auctor neque tincidunt. Pellentesque tincidunt,
              dolor quis consectetur sagittis, leo nisl maximus arcu, at euismod felis arcu id nunc. Vivamus eleifend,
              leo ac luctus accumsan, nulla massa sollicitudin erat, laoreet dignissim massa lorem sit amet nulla.
              Pellentesque hendrerit dolor dignissim sapien tempor posuere. Aliquam scelerisque consectetur turpis,
              aliquet commodo ipsum dapibus sed. Nulla facilisi. In hac habitasse platea dictumst. Curabitur
              sollicitudin facilisis enim, ut cursus nunc. Mauris sit amet feugiat arcu, eget accumsan nulla. Nullam
              egestas eleifend congue. Aliquam sit amet justo mollis, egestas quam et, placerat sem. Nunc sit amet augue
              in purus convallis accumsan non ac leo. Etiam vitae risus ut risus maximus ornare. Vivamus id purus
              dignissim, imperdiet ante ut, faucibus ipsum. Aenean aliquam tortor tortor, sit amet sodales orci molestie
              laoreet. Nunc malesuada vel nulla sit amet vulputate. Duis sem mauris, rhoncus et sollicitudin interdum,
              luctus at felis. Vivamus dignissim vitae metus at vehicula. Duis odio nisl, finibus vel consectetur a,
              vulputate sit amet augue.
            </p>
          </div>
        </div>
      </ReportPage>
    );
  }
}
