import React from 'react';
import { Typography } from '@material-ui/core';
import commonUtil from 'utilsModule/common';

class StylePreview extends React.Component {
  render() {
    commonUtil.renameDocumentTitle('Styles');
    return (
      // prettier-ignore
      <div style={{ margin: '16px' }}>
        <Typography variant='h1'>
          h1: Headline 1
        </Typography>
        <Typography paragraph variant='caption'>
          - fontSize: 96,
          fontWeight: 300,
          fontStyle: 'normal',
          fontStretch: 'normal',
          lineHeight: 1.17,
          letterSpacing: -0.14,
          color: '#212121',
        </Typography>
        <Typography variant='h2'>
          h2: Headline 2
        </Typography>
        <Typography paragraph variant='caption'>
          - fontSize: 60,
          fontWeight: 300,
          fontStyle: 'normal',
          fontStretch: 'normal',
          lineHeight: 1.18,
          letterSpacing: -0.3,
          color: '#212121'
        </Typography>
        <Typography variant='h3'>
          h3: Headline 3
        </Typography>
        <Typography paragraph variant='caption'>
          - fontSize: 48,
          fontWeight: 'normal',
          fontStyle: 'normal',
          fontStretch: 'normal',
          lineHeight: 1.19,
          letterSpacing: 0,
          color: '#212121'
        </Typography>
        <Typography variant='h4'>
          h4: Headline 4
        </Typography>
        <Typography paragraph variant='caption'>
          - fontSize: 34,
          fontWeight: 500,
          fontStyle: 'normal',
          fontStretch: 'normal',
          lineHeight: 1.18,
          letterSpacing: 0.02,
          color: '#212121'
        </Typography>
        <Typography variant='h5'>
          h5: Headline 5
        </Typography>
        <Typography paragraph variant='caption'>
          - fontSize: 24,
          fontWeight: 500,
          fontStyle: 'normal',
          fontStretch: 'normal',
          lineHeight: 1.17,
          letterSpacing: 0.01,
          color: '#212121'
        </Typography>
        <Typography variant='h6'>
          h6: Headline 6
        </Typography>
        <Typography paragraph variant='caption'>
          - fontSize: 20,
          fontWeight: 500,
          fontStyle: 'normal',
          fontStretch: 'normal',
          lineHeight: 1.19,
          letterSpacing: 0,
          color: '#212121'
        </Typography>
        <Typography variant='subtitle1'>
          subtitle1: Title 1 (Body 1 bolded)
        </Typography>
        <Typography paragraph variant='caption'>
          - fontSize: 16,
          fontWeight: 500,
          fontStyle: 'normal',
          fontStretch: 'normal',
          lineHeight: 1.19,
          letterSpacing: 0,
          color: '#212121'
        </Typography>
        <Typography variant='subtitle2'>
          subtitle2: Title 2 (Body 2 bolded)
        </Typography>
        <Typography paragraph variant='caption'>
          - fontSize: 14,
          fontWeight: 500,
          fontStyle: 'normal',
          fontStretch: 'normal',
          lineHeight: 1.14,
          letterSpacing: 0,
          color: '#212121'
        </Typography>
        <Typography variant='body1'>
          body1: Content Text 1 (DEFAULT)
        </Typography>
        <Typography paragraph variant='caption'>
          - fontSize: 16,
          fontWeight: 'normal',
          fontStyle: 'normal',
          fontStretch: 'normal',
          lineHeight: 1.19,
          letterSpacing: 0.01,
          color: '#212121'
        </Typography>
        <Typography variant='body2'>
          body2: Content Text 2
        </Typography>
        <Typography paragraph variant='caption'>
          - fontSize: 14,
          fontWeight: 'normal',
          fontStyle: 'normal',
          fontStretch: 'normal',
          lineHeight: 1.14,
          letterSpacing: 0,
          color: '#212121'
        </Typography>
        <Typography variant='button'>
          button: Button Text
        </Typography>
        <Typography paragraph variant='caption'>
          - fontSize: 14,
          fontWeight: 500,
          fontStyle: 'normal',
          fontStretch: 'normal',
          lineHeight: 1.14,
          letterSpacing: 0.02,
          color: '#212121',
          textTransform: 'none'
        </Typography>
        <Typography variant='caption'>
          caption: Caption Text
        </Typography>
        <Typography paragraph variant='caption'>
          - fontSize: 12,
          fontWeight: 'normal',
          fontStyle: 'normal',
          fontStretch: 'normal',
          lineHeight: 1.17,
          letterSpacing: 0,
          color: '#9e9e9e'
        </Typography>
        <Typography variant='overline'>
          overline: Overline Text
        </Typography>
        <Typography paragraph variant='caption'>
          - fontSize: 10,
          fontWeight: 'normal',
          fontStyle: 'normal',
          fontStretch: 'normal',
          lineHeight: 1,
          letterSpacing: 0.6,
          color: '#212121'
        </Typography>
        <Typography variant='wrong'>
          Default Variant (Invalid Variant)
        </Typography>
      </div >
    );
  }
}

export default StylePreview;
