import React from 'react';
import { Switch, Route } from 'react-router-dom';
import UnauthorizedGlobal from 'utilsModule/components/UnauthorizedGlobal';
import SessionTimeout from 'utilsModule/components/SessionTimeout';

export default ({ match }) => (
  <Switch>
    <Route path={`${match.path}/session-timeout`} component={SessionTimeout} />
    <Route component={UnauthorizedGlobal} />
  </Switch>
);
