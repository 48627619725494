import React from 'react';
import { withResource, resourceTypes, gettersOf } from 'dataModule/store/resources';
import { Loading } from 'utilsModule/components';
import CRForm from '../components/CRForm';

@withResource([
  {
    resourceType: resourceTypes.CHANGEREQUEST,
    method: 'retrieveChangeRequestFormByUuid',
    input: ({ location: { query: params } }) => ({
      params: {
        queries: [
          { name: 'type', value: 'CR' },
          { name: 'uuid', value: params.uuid },
          { name: 'session-token', value: params['session-token'] },
        ],
      },
    }),
    options: { runOnDidMount: true, forceFetch: true },
  },
])
class CRFormReadOnlyContainer extends React.Component {
  render() {
    const { data } = this.props;
    if (data.status.loading) return <Loading />;

    const { value: crFormData } = gettersOf(resourceTypes.CHANGEREQUEST).getChangeRequestFormByUuid()(data);
    return <CRForm {...this.props} crFormData={crFormData} isReadOnly={true} />;
  }
}

export default CRFormReadOnlyContainer;
