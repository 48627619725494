/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { formValueSelector, change, SubmissionError } from 'redux-form';
import * as R from 'ramda';
import { replace, size } from 'lodash';
import uuidv4 from 'uuid/v4';
import update from 'immutability-helper';
import { memoize } from 'utilsModule';
import { withResource, resourceTypes, gettersOf } from 'dataModule/store/resources';
import { actionCreators as messageActionCreators } from 'appModule/message/ducks/message';
import { Loading } from 'utilsModule/components';
import CovidBusinessCreate from '../components/CovidBusinessCreate';
import CovidUtil from '../utils/CovidUtil';

const billingAddressOptions = [
  { id: uuidv4(), value: 'same', label: 'Same as above' },
  { id: uuidv4(), value: 'new', label: 'New address' },
];


@withResource([
  { resourceType: resourceTypes.COUNTRIES, method: 'retrieveiHealthCountries', options: { runOnDidMount: true } },
  { resourceType: resourceTypes.COUNTRIES, method: 'retrieveMany', options: { runOnDidMount: true } },
  { resourceType: resourceTypes.CONFIGS, method: 'retrieveStates', options: { runOnDidMount: true } },
  { resourceType: resourceTypes.CONFIGS, method: 'retrieveCities', options: { runOnDidMount: true } },
  {
    resourceType: resourceTypes.CHARGEITEMCODES,
    method: 'retrieveCovidTier',
    input: {
      params: {
        queries: [
          { name: 'tier', value: 'TIER_3' },
        ],
      },
    },
    options: { runOnDidMount: true },
  },
  { resourceType: resourceTypes.CONFIGS, method: 'retrieveCitiesByState' },
  { resourceType: resourceTypes.ORGS, method: 'create' },
])
@connect(
  state => ({
    selectedCountry: formValueSelector('createCovidGroupOrg')(state, 'basicInfo.country'),
    selectedBillingCountry: formValueSelector('createCovidGroupOrg')(state, 'paymentDetails.billingCountry'),
    stateOptions: formValueSelector('createCovidGroupOrg')(state, 'basicInfo.state.options'),
    billingStateOptions: formValueSelector('createCovidGroupOrg')(state, 'paymentDetails.billingState.options'),
    compAddr1: formValueSelector('createCovidGroupOrg')(state, 'basicInfo.compAddr1'),
    compAddr2: formValueSelector('createCovidGroupOrg')(state, 'basicInfo.compAddr2'),
    postalCode: formValueSelector('createCovidGroupOrg')(state, 'basicInfo.postalCode'),
    country: formValueSelector('createCovidGroupOrg')(state, 'basicInfo.country'),
    state: formValueSelector('createCovidGroupOrg')(state, 'basicInfo.state'),
    city: formValueSelector('createCovidGroupOrg')(state, 'basicInfo.city'),
    secFirstName: formValueSelector('createCovidGroupOrg')(state, 'contactAndLogin.secFirstName'),
    secLastName: formValueSelector('createCovidGroupOrg')(state, 'contactAndLogin.secLastName'),
    secEmail: formValueSelector('createCovidGroupOrg')(state, 'contactAndLogin.secEmail'),
    secMobile: formValueSelector('createCovidGroupOrg')(state, 'contactAndLogin.secMobile'),
    secOffice: formValueSelector('createCovidGroupOrg')(state, 'contactAndLogin.secOffice'),
    cardNumber: formValueSelector('createCovidGroupOrg')(state, 'paymentDetails.cardNumber'),
    cardExpiry: formValueSelector('createCovidGroupOrg')(state, 'paymentDetails.cardExpiry'),
    billingAddress: formValueSelector('createCovidGroupOrg')(state, 'paymentDetails.billingAddress.value'),
    currentActiveField: R.path(['form', 'createCovidGroupOrg', 'active'], state),
  }),
  {
    notify: messageActionCreators.show,
    changeField: change,
  },
)
@withRouter
class CovidBusinessCreateContainer extends React.Component {
  state = {
    loading: false,
  }
  componentDidMount() {
    // Load Stripe library only in this component
    this.mounted = true;
    this.loadStripe();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  loadStripe = () => {
    if (!window.document.getElementById('stripe-script')) {
      const s = window.document.createElement('script');
      s.id = 'stripe-script';
      s.type = 'text/javascript';
      s.src = 'https://js.stripe.com/v2/';
      s.onload = () => {
        window.Stripe.setPublishableKey(window.MILO_CONFIG.STRIPE_PUBLISHABLE_KEY || '');
      };
      window.document.body.appendChild(s);
    }
  }

  shouldComponentUpdate(nextProps) {
    const { data } = nextProps;
    const donotUpdate = data.status.loading;
    return !donotUpdate;
  }

  updatePaymentDetailsBillingInfo = () => {
    const {
      changeField,
      currentActiveField,
      billingAddress,
      compAddr1,
      compAddr2,
      postalCode,
      country,
      state,
      city,
    } = this.props;

    if ((currentActiveField === undefined ||
        currentActiveField === 'paymentDetails.billingAddress' ||
        currentActiveField === 'basicInfo.compAddr1' ||
        currentActiveField === 'basicInfo.compAddr2' ||
        currentActiveField === 'basicInfo.postalCode' ||
        currentActiveField === 'basicInfo.country' ||
        currentActiveField === 'basicInfo.state' ||
        currentActiveField === 'basicInfo.city')
        && billingAddress === 'same') {
      changeField('createCovidGroupOrg', 'paymentDetails.billingAddr1', compAddr1);
      changeField('createCovidGroupOrg', 'paymentDetails.billingAddr2', compAddr2);
      changeField('createCovidGroupOrg', 'paymentDetails.billingPostalCode', postalCode);
      changeField('createCovidGroupOrg', 'paymentDetails.billingCountry', country);
      changeField('createCovidGroupOrg', 'paymentDetails.billingState', state);
      changeField('createCovidGroupOrg', 'paymentDetails.billingCity', city);
    } else if (currentActiveField === 'paymentDetails.billingAddress' && billingAddress === 'new') {
      changeField('createCovidGroupOrg', 'paymentDetails.billingAddr1', '');
      changeField('createCovidGroupOrg', 'paymentDetails.billingAddr2', '');
      changeField('createCovidGroupOrg', 'paymentDetails.billingPostalCode', '');
    }
  }

  handleCardNumberChange = (value) => {
    const { changeField } = this.props;
    const filterValue = replace(value, / /g, '');
    const cardNumberLength = size(filterValue);
    const spaceCount = Math.floor((cardNumberLength - 1) / 4);
    let modifyValue = filterValue.slice(0, cardNumberLength > 4 ? 4 : cardNumberLength);

    for (let i = 0; i < spaceCount; i += 1) {
      modifyValue = `${modifyValue} ${filterValue.slice(
        (i + 1) * 4,
        cardNumberLength < (i + 2) * 4 ? cardNumberLength : (i + 2) * 4,
      )}`;
    }
    setTimeout(() => changeField('createCovidGroupOrg', 'paymentDetails.cardNumber', modifyValue), 1);
  }

  handleCardExpiryChange = (value) => {
    const { cardExpiry = '', changeField } = this.props;
    const filterValue = replace(value, /\//g, '');
    const cardNumberLength = size(filterValue);
    let modifyValue = filterValue;

    if (cardNumberLength === 2 && size(cardExpiry) >= 3) {
      modifyValue = filterValue;
    } else if (cardNumberLength >= 2) {
      modifyValue = `${filterValue.slice(0, 2)}/${filterValue.slice(2, size(filterValue))}`;
    }
    setTimeout(() => changeField('createCovidGroupOrg', 'paymentDetails.cardExpiry', modifyValue), 1);
  }

  handleCreate = async (values) => {
    this.setState({ loading: true });
    const { actionCreators, notify, history } = this.props;

    // 1. Call Stripe CreateToken API to tokenize credit card
    const cloneValues = R.clone(values);
    const { paymentDetails: { cardNumber, cardExpiry, cardCVV } } = cloneValues;

    // const fakeCardExpiry = '11/25';
    // const stripeTestCard = {
    //   number: '4242424242424242',
    //   expMonth: R.split('/', fakeCardExpiry)[0],
    //   expYear: R.split('/', fakeCardExpiry)[1],
    //   cvc: '123',
    // };

    // For testing, please use any card provided by Stripe
    // https://stripe.com/docs/testing
    const creditCardPayload = {
      number: replace(cardNumber, / /g, ''),
      expMonth: R.split('/', cardExpiry)[0],
      expYear: R.split('/', cardExpiry)[1],
      cvc: cardCVV,
    };

    const stripeCallBack = (status, response) => {
      if (status === 200) {
        // 2. Prepare payload and call Organisation Create API
        const addCountryCode = mobileValue => ({
          countryCode: this.runtimeProps.body.shared.countryCode,
          value: mobileValue,
        });
        const { id: token } = response;
        const cargo = {
          method: 'createCovid',
          input: {
            content: R.pipe(
              R.assocPath(['basicInfo', 'orgType', 'value'], 'GROUP'),
              R.assocPath(['paymentDetails', 'creditCardToken'], token),
              v => update(v, {
                basicInfo: {
                  compPhone: addCountryCode,
                },
                contactAndLogin: {
                  priMobile: addCountryCode,
                  priOffice: addCountryCode,
                  secMobile: R.when(R.identity, addCountryCode),
                  secOffice: R.when(R.identity, addCountryCode),
                },
              }),
            )(values),
          },
        };

        // console.log('%crender Organisation Create Cargo', 'font-size: 12px; color: #00b3b3', cargo);

        return new Promise((resolve, reject) =>
          actionCreators[resourceTypes.ORGS].ajax({
            cargo,
            onSuccess: ({ data: result }) => {
              if (this.mounted) {
                resolve(result);
                const { createCovid: { value: { organization: { invoice: { invoiceFormUri = '', invoiceNo = '' } = {} } = {} } = {} } = {} } = result;
                const param = {
                  invoiceFormUri,
                  invoiceNo,
                  form: 'GROUP',
                };
                history.push('/covid-19-reg-success', param);
              }
            },
            onFailure: ({ error }) => {
              this.setState({ loading: false });
              if (this.mounted) {
                reject(new SubmissionError({ _error: JSON.stringify(error) }));
                notify({
                  message: process.env.DEBUG
                    ? `Create: ${JSON.stringify(error, ['code', 'message'])}`
                    : 'Failed to create organisation',
                  type: 'error',
                });
              }
            },
          }));
      }
      notify({ message: 'There has been an error verifying your credit card. Please try again or use another card.', type: 'error' });
      this.setState({ loading: false });
    };
    window.Stripe.card.createToken(creditCardPayload, stripeCallBack.bind(this));
  }

  getInitialCountryStateCity = (selectedCountry) => {
    const { actionCreators, notify, changeField } = this.props;

    actionCreators[resourceTypes.CONFIGS].ajax({
      cargo: {
        method: 'retrieveStates',
        input: {
          params: {
            queries: [
              { name: 'country', value: selectedCountry },
            ],
          },
        },
      },
      onSuccess: ({ cargo: { method }, data: result }) => {
        if (result[method].list.length === 0) {
          actionCreators[resourceTypes.CONFIGS].ajax({
            cargo: {
              method: 'retrieveCities',
              input: {
                params: {
                  queries: [
                    { name: 'country', value: selectedCountry },
                  ],
                },
              },
            },
            onSuccess: ({ cargo: { method: retrieveCities }, data: cities }) => {
              changeField('createCovidGroupOrg', 'basicInfo.city', {
                options: cities[retrieveCities].list,
                value: R.pathOr('', [retrieveCities, 'list', 0, 'value'], cities),
              });
            },
            onFailure: ({ error }) => {
              const reportedError = JSON.stringify(error, ['code', 'message']);
              notify({ message: `Couldn't get cities of ${selectedCountry}.\nReason: ${reportedError}`, type: 'error' });
              changeField('createCovidGroupOrg', 'basicInfo.city', { options: [] });
            },
          });
        } else {
          changeField('createCovidGroupOrg', 'basicInfo.state', {
            options: result[method].list,
            value: R.pathOr('', [method, 'list', 0, 'value'], result),
          });
          // Workaround as changeField does not trigger onChange method of city
          actionCreators[resourceTypes.CONFIGS].ajax({
            cargo: {
              method: 'retrieveCitiesByState',
              input: {
                params: {
                  queries: [
                    { name: 'state', value: R.pathOr('', [method, 'list', 0, 'value'], result) },
                  ],
                },
              },
            },
            onSuccess: ({ cargo: { method: retrieveCitiesByState }, data: cities }) => {
              changeField('createCovidGroupOrg', 'basicInfo.city', {
                options: cities[retrieveCitiesByState].list,
                value: R.pathOr('', [retrieveCitiesByState, 'list', 0, 'value'], cities),
              });
            },
            onFailure: ({ error }) => {
              const reportedError = JSON.stringify(error, ['code', 'message']);
              notify({ message: `Couldn't get cities of ${R.pathOr('', [method, 'list', 0, 'value'], result)}.\nReason: ${reportedError}`, type: 'error' });
              changeField('createCovidGroupOrg', 'basicInfo.city', { options: [] });
            },
          });
        }
      },
      onFailure: ({ error: { code, message } }) => {
        notify({ message: `Couldn't get cities of ${selectedCountry}.\nReason: ${code} - ${message}`, type: 'error' });
        changeField('createCovidGroupOrg', 'basicInfo.city', { options: [] });
      },
    });
  }

  getInitialValues = memoize((countryList) => {
    const { data } = this.props;
    const state = gettersOf(resourceTypes.CONFIGS).getStates()(data);
    const city = gettersOf(resourceTypes.CONFIGS).getCities()(data);
    const cityOptions = R.map(R.pick(['id', 'value', 'label']), city);

    const getPricePlan = gettersOf(resourceTypes.CHARGEITEMCODES).getCovidTier()(data);
    const pricingPlan = R.map(
      item =>
        ({
          id: item.id,
          value: item.id,
          label: `${item.minSize} ${item.maxSize ? `- ${item.maxSize}` : 'and Above'}`,
          priceMonthly: `USD ${item.fixedMonthlyPrice} /month`,
        })
      , R.pathOr([], ['value', 'covidChargeCodes'], getPricePlan),
    );

    const { location: { query: { country = '' } = {} } = {} } = this.props;
    this.getInitialCountryStateCity(country);

    return {
      basicInfo: {
        country: {
          options: R.map(R.pick(['id', 'value', 'label']), countryList),
          value: country,
        },
        state: {
          options: R.map(R.pick(['id', 'value', 'label']), state),
        },
        city: {
          options: cityOptions,
        },
      },
      contactAndLogin: {
        priFirstName: '',
        priLastName: '',
        priEmail: '',
        priMobile: '',
        priOffice: '',
        secFirstName: '',
        secLastName: '',
        secEmail: '',
        secMobile: '',
        secOffice: '',
      },
      paymentDetails: {
        paymentAmount: pricingPlan.length > 0 ? pricingPlan[0].priceMonthly : '',
        noOfUsersMonitored: {
          options: pricingPlan,
          value: pricingPlan.length > 0 ? pricingPlan[0].value : '',
        },
        billingAddress: {
          options: billingAddressOptions,
          value: 'same',
        },
        billingCountry: {
          options: R.map(R.pick(['id', 'value', 'label']), countryList),
        },
        billingState: {
          options: R.map(R.pick(['id', 'value', 'label']), state),
        },
        billingCity: {
          options: cityOptions,
        },
      },
      acceptance: false,
    };
  });

  render() {
    const {
      data, actionCreators,
      notify, changeField,
      selectedCountry, selectedBillingCountry, stateOptions,
      secFirstName, secLastName, secEmail, secMobile, secOffice,
      billingAddress, billingStateOptions, cardNumber,
    } = this.props;

    // console.log('%crender CovidGroupCreateContainer', 'font-size: 12px; color: #00b3b3', this.props);

    // First render
    if (data.status.loading) return <Loading />;

    // Sanitize data
    const iHealthCountryList = gettersOf(resourceTypes.COUNTRIES).getStatus('retrieveiHealthCountries')(data, false).success
      ? gettersOf(resourceTypes.COUNTRIES).getiHealthCountries(data, false)
      : [];
    const commonCountryList = gettersOf(resourceTypes.COUNTRIES).getStatus('retrieveMany')(data, false).success
      ? gettersOf(resourceTypes.COUNTRIES).getCountries(data, false)
      : [];
    const countryList = R.map(({ id, name }) => ({
      id, value: name, label: name, countryCode: R.pipe(R.find(R.propEq('value', name)), R.prop('countryCode'))(commonCountryList),
    }), iHealthCountryList);
    const countryCode = R.prop('countryCode', R.find(R.propEq('value', R.prop('value', selectedCountry)), commonCountryList));

    this.initialValues = this.getInitialValues(countryList);
    const creditCardType = CovidUtil.getCreditCardType(replace(cardNumber, / /g, ''));
    const getPricePlan = gettersOf(resourceTypes.CHARGEITEMCODES).getCovidTier()(data);
    const pricingPlan = R.map(
      item =>
        ({
          id: item.id,
          value: item.id,
          label: `${item.minSize} ${item.maxSize ? `- ${item.maxSize}` : 'and Above'}`,
          priceMonthly: `USD ${item.fixedMonthlyPrice} /month`,
        })
      , R.pathOr([], ['value', 'covidChargeCodes'], getPricePlan),
    );

    if (!this.inited) {
      this.runtimeProps = {
        body: {
          shared: {
            countryCode,
            billingAddress,
          },
          basicInfo: {
            state: {
              onChange: (event, { value: stateValue }) =>
                actionCreators[resourceTypes.CONFIGS].ajax({
                  cargo: {
                    method: 'retrieveCitiesByState',
                    input: {
                      params: {
                        queries: [
                          { name: 'state', value: stateValue },
                        ],
                      },
                    },
                  },
                  onSuccess: ({ cargo: { method }, data: result }) => {
                    changeField('createCovidGroupOrg', 'basicInfo.city', {
                      options: result[method].list,
                      value: R.pathOr('', [method, 'list', 0, 'value'], result),
                    });
                  },
                  onFailure: ({ error }) => {
                    const reportedError = JSON.stringify(error, ['code', 'message']);
                    notify({ message: `Couldn't get cities of ${stateValue}.\nReason: ${reportedError}`, type: 'error' });
                    changeField('createCovidGroupOrg', 'basicInfo.city', { options: [] });
                  },
                }),
            },
          },
          contactAndLogin: {
            secFirstName: {
              required: false,
            },
            secLastName: {
              required: false,
            },
            secEmail: {
              required: false,
            },
            secMobile: {
              required: false,
            },
            secOffice: {
              required: false,
            },
          },
          paymentDetails: {
            noOfUsersMonitored: {
              onChange: (event, { value: selectedPricingPlan }) => {
                const paymentAmount = pricingPlan.filter(pp => pp.value === selectedPricingPlan);
                changeField('createCovidGroupOrg', 'paymentDetails.paymentAmount', paymentAmount[0].priceMonthly);
              },
            },
            cardExpiry: {
              onChange: (event, value) => this.handleCardExpiryChange(value),
            },
            cardNumber: {
              onChange: (event, value) => this.handleCardNumberChange(value),
              creditCardType,
            },
            billingCountry: {
              onChange: (event, { value: countryValue }) => {
                changeField('createCovidGroupOrg', 'paymentDetails.billingCity', {
                  options: [],
                  value: '',
                });
                changeField('createCovidGroupOrg', 'paymentDetails.billingState', {
                  options: [],
                  value: '',
                });

                actionCreators[resourceTypes.CONFIGS].ajax({
                  cargo: {
                    method: 'retrieveStates',
                    input: {
                      params: {
                        queries: [
                          { name: 'country', value: countryValue },
                        ],
                      },
                    },
                  },
                  onSuccess: ({ cargo: { method }, data: result }) => {
                    if (result[method].list.length === 0) {
                      actionCreators[resourceTypes.CONFIGS].ajax({
                        cargo: {
                          method: 'retrieveCities',
                          input: {
                            params: {
                              queries: [
                                { name: 'country', value: countryValue },
                              ],
                            },
                          },
                        },
                        onSuccess: ({ cargo: { method: retrieveCities }, data: cities }) => {
                          changeField('createCovidGroupOrg', 'paymentDetails.billingCity', {
                            options: cities[retrieveCities].list,
                            value: R.pathOr('', [retrieveCities, 'list', 0, 'value'], cities),
                          });
                        },
                        onFailure: ({ error }) => {
                          const reportedError = JSON.stringify(error, ['code', 'message']);
                          notify({ message: `Couldn't get cities of ${countryValue}.\nReason: ${reportedError}`, type: 'error' });
                          changeField('createCovidGroupOrg', 'paymentDetails.billingCity', { options: [] });
                        },
                      });
                    } else {
                      changeField('createCovidGroupOrg', 'paymentDetails.billingState', {
                        options: result[method].list,
                        value: R.pathOr('', [method, 'list', 0, 'value'], result),
                      });

                      // Workaround as changeField does not trigger onChange method of city
                      actionCreators[resourceTypes.CONFIGS].ajax({
                        cargo: {
                          method: 'retrieveCitiesByState',
                          input: {
                            params: {
                              queries: [
                                { name: 'state', value: R.pathOr('', [method, 'list', 0, 'value'], result) },
                              ],
                            },
                          },
                        },
                        onSuccess: ({ cargo: { method: retrieveCitiesByState }, data: cities }) => {
                          changeField('createCovidGroupOrg', 'paymentDetails.billingCity', {
                            options: cities[retrieveCitiesByState].list,
                            value: R.pathOr('', [retrieveCitiesByState, 'list', 0, 'value'], cities),
                          });
                        },
                        onFailure: ({ error }) => {
                          const reportedError = JSON.stringify(error, ['code', 'message']);
                          notify({ message: `Couldn't get cities of ${R.pathOr('', [method, 'list', 0, 'value'], result)}.\nReason: ${reportedError}`, type: 'error' });
                          changeField('createCovidGroupOrg', 'paymentDetails.billingCity', { options: [] });
                        },
                      });
                    }
                  },
                  onFailure: ({ error: { code, message } }) => {
                    notify({ message: `Couldn't get cities of ${countryValue}.\nReason: ${code} - ${message}`, type: 'error' });
                    changeField('createCovidGroupOrg', 'paymentDetails.billingCity', { options: [] });
                  },
                });
              },
            },
            billingState: {
              onChange: (event, { value: stateValue }) =>
                actionCreators[resourceTypes.CONFIGS].ajax({
                  cargo: {
                    method: 'retrieveCitiesByState',
                    input: {
                      params: {
                        queries: [
                          { name: 'state', value: stateValue },
                        ],
                      },
                    },
                  },
                  onSuccess: ({ cargo: { method }, data: result }) => {
                    changeField('createCovidGroupOrg', 'paymentDetails.billingCity', {
                      options: result[method].list,
                      value: R.pathOr('', [method, 'list', 0, 'value'], result),
                    });
                  },
                  onFailure: ({ error }) => {
                    const reportedError = JSON.stringify(error, ['code', 'message']);
                    notify({ message: `Couldn't get cities of ${stateValue}.\nReason: ${reportedError}`, type: 'error' });
                    changeField('createCovidGroupOrg', 'paymentDetails.billingCity', { options: [] });
                  },
                }),
            },
          },
        },
      };

      this.inited = true;
    }

    this.runtimeProps = update(this.runtimeProps, {
      body: {
        shared: {
          countryCode: { $set: countryCode },
          billingAddress: { $set: billingAddress },
        },
        basicInfo: {
          state: {
            $merge: {
              disableState: selectedCountry && selectedCountry.value === '',
              notApplicable: selectedCountry && selectedCountry.value !== '' && stateOptions && stateOptions.length <= 0,
            },
          },
        },
        contactAndLogin: {
          secFirstName: { $set: { required: secFirstName !== '' || secLastName !== '' || secEmail !== '' || secMobile !== '' || secOffice !== '' } },
          secLastName: { $set: { required: secFirstName !== '' || secLastName !== '' || secEmail !== '' || secMobile !== '' || secOffice !== '' } },
          secEmail: { $set: { required: secFirstName !== '' || secLastName !== '' || secEmail !== '' || secMobile !== '' || secOffice !== '' } },
          secMobile: { $set: { required: secFirstName !== '' || secLastName !== '' || secEmail !== '' || secMobile !== '' || secOffice !== '' } },
          secOffice: { $set: { required: secFirstName !== '' || secLastName !== '' || secEmail !== '' || secMobile !== '' || secOffice !== '' } },
        },
        paymentDetails: {
          cardNumber: { $merge: { creditCardType } },
          billingState: {
            $merge: {
              disableState: selectedBillingCountry && selectedBillingCountry.value === '',
              notApplicable: selectedBillingCountry && selectedBillingCountry.value !== '' && billingStateOptions && billingStateOptions.length <= 0,
            },
          },
        },
      },
    });

    this.updatePaymentDetailsBillingInfo();

    return (
      <CovidBusinessCreate
        handleEdit={this.handleEdit}
        initialValues={this.initialValues}
        runtimeProps={this.runtimeProps}
        handleCreate={this.handleCreate}
        handleCancel={this.handleCancel}
        pricingPlan={pricingPlan}
        loading={this.state.loading}
      />
    );
  }
}

export default CovidBusinessCreateContainer;
