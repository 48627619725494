/* Constants */

/* Action Creators */

/* Epics */
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/concat';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/catch';

import DM from 'dataModule';
import { findIndex, propEq, adjust, __, merge, find } from 'ramda';

const MAX_PAGE_SIZE = 9e6;
/* Reducer */
/*
**************************************************
  Action Types
**************************************************
*/

const actionTypes = {
  FETCH_LIST: Symbol('FETCH_LIST'),
  FETCH_VITAL_LIST: Symbol('FETCH_VITAL_LIST'),
  SET_LIST: Symbol('SET_LIST'),
  SET_DATE_RANGE: Symbol('SET_DATE_RANGE'),
  SET_VIEW: Symbol('SET_VIEW'),
  SET_PAGE: Symbol('SET_PAGE'),
  SET_VITAL_REPORT_PAGE: Symbol('SET_VITAL_REPORT_PAGE'),
  FETCH_LIST_SUCCESS: Symbol('FETCH_LIST_SUCCESS'),
  FETCH_VITAL_LIST_SUCCESS: Symbol('FETCH_VITAL_LIST_SUCCESS'),
  FETCH_LIST_FAILURE: Symbol('FETCH_LIST_FAILURE'),
  SET_VITAl_LIST: Symbol('SET_VITAL_LIST'),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  fetchList: payload => ({ type: actionTypes.FETCH_LIST, payload }),
  fetchVitalList: payload => ({ type: actionTypes.FETCH_VITAL_LIST, payload }),
  fetchListSuccess: ({ result, vitalNameAndGroupId }) => ({
    type: actionTypes.FETCH_LIST_SUCCESS,
    payload: { result, vitalNameAndGroupId },
  }),
  fetchVitalListSuccess: ({ result, name, group }) => ({
    type: actionTypes.FETCH_VITAL_LIST_SUCCESS,
    payload: { result, name, group },
  }),
  fetchListFailure: payload => ({ type: actionTypes.FETCH_LIST_FAILURE, payload }),
  setList: ({ list }) => ({ type: actionTypes.SET_LIST, payload: list }),
  setVitalList: ({ list }) => ({ type: actionTypes.SET_VITAl_LIST, payload: list }),
  setDateRange: ({ vitalNameAndGroupId, range }) => ({
    type: actionTypes.SET_DATE_RANGE,
    payload: { vitalNameAndGroupId, range },
  }),
  setView: ({ vitalNameAndGroupId, view }) => ({ type: actionTypes.SET_VIEW, payload: { vitalNameAndGroupId, view } }),
  setPage: ({ vitalNameAndGroupId, page }) => ({ type: actionTypes.SET_PAGE, payload: { vitalNameAndGroupId, page } }),
  setVitalReportPage: ({ vGroup, page }) => ({ type: actionTypes.SET_VITAL_REPORT_PAGE, payload: { vGroup, page } }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/

const epics = [
  action$ =>
    action$
      .ofType(actionTypes.FETCH_LIST)
      .mergeMap(({ payload }) =>
        Observable.fromPromise(DM.measurement.fetchList(payload)).map(result =>
          actionCreators.fetchListSuccess({ result, vitalNameAndGroupId: payload.vitalNameAndGroupId }))),
  action$ =>
    action$
      .ofType(actionTypes.FETCH_VITAL_LIST)
      .mergeMap(({ payload }) =>
        Observable.fromPromise(DM.measurement.fetchList(payload)).map(result =>
          actionCreators.fetchVitalListSuccess({ result, name: payload.name, group: payload.vGroup }))),
  action$ =>
    action$.ofType(actionTypes.SET_LIST).mergeMap(({ payload }) =>
      Observable.concat(payload.map((l) => {
        const {
          name, dateRange, pageSize, page, progId, vitalGroupId, vitalNameAndGroupId
        } = l;
        const query = {
          pageSize,
          page: page + 1,
          vital: name,
          startDate: dateRange[0],
          endDate: dateRange[1],
          enrolledProgramId: progId,
          status: ['registered', 'preliminary', 'final'],
          vitalGroupId,
          vitalNameAndGroupId
        };
        return actionCreators.fetchList(query);
      }))),
  action$ =>
    action$.ofType(actionTypes.SET_VITAl_LIST).mergeMap(({ payload }) =>
      Observable.concat(payload.map((l) => {
        const {
          name, pageSize, dateRange, page, progId, vitalGroupId, vGroup
        } = l;
        const query = {
          pageSize: pageSize,
          page: page + 1,
          vital: name,
          vGroup,
          startDate: dateRange[0],
          endDate: dateRange[1],
          enrolledProgramId: progId,
          dataNotAbsent: true,
          status: ['final'],
          vitalGroupId
        };
        return actionCreators.fetchVitalList(query);
      }))),
  (action$, store) =>
    action$.ofType(actionTypes.SET_DATE_RANGE).mergeMap(({ payload }) => {
      const {
        measurements: { list },
      } = store.getState();
      const { vitalNameAndGroupId, range } = payload;
      const prog = find(propEq('vitalNameAndGroupId', vitalNameAndGroupId), list);
      const query = {
        page: 1,
        vital: prog.name,
        pageSize: prog.pageSize,
        startDate: range[0],
        endDate: range[1],
        enrolledProgramId: prog.progId,
        vitalGroupId: prog.vitalGroupId
      };
      return Observable.fromPromise(DM.measurement.fetchList(query))
        .map(result => actionCreators.fetchListSuccess({ result, vitalNameAndGroupId }))
        .catch(error => Observable.of(actionCreators.fetchListFailure({ error })));
    }),
  (action$, store) =>
    action$.ofType(actionTypes.SET_PAGE).mergeMap(({ payload }) => {
      const {
        measurements: { list },
      } = store.getState();
      const { page, vitalNameAndGroupId } = payload;
      const prog = find(propEq('vitalNameAndGroupId', vitalNameAndGroupId), list);
      const query = {
        vital: prog.name,
        page: page + 1,
        pageSize: prog.pageSize,
        startDate: prog.dateRange[0],
        endDate: prog.dateRange[1],
        enrolledProgramId: prog.progId,
        vitalGroupId: prog.vitalGroupId
      };
      return Observable.fromPromise(DM.measurement.fetchList(query))
        .map(result => actionCreators.fetchListSuccess({ result, vitalNameAndGroupId }))
        .catch(error => Observable.of(actionCreators.fetchListFailure({ error })));
    }),
  (action$, store) =>
    action$.ofType(actionTypes.SET_VITAL_REPORT_PAGE).mergeMap(({ payload }) => {
      const {
        measurements: { list },
      } = store.getState();
      const { page, vGroup } = payload;
      const prog = find(propEq('vGroup', vGroup), list);
      const query = {
        vital: prog.name,
        page: page + 1,
        pageSize: prog.pageSize,
        startDate: prog.dateRange[0],
        endDate: prog.dateRange[1],
        dataNotAbsent: true,
        enrolledProgramId: prog.progId,
        vitalGroupId: prog.vitalGroupId,
        status: ['final'],
      };
      return Observable.fromPromise(DM.measurement.fetchList(query))
        .map(result => actionCreators.fetchVitalListSuccess({ result, name, group: prog.vGroup }))
        .catch(error => Observable.of(actionCreators.fetchListFailure({ error })));
    }),
  (action$, store) =>
    action$.ofType(actionTypes.SET_VIEW).mergeMap(({ payload }) => {
      const { vitalNameAndGroupId, view } = payload;
      const progs = store.getState().measurements.list;
      const { dateRange, progId, name, vitalGroupId } = find(propEq('vitalNameAndGroupId', vitalNameAndGroupId), progs);
      if (view === 'list') {
        const query = {
          pageSize: 5,
          page: 1,
          vital: name,
          startDate: dateRange[0],
          endDate: dateRange[1],
          enrolledProgramId: progId,
          vitalGroupId,
          vitalNameAndGroupId
        };
        return [actionCreators.fetchList(query)];
      } else if (view === 'chart') {
        const query = {
          vital: name,
          page: 1,
          pageSize: MAX_PAGE_SIZE,
          startDate: dateRange[0],
          endDate: dateRange[1],
          enrolledProgramId: progId,
          vitalGroupId,
          vitalNameAndGroupId
        };
        return [actionCreators.fetchList(query)];
      }
      return [];
    }),
];

export { epics };
/*
**************************************************
  Reducer
**************************************************
*/
const initState = [];

export default (state = initState, action) => {
  switch (action.type) {
    // case actionTypes.FETCH_LIST: {
    //   const { info } = action.payload;
    //   return { ...state, info };
    // }
    case actionTypes.SET_LIST: {
      return action.payload;
    }
    case actionTypes.SET_VITAl_LIST: {
      return action.payload;
    }
    case actionTypes.SET_DATE_RANGE: {
      const { vitalNameAndGroupId, range } = action.payload;
      const idx = findIndex(propEq('vitalNameAndGroupId', vitalNameAndGroupId), state);
      return adjust(merge(__, { dateRange: range, page: 0, loading: true }), idx, state);
    }
    case actionTypes.SET_VIEW: {
      const { vitalNameAndGroupId, view } = action.payload;
      const idx = findIndex(propEq('vitalNameAndGroupId', vitalNameAndGroupId), state);
      return adjust(merge(__, { type: view, page: 0, loading: true }), idx, state);
    }
    case actionTypes.SET_PAGE: {
      const { vitalNameAndGroupId, page } = action.payload;
      const idx = findIndex(propEq('vitalNameAndGroupId', vitalNameAndGroupId), state);
      return adjust(merge(__, { page, loading: true }), idx, state);
    }
    case actionTypes.SET_VITAL_REPORT_PAGE: {
      const { vGroup, page } = action.payload;
      const idx = findIndex(propEq('vGroup', vGroup), state);
      return adjust(merge(__, { page, loading: true }), idx, state);
    }
    case actionTypes.FETCH_LIST_SUCCESS: {
      const { result, vitalNameAndGroupId } = action.payload;
      const idx = findIndex(propEq('vitalNameAndGroupId', vitalNameAndGroupId), state);
      const page = result.page - 1;
      return adjust(merge(__, { loading: false, ...result, page }), idx, state);
    }
    case actionTypes.FETCH_VITAL_LIST_SUCCESS: {
      const { result, group } = action.payload;
      const idx = findIndex(propEq('vGroup', group), state);
      const page = result.page - 1;
      return adjust(merge(__, { loading: false, ...result, page }), idx, state);
    }
    case actionTypes.FETCH_LIST_FAILURE: {
      const { error } = action.payload;
      return { ...state, result: { ok: false, error } };
    }
    default: {
      return state;
    }
  }
};
