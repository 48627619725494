/* eslint-disable */
/* Constants */

/* Action Creators */

/* Reducer */

/* Libs */

/*
**************************************************
  Action Types
**************************************************
*/
const prefix = "program/create/vitals/deviceList";

const actionTypes = {
  INIT: Symbol(`${prefix}/INIT`),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  init: payload => ({ type: actionTypes.INIT, payload }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/
const epics = [];

export { epics };

/*
**************************************************
  Reducer
**************************************************
*/
const initState = [
  {
    name: 'BP',
    label: 'Blood Presure',
    defaultModel: 'BP5',
    models: [
      { name: 'BP5', label: 'BP5'},
      { name: 'BP7', label: 'BP7'},  
    ],
  },
  {
    name: 'BG',
    label: 'Blood Glucose',
    defaultModel: 'BG5',
    models: [
      { name: 'BG5', label: 'BG5'},
      { name: 'BG3L', label: 'BG3L'},  
    ],
  },
  {
    name: 'PO',
    label: 'SPO2',
    defaultModel: 'PO3',
    models: [
      { name: 'PO3', label: 'PO3'},
    ],
  },
  {
    name: 'HS',
    label: 'Weight',
    defaultModel: 'HS5',
    models: [
      { name: 'HS5', label: 'HS5'},
    ],
  },
  // TODO: HR doesn't have a dedicated device, bundle it with BP & SPO2
  {
    name: 'HR',
    label: 'Heart Rate',
    defaultModel: 'HR5',
    models: [
      { name: 'HR5', label: 'HR5'},
    ],
  },
];

export default (state = initState, action) => {
  switch (action.type) {
    default: {
      return state;
    }
  }
};
