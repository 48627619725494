import React from 'react';
import { withStyles } from '@material-ui/styles';

const styles = {
  td: {
    border: '1px solid black',
    borderCollapse: 'collapse',
    textAlign: 'left',
    fontSize: 12,
    padding: 8,
  },
};

@withStyles(styles, { index: 1 })
class ReportDataViewTableCell extends React.Component {

  render() {
    const { classes, tableData, reportType } = this.props;
    const dataLength = tableData.length;

    let padding = 8;
    if (dataLength === 24) {
      if (reportType === 'INDIVIDUAL') padding = 3;
      padding = 5;
    } else if (dataLength >= 20) {
      if (reportType === 'INDIVIDUAL') padding = 5;
      padding = 7;
    }

    return (
      tableData.map((row, i) => {
        return (
          <tr key={i}>
            {
              row.map((data, j) => {
                return (
                  <td className={classes.td} style={{ padding }} key={`${i}-${j}`}>
                    {data}
                  </td>
                );
              })
            }
          </tr>
        );
      })
    );
  }
}

export default ReportDataViewTableCell;
