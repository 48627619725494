import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import uuidv4 from 'uuid/v4';
import { withReduxForm, Phone, ShowHidePasswordTextField } from 'utilsModule/form/reduxFormMui';
import { Back } from 'utilsModule/components';
import Logo from 'appModule/logo/logoNoText';
import HeaderComponent from 'appModule/main/layouts/Header';

const styles = {
  pageContainer: {
    height: '84vh',
    width: '100vw',
    margin: 0,
    backgroundColor: '#fff',
    paddingBottom: 100
  },
  formContainer: {
    // width:606,
  },
  banner: {
    textAlign: 'center',
  },
  back: {
    height: '16vh',
    padding: 20,
    backgroundColor: '#fff',
  },
  title : {
    color: '#212121',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 19,
  },
  subTitle: {
    color: '#212121',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '19px',
  },
};

const layout = {
  header: {
    itemProps: {
      style: {
        marginBottom: 42,
      },
    },
    containerProps: {
      justify: 'center',
    },
    components: [
      {
        id: uuidv4(),
        name: 'banner',
        component: ({ title, subtitle }) => (
          <Grid container style={styles.banner}>
            <Grid item xs={12} style={{ paddingBottom: '20px' }}><Logo /></Grid>
            <Grid item xs={12} style={{marginBottom:16}}><Typography variant='h4' styles={styles.title} style={{letterSpacing:0.5}}>{title}</Typography></Grid>
            <Grid item xs={12}><Typography variant='body1' styles={styles.subTitle} style={{letterSpacing:0.5}}>{subtitle}</Typography></Grid>
          </Grid>
        ),
        colSpans: { xs: 12 }, 
        inject: ({
          runtimeProps: {
            header: { banner },
          },
        }) => banner,
      },
    ],
  },
  body: {
    sections: [
      {
        id: uuidv4(),
        rows: [
          {
            id: uuidv4(),
            containerProps: {
              justify: 'center',
              spacing: 1,
              // style: { marginBottom: '25px' },
            },
            cols: [
              {
                name: 'oldPassword',
                label: 'Current Password',
                component: ShowHidePasswordTextField,
                required: true,
                colSpans: { xs: 6 },
              },
            ],
          },
        ],
      },
    ],
  },
  footer: {
    itemProps: {
      style: {
        marginTop: 40,
      },
    },
    containerProps: {
      justify: 'center',
    },
    components: [
      {
        id: uuidv4(),
        component: Button,
        type: 'submit',
        children: 'Next',
        variant: 'contained',
        color: 'primary',
        style: { width: '100%' },
        className: 'milo-btn-submit-medium',
        colSpans: { xs: 6 },
        inject: ({ submitting }) => ({ disabled: submitting }),
      },
    ],
  },
};

const validate = ({ oldPassword }) => ({
  oldPassword: oldPassword ? '' : 'Please enter current password',
});

const config = {
  form: 'changePassword',
  validate,
};

@withStyles(styles, { index: 1 })
@withReduxForm({ layout, config })
class ChangePassword extends PureComponent {
  static propTypes = {
    doChangePassword: PropTypes.func.isRequired,
    /* container */
    changePassword: PropTypes.func.isRequired,
    /* withStyles */
    classes: PropTypes.object,
    /* createReduxForm */
    Form: PropTypes.func.isRequired,
  };


  render() {
    const { classes, Form, runtimeProps, doChangePassword, changePassword } = this.props;
    return <React.Fragment>
        <HeaderComponent isOnBoard={true} />
        <div style={{paddingTop: 50}}>
          <Back className={classes.back} label={changePassword ? 'Back' : 'Log in'} backToWebsite={!changePassword} />
          <Grid container className={classes.pageContainer} alignItems="center">
            <Grid item xs />
            <Grid item xs={5}>
              <Form runtimeProps={{ ...runtimeProps }} className={classes.formContainer} onSubmit={doChangePassword} />
            </Grid>
            <Grid item xs />
          </Grid>
        </div>
      </React.Fragment>;
  }
}

export default ChangePassword;
