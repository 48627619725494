import { combineReducers } from 'redux';
import create, { epics as createEpics } from './create';
import list, { epics as listEpics } from './list';
// import enroll, { epics as enrollEpics } from './enroll';
// import availableList, { epics as availableListEpics } from './availableList';

export const epics = [
  ...createEpics,
  ...listEpics,
  //   ...enrollEpics,
  //   ...availableListEpics,
];

export default list;
