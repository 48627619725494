import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Login, OTP, SelectProfile } from '../containers';

class AuthLayout extends React.Component {
  static propTypes = {};

  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route path={`${match.path}/login/:type`} component={Login} />
        <Route path={`${match.path}/otp/:type`} component={OTP} />
        <Route path={`${match.path}/selectProfile`} component={SelectProfile} />
        <Route component={Login} />
      </Switch>
    );
  }
}

export default AuthLayout;
