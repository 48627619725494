import { Observable } from 'rxjs/Observable';
import * as R from 'ramda';
import history from 'dataModule/store/history';

export const getSessionToken = () => sessionStorage.getItem('session-token');
const process = (res) => {  
  if (res.ok) {
    return res.json().then(({ code, msg, ...data }) => {
      if (code === 1) {
        return data;
      } else {
        throw { code, message: msg };
      }
    });
  } else if (!res.ok && res.status === 401) {
    /* 
      HTTP 401 is returned from Backend if the request is Unauthorized (Backend ACL)
      As long as there is an API that returns HTTP 401 the user will be directed to the Unauthorized page
    */
    let path = '/app/unauthorized';
    if (window.location.href.includes('/wsb/')) path = '/app/wsb/unauthorized';
    history.push(path);
  } else if (!res.ok && res.status === 403) {
    let path = '/app/unauthorized';
    
    return res.json().then(({ code, msg }) => {
      if(code === 2000104) {
        path = '/app/unauthorized/session-timeout';
        if (window.location.href.includes('/wsb/')) path = '/app/wsb/unauthorized/session-timeout';
        history.push(path);  
      } else {
        if (window.location.href.includes('/wsb/')) path = '/app/wsb/unauthorized';
        history.push(path);  
      }
      throw { code, message: msg };
    });
  } else {
    return ({ error: { code: res.status, message: res.statusText } });
  }
};

// HTTP GET
export const httpGet = R.curry((url) => {
  const { API_URL } = window.MILO_CONFIG;
  return fetch(`${API_URL}/${url}`, {
    method: 'GET',
    headers: {
      'session-token': getSessionToken(),
    },
  }).then(process);
});

// HTTP POST
export const httpPost = R.curry((url, body) => {
  const { API_URL } = window.MILO_CONFIG;
  return fetch(`${API_URL}/${url}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'session-token': getSessionToken(),
    },
    body: JSON.stringify(body),
  }).then(process);
});

// HTTP PUT
export const httpPut = R.curry((url, body) => {
  const { API_URL } = window.MILO_CONFIG;
  return fetch(`${API_URL}/${url}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'session-token': getSessionToken(),
    },
    body: JSON.stringify(body),
  }).then(process);
});

// HTTP PATCH
export const httpPatch = R.curry((url, body) => {
  const { API_URL } = window.MILO_CONFIG;
  return fetch(`${API_URL}/${url}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'session-token': getSessionToken(),
    },
    body: JSON.stringify(body),
  }).then(process);
});

// HTTP DELETE
export const httpDelete = R.curry((url) => {
  const { API_URL } = window.MILO_CONFIG;
  return fetch(`${API_URL}/${url}`, {
    method: 'DELETE',
    headers: {
      'session-token': getSessionToken(),
    },
  }).then(process);
});


// HTTP GET Observable
export const httpGetObservable = (url) => {
  const request = httpGet(url);
  return Observable.fromPromise(request);
};

// HTTP POST Observable
export const httpPostObservable = (url, body) => {
  const request = httpPost(url, body);
  return Observable.fromPromise(request);
};

// HTTP PUT Observable
export const httpPutObservable = (url, body) => {
  const request = httpPut(url, body);
  return Observable.fromPromise(request);
};

// HTTP PATCH Observable
export const httpPatchObservable = (url, body) => {
  const request = httpPatch(url, body);
  return Observable.fromPromise(request);
};

// HTTP DELETE Observable
export const httpDeleteObservable = (url, body) => {
  const request = httpDelete(url, body);
  return Observable.fromPromise(request);
};
