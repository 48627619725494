/* eslint-disable arrow-body-style */
import React from 'react';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import * as _ from 'lodash';
import { roundFloatToFixedDecimal } from 'utilsModule/common';

const invoiceHeader = (organizationInvoice, healthbeatsOrg) => {
  const billingAddress = _.find(organizationInvoice.recipientGroupOrg.address, { type: 'BILLING' });
  const billingCountry = _.get(billingAddress, 'country', null);
  return (
    <Grid style={{ padding: '20px' }}>
      <div style={{ textAlign: 'center' }}>
        <img src={healthbeatsOrg.logoUrl} alt='' style={{ width: '150px' }} />
      </div>
      <h2 style={{ textAlign: 'center' }}> Tax Invoice </h2>

      <Grid container>
        <Grid item xs={6} style={{ textAlign: 'left' }}>
          {healthbeatsOrg.name} <br />
          {healthbeatsOrg.address[0].line_1} <br />
          {healthbeatsOrg.address[0].line_2} {healthbeatsOrg.address[0].line_2 && <br />}
          {healthbeatsOrg.address[0].country} {healthbeatsOrg.address[0].postalCode} <br />
          {
            healthbeatsOrg.taxRegistrationNumber && billingCountry === 'Singapore' ?
              `GST Registration No: ${healthbeatsOrg.taxRegistrationNumber}` : ''
          }
          <br /><br />
          {`Invoice To: ${organizationInvoice.recipientGroupOrg.name}`} <br />
          {`Address: ${organizationInvoice.recipientGroupOrg.address[0].line_1} ${organizationInvoice.recipientGroupOrg.address[0].line_2 || ''}`} <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {`${organizationInvoice.recipientGroupOrg.address[0].country} ${organizationInvoice.recipientGroupOrg.address[0].postalCode}`}
        </Grid>

        <Grid item xs={6}>
          <Grid container justify='flex-end'>
            <Grid item style={{ textAlign: 'left' }}>
              {`Invoice No: ${organizationInvoice.invoiceNo}`} <br />
              {`Invoice Date: ${moment.tz(organizationInvoice.statementDate, organizationInvoice.timezone).format('DD MMM YYYY')}`} <br />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const invoiceTableHeader = (currency) => {
  return (
    <thead>
      <tr style={{ verticalAlign: 'text-top' }}>
        <th>DESCRIPTION</th>
        <th style={{ width: '15%' }}>QTY</th>
        <th style={{ width: '15%' }}>ITEM CODE</th>
        <th style={{ width: '15%' }}>UNIT PRICE ({currency})</th>
        <th style={{ width: '15%' }}>AMOUNT ({currency})</th>
      </tr>
    </thead>
  );
};

const invoiceTableBody = (organizationInvoice) => {
  return (
    <tbody>
      <tr style={{ background: '#8080803b' }}>
        <td style={{ paddingLeft: '10px' }}>Outstanding Balance</td>
        <td></td>
        <td></td>
        <td></td>
        <td style={{ textAlign: 'center' }}>${roundFloatToFixedDecimal(organizationInvoice.outstandingBalance, 2).toFixed(2)}</td>
      </tr>

      {organizationInvoice.organizationInvoiceLines.map((line) => {
        const background = (line.sequence % 2 === 0) ? '#8080803b' : '#fff';
        return (
          <tr key={line.id} style={{ verticalAlign: 'text-top', background }}>
            <td style={{ paddingLeft: '10px' }}> {line.description} </td>
            <td style={{ textAlign: 'center' }}> {line.quantity} </td>
            <td style={{ textAlign: 'center' }}> {line.itemCodeCode} </td>
            { line.priceBeforeTax ? <td style={{ textAlign: 'center' }}> ${line.priceBeforeTax} </td> : <td />}
            { line.amount ? <td style={{ textAlign: 'center' }}> ${line.amount} </td> : <td />}
          </tr>
        );
      })}
    </tbody>
  );
};

const invoiceAmountSummary = (patientInvoice) => {
  return (
    <div style={{ float: 'right', paddingRight: '30px', paddingTop: '20px' }}>
      <div style={{ display: 'inline-grid' }}>
        <span> Subtotal </span> <br />
        <span> Tax Total </span> <br />
        <span> Grand Total </span> <br />
        <span> Balance Due </span>
      </div>

      <div style={{ display: 'inline-grid', paddingLeft: '20px' }}>
        <span> ${roundFloatToFixedDecimal(patientInvoice.subTotal, 2).toFixed(2)} </span> <br />
        <span> ${roundFloatToFixedDecimal(patientInvoice.taxTotal, 2).toFixed(2)} </span> <br />
        <span> ${roundFloatToFixedDecimal(patientInvoice.total, 2).toFixed(2)} </span> <br />
        <span> ${roundFloatToFixedDecimal(patientInvoice.balanceDue, 2).toFixed(2)} </span>
      </div>
    </div>
  );
};

const generateInvoiceHTML = (invoiceData) => {
  return (
    <div style={{
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: '#FFF',
    }}>
      { invoiceHeader(invoiceData.organizationInvoice, invoiceData.healthbeatsOrg)}
      <hr style={{ clear: 'both' }} />
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        {invoiceTableHeader(invoiceData.organizationInvoice.currency)}
        {invoiceTableBody(invoiceData.organizationInvoice)}
      </table>
      <hr />
      { invoiceAmountSummary(invoiceData.organizationInvoice)}
    </div>
  );
};

export default generateInvoiceHTML;
