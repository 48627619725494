export function setGradientColours(adhRate) {
  // 75.1 to 100
  if (adhRate > 75) return { startColour: '#34D196', endColour: '#34EC7B' };
  // 50.1 to 75
  if (adhRate > 50) return { startColour: '#00BBF9', endColour: '#69DAFF' };
  // 25.1 to 50
  if (adhRate > 25) return { startColour: '#F15BB5', endColour: '#FF96D5' };
  //  0.1 to 25
  if (adhRate > 0) return { startColour: '#985DE5', endColour: '#BE87FF' };
  if (adhRate === 0) return { startColour: '#9E9E9E', endColour: '#C7C7C7' };
  // Catch all
  return { startColour: '#9E9E9E', endColour: '#C7C7C7' };
}

export function setColourByRate(adhRate) {
  if (adhRate > 75) return '#34D196';
  if (adhRate > 50) return '#00BBF9';
  if (adhRate > 25) return '#F15BB5';
  if (adhRate > 0) return '#985DE5';
  if (adhRate === 0) return '#9E9E9E';
  // Catch all
  return '#9E9E9E';
}
