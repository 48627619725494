import React, { PureComponent } from 'react';
import { Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Logo from 'appModule/logo';
import * as FileSaver from 'file-saver';
import { actionCreators as messageActionCreators } from 'appModule/message/ducks/message';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { Loading } from 'utilsModule/components';

const styles = {
  pageContainer: {
    height: '100vh',
    width: '100vw',
    margin: 0,
    backgroundColor: '#fff',
    overflow: 'hidden'
  },
};

@withStyles(styles)
@connect(null, { notify: messageActionCreators.show })
class EdfConverterComponent extends PureComponent {
  state = {
    selectedFile: [],
    loading: false
  }

  onChangeHandler = event => {
    this.setState({
      selectedFile: event.target.files,
    });
  }

  _validate = () => {
    // Not Empty
    const { selectedFile } = this.state;
    if (!selectedFile || (selectedFile && selectedFile.length === 0)) {
      return {
        valid: false,
        errors: 'Please select a .dat file.'
      };
    }

    // .dat Files only
    let hasInvalidFile = false;
    for (let x = 0; x < selectedFile.length; x++) {
      const theFile = selectedFile[x];
      const ext = theFile.name.split('.')[1];
      if (ext !== 'dat') {
        hasInvalidFile = true;
      }
    }

    if (hasInvalidFile) {
      return {
        valid: false,
        errors: 'Only .dat files are allowed'
      };
    }

    return {
      valid: true,
      errors: null
    };

  }

  onClickHandler = () => {
    const { valid, errors } = this._validate();
    const { notify } = this.props;
    if (!valid) {
      notify({ message: errors, type: 'error' });
      return;
    }
    const data = new FormData();
    for (let x = 0; x < this.state.selectedFile.length; x++) {
      const theFile = this.state.selectedFile[x];
      data.append('files', theFile, theFile.name);
    }
    this.setState({ loading: true });
    const { API_URL } = window.MILO_CONFIG;
    fetch(`${API_URL}/edf-converter/convert`, {
      method: 'POST',
      body: data,
    }).then((response) => {
      return response.blob();
    }) .then((data) => {
      this.setState({ loading: false });
      const blob = new Blob([data], {
        type: 'application/octet-stream'
      });
      FileSaver.saveAs(blob, 'edf.zip');
    });
  }

  render() {
    const {
      classes,
    } = this.props;
    const { loading } = this.state;
    if (loading) {
      return <Loading />;
    }
    return (
      <React.Fragment>
        <Grid container className={classes.pageContainer} alignItems='center'>
          <Grid item xs />
          <Grid item xs={4}>
            <Grid container style={{ textAlign: 'center' }}>
              <Grid item xs={12}>
                <Grid item xs={12}><Logo /></Grid>
                <Grid item xs={12}>
                  <h1>EDF Converter</h1>
                  <h4>1. Upload .dat files.</h4>
                  <h4>2. Click Convert Button.</h4>
                  <h4>3. Save the zip file.</h4>
                </Grid>
                <Grid item xs={12}>
                  <input style={{ fontSize: 16 }} type="file" className="form-control" multiple onChange={this.onChangeHandler} />
                </Grid>
                <Grid item xs={12}>
                  <Button onClick={this.onClickHandler}  color='primary' raised className='milo-btn-submit' style={{ color: 'white', marginTop: 20 }}>
                    Convert to EDF
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs />
        </Grid>
      </React.Fragment>

    );
  }
}

export default EdfConverterComponent;
