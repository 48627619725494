/* Reducer */
/*
**************************************************
  Action Types
**************************************************
*/
const prefix = 'PROVIDER/NOTIFICATIONS';
const actionTypes = {

    // Chat
    GET_CHAT_NOTIFICATIONS: Symbol(`${prefix}/GET_CHAT_NOTIFICATIONS`),
    GET_CHAT_NOTIFICATIONS_SUCCESS: Symbol(`${prefix}/GET_CHAT_NOTIFICATIONS_SUCCESS`),
    INACTIVATE_ALL_CHAT_NOTIFICATIONS: Symbol(`${prefix}/INACTIVATE_ALL_CHAT_NOTIFICATIONS`),
    INACTIVATE_EP_CHAT_NOTIFICATIONS: Symbol(`${prefix}/INACTIVATE_EP_CHAT_NOTIFICATIONS`),

    // Others / General
    GET_OTHER_NOTIFICATIONS: Symbol(`${prefix}/GET_OTHER_NOTIFICATIONS`),
    GET_OTHER_NOTIFICATIONS_SUCCESS: Symbol(`${prefix}/GET_OTHER_NOTIFICATIONS_SUCCESS`),
    INACTIVATE_OTHER_NOTIFICATION: Symbol(`${prefix}/INACTIVATE_OTHER_NOTIFICATION`),

    PANEL_OPEN: Symbol(`${prefix}/PANEL_OPEN`),
    PANEL_CLOSE: Symbol(`${prefix}/PANEL_CLOSE`),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {

    // Chat
    getChatNotificationsSuccess: payload => ({ type: actionTypes.GET_CHAT_NOTIFICATIONS_SUCCESS, payload }),
    inactivateAllChatNotifications: payload => ({ type: actionTypes.INACTIVATE_ALL_CHAT_NOTIFICATIONS, payload }),
    inactivateEpChatNotifications: payload => ({ type: actionTypes.INACTIVATE_EP_CHAT_NOTIFICATIONS, payload }),

    // Others / General
    getOtherNotificationsSuccess: payload => ({ type: actionTypes.GET_OTHER_NOTIFICATIONS, payload }),
    inactivateOtherNotifications: payload => ({ type: actionTypes.INACTIVATE_OTHER_NOTIFICATION, payload }),

    panelOpen: payload => ({ type: actionTypes.PANEL_OPEN, payload }),
    panelClose: payload => ({ type: actionTypes.PANEL_CLOSE, payload }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/

const epics = [];

export { epics };
/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
    open: false,
    chatNotifications: [],
    otherNotifications: []
};

export default (state = initState, action) => {
    switch (action.type) {

        // Chat
        case actionTypes.GET_CHAT_NOTIFICATIONS_SUCCESS:
            {
                return {
                    ...state,
                    chatNotifications: action.payload
                };
            }
        case actionTypes.INACTIVATE_ALL_CHAT_NOTIFICATIONS:
            {
                return {
                    ...state,
                    chatNotifications: [],
                    otherNotifications: [],
                };
            }
        case actionTypes.INACTIVATE_EP_CHAT_NOTIFICATIONS:
            {
                return {
                    ...state,
                    chatNotifications: state.chatNotifications.filter(n => n.enrolledProgram.id !== action.payload.epId)
                };
            }

        // Others / General

        case actionTypes.GET_OTHER_NOTIFICATIONS:
            {
                return {
                    ...state,
                    otherNotifications: action.payload
                };
            }

        case actionTypes.INACTIVATE_OTHER_NOTIFICATION:
            {
                return {
                    ...state,
                    otherNotifications: state.otherNotifications.filter(n => n.id !== action.payload.notificationId)
                };
            }

        case actionTypes.PANEL_OPEN:
            {
                return { ...state, open: true };
            }
        case actionTypes.PANEL_CLOSE:
            {
                return { ...state, open: false };
            }
        default:
            {
                return state;
            }
    }
};