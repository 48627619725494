/* Constants */

/* Action Creators */

/* Epics */
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/catch';
import DM from 'dataModule';
import { getChat } from './chats';
import { actionTypes as chatActionTypes } from './chats';

/* Reducer */
/*
**************************************************
  Action Types
**************************************************
*/
const prefix = 'PROVIDER/CHAT';

const actionTypes = {
  GET_CHATROOM: Symbol(`${prefix}/GET_CHATROOM`),
  GET_CHATROOM_SUCCESS: Symbol(`${prefix}/GET_CHATROOM_SUCCESS`),
  GET_CHATROOM_FAILURE: Symbol(`${prefix}/GET_CHATROOM_FAILURE`),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  getChatroom: payload => ({ type: actionTypes.GET_CHATROOM, payload }),
  getChatroomSuccess: payload => ({ type: actionTypes.GET_CHATROOM_SUCCESS, payload }),
  getChatroomFailure: payload => ({ type: actionTypes.GET_CHATROOM_FAILURE, payload }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/

const epics = [
  //get chatRoomInfo
  action$ =>
    action$.ofType(actionTypes.GET_CHATROOM).mergeMap(({ payload }) =>
      DM.chat
        .chatRoomById(payload)
        .map((result) => {
          const { members, epId } = result.value;
          const chat = getChat(`${epId}`);
          if (chat) {
            chat.setUsers({ members });
          }
          return actionCreators.getChatroomSuccess({ result });
        })
        .catch((error) => {
          console.log(error);
          return Observable.of(actionCreators.getChatroomFailure({ error }));
        })),
];

export { epics };
/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
  code: null,
  value: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.GET_CHATROOM: {
      const { info } = action.payload;
      return { ...state, info };
    }
    case actionTypes.GET_CHATROOM_SUCCESS: {
      const { result } = action.payload;
      return { ...state, ...result };
    }
    case actionTypes.GET_CHATROOM_FAILURE: {
      const { error } = action.payload;
      return { ...state, result: { ok: false, error } };
    }
    case chatActionTypes.LOGOUT: {
      return initState;
    }
    default: {
      return state;
    }
  }
};
