/* Reducer */
/*
**************************************************
  Action Types
**************************************************
*/
const prefix = 'GLOBALSESSION';
const actionTypes = {
    SESSION_TIMEOUT_OPEN: Symbol(`${prefix}/SESSION_TIMEOUT_OPEN`),
    SESSION_TIMEOUT_CLOSE: Symbol(`${prefix}/SESSION_TIMEOUT_CLOSE`),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {

    sessionTimeoutOpen: payload => ({ type: actionTypes.SESSION_TIMEOUT_OPEN, payload }),
    sessionTimeoutClose: payload => ({ type: actionTypes.SESSION_TIMEOUT_CLOSE, payload }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/

const epics = [];

export { epics };
/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
    sessionDialogOpen: false
};

export default (state = initState, action) => {
    switch (action.type) {

        // Chat
        case actionTypes.SESSION_TIMEOUT_OPEN: {
            return { ...state, sessionDialogOpen: true };
        }
        case actionTypes.SESSION_TIMEOUT_CLOSE: {
            return { ...state, sessionDialogOpen: false };
        }
        default:
            {
                return state;
            }
    }
};