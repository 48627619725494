import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Typography,
} from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import CancelIcon from '@material-ui/icons/Cancel';
import MessageIcon from '@material-ui/icons/Message';
import { withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import EmptyNotifications from 'dataModule/assets/img/empty_notification.png';
import Adherence from 'dataModule/assets/img/Adherence.png';
import { ROLES } from 'dataModule/constants';
import { resourceTypes, withResource } from 'dataModule/store/resources';
import _ from 'lodash';
import moment from 'moment';
import * as R from 'ramda';
import React, { Component } from 'react';
import { Manager, Popper, Target } from 'react-popper';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { initialsOf } from 'utilsModule';
import { ImageAvatar } from 'utilsModule/components';
import { fetchGoThumbSignedUrl } from 'utilsModule/gothumb';
import { getters as usersGetters } from 'dataModule/store/resources/users';
import { actionCreators as notificationActionCreators } from '../ducks';
import { actionCreators as sessionActionCreators } from '../../globalSession/ducks';
import AvatarImg from 'dataModule/assets/img/avatar.png';
import HBLogo from '../assets/hb-logo.png';

const styles = {
  popper: {
    width: 400,
    // The popper's right edge aligns with the avatar icon, which is 30px from the right edge of the screen
    // To achieve a right margin of 10px, we shift the popper by 20px to the right.
    marginTop: '5px',
    marginRight: '-155px',
    minHeight: 200,
    maxHeight: 400,
  },
  root: {
    display: 'flex',
  },
  popperClose: {
    pointerEvents: 'none',
  },
  listRoot: {
    width: '100%',
    maxWidth: 500,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 400,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  badge: {
    backgroundColor: '#ff5100',
    color: 'white',
  },
  shown: {
    opacity: 1,
    marginLeft: 5,
    marginRight: 10,
    transition: '0.5s',
    width: '1em',
    height: '1em',
    fontSize: 'inherit',
    color: '#888888',
  },
  hidden: {
    opacity: 0,
    marginLeft: -13,
    marginRight: 10,
    transition: '0.5s',
    width: '1em',
    height: '1em',
    fontSize: 'inherit',
    color: '#888888',
  },
  listSubHeader: {
    lineHeight: '40px',
  },
  listSubHeaderText: { color: 'black' },
  listItemTextPrimary: {
    fontSize: 14,
  },
  listItemTextSecondary: {
    fontSize: 12,
    color: 'grey',
    marginTop: 3,
  },
  listItemSecondaryAction: {
    display: 'flex',
    flexDirection: 'column',
  },
  listItemSecondaryActionText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  time: { textAlign: 'center', color: 'black' },
  notificationCount: {
    marginTop: 3,
    alignSelf: 'flex-end',
    textAlign: 'right',
    color: 'white',
    backgroundColor: '#ff5100',
    paddingTop: 2,
    paddingBottom: 2,
    paddingRight: 4,
    paddingLeft: 4,
    borderRadius: 10,
    marginRight: 10,
  },
  noNotification: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    paddingBottom: 100,
  },
  popperGrid: {
    padding: 15,
  },
  messageIconButton: { fontSize: '40px', marginRight: 15, color: '#9e9e9e' },
  messageIcon: { width: '1em', height: '1em', fontSize: 'inherit' },
  noNotificationTitle: {
    marginBottom: 10,
  },
  noNotificationCaption: {
    textAlign: 'center',
    paddingRight: 15,
    paddingLeft: 15,
    color: '#888888',
  },
  notificationTabButton: {
    marginTop: 5,
    padding: '15px 15px',
    borderRadius: 0,
    backgroundColor: 'transparent',
    borderBottom: '2px solid transparent',
  },
  notificationTabButtonActive: {
    borderBottom: '2px solid #ff5100',
  },
};

@connect(
  (state) => ({
    currentUserType: R.path(['resources', 'users', 'retrieveCurrent', 'profile', 'type'], state),
    open: R.path(['globalNotification', 'open'], state),
    sessionDialogOpen: R.path(['globalSession', 'sessionDialogOpen'], state),
    chatNotifications: R.path(['globalNotification', 'chatNotifications'], state),
    otherNotifications: R.path(['globalNotification', 'otherNotifications'], state),
    groupOrganisation: usersGetters.getLoggedInGroupOrganization({ root: true })(state),
  }),
  {
    ...notificationActionCreators,
    ...sessionActionCreators 
  },
)
@withStyles(styles, { index: 1 })
@withRouter
@withResource([
  { resourceType: resourceTypes.USERS, method: 'retrieveNotifications', options: { runOnDidMount: false } },
])
class SettingContainer extends Component {
  state = {
    hoveredNotificationId: null,
    activeNotificationTab: 'carechat', // carechat / others
  };

  componentDidMount = () => {
    const { actionCreators, getChatNotificationsSuccess, getOtherNotificationsSuccess, currentUserType } = this.props;

    const getPollingInfo = async () => {
      actionCreators[resourceTypes.USERS].ajax({
        cargo: {
          method: 'getPollingInfo',
        },
        onSuccess: () => {
          if (
            [ROLES.DOCTOR, ROLES.NURSE, ROLES.CARE_MANAGER, ROLES.PHYSIOTHERAPIST, ROLES.DIETICIAN, ROLES.OTHERS].includes(
              currentUserType,
            )
          ) {
            const updateNotification = async () => {
              actionCreators[resourceTypes.USERS].ajax({
                cargo: {
                  method: 'retrieveNotifications',
                },
                onSuccess: ({ data }) => {
                  const carechatNotifications = _.get(data, 'retrieveNotifications.messageNotifications', []);
                  const otherNotifications = _.get(data, 'retrieveNotifications.generalNotifications', []).map((m) => {
                    // const goThumbUrl = AvatarImg;
                    // if (m.profile.organization.logoUrl) {
                    //   try {
                    //     goThumbUrl = fetchGoThumbSignedUrl(m.profile.organization.logoUrl);
                    //   } catch (err) {
                    //     console.log(err);
                    //   }
                    // }
                    return {
                      ...m,
                      // hospitalLogo: goThumbUrl
                    };
                  });
                  getChatNotificationsSuccess(carechatNotifications);
                  getOtherNotificationsSuccess(otherNotifications);
                },
                onFailure: ({error}) => {
                  console.log(error);
                },
              });
            };
            updateNotification();
          }
        },
        onFailure: ({ error }) => {
          if(error.code && error.code === 2000104) {
            this.stopAllInterval();
            this.dialogSessionTimeoutOpen();
          }
          console.error((error.message) ? error.message : error);
        },
      });
      this.getPollingInfoInterval = setTimeout(getPollingInfo, 10000);
    };
    getPollingInfo();
  };

  componentWillUnmount() {
      this.stopAllInterval();
  }

  stopAllInterval() {
    // this.stopInterval(this.updateNotificationInterval);
    this.stopInterval(this.getPollingInfoInterval);
  }

  stopInterval(objInterval) {
    if(objInterval) {
      clearInterval(objInterval);
    }
  }

  handleClose = () => {
    const { open, panelClose } = this.props;
    if (open) {
      panelClose();
    }
  };

  handleOpen = () => {
    const { open, panelOpen, panelClose } = this.props;
    if (open) {
      panelClose();
    } else {
      panelOpen();
    }
  };
  
  dialogSessionTimeoutOpen = () => {
    const { sessionDialogOpen, sessionTimeoutOpen } = this.props;
    if (!sessionDialogOpen) {
      sessionStorage.clear();
      sessionTimeoutOpen();
    }
  };

  /**
   * Remove All Chat Notifications
   */
  handleInactivateAllNotifications = () => {
    const { inactivateAllChatNotifications, actionCreators } = this.props;
    actionCreators[resourceTypes.USERS].ajax({
      cargo: {
        method: 'inactivateNotifications',
        input: {
          content: {
            mode: 'ALL',
          },
        },
      },
      onSuccess: ({ data }) => {
        inactivateAllChatNotifications();
      },
      onFailure: ({ error }) => {
        console.log(error);
      },
    });
  };

  /**
   * Handle Click Chat Notification
   */
  handleClickNotification = (notification, type = 'carechat') => {
    const { actionCreators, inactivateEpChatNotifications, inactivateOtherNotifications } = this.props;
    let payload = null;
    if (type === 'carechat') {
      const {
        enrolledProgram: { id: epId, patientId },
      } = notification;
      this.handleClose();

      this.props.history.push(`/app/participants/${patientId}/program?id=${epId}`);
      inactivateEpChatNotifications({ epId });
      payload = {
        mode: 'ENROLLED_PROGRAM',
        enrolledProgramId: epId,
      };
    } else {
      const { id, data } = notification;
      fetchGoThumbSignedUrl(data.uri).then((signedUrl) => {
        const buttonElement = document.createElement('a');
        buttonElement.setAttribute('href', signedUrl);
        buttonElement.setAttribute('target', '_blank');
        buttonElement.style.display = 'none';
        document.body.appendChild(buttonElement);
        buttonElement.click();
        buttonElement.remove();
      });
      inactivateOtherNotifications({ notificationId: id });
      payload = {
        mode: 'GENERAL',
        notificationId: id,
      };
    }

    // inactivate notifications in background
    actionCreators[resourceTypes.USERS].ajax({
      cargo: {
        method: 'inactivateNotifications',
        input: {
          content: payload,
        },
      },
      onSuccess: ({ data }) => {
        // success
      },
      onFailure: ({ error }) => {
        console.log(error);
      },
    });
  };

  /**
   * Handle Remove Chat Notification
   */
  handleRemoveNotification = (notification, type = 'carechat') => {
    const { actionCreators, inactivateEpChatNotifications, inactivateOtherNotifications } = this.props;
    let payload = null;
    alert('REmove');
    if (type === 'carechat') {
      const {
        enrolledProgram: { id: epId },
      } = notification;
      inactivateEpChatNotifications({ epId });

      payload = {
        mode: 'ENROLLED_PROGRAM',
        enrolledProgramId: epId,
      };
    } else {
      const { id } = notification;
      inactivateOtherNotifications({ notificationId: id });
      payload = {
        mode: 'GENERAL',
        notificationId: id,
      };
    }

    // inactivate notifications in background
    actionCreators[resourceTypes.USERS].ajax({
      cargo: {
        method: 'inactivateNotifications',
        input: {
          content: payload,
        },
      },
      onSuccess: ({ data }) => {
        // success
      },
      onFailure: ({ error }) => {
        console.log(error);
      },
    });
  };

  /**
   * Display Carechat Notifications
   */
  displayCarechatNotifications = () => {
    const { chatNotifications = [], classes } = this.props;
    const { hoveredNotificationId } = this.state;

    const groupedNotifications = this.groupNotifications(chatNotifications);
    const groupedNotificationsKeys = Object.keys(groupedNotifications);

    if (chatNotifications.length === 0) {
      return (
        <div className={classes.noNotification}>
          <img src={EmptyNotifications} alt="" style={{ width: '70%' }} />
          <Typography variant={'h5'} className={classes.noNotificationTitle}>
            No notification yet.
          </Typography>
          <Typography variant={'body2'} className={classes.noNotificationCaption}>
            Stay tuned! Notification about your participant's Care Chat activity will show up here.
          </Typography>
        </div>
      );
    }

    return (
      <List className={classes.listRoot} subheader={<li />}>
        {groupedNotificationsKeys.map((groupedNotificationKey) => {
          const groupedNotification = groupedNotifications[groupedNotificationKey];
          return (
            <li key={`section-${groupedNotification.id}`} className={classes.listSection}>
              <ul className={classes.ul}>
                <ListSubheader disableSticky className={classes.listSubHeader}>
                  <Typography variant={'caption'} className={classes.listSubHeaderText}>
                    {groupedNotification.dateSent}
                  </Typography>
                </ListSubheader>
                <Divider />
                {groupedNotification.notifications.map((notification) => {
                  const senderFirstName = _.get(notification, 'senderProfile.currentDemographics.name.firstName', '');
                  const senderLastName = _.get(notification, 'senderProfile.currentDemographics.name.lastName', '');
                  const profile_photo = _.get(notification, 'senderProfile.currentDemographics.profile_photo', '');
                  const enrolledProgramTitle = _.get(notification, 'enrolledProgram.title', '');
                  return (
                    <ListItem
                      key={`item-${groupedNotification.id}`}
                      onClick={() => this.handleClickNotification(notification, 'carechat')}
                      ContainerComponent="div"
                      ContainerProps={{
                        style: {
                          padding: '10px 10px 0px 0px',
                          paddingBottom: 10,
                          borderBottom: '.5px solid #E1E1E1',
                          cursor: 'pointer',
                        },
                      }}>
                      <ListItemAvatar>
                        <ImageAvatar
                          src={profile_photo}
                          alt={initialsOf(`${senderFirstName} ${senderLastName}`.toUpperCase())}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primaryTypographyProps={{
                          className: classes.listItemTextPrimary,
                        }}
                        secondaryTypographyProps={{
                          className: classes.listItemTextSecondary,
                        }}
                        primary={`${senderFirstName} ${senderLastName}`}
                        secondary={`${enrolledProgramTitle}`}
                      />
                      <ListItemSecondaryAction>
                        <div className={classes.listItemSecondaryAction}>
                          <div
                            className={classes.listItemSecondaryActionText}
                            onMouseOver={() =>
                              this.setState({
                                hoveredNotificationId: notification.id,
                              })
                            }
                            onMouseOut={() =>
                              this.setState({
                                hoveredNotificationId: null,
                              })
                            }>
                            <Typography className={classes.time} variant={'caption'}>
                              {moment(notification.dateSent).format('h:mm A')}
                            </Typography>
                            <CancelIcon
                              onClick={() => this.handleRemoveNotification(notification, 'carechat')}
                              className={hoveredNotificationId === notification.id ? classes.shown : classes.hidden}
                            />
                          </div>
                          <Typography variant={'caption'} className={classes.notificationCount}>
                            {notification.count}
                          </Typography>
                        </div>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </List>
    );
  };

  /**
   * Display Other Notifications
   */
  displayOtherNotifications = () => {
    const { otherNotifications = [], classes } = this.props;

    if (otherNotifications.length === 0) {
      return (
        <div className={classes.noNotification}>
          <img src={Adherence} alt="" style={{ width: '70%' }} />
          <Typography variant={'h5'} className={classes.noNotificationTitle}>
            No notification yet.
          </Typography>
          <Typography variant={'body2'} className={classes.noNotificationCaption}>
            Notification about your Adherence activity will show up here on the 1st day of the next calendar month.
          </Typography>
        </div>
      );
    }

    return (
      <List className={classes.listRoot} subheader={<li />}>
        {otherNotifications.map((otherNotification) => {
          return (
            <ListItem
              key={`item-${otherNotification.id}`}
              onClick={() => this.handleClickNotification(otherNotification, 'other')}
              ContainerComponent="div"
              ContainerProps={{
                style: {
                  padding: '10px 10px 0px 0px',
                  paddingBottom: 10,
                  borderBottom: '.5px solid #E1E1E1',
                  cursor: 'pointer',
                },
              }}>
              <ListItemAvatar>
                <ImageAvatar src={HBLogo} />
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{
                  className: classes.listItemTextPrimary,
                }}
                secondaryTypographyProps={{
                  className: classes.listItemTextSecondary,
                }}
                primary={'Participants\' Adherence Report'}
                secondary={otherNotification.title}
              />
              <ListItemSecondaryAction>
                <div className={classes.listItemSecondaryAction}>
                  <div
                    className={classes.listItemSecondaryActionText}
                    onMouseOver={() =>
                      this.setState({
                        hoveredNotificationId: otherNotification.id,
                      })
                    }
                    onMouseOut={() =>
                      this.setState({
                        hoveredNotificationId: null,
                      })
                    }>
                    <Typography className={classes.time} variant={'caption'} style={{ marginTop: '-7px' }}>
                      {moment(otherNotification.createdAt).format('hh:mm A')}
                    </Typography>
                  </div>
                  <Badge
                    badgeContent={1}
                    classes={{ badge: classes.badge }}
                    max={1000}
                    style={{ marginTop: '10px', marginRight: '10px' }}
                  />
                </div>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    );
  };

  groupNotifications = (notifications = []) => {
    const groupedNotifications = {};
    notifications.forEach((notification) => {
      const { dateSent } = notification;
      const dateKey = moment(dateSent).format('MM_DD_YYYY');
      if (!groupedNotifications[dateKey]) {
        groupedNotifications[dateKey] = {
          notifications: [notification],
          dateSent: moment(dateSent).format('DD MMM YYYY, dddd'),
        };
      } else {
        groupedNotifications[dateKey] = {
          ...groupedNotifications[dateKey],
          notifications: [...groupedNotifications[dateKey].notifications, notification],
        };
      }
    });
    return groupedNotifications;
  };

  toggleActiveNotificationTab(tab) {
    this.setState({
      activeNotificationTab: tab,
    });
  }

  displayCarechatNotifs() {
    const { activeNotificationTab } = this.state;

    return (
      <React.Fragment>
        {activeNotificationTab === 'carechat' ? this.displayCarechatNotifications() : this.displayOtherNotifications()}
      </React.Fragment>
    );
  }

  render() {
    const {
      classes: { popperClose },
      open,
      classes,
      chatNotifications,
      otherNotifications = [],
      currentUserType,
      groupOrganisation: { isKioskOrg },
    } = this.props;

    const { activeNotificationTab } = this.state;

    const carechatNotificationsCount = _.sumBy(chatNotifications, 'count');
    const otherNotificationsCount = otherNotifications.length;
    const badgeCount = Number(carechatNotificationsCount) + Number(otherNotificationsCount);

    if (
      ![ROLES.DOCTOR, ROLES.NURSE, ROLES.CARE_MANAGER, ROLES.PHYSIOTHERAPIST, ROLES.DIETICIAN, ROLES.OTHERS].includes(
        currentUserType,
      )
    ) {
      return null;
    }

    const isGlobalProfileSelected = document.querySelector('.globalProfileClassName');
    if (isGlobalProfileSelected !== null) {
      isGlobalProfileSelected.addEventListener('click', () => {
        this.handleClose();
      });
    }

    return (
      <div className={classes.root}>
        <Manager>
          <Target>
            <div className="globalNotificationClassName">
              <IconButton
                title="Help"
                className={classes.messageIconButton}
                onClick={(e) => {
                  this.handleOpen();
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                }}>
                <Badge badgeContent={badgeCount} classes={{ badge: classes.badge }} max={1000}>
                  <MessageIcon className={classes.messageIcon} />
                </Badge>
              </IconButton>
            </div>
          </Target>
          <Popper
            placement="bottom-end"
            eventsEnabled={open}
            style={styles.popper}
            className={classNames({ [popperClose]: !open })}>
            <ClickAwayListener onClickAway={this.handleClose}>
              <Grow in={open} id="menu-list">
                <Paper elevation={10} style={{ height: '100%' }}>
                  <Grid container direction="row" alignItems="center" className={classes.popperGrid}>
                    <Grid item xs={8}>
                      <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                        Notifications
                      </Typography>
                    </Grid>
                    <Grid item xs={4} style={{ paddingLeft: '30px' }}>
                      <Button
                        className="milo-btn-black"
                        style={{ width: '95px' }}
                        onClick={(e) => {
                          this.handleInactivateAllNotifications();
                          e.stopPropagation();
                          e.nativeEvent.stopImmediatePropagation();
                        }}>
                        <Typography variant={'button'} style={{ color: 'white' }}>
                          Dismiss All
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                  <Divider />
                  <div
                    style={{
                      margin: 5,
                    }}>
                    <Button
                      onClick={() => this.toggleActiveNotificationTab('carechat')}
                      className={classNames(classes.notificationTabButton, {
                        [classes.notificationTabButtonActive]: activeNotificationTab === 'carechat',
                      })}
                      style={{
                        backgroundColor: 'transparent',
                      }}>
                      <Typography style={{ color: activeNotificationTab === 'carechat' ? '#ff5100' : 'black' }}>
                        Care Chat
                      </Typography>
                      {chatNotifications.length > 0 && (
                        <div
                          style={{
                            width: '10px',
                            height: '10px',
                            backgroundColor: '#ff5100',
                            borderRadius: '50%',
                            marginLeft: '2px',
                            marginTop: '-10px',
                          }}
                        />
                      )}
                    </Button>
                    {!isKioskOrg && (
                      <Button
                        onClick={() => this.toggleActiveNotificationTab('others')}
                        className={classNames(classes.notificationTabButton, {
                          [classes.notificationTabButtonActive]: activeNotificationTab === 'others',
                        })}
                        style={{
                          backgroundColor: 'transparent',
                        }}>
                        <Typography style={{ color: activeNotificationTab === 'others' ? '#ff5100' : 'black' }}>
                          Adherence
                        </Typography>
                        {otherNotifications.length > 0 && (
                          <div
                            style={{
                              width: '10px',
                              height: '10px',
                              backgroundColor: '#ff5100',
                              borderRadius: '50%',
                              marginLeft: '2px',
                              marginTop: '-10px',
                            }}
                          />
                        )}
                      </Button>
                    )}
                    <Divider />
                  </div>
                  {this.displayCarechatNotifs()}
                </Paper>
              </Grow>
            </ClickAwayListener>
          </Popper>
        </Manager>
      </div>
    );
  }
}

export default SettingContainer;
