/* eslint-disable */
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/catch';
import * as R from 'ramda';
import DM from 'dataModule';

/*
**************************************************
  Action Types
**************************************************
*/
const prefix = 'PATIENTMIN';

const actionTypes = {
  PATIENT_MIN_LIST: Symbol(`${prefix}/PATIENT_MIN_LIST`),
  PATIENT_MIN_LIST_SUCCESS: Symbol(`${prefix}/PATIENT_MIN_LIST_SUCCESS`),
  PATIENT_MIN_LIST_FAILURE: Symbol(`${prefix}/PATIENT_MIN_LIST_FAILURE`),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  patientMinList: payload => ({ type: actionTypes.PATIENT_MIN_LIST, payload }),
  patientMinListSuccess: payload => ({ type: actionTypes.PATIENT_MIN_LIST_SUCCESS, payload }),
  patientMinListFailure: payload => ({ type: actionTypes.PATIENT_MIN_LIST_FAILURE, payload }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/
const epics = [
  action$ =>
    action$.ofType(actionTypes.PATIENT_MIN_LIST).mergeMap(({ payload }) =>
      DM.patient
        .patientMinList(payload)
        .map(res => {
          return actionCreators.patientMinListSuccess({ res });
        })
        .catch(error => {
          return Observable.of(actionCreators.patientMinListFailure({ profiles: [], error }));
        }),
    ),
];

export { epics };

/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
  list: {
    profiles: [],
    pagination: {
      totalPages: 1,
      totalCount: 0,
    }
  }
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.PATIENT_MIN_LIST: {
      return { ...state, profiles: [] };
    }
    case actionTypes.PATIENT_MIN_LIST_SUCCESS: {
      const { res } = action.payload;
      return { ...state,
        list: {
          profiles: R.pathOr([], ['value', 'profiles'], res),
          pagination: {
            totalPages: res.totalPages,
            totalCount: res.totalRecords,
          }
        }
      };
    }

    case actionTypes.PATIENT_MIN_LIST_FAILURE: {
      const { profiles, error } = action.payload;
      return { ...state, profiles };
    }

    default: {
      return state;
    }
  }
};
