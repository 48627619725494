/* eslint-disable */
import get from 'lodash/get';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/catch';
import DM from 'dataModule';

/*
**************************************************
  Action Types
**************************************************
*/
const prefix = 'CONSUMABLE';

const actionTypes = {
  CONSUMABLE_WAREHOUSE_LIST: Symbol(`${prefix}/CONSUMABLE_WAREHOUSE_LIST`),
  CONSUMABLE_WAREHOUSE_LIST_SUCCESS: Symbol(`${prefix}/CONSUMABLE_WAREHOUSE_LIST_SUCCESS`),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  consumableWarehouseList: payload => ({ type: actionTypes.CONSUMABLE_WAREHOUSE_LIST, payload }),
  consumableWarehouseListSuccess: payload => ({ type: actionTypes.CONSUMABLE_WAREHOUSE_LIST_SUCCESS, payload }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/
const epics = [
  // List all lot item (consumables) of a particular warehouse
  action$ =>
    action$.ofType(actionTypes.CONSUMABLE_WAREHOUSE_LIST).mergeMap(({ payload }) =>
      DM.consumables
        .consumableWarehouseList(payload)
        .map(res => {
          return actionCreators.consumableWarehouseListSuccess({
            sku: get(res, 'value.sku', {}),
            rows: get(res, 'value.consumables', []),
            totalRecords: get(res, 'totalRecords', 0),
          });
        })
        .catch(error => {
          return actionCreators.consumableWarehouseListSuccess({ rows: [], error });
        }),
    ),
];

export { epics };

/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
  list: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.CONSUMABLE_WAREHOUSE_LIST: {
      return { ...state, list: null };
    }
    case actionTypes.CONSUMABLE_WAREHOUSE_LIST_SUCCESS: {
      const { rows, sku, totalRecords } = action.payload;
      return { ...state, list: { sku, rows, totalRecords } };
    }

    default: {
      return state;
    }
  }
};
