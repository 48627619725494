/* eslint-disable */
import get from 'lodash/get';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/catch';
import DM from 'dataModule';

/*
**************************************************
  Action Types
**************************************************
*/
const prefix = 'DEVICE';

const actionTypes = {
  DEVICE_WAREHOUSE_LIST: Symbol(`${prefix}/DEVICE_WAREHOUSE_LIST`),
  DEVICE_WAREHOUSE_LIST_SUCCESS: Symbol(`${prefix}/DEVICE_WAREHOUSE_LIST_SUCCESS`),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  deviceWarehouseList: payload => ({ type: actionTypes.DEVICE_WAREHOUSE_LIST, payload }),
  deviceWarehouseListSuccess: payload => ({ type: actionTypes.DEVICE_WAREHOUSE_LIST_SUCCESS, payload }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/
const epics = [
  // List all serialized item (devices) of a particular warehouse
  action$ =>
    action$.ofType(actionTypes.DEVICE_WAREHOUSE_LIST).mergeMap(({ payload }) =>
      DM.device
        .deviceWarehouseList(payload)
        .map(res => {
          return actionCreators.deviceWarehouseListSuccess({
            sku: get(res, 'value.sku', {}),
            rows: get(res, 'value.devices', []),
            totalRecords: get(res, 'totalRecords', 0),
          });
        })
        .catch(error => {
          return actionCreators.deviceWarehouseListSuccess({ rows: [], sku: {} , error });
        }),
    ),
];

export { epics };

/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
  list: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.DEVICE_WAREHOUSE_LIST: {
      return { ...state, list: null };
    }
    case actionTypes.DEVICE_WAREHOUSE_LIST_SUCCESS: {
      const { rows, sku, totalRecords } = action.payload;
      return { ...state, list: { sku, rows, totalRecords } };
    }

    default: {
      return state;
    }
  }
};
