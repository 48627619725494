import React, { PureComponent } from 'react';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid, Button, Card, CardContent, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import uuidv4 from 'uuid/v4';
import { withReduxForm, ShowHidePasswordTextField, Phone } from 'utilsModule/form/reduxFormMui';
import { Version } from 'utilsModule/components';
import { Back } from 'utilsModule/components';
import Logo from 'appModule/logo';
import HeaderComponent from 'appModule/main/layouts/Header';
import backgroundImage from '../assets/img/Lifestyle-Login-Image-1x.png';

const styles = theme => ({
  reverifyPageContainer: {
    height: '100vh',
    width: '100vw',
    margin: 0,
  },
  pageContainer: {
    height: '100vh',
    width: '100vw',
    margin: 0,
    background: `url(${backgroundImage}) center/cover no-repeat`,
  },
  card: {
    maxWidth: 350,
    margin: '0 auto',
    borderRadius: 20,
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
  },
  policy: {
    marginTop: 20,
    fontSize: 15,
    textAlign: 'center',
    lineHeight: 1.5,
    display: 'inline-block',
  },
  link: {
    color: '#2c7df6',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  right: {
    textAlign: 'right',
    cursor: 'pointer',
  },
});

const layout = {
  header: {
    containerProps: {
      justify: 'center',
    },
    components: [
      {
        id: uuidv4(),
        component: Logo,
        width: 300,
        style: {
          display: 'block',
          margin: '0 auto',
        },
        colSpans: { xs: 12 },
      },
    ],
  },
  body: {
    sections: [
      {
        id: uuidv4(),
        containerProps: {
          overall: {
            spacing: 0,
          },
          body: {
            spacing: 0,
          },
        },
        rows: [
          {
            id: uuidv4(),
            cols: [
              {
                name: 'mobile',
                label: 'Mobile No',
                component: Phone,
                required: true,
                inject: ({ runtimeProps: { t } }) => ({ label: t('Mobile No') }),
              },
            ],
          },
          {
            id: uuidv4(),
            cols: [
              {
                name: 'encryptPassword',
                label: 'Password',
                component: ShowHidePasswordTextField,
                required: true,
                inject: ({ runtimeProps: { t } }) => ({ label: t('Password') }),
              },
            ],
          },
          {
            id: uuidv4(),
            cols: [
              {
                name: 'forgetPassword',
                component: ({ forgetPassword, classes, t }) => (
                  <Grid className={classes.right} onClick={forgetPassword}>
                    <Typography variant='caption'>{t('Forgot Password?')}</Typography>
                  </Grid>
                ),
                inject: ({
                  runtimeProps: { forgetPassword, classes, t },
                }) => ({ forgetPassword, classes, t }),
              },
            ],
          },
        ],
      },
    ],
  },
  footer: {
    itemProps: {
      style: {
        marginTop: 50,
      },
    },
    components: [
      {
        id: uuidv4(),
        component: Button,
        type: 'submit',
        children: 'Log In',
        variant: 'contained',
        color: 'primary',
        style: { width: '100%' },
        className: 'milo-btn-submit',
        colSpans: { xs: 12 },
        inject: ({ submitting, runtimeProps: { t } }) => ({ disabled: submitting, children: t('Log In') }),
      },
    ],
  },
};

const validate = ({ mobile: { value }, password }, { runtimeProps: { t } }) => ({
  value: (value !== undefined || value) ? '' : t('validation:Please enter Mobile No'),
  password: password ? '' : t('validation:Please enter Password'),
});

const config = {
  form: 'login',
  validate,
};
@withNamespaces('auth', { wait: true })
@withRouter
@withStyles(styles, { index: 1 })
@withReduxForm({ layout, config })
class Login extends PureComponent {
  static propTypes = {
    /* container */
    signIn: PropTypes.func.isRequired,
    forgetPassword: PropTypes.func.isRequired,
    /* withStyles */
    classes: PropTypes.object,
    /* createReduxForm */
    Form: PropTypes.func.isRequired,
  };

  render() {
    const {
      classes, Form, initialValues, runtimeProps, signIn, t, forgetPassword
    } = this.props;

    return (
      <React.Fragment>
        <Grid container spacing={2} className={classes.pageContainer} alignItems="center">
          <Grid item xs={1} sm={5} md={6} lg={7} />
          <Grid item xs={10} sm={6} md={5} lg={4}>
            <Card className={classes.card}>
              <CardContent>
                <Form
                  runtimeProps={{
                    ...runtimeProps,
                    t,
                    forgetPassword,
                    classes,
                  }}
                  initialValues={initialValues}
                  className={classes.formContainer}
                  onSubmit={signIn}
                  sections={[]}
                />
                <Typography variant="caption" className={classes.policy}>
                  By clicking the "Log In" button, you accept and agree to be bound by the terms in
                  our{' '}
                  <Link target="_blank" to="/policies/privacy" className={classes.link}>
                    Privacy Policy
                  </Link>{' '}
                  &{' '}
                  <Link target="_blank" to="/policies/tou" className={classes.link}>
                    Terms of Use
                  </Link>
                  .
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} />
        </Grid>
        <Version />
      </React.Fragment>
    );
    
  }
}

export default Login;
