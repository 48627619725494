/* eslint-disable consistent-return */
/* eslint-disable object-curly-newline */
import React from 'react';
import * as _ from 'lodash';
import moment from 'moment-timezone';
import { withStyles } from '@material-ui/styles';
import { normalizeVitalsDecimals } from 'utilsModule/vital';
const footer = require('../../../../public/hb_powered_by_int.png');

const styles = () => ({
    container: {
        backgroundColor: 'white',
        width: '21cm',
        height: '29.7cm',
        overflow: 'hidden',
    },
    metadataTitle: {
        width: '40%',
        fontSize: 14,
    },
    metadataValue: {
        width: '60%',
        fontSize: 14,
    },
    tdHeader: {
        border: '1px solid black',
        borderCollapse: 'collapse',
        textAlign: 'left',
        fontSize: 12,
        fontWeight: 'bold',
        padding: 8,
    },
    td: {
        border: '1px solid black',
        borderCollapse: 'collapse',
        textAlign: 'left',
        fontSize: 12,
        padding: 8,
    },
    tdTotal: {
        border: '1px solid black',
        borderCollapse: 'collapse',
        textAlign: 'right',
        fontSize: 12,
        fontWeight: 'bold',
        padding: 8,
    },
    metadataSpace: {
        height: 10,
    },
});

@withStyles(styles, { index: 1 })
class PatientVitalReportComponent extends React.Component {
    render = () => {
        const { classes } = this.props;
        return ( <
            div className = { classes.container } >
            <
            div style = {
                { padding: 20 } } > { this._header() } <
            div style = {
                { height: 20 } }
            /> { this._table() } { this._footer() } <
            /div> <
            /div>
        );
    };

    _header = () => {
        const { reportMetaData, classes } = this.props;
        if (!reportMetaData) return;
        return ( <
            div style = {
                {
                    width: '100%',
                }
            } >
            <
            div style = {
                { display: 'flex', justifyContent: 'center', alignItems: 'center' } } >
            <
            img src = { reportMetaData.hospitalLogo }
            style = {
                { width: 110, height: 110, marginTop: 12, marginBottom: 12 } }
            /> <
            /div> <
            div style = {
                {
                    width: '100%',
                    textAlign: 'center',
                    fontSize: 20,
                    fontWeight: 'bold',
                    fontFamily: 'Roboto',
                    marginTop: 12,
                    marginBottom: 12,
                }
            } >
            Participant 's Vital Results Report <
            /div> <
            table style = {
                { width: '100%' } } >
            <
            tr >
            <
            td className = { classes.metadataTitle } > Participant Name < /td> <
            td className = { classes.metadataValue } >: { reportMetaData.patient } < /td> <
            /tr> <
            tr >
            <
            td className = { classes.metadataTitle } > Participant ID and ID No. < /td> <
            td className = { classes.metadataValue } >
            : { reportMetaData.idType }
            and { reportMetaData.idNo } <
            /td> <
            /tr> <
            tr >
            <
            td className = { classes.metadataTitle } > Date of Birth < /td> <
            td className = { classes.metadataValue } >: { reportMetaData.dateOfBirth } < /td> <
            /tr> <
            tr >
            <
            td className = { classes.metadataSpace }
            /> <
            td className = { classes.metadataSpace }
            /> <
            /tr> <
            tr >
            <
            td className = { classes.metadataTitle } > Provider Name < /td> <
            td className = { classes.metadataValue } >: { reportMetaData.provider } < /td> <
            /tr> <
            tr >
            <
            td className = { classes.metadataTitle } > Hospital / Clinic Name < /td> <
            td className = { classes.metadataValue } >: { reportMetaData.hospital } < /td> <
            /tr> <
            tr >
            <
            td className = { classes.metadataTitle } > Programme Name < /td> <
            td className = { classes.metadataValue } >: { reportMetaData.program } < /td> <
            /tr> <
            tr >
            <
            td className = { classes.metadataTitle } > Period of Report(Date) < /td> <
            td className = { classes.metadataValue } >: { reportMetaData.periodReport } < /td> <
            /tr> <
            tr >
            <
            td className = { classes.metadataSpace }
            /> <
            td className = { classes.metadataSpace }
            /> <
            /tr> <
            tr >
            <
            td className = { classes.metadataTitle } > Date and Time of Report < /td> <
            td className = { classes.metadataValue } >: { reportMetaData.dateOfReport } < /td> <
            /tr> <
            tr >
            <
            td className = { classes.metadataTitle } > Downloaded By < /td> <
            td className = { classes.metadataValue } >: { reportMetaData.downloadedBy } < /td> <
            /tr> <
            /table> <
            /div>
        );
    };

    _table = () => {
        const { classes, data, vitalGroupId } = this.props;
        const {
            location: {
                query: { skuName },
            },
        } = this.props;
        const vitalHeaders = this._getHeadersForVitalGroupId(vitalGroupId);
        const totalColumns = vitalHeaders.columns.length + 2;

        return ( <
            div style = {
                { height: '600px' } } >
            <
            table style = {
                { borderCollapse: 'collapse', width: '100%', marginBottom: '4%' } } >
            <
            tr >
            <
            td className = { classes.tdHeader }
            colSpan = { totalColumns } > { skuName || '' } <
            /td> <
            /tr> <
            tr >
            <
            td className = { classes.tdHeader }
            style = {
                { width: '20%' } } >
            Date / Time Taken <
            /td>

            { /* HEADERS */ } {
                vitalHeaders.columns.map((vitalHeader) => {
                    return ( <
                        td key = { vitalHeader }
                        className = { classes.tdHeader } > { vitalHeader } <
                        /td>
                    );
                })
            }

            <
            td className = { classes.tdHeader } > Schedule < /td> <
            /tr> { /* DATA CELLS */ } {
                data.tasks.map((task) => {
                    return this._tableCell(task, vitalHeaders);
                })
            }

            { /* PAGE AVERAGE */ } { this._tableCellAverage(vitalHeaders, data.summary) }

            { /* TOTAL READINGS */ } { this._totalCell(totalColumns) } <
            /table> <
            /div>
        );
    };

    _totalCell = (totalColumns) => {
        const { classes, page, totalPages, reportMetaData } = this.props;
        if (+page !== +totalPages) return;
        return ( <
            tr >
            <
            td className = { classes.tdTotal }
            colSpan = { totalColumns } >
            Total number of Readings: { reportMetaData.total } <
            /td> <
            /tr>
        );
    };

    _tableCell = (task, vitalHeaders) => {
        const { classes, reportMetaData } = this.props;
        return ( <
            tr >
            <
            td className = { classes.td } > {
                moment(task.completedAt)
                .tz(reportMetaData.timezone)
                .format('DD MMM YYYY, HH:mm')
            } <
            /td> {
                vitalHeaders.vitals.map((vital) => {
                    const observations = task.observationCompiled;
                    const observation = _.find(observations, (obs) => {
                        return obs.vitals.indexOf(vital) > -1;
                    });

                    if (!observation) {
                        return ( <
                            td key = { vital.name }
                            className = { classes.td } >
                            -
                            <
                            /td>
                        );
                    }

                    switch (vital) {
                        case 'BP':
                            const diaComponent = _.find(observation.component, (item) => {
                                return item.code.text === 'dia';
                            });
                            const sysComponent = _.find(observation.component, (item) => {
                                return item.code.text === 'sys';
                            });
                            const sys = normalizeVitalsDecimals(sysComponent.valueQuantity.value, 'Blood Pressure');
                            const dia = normalizeVitalsDecimals(diaComponent.valueQuantity.value, 'Blood Pressure');
                            return ( <
                                td key = { vital.name }
                                className = { classes.td } > { sys }
                                /{dia} {diaComponent.valueQuantity.unit} <
                                /td>
                            );

                        case 'HR':
                            const hr = normalizeVitalsDecimals(observation.valueQuantity.value, 'Heart Rate');
                            return ( <
                                td key = { vital.name }
                                className = { classes.td } > { hr }
                                beats / min <
                                /td>
                            );

                        case 'BG':
                            const bg = normalizeVitalsDecimals(observation.valueQuantity.value, 'Blood Glucose');
                            return ( <
                                td key = { vital.name }
                                className = { classes.td } > { bg } { observation.valueQuantity.unit } <
                                /td>
                            );

                        case 'BTM':
                            const btm = normalizeVitalsDecimals(observation.valueQuantity.value, 'Body Temperature');
                            return ( <
                                td key = { vital.name }
                                className = { classes.td } > { btm } { observation.valueQuantity.unit } <
                                /td>
                            );

                        case 'PO':
                            const po = normalizeVitalsDecimals(observation.valueQuantity.value, 'Blood Oxygen');
                            return ( <
                                td key = { vital.name }
                                className = { classes.td } > { po } { observation.valueQuantity.unit } <
                                /td>
                            );

                        case 'HS':
                            const hs = normalizeVitalsDecimals(observation.valueQuantity.value, 'Body Weight');
                            return ( <
                                td key = { vital.name }
                                className = { classes.td } > { hs } { observation.valueQuantity.unit } <
                                /td>
                            );

                        case 'RR':
                            const rr = normalizeVitalsDecimals(observation.valueQuantity.value, 'Respiration Rate');
                            return ( <
                                td key = { vital.name }
                                className = { classes.td } > { rr } { observation.valueQuantity.unit } <
                                /td>
                            );

                        default:
                            return ( <
                                td key = { vital.name }
                                className = { classes.td } >
                                -
                                <
                                /td>
                            );
                    }
                })
            } <
            td className = { classes.td } > { task.schedule.display } { task.type && task.type === 'one-time' && '(OTT)' } <
            /td> <
            /tr>
        );
    };

    _tableCellAverage = (vitalHeaders, summary) => {
        const { classes } = this.props;
        const vitalGroupSummary = summary[vitalHeaders.vitalGroupName];

        const getSummaryForVital = (vital) => {
            switch (vital) {
                case 'BG':
                    return vitalGroupSummary.BG.bg ? `${normalizeVitalsDecimals(vitalGroupSummary.BG.bg, 'Blood Glucose')} ${vitalGroupSummary.BG.unit}` : '-';
                case 'BP':
                    return vitalGroupSummary.BP.sys && vitalGroupSummary.BP.dia ? `${normalizeVitalsDecimals(vitalGroupSummary.BP.sys, 'Blood Pressure')}/${normalizeVitalsDecimals(
            vitalGroupSummary.BP.dia,
            'Blood Pressure',
          )} ${vitalGroupSummary.BP.unit}` : '-';
                case 'HR':
                    return vitalGroupSummary.HR.hr ? `${normalizeVitalsDecimals(vitalGroupSummary.HR.hr, 'Heart Rate')} beats/min` : '-';
                case 'BTM':
                    return vitalGroupSummary.BTM.btm ? `${normalizeVitalsDecimals(vitalGroupSummary.BTM.btm, 'Body Temperature')} ${vitalGroupSummary.BTM.unit
            }` : '-';
                case 'PO':
                    return vitalGroupSummary.PO.po ? `${normalizeVitalsDecimals(vitalGroupSummary.PO.po, 'Blood Oxygen')} ${vitalGroupSummary.PO.unit}` : '-';
                case 'HS':
                    return vitalGroupSummary.HS.hs ? `${normalizeVitalsDecimals(vitalGroupSummary.HS.hs, 'Body Weight')} ${vitalGroupSummary.HS.unit}` : '-';
                case 'RR':
                    return vitalGroupSummary.RR.rr ? `${normalizeVitalsDecimals(vitalGroupSummary.RR.rr, 'Respiration Rate')} ${vitalGroupSummary.RR.unit}` : '-';
            }
        };

        return ( <
            tr >
            <
            td className = { classes.tdHeader } > Overall Average: < /td> {
                vitalHeaders.vitals.map((vital) => {
                    return ( <
                        td key = { vital }
                        className = { classes.tdHeader } > { getSummaryForVital(vital) } <
                        /td>
                    );
                })
            } <
            td className = { classes.tdHeader }
            /> <
            /tr>
        );
    };

    _footer = () => {
        const { page, totalPages } = this.props;
        return ( <
            div >
            <
            div style = {
                { flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' } } >
            <
            div style = {
                { flex: 1 } } >
            <
            img src = { footer }
            style = {
                { width: '50%', float: 'right', marginRight: '-30px' } }
            /> <
            /div> <
            div style = {
                { flex: 1 } } >
            <
            span style = {
                { float: 'right', fontSize: 12 } } >
            Page { page }
            of { totalPages } <
            /span> <
            /div> <
            /div> <
            /div>
        );
    };

    _getHeadersForVitalGroupId = (vitalGroupId) => {
        switch (+vitalGroupId) {
            // BG
            case 1:
                return {
                    columns: ['Blood Glucose'],
                    vitalGroupId,
                    vitalGroupName: 'BG',
                    vitals: ['BG'],
                };

                // BP HR
            case 2:
                return {
                    columns: ['Blood Pressure', 'Heart Rate'],
                    vitalGroupId,
                    vitalGroupName: 'BP_HR',
                    vitals: ['BP', 'HR'],
                };

                // PO HR
            case 3:
                return {
                    columns: ['SpO2', 'Heart Rate'],
                    vitalGroupId,
                    vitalGroupName: 'PO_HR',
                    vitals: ['PO', 'HR'],
                };

                // HS
            case 4:
                return {
                    columns: ['Body Weight'],
                    vitalGroupId,
                    vitalGroupName: 'HS',
                    vitals: ['HS'],
                };

                // BP
            case 5:
                return {
                    columns: ['Blood Pressure'],
                    vitalGroupId,
                    vitalGroupName: 'BP',
                    vitals: ['BP'],
                };

                // HR
            case 6:
                return {
                    columns: ['Heart Rate'],
                    vitalGroupId,
                    vitalGroupName: 'HR',
                    vitals: ['HR'],
                };

                // PO
            case 7:
                return {
                    columns: ['SpO2'],
                    vitalGroupId,
                    vitalGroupName: 'PO',
                    vitals: ['PO'],
                };

                // BTM
            case 9:
                return {
                    columns: ['Temperature'],
                    vitalGroupId,
                    vitalGroupName: 'BTM',
                    vitals: ['BTM'],
                };

                // HILLROM
            case 10:
                return {
                    columns: ['Blood Pressure', 'Heart Rate', 'SpO2', 'Temperature'],
                    vitalGroupId,
                    vitalGroupName: 'HILLROM_WACSM',
                    vitals: ['BP', 'HR', 'PO', 'BTM'],
                };

                // HILLROM RR
            case 11:
                return {
                    columns: ['Blood Pressure', 'Heart Rate', 'SpO2', 'Temperature', 'Respiration Rate'],
                    vitalGroupId,
                    vitalGroupName: 'HILLROM_WACSM_RR',
                    vitals: ['BP', 'HR', 'PO', 'BTM', 'RR'],
                };

            default:
                return [];
        }
    };
}

export default PatientVitalReportComponent;