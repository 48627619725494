/* eslint-disable */
/* Constants */

/* Action Creators */

/* Epics */
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/catch';

// import apolloClient from 'libModule/gqlClient';
/* Reducer */
import vital from 'appModule/vital/ducks';
/* Libs */
import { combineReducers } from 'redux';
import DM from 'dataModule';
import { push } from 'react-router-redux';
/*
**************************************************
  Action Types
**************************************************
*/
const prefix = "program/create";

const actionTypes = {
  SHOW_PAGE: Symbol(`${prefix}/SHOW_PAGE`),
  SAVE: Symbol(`${prefix}/SAVE`),
  SAVE_SUCCESS: Symbol(`${prefix}/SAVE_SUCCESS`),
  SAVE_FAILURE: Symbol(`${prefix}/SAVE_FAILURE`),
  CREATE: Symbol(`${prefix}/CREATE`),
  CREATE_SUCCESS: Symbol(`${prefix}/CREATE_SUCCESS`),
  CREATE_FAILURE: Symbol(`${prefix}/CREATE_FAILURE`),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  showPage: () => ({ type: actionTypes.SHOW_PAGE }),
  save: payload => ({ type: actionTypes.SAVE, payload }),
  saveSuccess: payload => ({ type: actionTypes.SAVE_SUCCESS, payload }),
  saveFailure: payload => ({ type: actionTypes.SAVE_FAILURE, payload }),
  create: payload => ({ type: actionTypes.CREATE, payload }),
  createSuccess: payload => ({ type: actionTypes.CREATE_SUCCESS, payload }),
  createFailure: payload => ({ type: actionTypes.CREATE_FAILURE, payload }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/
const epics = [
  // Show page
  action$ => action$.ofType(actionTypes.SHOW_PAGE).mapTo(push('/app/program/create')),
  // Create new program
  action$ => action$.ofType(actionTypes.CREATE)
    .mergeMap(({ payload: { program, onCreateSuccess, onCreateFailure } }) =>
      Observable.fromPromise(DM.program.admin.createAdminProgram(program))
        .map(program => {
          console.log('program created: ', program);
          onCreateSuccess(program);
          return actionCreators.createSuccess({ program });
        })
        .catch(error => {
          console.log('%cerror', 'font-size: 12px; color: #00b3b3', error);
          onCreateFailure(error);
          return Observable.of(actionCreators.createFailure({ error }));
        })),
  action$ => action$.ofType(actionTypes.CREATE_SUCCESS).mapTo(push('/app/program')),
  // Save as draft
  action$ => action$.ofType(actionTypes.SAVE)
    .mergeMap(({ payload: { program, onSaveSuccess, onSaveFailure } }) =>
      Observable.fromPromise(DM.program.admin.saveDraftAdminProgram(program))
        .map(program => {
          console.log('program saved: ', program);
          onSaveSuccess(program);
          return actionCreators.saveSuccess({ program });
        })
        .catch(error => {
          console.log('%cerror', 'font-size: 12px; color: #00b3b3', error);
          onSaveFailure(error);
          return Observable.of(actionCreators.saveFailure({ error }));
        })),
  action$ => action$.ofType(actionTypes.SAVE_SUCCESS).mapTo(push('/app/program')),
];

export { epics };
/*
**************************************************
  Reducer
**************************************************
*/
export default combineReducers({
  vital,
});
