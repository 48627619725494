import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import uuidv4 from 'uuid/v4';
import { withReduxForm, TypeSelect } from 'utilsModule/form/reduxFormMui';
import BrandLogo from 'appModule/authLayout/components/BrandLogo';
import { Back } from 'utilsModule/components';

const styles = {
  containerWrapper: {
    backgroundColor: '#fff',
    padding: '0px',
    height: '60px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'sticky',
    top: 0,
    background: 'inherit',
    zIndex: 100,
    webkitBoxShadow: '0 3px 5px rgba(57, 63, 72, 0.3)',
    mozBoxShadow: '0 3px 5px rgba(57, 63, 72, 0.3)',
    boxShadow: '0 3px 5px rgba(57, 63, 72, 0.3)',
  },
  brandLogo: { marginLeft: 20 },
  pageContainer: {
    height: '100vh',
    width: '100vw',
    margin: 0,
    background: '#fff',
  },
  formContainer: {
    maxWidth: 850,
    margin: '0 auto',
    backgroundColor: '#fff',
    borderRadius: 10,
  },
  back: {
    padding: 20,
    backgroundColor: '#fff',
  },
};

const layout = {
  header: {
    components: [
      {
        id: uuidv4(),
        name: 'banner',
        component: () => (
          <Paper elevation={0} style={{ background: 'inherit' }}>
            <Typography variant="h5" style={{ fontSize: '32px' }}>Select Your Country or Location</Typography>
          </Paper>
        ),
      },
    ],
  },
  body: {
    sections: [
      {
        id: uuidv4(),
        style: {
          marginTop: 50,
          padding: 20,
          borderRadius: '10px',
          boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
        },
        rows: [
          {
            id: uuidv4(),
            cols: [
              {
                name: 'country',
                label: 'Your Country',
                component: TypeSelect,
                required: true,
                colSpans: { xs: 12 },
              },
            ],
          },
        ],
      },
    ],
  },
  footer: {
    itemProps: {
      style: {
        marginTop: 100,
      },
    },
    components: [
      {
        id: uuidv4(),
        component: Button,
        type: 'button',
        children: '',
        style: { visibility: 'hidden' },
        variant: 'contained',
        colSpans: { xs: 3 },
      },
      {
        id: uuidv4(),
        component: Button,
        type: 'submit',
        children: 'Next',
        className: 'milo-btn-orange',
        variant: 'contained',
        style: { width: '100%' },
        colSpans: { xs: 3 },
        inject: ({
          pristine,
          submitting,
        }) => ({
          disabled: pristine || submitting,
        }),
      },
    ],
  },
};

const validate = ({ country: { value } = {} }) => ({ country: value ? '' : 'Please select a country' });

const config = {
  form: 'selectCountry',
  validate,
};

@withStyles(styles, { index: 1 })
@withReduxForm({ layout, config })
class SelectCountry extends React.PureComponent {
  static propTypes = {
    /* container */
    selectCountry: PropTypes.func.isRequired,
    /* withStyles */
    classes: PropTypes.object,
    /* createReduxForm */
    Form: PropTypes.func.isRequired,
  };

  render() {
    const {
      classes,
      Form, initialValues, runtimeProps, handleSubmit,
    } = this.props;

    // console.log('%crender SelectCountry', 'font-size: 12px; color: #00b3b3', this.props);

    return (
      <React.Fragment>
        <div className={classes.containerWrapper}>
          <BrandLogo width={180} height={60} className={classes.brandLogo} />
        </div>
        <Back className={classes.back} label='' backToWebsite={true} showCustomerSupportIcon={false} />
        <div className={classes.pageContainer}>
          <Form
            initialValues={initialValues}
            runtimeProps={runtimeProps}
            className={classes.formContainer}
            onSubmit={handleSubmit}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default SelectCountry;
