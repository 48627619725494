import React from 'react';
import Notification from 'appModule/chat/notification';
import GlobalSetting from 'appModule/globalSetting';
import GlobalNotification from 'appModule/globalNotification';
import HelpIcon from '@material-ui/icons/Help';
import { Badge, Grid, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import DialogCustomerSupportContactComponent from './DialogCustomerSupportContact';
import DialogSessionTimeOutComponent from './DialogSessionTimeOut';
import HomeButton from './HomeButton';
import DialogDisclaimer from '../components/DialogDisclaimer';
import InfoIcon from '@material-ui/icons/Info';

const styles = () => ({
  containerWrapper: {
    backgroundColor: '#fff',
    padding: '0px 30px 0px 20px',
    height: '70px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    background: 'inherit',
    zIndex: 999,
    webkitBoxShadow: '0 3px 5px rgba(57, 63, 72, 0.3)',
    mozBoxShadow: '0 3px 5px rgba(57, 63, 72, 0.3)',
    boxShadow: '0 3px 5px rgba(57, 63, 72, 0.3)',
    width: '100%',
  },
  pullRight: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '55px',
  },
  badge: {
    backgroundColor: '#ff5100',
    color: 'white',
    marginRight: '5px',
    marginTop: '3px'
  },
});

@withStyles(styles, { index: 1 })
export default class HeaderComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openDialogCustomerSupportContact: false,
      openDialogDisclaimer: false,
      disclaimerBadge: 1,
    };
  }

  componentDidMount() {
    if (localStorage) {
      const showDisclaimerBadge = localStorage.getItem('showDisclaimerBadge');
      if (!showDisclaimerBadge) {
        localStorage.setItem('showDisclaimerBadge', 'show');
        this.setState({ disclaimerBadge: 1 });
      } else if (showDisclaimerBadge == 'hidden') {
        this.setState({ disclaimerBadge: 0 });
      } else if (showDisclaimerBadge == 'show') {
        this.setState({ disclaimerBadge: 1 });
      }
    }
  }

  toggledialogCustomerSupportContact = async (open) => {
    this.setState({ openDialogCustomerSupportContact: open });
  };

  toggleDisclaimerDialog = async (open) => {
    if (open && this.state.disclaimerBadge !== 0 && localStorage) {
      localStorage.setItem('showDisclaimerBadge', 'hidden');
      this.setState({ disclaimerBadge: 0 });
    }
    this.setState({ openDialogDisclaimer: open });
  };

  render() {
    const {
      classes: { containerWrapper, pullRight, badge },
      isOnBoard,
      hideComponents = {
        isDisclaimer : false
      }
    } = this.props;
    return (
      <div className={containerWrapper}>
        <HomeButton {...this.props} />
        <div className={pullRight}>
          <DialogCustomerSupportContactComponent
            open={this.state.openDialogCustomerSupportContact}
            toggledialogCustomerSupportContact={this.toggledialogCustomerSupportContact}
          />
          <DialogDisclaimer
            open={this.state.openDialogDisclaimer}
            toggleDisclaimerDialog={this.toggleDisclaimerDialog}
          />
          {!hideComponents.isDisclaimer && <Grid item onClick={() => this.toggleDisclaimerDialog(true)}>
            <IconButton title="Disclaimer" style={{ fontSize: '40px', color: '#9e9e9e', marginRight: '15px' }}>
              <Badge badgeContent={this.state.disclaimerBadge} classes={{ badge }} max={1000}>
                <InfoIcon style={{ width: '1em', height: '1em', fontSize: 'inherit' }} />
              </Badge>
            </IconButton>
          </Grid>}
          {!isOnBoard && <GlobalNotification />}
          <Grid item onClick={() => this.toggledialogCustomerSupportContact(true)}>
            <IconButton title="Help" style={{ fontSize: '40px', color: '#9e9e9e', marginRight: '5px' }}>
              <HelpIcon style={{ width: '1em', height: '1em', fontSize: 'inherit' }} />
            </IconButton>
          </Grid>
          {!isOnBoard && <GlobalSetting />}
        </div>
        {!isOnBoard && <Notification />}
        {!isOnBoard && <DialogSessionTimeOutComponent />}
      </div>
    );
  }
}
