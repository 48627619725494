/* eslint-disable max-len */
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  MenuItem,
  Paper,
  Select,
  Typography,
  InputLabel,
} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { VITAL_NAME_MAPPING } from 'dataModule/constants/vital';
import { Hint } from 'hb-react-vis';
import moment from 'moment';
import * as R from 'ramda';
import React from 'react';
import { MiloTable } from 'utilsModule/components';
import { Check } from 'utilsModule/material-ui-icons';
import uuidv4 from 'uuid/v4';
import { normalizeVitalsDecimals } from '../../../../utils/vital';
import VitalChart from '../ReportVitalChart';
import commonUtil from 'utilsModule/common';
import { withRouter } from 'react-router';
import { InfoOutline } from 'utilsModule/material-ui-icons';
import EmptyStateReportChart from './../../assets/EmptyStateReportChart.png';

const chartList = [
  { value: 'LINE_CHART', name: 'line', label: 'Line Chart' },
  { value: 'DATA_VIEW', name: 'dataTable', label: 'Data Table' },
];

const thresholdSettingsData = [{ value: 'SCHEDULED', label: 'Scheduled' }, { value: 'ONE_TIME', label: 'One-Time' }];

const styles = {
  description: {
    marginTop: '5px',
    fontSize: '12px',
    letterSpacing: '0.08px',
    marginBottom: '5px',
    color: '#212121',
  },
  patientProgramLabel: {
    fontSize: '12px',
    color: '#9E9E9E',
  },
  patientProgramGrid2: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  patientProgramGridContent: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  patientProgramDetails: {
    fontSize: '14px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  advancePropsTypograph: {
    height: '100px',
    alignItems: 'center',
    display: 'flex',
  },
  cardLabel: {
    fontFamily: 'Roboto',
    fontWeight: 'medium',
    fontSize: '12px',
    color: '#FFFFFF',
    paddingTop: 16,
  },
  cardDetail: {
    fontFamily: 'Roboto',
    fontSize: '21px',
    color: '#FFFFFF',
  },
  vitalDiv: {
    borderLeft: '1px solid',
    height: '120px',
    marginTop: 3,
  },
  vitalDiv2: {
    borderBottom: '1px solid',
    height: '20px',
    marginTop: 3,
  },
  selectionGridThreshold: {
    cursor: 'pointer',
    width: '9px',
    height: '15px',
    float: 'right',
    borderRadius: '14px',
    opacity: 1,
    marginRight: '3px',
  },
  fabBtn: {
    height: '10px',
    width: '38px',
    float: 'right',
    backgroundColor: 'white',
    boxShadow: 'none',
  },
  iconClose: {
    color: '#4c4b4b',
    fontSize: '20px',
  },
  menuItemTypograph: {
    paddingLeft: '10px',
    textAlign: 'left',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0px',
    color: '#474747',
    opacity: 1,
  },
  menuItemTypographData: {
    paddingLeft: '10px',
    textAlign: 'left',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '500',
    letterSpacing: '0px',
    color: '#474747',
    opacity: 1,
  },
  dataViewSelect: {
    paddingLeft: '16px',
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  paperBoxShadow: {
    boxShadow: '0px 0px 0px 1px rgb(0 0 0 / 10%), 0px 1px 1px 1px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%)',
  },
  statusInfoBoxShadow: {
    boxShadow: '0px 2px 11px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
  dialogAction: {
    justifyContent: 'space-between',
    paddingLeft: '25px',
    paddingRight: '25px',
    paddingBottom: '16px',
    paddingTop: '16px',
    borderTop: '1px solid rgb(240, 240, 240)',
  },
  vitalCardTooltip: {
    height: 'auto',
    width: '275px',
    marginBottom: '10px',
    marginRight: '10px',
    padding: '12px',
    borderRadius: '8px',
  },
  typography1: {
    fontSize: '14px',
    paddingLeft: 0,
    color: 'grey',
    paddingBottom: 20,
  },
  circle: {
    height: '10px',
    width: '10px',
    backgroundColor: '#A7E1D2',
    borderRadius: '50%',
    display: 'inline-block',
  },
};

const VitalToolTip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#212121',
    borderRadius: '16px',
    color: 'white',
    padding: 10,
    width: '389px',
  },
}))(Tooltip);

const monthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const monthsLong = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'Novemver',
  'December',
];

const selectStyle = createMuiTheme({
  overrides: {
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: 'transparent !important',
        },
        '&:after': {
          borderBottom: 'transparent !important',
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: 'transparent !important',
        },
      },
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      borderColor: 'white',
      position: 'relative',
      backgroundColor: 'white',
      border: '1px solid #ced4da',
      fontSize: 16,
      display: 'block',
      width: '100%',
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
    MuiSelect: {
      selectMenu: {
        height: 'auto',
      },
      root: {
        paddingRight: '0px !important',
      },
    },
    MuiInputBase: {
      root: {
        display: 'block',
        height: '30px',
      },
    },
    MuiTypography: {
      root: {
        fontSize: '14px',
      },
    },
  },
});

const dialogTheme = createMuiTheme({
  overrides: {
    MuiPaper: {
      root: {
        minWidth: '850px',
        borderRadius: '8px',
        paddingTop: '0px',
      },
    },
    MuiDialogContent: {
      root: {
        paddingLeft: '0px',
        paddingRight: '0px',
      },
    },
    MuiDialogTitle: {
      root: {
        paddingBottom: '0px',
      },
    },
    TableCell: {
      cell: {
        paddingTop: '10px',
        paddingBottom: '10px',
      },
    },
    MuiCardContent: {
      root: {
        width: '100%',
      },
    },
  },
});

const stylesPaper = () => ({
  paper: {
    minWidth: '1050px',
  },
});

@withStyles(stylesPaper, { index: 1 })
@withRouter
export default class VitalInterface extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showDialog: false,
      dialogData: {
        dialogValue: 'SCHEDULED',
        dialogValueSelected: 'SCHEDULED',
        dialogProgrammeId: 0,
        dialogProgrammeIdSelected: 0,
      },
      dialogValue: 'SCHEDULED',
      hoveredNode: null,
      pageSize: 10,
      currentPage: 0,
      totalCount: 0,
      listDataTable: [],
      list: [],
      pageSizeProgram: 10,
      currentPageProgram: 0,
      totalCountProgram: 0,
      listDataProgram: [],
      listProgram: [],
      epSelectActive: false,
      thresholdSelectActive: false,
    };

    this.changeCurrentPage = (currentPage) => {
      const { list, totalCount } = this.paginate(currentPage);
      this.setState({ list, totalCount, currentPage });
    };

    this.changeCurrentPageProgram = (currentPageProgram) => {
      const { listProgram, totalCountProgram } = this.paginateProgram(currentPageProgram);
      this.setState({ listProgram, totalCountProgram, currentPageProgram });
    };
  }

  getDisablePopup() {
    const { dialogData } = this.state;
    if (
      dialogData.dialogValue !== dialogData.dialogValueSelected ||
      dialogData.dialogProgrammeId !== dialogData.dialogProgrammeIdSelected
    ) {
      return false;
    }

    return true;
  }

  getDisablePopupReset() {
    const { dialogData } = this.state;
    const {
      mode,
      data: { key },
      reportData,
    } = this.props;
    if (
      mode === 'create' &&
      (dialogData.dialogValue !== dialogData.dialogValueSelected ||
        dialogData.dialogProgrammeId !== dialogData.dialogProgrammeIdSelected)
    ) {
      return false;
    } else if (mode === 'edit') {
      const module = reportData.modules.find((f) => f.key === 'vitals');
      if (module) {
        const vital = module.components.find((f) => f.key === key);
        if (!vital) return true;

        if (vital.thresholdSettings) {
          if (
            vital.thresholdEpId !== dialogData.dialogProgrammeId ||
            vital.thresholdSettings !== dialogData.dialogValue
          )
            return false;
        } else {
          if (vital.thresholdEpId !== dialogData.dialogProgrammeId || 'SCHEDULED' !== dialogData.dialogValue)
            return false;
        }
      }
    }

    return true;
  }

  componentDidMount() {
    const { dialogData } = this.state;
    const {
      data: { selectedData },
    } = this.props;
    dialogData.dialogProgrammeId = selectedData ? selectedData.id : 0;
    dialogData.dialogProgrammeIdSelected = selectedData ? selectedData.id : 0;
    this.setState({ dialogData });

    this.changeCurrentPage(0);
    this.changeCurrentPageProgram(0);
  }

  UNSAFE_componentWillReceiveProps({ data }) {
    const { data: oldData, key } = this.props;
    const { dialogData: oldDialogData } = this.state;

    // handle change data view
    if (data.dataView !== oldData.dataView) {
      const listDataTable = data.dataView.map((m) => {
        const {
          reportDate: { display },
          average: { measurementAverage, unit, sysAverage, diaAverage } = {},
        } = m;
        const measurementCheck = measurementAverage === 'N.A.' ? 'N.A.' : `${measurementAverage} ${unit}`;
        const bpMeasurementAverageCheck =
          sysAverage && diaAverage === 'N.A.' ? 'N.A./N.A.' : `${sysAverage}/${diaAverage} ${unit}`;
        return {
          date: display,
          noOfTasks: 1,
          adherenceRate: key === 'BP' ? bpMeasurementAverageCheck : measurementCheck,
        };
      });
      this.setState({ listDataTable, totalCount: listDataTable.length }, () => this.changeCurrentPage(0));
    }

    // handle change selected programme
    if (data.selectedData !== oldData.selectedData) {
      const dialogData = {
        ...oldDialogData,
        dialogProgrammeId: data.selectedData ? data.selectedData.id : 0,
        dialogProgrammeIdSelected: data.selectedData ? data.selectedData.id : 0,
      };
      this.setState({ dialogData });
    }

    // handle enrolledProgramData change
    if (data.enrolledProgramData !== oldData.enrolledProgramData) {
      const listDataProgram = data.selectedEnrolledProgramme.map((m) => {
        let rate = 0;
        const getEPAlerts = data.enrolledProgramData.find((f) => f.epId === m.id);
        if (getEPAlerts) {
          rate = getEPAlerts.total;
        }
        return {
          name: m.title,
          rate,
          value: m.id,
          label: m.title,
        };
      });
      this.setState({ listDataProgram, totalCountProgram: listDataProgram.length }, () =>
        this.changeCurrentPageProgram(0),
      );
    }
  }

  paginate = (currentPage) => {
    const { pageSize } = this.state;
    const { data } = this.props;
    const listDataTable = data.dataView.map((m) => {
      const {
        reportDate: { display },
        average: { measurementAverage, sysAverage, diaAverage, unit } = {},
      } = m;
      const measurementCheck = measurementAverage === 'N.A.' ? 'N.A.' : `${measurementAverage} ${unit}`;
      const bpMeasurementAverageCheck =
        sysAverage && diaAverage === 'N.A.' ? 'N.A./N.A.' : `${sysAverage}/${diaAverage} ${unit}`;
      return {
        date: display,
        noOfTasks: 1,
        adherenceRate: data.key === 'BP' ? bpMeasurementAverageCheck : measurementCheck,
      };
    });

    const list =
      listDataTable.length > pageSize
        ? listDataTable.slice(currentPage * pageSize, currentPage * pageSize + pageSize)
        : listDataTable;
    return { list, listDataTable, totalCount: listDataTable.length };
  };

  paginateProgram = (currentPageProgram) => {
    const { pageSizeProgram } = this.state;
    const { data } = this.props;

    const listDataProgram = data.selectedEnrolledProgramme.map((m) => {
      let rate = 0;
      const getEPAlerts = data.enrolledProgramData.find((f) => f.epId === m.id);
      if (getEPAlerts) {
        rate = getEPAlerts.total;
      }
      return {
        name: m.title,
        rate,
        value: m.id,
        label: m.title,
      };
    });

    const listProgram =
      listDataProgram.length > pageSizeProgram
        ? listDataProgram.slice(
            currentPageProgram * pageSizeProgram,
            currentPageProgram * pageSizeProgram + pageSizeProgram,
          )
        : listDataProgram;
    return { listProgram, listDataProgram, totalCountProgram: listDataProgram.length };
  };

  renderSummaryCard({
    vitalsSummary: { high = 0, low = 0, criticallyHigh = 0, criticallyLow = 0 } = {},
    average: { measurementAverage = 0, unit = '', diaAverage = 0, sysAverage = 0 } = {},
    totalTasks = 0,
  }) {
    const dynamicColor = (thresholdData, number, markFill = false, verticalLine = false, statusIndicator) => {
      if (thresholdData !== null || number !== null) {
        const cHigh = !R.isNil(thresholdData.CH.y) && thresholdData.CH.y;
        const cLow = !R.isNil(thresholdData.CL.y) && thresholdData.CL.y;
        const high = thresholdData.H.y;
        const low = thresholdData.L.y;
        // Critical High & Critical Low
        if ((cHigh && number >= cHigh) || (cLow && number <= cLow)) {
          // eslint-disable-next-line no-nested-ternary
          return markFill
            ? verticalLine
              ? '#E17777'
              : '#D72B07'
            : statusIndicator
              ? '#D34431'
              : 'linear-gradient(#FF6A4A, #B82403)';
        }
        // High & Low
        if (
          (number >= high && number < cHigh) ||
          (number <= low && number > cLow) ||
          (number >= high && cHigh === false) ||
          (number <= low && cLow === false)
        ) {
          // eslint-disable-next-line no-nested-ternary
          return markFill
            ? verticalLine
              ? '#F9B03A'
              : '#FBAF39'
            : statusIndicator
              ? '#FE7F22'
              : 'linear-gradient(#FEB52C, #FF6400)';
        }
        // Normal
        if (number > low && number < high) {
          // eslint-disable-next-line no-nested-ternary
          return markFill
            ? verticalLine
              ? '#77E192'
              : '#1AC606'
            : statusIndicator
              ? '#34BF7B'
              : 'linear-gradient(#0FDA68, #32B66C)';
        }
      }
      // Missed
      // eslint-disable-next-line no-nested-ternary
      return markFill && verticalLine ? '#ABABAA' : statusIndicator ? '#465C66' : 'linear-gradient(#D0CFCF, #B3ACAB)';
    };

    const firstChartAverageData = null;
    const {
      data: { key },
    } = this.props;
    return (
      <Paper
        elevation={1}
        style={{
          // background: dynamicColor(null, firstChartAverageData, false, false, true),
          backgroundColor: '#212121',
          display: 'flex',
          borderRadius: 3,
          marginLeft: 16,
          marginRight: 16,
          marginBottom: 20,
          boxShadow: 'none',
        }}>
        <Grid container direction="row" alignItems="center">
          <div direction="column" style={{ paddingLeft: '2%', width: '32%' }}>
            <Typography style={styles.cardLabel}>
              {key === 'BP' ? 'Overall Average (Sys/Dia)' : 'Overall Average'}
            </Typography>
            {key === 'BP' ? (
              <Typography style={styles.cardDetail}>{`${sysAverage}/${diaAverage} ${unit}`}</Typography>
            ) : (
              <Typography style={styles.cardDetail}>{`${measurementAverage} ${unit}`}</Typography>
            )}
            <div style={R.merge(styles.vitalDiv2, { color: dynamicColor(null, firstChartAverageData, true, true) })} />
            <Typography style={styles.cardLabel}>Total No. of Tasks</Typography>
            <Typography style={R.merge(styles.cardDetail, { paddingBottom: 16 })}>{totalTasks}</Typography>
          </div>
          <div style={R.merge(styles.vitalDiv, { color: dynamicColor(null, firstChartAverageData, true, true) })} />
          <div direction="column" style={{ width: '32%' }}>
            <Typography
              style={{
                fontFamily: 'Roboto',
                fontWeight: 'medium',
                fontSize: '12px',
                color: '#FFFFFF',
                paddingTop: key === 'BP' ? 0 : 16,
                paddingLeft: 16,
              }}>
              {key === 'BP' ? 'No. of Low Threshold (Sys/Dia)' : 'No. of Low Threshold'}
            </Typography>
            {key === 'BP' ? (
              <Typography style={R.merge(styles.cardDetail, { paddingLeft: 16 })}>{`${low.sys}/${low.dia}`}</Typography>
            ) : (
              <Typography style={R.merge(styles.cardDetail, { paddingLeft: 16 })}>{low}</Typography>
            )}
            <div style={R.merge(styles.vitalDiv2, { color: dynamicColor(null, firstChartAverageData, true, true) })} />
            <Typography style={R.merge(styles.cardLabel, { paddingLeft: 16 })}>
              {key === 'BP' ? 'No. of Critically Low Threshold (Sys/Dia)' : 'No. of Critically Low Threshold'}
            </Typography>
            {key === 'BP' ? (
              <Typography style={R.merge(styles.cardDetail, { paddingLeft: 16 })}>{`${criticallyLow.sys}/${
                criticallyLow.dia
              }`}</Typography>
            ) : (
              <Typography style={R.merge(styles.cardDetail, { paddingBottom: 16, paddingLeft: 16 })}>
                {criticallyLow}
              </Typography>
            )}
          </div>
          <div style={R.merge(styles.vitalDiv, { color: dynamicColor(null, firstChartAverageData, true, true) })} />
          <div direction="column" style={{ width: '31.5%' }}>
            <Typography
              style={{
                fontFamily: 'Roboto',
                fontWeight: 'medium',
                fontSize: '12px',
                color: '#FFFFFF',
                paddingTop: key === 'BP' ? 0 : 16,
                paddingLeft: 16,
              }}>
              {key === 'BP' ? 'No. of High Threshold (Sys/Dia)' : 'No. of High Threshold'}
            </Typography>
            {key === 'BP' ? (
              <Typography style={R.merge(styles.cardDetail, { paddingLeft: 16 })}>{`${high.sys}/${
                high.dia
              }`}</Typography>
            ) : (
              <Typography style={R.merge(styles.cardDetail, { paddingLeft: 16 })}>{high}</Typography>
            )}
            <div style={R.merge(styles.vitalDiv2, { color: dynamicColor(null, firstChartAverageData, true, true) })} />
            <Typography style={R.merge(styles.cardLabel, { paddingLeft: 16 })}>
              {key === 'BP' ? 'No. of Critically High Threshold (Sys/Dia)' : 'No. of Critically High Threshold'}
            </Typography>
            {key === 'BP' ? (
              <Typography style={R.merge(styles.cardDetail, { paddingLeft: 16 })}>{`${criticallyHigh.sys}/${
                criticallyHigh.dia
              }`}</Typography>
            ) : (
              <Typography style={R.merge(styles.cardDetail, { paddingBottom: 16, paddingLeft: 16 })}>
                {criticallyHigh}
              </Typography>
            )}
          </div>
        </Grid>
      </Paper>
    );
  }

  formatTickLabel(v, transformedData) {
    const index = v - 1;
    const item = transformedData[index];
    const month = monthsShort[item.month - 1];
    const year = item.year.toString().substr(-2);
    return `${month} ${year}`;
  }

  renderCareTaskInfo(
    y,
    {
      totalData: {
        totalTasks,
        totalCompletedOneTimeTasks,
        totalCompletedScheduledTasks,
        totalMissedOneTimeTasks,
        totalMissedScheduledTasks,
      },
    },
  ) {
    return (
      <Grid
        container
        direction="column"
        style={{
          marginTop: '16px',
        }}>
        <Grid item style={{ paddingBottom: '10px' }}>
          <Grid container direction="row" style={{ justifyContent: 'space-between' }}>
            <Typography variant="body2" style={{ fontSize: '12px' }}>
              {this.renderLegendCategory('Total No. of Tasks', '#33BD7F')}
            </Typography>
            <Typography variant="body2" style={{ fontSize: '12px' }}>
              {totalTasks}
            </Typography>
          </Grid>
        </Grid>
        <Grid style={{ display: 'flex', alignItems: 'center', paddingBottom: '10px' }}>
          <Grid container direction="row" style={{ justifyContent: 'space-between' }}>
            <Typography variant="body2" style={{ fontSize: '12px' }}>
              {this.renderLegendCategory('Total Completed Schedule Tasks', '#F47D29')}
            </Typography>
            <Typography variant="body2" style={{ fontSize: '12px' }}>
              {totalCompletedScheduledTasks}
            </Typography>
          </Grid>
        </Grid>
        <Grid style={{ display: 'flex', alignItems: 'center', paddingBottom: '10px' }}>
          <Grid container direction="row" style={{ justifyContent: 'space-between' }}>
            <Typography variant="body2" style={{ fontSize: '12px' }}>
              {this.renderLegendCategory('Missed Scheduled Tasks', '#F47D29')}
            </Typography>
            <Typography variant="body2" style={{ fontSize: '12px' }}>
              {totalMissedScheduledTasks}
            </Typography>
          </Grid>
        </Grid>
        <Grid style={{ display: 'flex', alignItems: 'center', paddingBottom: '10px' }}>
          <Grid container direction="row" style={{ justifyContent: 'space-between' }}>
            <Typography variant="body2" style={{ fontSize: '12px' }}>
              {this.renderLegendCategory('Completed One-Time Tasks', '#CB3E2C')}
            </Typography>
            <Typography variant="body2" style={{ fontSize: '12px' }}>
              {totalCompletedOneTimeTasks}
            </Typography>
          </Grid>
        </Grid>
        <Grid style={{ display: 'flex', alignItems: 'center', paddingBottom: '10px' }}>
          <Grid container direction="row" style={{ justifyContent: 'space-between' }}>
            <Typography variant="body2" style={{ fontSize: '12px' }}>
              {this.renderLegendCategory('Missed One-Time Tasks', '#CB3E2C')}
            </Typography>
            <Typography variant="body2" style={{ fontSize: '12px' }}>
              {totalMissedOneTimeTasks}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderTooltip(transformedData) {
    const { hoveredNode } = this.state;
    return (
      <Hint
        align={{ horizontal: 'left' }}
        value={{
          x: hoveredNode.x,
          y: hoveredNode.y,
        }}>
        <Card elevation={8} style={styles.vitalCardTooltip}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{
                  fontSize: '18px',
                  fontWeight: '400',
                  marginBottom: '6px',
                }}>
                {/** Month */}
                {monthsShort[transformedData[hoveredNode.x - 1].month - 1]}
                &nbsp;
                {/** Year */}
                {transformedData[hoveredNode.x - 1].year}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{
                  fontSize: '32px',
                  fontWeight: '200',
                  marginBottom: '6px',
                }}>
                {transformedData[hoveredNode.x - 1].label}
              </Typography>
            </Grid>
            {/** Progress bar to visualise adherence rate */}
            <Grid item xs={12}>
              {/* {this.renderCareTaskInfo(hoveredNode.y, transformedData[hoveredNode.x - 1])} */}
            </Grid>
          </Grid>
        </Card>
      </Hint>
    );
  }

  renderRow = ({ row }, currentKey) => {
    return (
      <Grid key={uuidv4()} container style={{ padding: '16px' }}>
        <Grid style={R.merge(styles.paperBoxShadow, { paddingBottom: '16px', borderRadius: '10px', width: '100%' })}>
          <Grid container direction="row" style={R.merge(styles.patientProgramGridContent, styles.patientProgramGrid2)}>
            <Grid item xs={4} style={{ paddingTop: '24px' }}>
              <Typography style={styles.patientProgramLabel}>Type of Vital Threshold</Typography>
            </Grid>
            <Grid item xs={8} style={styles.patientProgramGrid2}>
              <Grid container direction="row" style={styles.patientProgramGrid2}>
                <Grid item xs={2} />
                <Grid item xs={2} style={styles.patientProgramGrid2}>
                  <Grid container direction="row" style={{ paddingTop: '24px' }}>
                    <Grid
                      style={R.merge(styles.selectionGridThreshold, {
                        background: 'linear-gradient(to bottom, #CB3D2D, #F96844)',
                      })}
                    />
                    <Typography style={styles.patientProgramLabel}>Critically High</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2} style={styles.patientProgramGrid2}>
                  <Grid container direction="row" style={{ paddingTop: '24px' }}>
                    <Grid
                      style={R.merge(styles.selectionGridThreshold, {
                        background: 'linear-gradient(to bottom, #FF7E23, #FDB618)',
                      })}
                    />
                    <Typography style={styles.patientProgramLabel}>High</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2} style={styles.patientProgramGrid2}>
                  <Grid container direction="row" style={{ paddingTop: '24px' }}>
                    <Grid
                      style={R.merge(styles.selectionGridThreshold, {
                        background: 'linear-gradient(to bottom, #33BD7F, #3FD762)',
                      })}
                    />
                    <Typography style={styles.patientProgramLabel}>Normal</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2} style={styles.patientProgramGrid2}>
                  <Grid container direction="row" style={{ paddingTop: '24px' }}>
                    <Grid
                      style={R.merge(styles.selectionGridThreshold, {
                        background: 'linear-gradient(to bottom, #FF7E23, #FDB618)',
                      })}
                    />
                    <Typography style={styles.patientProgramLabel}>Low</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2} style={styles.patientProgramGrid2}>
                  <Grid container direction="row" style={{ paddingTop: '24px' }}>
                    <Grid
                      style={R.merge(styles.selectionGridThreshold, {
                        background: 'linear-gradient(to bottom, #CB3D2D, #F96844)',
                      })}
                    />
                    <Typography style={styles.patientProgramLabel}>Critically Low</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {(row.activities || []).map((activity, activityIndex) => {
            const { threshold } = activity;
            let thresholdItems = Object.keys(threshold).filter((f) => f === currentKey.toLowerCase());
            if (currentKey === 'BG') {
              thresholdItems = Object.keys(threshold).filter((f) => ['bg_pre', 'bg_post'].includes(f));
            } else if (currentKey === 'BP') {
              thresholdItems = Object.keys(threshold).filter((f) => ['sys', 'dia'].includes(f));
            }
            return thresholdItems.map((key, index) => {
              const {
                range,
                vitalDim: { displayName: label, unit },
              } = threshold[key];
              const criticallyLow = range[0];
              const low = range[1];
              const high = range[2];
              const criticallyHigh = range[3];
              let step;

              const newStep =
                label === 'Body Weight' ? (step = 0.01) : label === 'Heart Rate' ? (step = 1) : (step = 0.1);
              const criticallyLowText = criticallyLow
                ? `\u2264 ${normalizeVitalsDecimals(criticallyLow, label)}`
                : 'N.A';
              const criticallyHighText = criticallyHigh
                ? `${normalizeVitalsDecimals(criticallyHigh, label)} \u2264`
                : 'N.A';
              const normalRangeText = `${low ? normalizeVitalsDecimals(low + newStep, label) : '?'} - ${
                high ? normalizeVitalsDecimals(high - newStep, label) : '?'
              }`;
              const lowRangeText = low
                ? criticallyLow
                  ? `${normalizeVitalsDecimals(criticallyLow + newStep, label)} - ${normalizeVitalsDecimals(
                      low,
                      label,
                    )}`
                  : `\u2264 ${normalizeVitalsDecimals(low, label)}`
                : 'N.A';
              const highRangeText = high
                ? criticallyHigh
                  ? `${normalizeVitalsDecimals(high, label)} - ${normalizeVitalsDecimals(
                      criticallyHigh - newStep,
                      label,
                    )}`
                  : `${normalizeVitalsDecimals(high, label)} \u2264`
                : 'N.A';
              return (
                <div key={`${activityIndex}-${index}`}>
                  {/* {index !== 0 && <Divider style={{ width: '100%' }} />} */}
                  <Grid
                    container
                    direction="row"
                    style={R.merge(styles.patientProgramGridContent, styles.patientProgramGrid2)}>
                    <Grid item xs={4}>
                      <Typography style={styles.patientProgramDetails}>
                        {label} ({unit})
                      </Typography>
                    </Grid>
                    <Grid item xs={8} style={styles.patientProgramGrid2}>
                      <Grid container direction="row" style={styles.patientProgramGrid2}>
                        <Grid item xs={2} />
                        <Grid item xs={2} style={styles.patientProgramGrid2}>
                          <Grid container direction="row">
                            <Typography style={styles.patientProgramDetails}>{criticallyHighText}</Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={2} style={styles.patientProgramGrid2}>
                          <Grid container direction="row">
                            <Typography style={styles.patientProgramDetails}>{highRangeText}</Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={2} style={styles.patientProgramGrid2}>
                          <Grid container direction="row">
                            <Typography style={styles.patientProgramDetails}>{normalRangeText}</Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={2} style={styles.patientProgramGrid2}>
                          <Grid container direction="row">
                            <Typography style={styles.patientProgramDetails}>{lowRangeText}</Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={2} style={styles.patientProgramGrid2}>
                          <Grid container direction="row">
                            <Typography style={styles.patientProgramDetails}>{criticallyLowText}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              );
            });
          })}

          <Grid container direction="column">
            <Divider style={{ width: '100%', marginTop: '30px' }} />
            <Grid style={{ padding: '16px', flex: 1 }}>
              <Typography variant="caption" style={styles.patientProgramLabel}>
                Description
              </Typography>
              <Typography variant="body2" style={{ fontSize: '14px' }}>
                {row.description ? commonUtil.truncateWithEllipsis(row.description, 80) : '-'}
              </Typography>
            </Grid>
            <Grid style={{ padding: '16px' }}>
              <Typography variant="caption" style={styles.patientProgramLabel}>
                Created by
              </Typography>
              <Typography variant="body2" style={{ fontSize: '14px' }}>
                {row.updatedByData && row.updatedByData.demographics && row.updatedByData.demographics.firstName
                  ? `${row.updatedByData.demographics.firstName} ${row.updatedByData.demographics.lastName && ''}`
                  : '-'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  noAvailableData() {
    return (
      <Grid style={{ padding: '16px 16px' }}>
        <Typography
          variant="h2"
          style={{
            paddingTop: '6px',
            paddingBottom: '6px',
            backgroundColor: '#FF95000D',
            border: '1px solid #FF950059',
            color: '#FF9500',
            fontSize: '12px',
            borderRadius: '5px',
            letterSpacing: '0.07px',
          }}>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <Grid item xs={1}>
              <InfoOutline
                style={{
                  paddingLeft: '8px',
                  marginTop: '3px',
                }}
              />
            </Grid>
            <Grid
              item
              xs={11}
              style={{
                display: 'inherit',
                fontSize: '14px',
                letterSpacing: '0.07px',
                fontWeight: 'normal',
                marginLeft: '-20px',
              }}>
              No Result for Vitals
            </Grid>
          </Grid>
        </Typography>
      </Grid>
    );
  }

  renderNoData() {
    return (
      <Grid
        style={{
          borderRadius: '0px 0px 5px 5px',
          paddingLeft: '24px',
          paddingTop: '40px',
          paddingBottom: '40px',
          height: '350px',
          paddingRight: '16px',
          flexDirection: 'column',
          width: '100%',
        }}>
        {' '}
        <Typography style={{ textAlign: 'center' }}>
          <img src={EmptyStateReportChart} style={{ width: '200px' }} />
        </Typography>
        <Typography style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>No data available</Typography>
        <Typography style={{ textAlign: 'center', color: '#757575', fontSize: '14px' }}>
          Select a different date range or filter(s)
        </Typography>
      </Grid>
    );
  }

  render() {
    const {
      data: { chartData: data, enrolledProgramData, loading, summaryData, key, selectedView, thresholdSettings },
      selectedEnrolledProgram,
      informationData: { startDate, endDate },
      changeVitalViewType,
      getLatestOTT,
      pdf = false,
      reportType,
      classes: { paper },
      mode,
      isEmbedded = false,
    } = this.props;
    let {
      data: { selectedData },
    } = this.props;
    const { list, listProgram } = this.state;

    const disablePopup = this.getDisablePopup();
    const disablePopupReset = this.getDisablePopupReset();

    const programmeList = [];
    for (const item of selectedEnrolledProgram) {
      let rate = 0;
      const getEPAlerts = enrolledProgramData.find((f) => f.epId === item.id);
      if (getEPAlerts) {
        rate = getEPAlerts.total;
      }
      programmeList.push({
        name: item.title,
        rate,
        value: item.id,
        label: item.title,
      });
    }

    const vitalDims = [];
    const chartData = [];
    let title = VITAL_NAME_MAPPING[key];
    let interpretationText = '';
    if (selectedData) {
      const activity = (selectedData.activities || []).find(
        (f) =>
          f.vitalsDataForActivity.vitalGroup.name === key ||
          (f.vitalsDataForActivity.vitalGroup.name == 'BP_HR' && ['BP', 'HR'].includes(key)) ||
          (f.vitalsDataForActivity.vitalGroup.name == 'PO_HR' && ['PO', 'HR'].includes(key)) ||
          (['BP', 'HR', 'PO', 'BTM'].includes(key) && f.vitalsDataForActivity.vitalGroup.name === 'HILLROM_WACSM') ||
          (['BP', 'HR', 'PO', 'BTM', 'RR'].includes(key) &&
            f.vitalsDataForActivity.vitalGroup.name === 'HILLROM_WACSM_RR'),
      );
      if (activity) {
        const {
          vitalsDataForActivity: {
            vitalGroup: { displayName },
          },
          vitalGroupId,
        } = activity;
        let step = 1;
        let unit = '';
        if (key === 'BG') {
          const {
            threshold: {
              bg_pre: {
                vitalDim: { unit: vitalUnit },
                range: bgPreRange,
              },
              bg_post: { range: bgPostRange },
            },
          } = activity;
          interpretationText = JSON.stringify(bgPreRange);
          step = 0.1;
          unit = vitalUnit;
          // vital dims
          vitalDims.push({ label: displayName, name: key.toLowerCase(), unit: unit, step: step });
        } else if (key === 'HR') {
          const {
            threshold: {
              hr: {
                vitalDim: { unit: vitalUnit },
                range,
              },
            },
          } = activity;
          interpretationText = JSON.stringify(range);
          step = 1;
          unit = vitalUnit;
          // vital dims
          vitalDims.push({ label: displayName, name: key.toLowerCase(), unit: unit, step: step });
        } else if (key === 'PO') {
          const {
            threshold: {
              po: {
                vitalDim: { unit: vitalUnit },
                range,
              },
            },
          } = activity;
          interpretationText = JSON.stringify(range);
          step = 1;
          unit = vitalUnit;
          // vital dims
          vitalDims.push({ label: displayName, name: key.toLowerCase(), unit: unit, step: step });
        } else if (key === 'HS') {
          const {
            threshold: {
              hs: {
                vitalDim: { unit: vitalUnit },
                range,
              },
            },
          } = activity;
          interpretationText = JSON.stringify(range);
          step = 1;
          unit = vitalUnit;
          // vital dims
          vitalDims.push({ label: displayName, name: key.toLowerCase(), unit: unit, step: step });
        } else if (key === 'BP') {
          const {
            threshold: {
              sys: {
                vitalDim: { unit: sysUnit, displayName: sysDisplayName },
                range: sysRange = [],
              },
              dia: {
                vitalDim: { unit: diaUnit, displayName: diaDisplayName },
                range: diaRange = [],
              },
            },
          } = activity;
          interpretationText = [JSON.stringify(sysRange), JSON.stringify(diaRange)];
          step = 1;
          // vital dims
          vitalDims.push({ label: sysDisplayName, name: 'sys', unit: sysUnit, step: step });
          vitalDims.push({ label: diaDisplayName, name: 'dia', unit: diaUnit, step: step });
        } else if (key === 'ECG') {
          // ECG
        } else if (key === 'BTM') {
          const {
            threshold: {
              btm: {
                vitalDim: { unit: vitalUnit },
                range,
              },
            },
          } = activity;
          interpretationText = JSON.stringify(range);
          step = 1;
          unit = vitalUnit;
          // vital dims
          vitalDims.push({ label: displayName, name: key.toLowerCase(), unit: unit, step: step });
        } else if (key === 'RR') {
          const {
            threshold: {
              rr: {
                vitalDim: { unit: vitalUnit },
                range,
              },
            },
          } = activity;
          interpretationText = JSON.stringify(range);
          step = 1;
          unit = vitalUnit;
          // vital dims
          vitalDims.push({ label: displayName, name: key.toLowerCase(), unit: unit, step: step });
        }
        // chart data

        const patientId = +this.props.match.params.patientId;

        for (const item of data) {
          const quantityValue = item.average.measurementAverage || 0;
          const yValue = item.average.measurementAverage || 0;
          const interpretationData =
            key !== 'BP'
              ? {
                  interpretation: {
                    text: interpretationText,
                    // needed
                    coding: [
                      {
                        system: 'http://hl7.org/fhir/v2/0078',
                        code: 'N',
                        display: 'Normal',
                      },
                    ],
                  },
                }
              : {
                  component: [
                    {
                      code: {
                        coding: [
                          {
                            system: '',
                            code: '8480-6',
                            display: 'Systolic Blood pressure',
                          },
                        ],
                        text: 'sys',
                      },
                      interpretation: {
                        text: interpretationText[0],
                      },
                    },
                    {
                      code: {
                        coding: [
                          {
                            system: '',
                            code: '8462-4',
                            display: 'Diastolic Blood pressure',
                          },
                        ],
                        text: 'dia',
                      },
                      interpretation: {
                        text: interpretationText[1],
                      },
                    },
                  ],
                };
          chartData.push({
            ...item,
            yValue,
            month: item.reportDate.month,
            year: item.reportDate.year,
            display: item.reportDate.display,
            _id: item.id,
            vitals: [key],
            enrolledProgram: { id: item.id, title: item.title, vitalGroupId },
            patient: {
              id: patientId,
            },
            valueQuantity: {
              value: quantityValue,
              system: '',
              unit: item.average.unit,
            },
            dataAbsentReason: item.average.measurementAverage ? null : 'Not Performed',
            ...interpretationData,
          });
        }
      }
    } else {
      selectedData = {};
    }

    let dateRange = [
      moment(startDate)
        .startOf('day')
        .valueOf(),
      moment(endDate)
        .endOf('day')
        .valueOf(),
    ];
    if (chartData.length > 0) {
      dateRange = [];
      let item = chartData[0];
      const start = parseInt(`${item.reportDate.year}${('00' + item.reportDate.month).slice(-2)}`);
      dateRange.push(start);
      item = chartData[0];
      const end = parseInt(`${item.reportDate.year}${('00' + item.reportDate.month).slice(-2)}`);
      dateRange.push(end);
    }

    // Make domain 1 point larger than data set so last data point will not hit edge of graph
    const xDomain = [0, chartData.length + 1];
    const xTicks = [];
    for (let i = 0; i < chartData.length; i++) {
      xTicks.push(i + 1);
    }

    const showEmptyState = !loading ? this.renderNoData() : <Grid style={{ height: '200px' }} />;

    if (title === 'Weight') title = 'Body Weight';

    const chartHasData = chartData.findIndex((f) => f.vitalChartEnrolledProgramData.length > 0) >= 0;

    return (
      <Grid container style={{ width: '100%' }}>
        <Card style={{ background: '#212121', width: '100%', height: '56px', borderRadius: 0 }}>
          <CardContent>
            <Grid container>
              <Grid item xs={10}>
                <Typography variant="body1" style={{ color: '#fff', fontSize: '18px', marginTop: '2px' }}>
                  {title}
                </Typography>
              </Grid>
              {!pdf && (
                <ThemeProvider theme={selectStyle}>
                  <Grid item xs={2} style={{ borderRadius: '4px', backgroundColor: 'white' }}>
                    <Select
                      disabled={mode === 'view' ? true : false}
                      value={selectedView}
                      placeholder="Line Chart"
                      onChange={(e) => changeVitalViewType(e.target.value, selectedData, key, thresholdSettings)}>
                      {chartList.map((p) => (
                        <MenuItem value={p.value} key={p.value}>
                          <Typography style={styles.menuItemTypograph}>{p.label}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </ThemeProvider>
              )}
            </Grid>
          </CardContent>
        </Card>

        <Grid style={{ marginBottom: 75 }} />
        {selectedView === 'LINE_CHART' ? (
          <Grid container>
            <Grid item xs={12} style={{ marginTop: 20 }}>
              {this.renderSummaryCard(summaryData)}
            </Grid>
            <Grid style={{ paddingLeft: '20px', width: '95%' }}>
              <Button
                disabled={mode === 'view' ? true : false}
                onClick={() =>
                  !!selectedData && Object.keys(selectedData).length > 0 && this.setState({ showDialog: true })
                }
                variant="outlined"
                style={{ width: '100%', justifyContent: 'space-between' }}>
                <Grid container direction="row">
                  <VitalToolTip
                    placement="bottom-start"
                    title={
                      <Typography style={{ fontSize: '14px', fontWeight: '400' }} color="inherit">
                        Select different threshold settings for different Programme(s) to support your meaningful
                        comparisons.
                      </Typography>
                    }>
                    <InfoOutline style={{ color: '#9E9E9E', marginLeft: '-5px' }} />
                  </VitalToolTip>
                  <Typography style={{ paddingLeft: 8, paddingTop: 2 }}>
                    Vital threshold settings based on: {selectedData.title}
                  </Typography>
                </Grid>
                <ArrowDropDown />
              </Button>
            </Grid>
            {loading && (
              <CircularProgress
                style={{
                  width: '80px',
                  height: '80px',
                  position: 'absolute',
                  left: '28.5%',
                  zIndex: '9',
                  padding: '280px',
                  color: '#ff5000',
                }}
              />
            )}
            {chartData.length > 0 && chartHasData ? (
              <VitalChart
                isEmbedded={isEmbedded}
                title={title}
                xTicks={xTicks}
                vitalDims={vitalDims}
                data={chartData}
                dateRange={xDomain}
                name={'Hello'}
                pdf={pdf}
                unit={summaryData.average ? summaryData.average.unit : ''}
              />
            ) : (
              showEmptyState
            )}
            <Grid
              container
              style={{
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
                margin: 0,
                paddingLeft: '200px',
                paddingRight: '200px',
                width: '100%',
              }}>
              {/* <Typography style={styles.typography1}>
              <span style={styles.circle} /> {title === 'Blood Pressure' ? 'Systolic' : title}
            </Typography>
            {title === 'Blood Pressure' && (
              <Typography style={styles.typography1}>
                <span style={{
                  height: '11px',
                  width: '11px',
                  backgroundColor: '#B0BAE0',
                  clipPath: 'polygon(50% 0, 100% 50%, 50% 100%, 0 50%)',
                  display: 'inline-block',
                }} /> Diastolic
              </Typography>
            )} */}
              <Typography style={styles.typography1}>
                <span
                  style={{
                    height: '10px',
                    width: '10px',
                    background: 'linear-gradient(to bottom, #FF3333, #CC3333)',
                    borderRadius: '50%',
                    display: 'inline-block',
                  }}
                />{' '}
                Critically Low/High
              </Typography>
              <Typography style={styles.typography1}>
                <span
                  style={{
                    height: '10px',
                    width: '10px',
                    background: 'linear-gradient(to bottom, #38EF66, #33CC99)',
                    borderRadius: '50%',
                    display: 'inline-block',
                  }}
                />{' '}
                Normal
              </Typography>
              <Typography style={styles.typography1}>
                <span
                  style={{
                    height: '10px',
                    width: '10px',
                    background: 'linear-gradient(to bottom, #FFCC33, #FF8330)',
                    borderRadius: '50%',
                    display: 'inline-block',
                  }}
                />{' '}
                Low/High
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <div style={{ width: '100%' }}>
            {loading && (
              <CircularProgress
                style={{
                  width: '80px',
                  height: '80px',
                  position: 'absolute',
                  left: '37.5%',
                  zIndex: '9',
                  padding: '130px',
                  color: '#ff5000',
                }}
              />
            )}{' '}
            <MiloTable
              header={{
                components: [
                  {
                    id: 0,
                    component: () => (
                      <Grid style={styles.dataViewSelect}>
                        {programmeList.length > 0 && (
                          <Select
                            value={selectedData.id}
                            placeholder="Enrolled Programme"
                            onChange={(e) => {
                              const curSelectedProgramme = selectedEnrolledProgram.find((f) => f.id === e.target.value);
                              changeVitalViewType(selectedView, curSelectedProgramme, key, thresholdSettings);
                            }}>
                            {programmeList.map((p) => (
                              <MenuItem value={p.value} key={p.value}>
                                <Typography style={styles.menuItemTypographData}>{p.label}</Typography>
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </Grid>
                    ),
                  },
                ],
              }}
              showHeaderDivider={true}
              paperStyle={R.merge(styles.paperBoxShadow, {
                borderRadius: 5,
                margin: '24px',
                padding: 0,
              })}
              content={{
                rows: list,
                columns: [
                  {
                    name: 'date',
                    title: 'Date',
                    getCellValue: (item) => {
                      return (
                        <div>
                          <span>{item.date}</span>
                        </div>
                      );
                    },
                  },
                  {
                    name: 'measurementAverage',
                    title: 'Measurement Average',
                    getCellValue: (item) => {
                      return <span>{item.adherenceRate}</span>;
                    },
                  },
                ],
                columnExtensions: [
                  { columnName: 'date', width: 400 },
                  { columnName: 'measurementAverage', width: null },
                ],
              }}
              options={{
                pagination: {
                  currentPage: this.state.currentPage,
                  pageSize: this.state.pageSize,
                  totalCount: this.state.totalCount,
                  handleCurrentPageChange: this.changeCurrentPage,
                },
              }}
              advanced={{
                tableProps: {
                  messages: {
                    noData: !loading ? (
                      <Grid
                        style={{
                          borderRadius: '0px 0px 5px 5px',
                          paddingLeft: '24px',
                          paddingTop: '40px',
                          paddingBottom: '40px',
                          height: '350px',
                          paddingRight: '16px',
                          flexDirection: 'column',
                        }}>
                        {' '}
                        <Typography style={{ textAlign: 'center' }}>
                          <img src={EmptyStateReportChart} style={{ width: '200px' }} />
                        </Typography>
                        <Typography style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>
                          No data available
                        </Typography>
                        <Typography style={{ textAlign: 'center', color: '#757575', fontSize: '14px' }}>
                          Select a different date range or filter(s)
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid style={{ heigth: '100px' }} />
                    ),
                  },
                },
              }}
            />
          </div>
        )}

        {pdf ? null : (
          <Grid style={{ padding: 20, width: '100%' }}>
            {reportType === 'INDIVDUAL_PARTCIPANT' && (
              <Card
                style={R.merge(styles.paperBoxShadow, {
                  paddingBottom: '16px',
                })}>
                <CardContent style={{ padding: 0 }}>
                  <Grid
                    container
                    direction="row"
                    style={{ paddingTop: 16, paddingBottom: 16, paddingLeft: 24, paddingRight: 24 }}>
                    <Grid item md={8}>
                      <Typography variant="caption">Programme</Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Typography variant="caption">Total Number of Results</Typography>
                    </Grid>
                  </Grid>
                  {programmeList.length >= 0 &&
                    programmeList.map((item, index) => {
                      return (
                        <div key={index}>
                          <Grid
                            container
                            direction="row"
                            style={{ paddingTop: 16, paddingBottom: 16, paddingLeft: 24, paddingRight: 24 }}>
                            <Grid item md={8}>
                              <Typography variant="body1">{commonUtil.truncateWithEllipsis(item.name)}</Typography>
                            </Grid>
                            <Grid item md={4}>
                              <Typography variant="body1">{item.rate}</Typography>
                            </Grid>
                          </Grid>
                          {index < programmeList.length - 1 && <Divider style={{ marginBottom: 5 }} />}
                        </div>
                      );
                    })}
                  {programmeList.length === 0 && this.noAvailableData()}
                </CardContent>
              </Card>
            )}

            {reportType === 'OVERALL_PARTICIPANT' &&
              listProgram.length === 0 && (
                <Card style={R.merge(styles.paperBoxShadow, { paddingBottom: '16px', margin: '24px' })}>
                  <CardContent style={{ padding: 0 }}>
                    <Grid
                      container
                      direction="row"
                      style={{ paddingTop: 16, paddingBottom: 16, paddingLeft: 24, paddingRight: 24 }}>
                      <Grid item md={8}>
                        <Typography variant="caption">Programme</Typography>
                      </Grid>
                      <Grid item md={4}>
                        <Typography variant="caption">Total Number of Results</Typography>
                      </Grid>
                    </Grid>
                    <Grid style={{ padding: '16px 16px' }}>{this.noAvailableData()}</Grid>
                  </CardContent>
                </Card>
              )}
            {reportType === 'OVERALL_PARTICIPANT' &&
              programmeList.length > 0 && (
                <MiloTable
                  paperStyle={R.merge(styles.paperBoxShadow, { borderRadius: 5, margin: '24px', padding: 0 })}
                  content={{
                    rows: listProgram,
                    columns: [
                      {
                        name: 'name',
                        title: 'Programme',
                        getCellValue: (item) => {
                          return (
                            <div>
                              <span>{commonUtil.truncateWithEllipsis(item.name)}</span>
                            </div>
                          );
                        },
                      },
                      {
                        name: 'rate',
                        title: 'Total Number of Results',
                        getCellValue: (item) => {
                          return <span>{item.rate}</span>;
                        },
                      },
                    ],
                    columnExtensions: [{ columnName: 'name', width: 400 }, { columnName: 'rate', width: null }],
                  }}
                  options={{
                    pagination: {
                      currentPage: this.state.currentPageProgram,
                      pageSize: this.state.pageSizeProgram,
                      totalCount: this.state.totalCountProgram,
                      handleCurrentPageChange: this.changeCurrentPageProgram,
                    },
                  }}
                  advanced={{
                    tableProps: {
                      messages: {
                        noData: (
                          <Grid>
                            <Typography variant="body1" style={styles.advancePropsTypograph}>
                              No Data
                            </Typography>
                          </Grid>
                        ),
                      },
                    },
                  }}
                />
              )}
          </Grid>
        )}

        <ThemeProvider theme={dialogTheme}>
          <Dialog
            classes={{ paper }}
            open={this.state.showDialog}
            onClose={() => {
              const { dialogData } = this.state;
              if (dialogData.dialogValueSelected === 'SCHEDULED') {
                const curSelectedProgramme = selectedEnrolledProgram.find((f) => f.id === selectedData.id);
                changeVitalViewType(selectedView, curSelectedProgramme, key, 'SCHEDULED', false);
              } else {
                getLatestOTT(selectedData, key, false);
              }
              dialogData.dialogProgrammeId = dialogData.dialogProgrammeIdSelected;
              dialogData.dialogValue = dialogData.dialogValueSelected;
              this.setState({ showDialog: false, dialogData });
            }}>
            <DialogTitle
              id="ihealth-customer-care-info"
              style={{ borderBottom: '1px solid rgb(240, 240, 240)', paddingBottom: '16px' }}>
              <Grid container direction="row">
                <Grid item xs={10} style={{ display: 'flex', paddingLeft: '7px' }}>
                  <VitalToolTip
                    placement="bottom-start"
                    title={
                      <Typography style={{ fontSize: '14px', fontWeight: '400' }} color="inherit">
                        Select different threshold settings for different Programme(s) to support your meaningful
                        comparisons.
                      </Typography>
                    }>
                    <InfoOutline style={{ color: '#9E9E9E', paddingRight: 10, paddingTop: 5 }} />
                  </VitalToolTip>
                  <Typography variant="h6">Threshold Settings: {title}</Typography>
                </Grid>
                <Divider />
                <Grid item xs={2}>
                  <Fab
                    style={styles.fabBtn}
                    onClick={() => {
                      const { dialogData } = this.state;
                      if (dialogData.dialogValueSelected === 'SCHEDULED') {
                        const curSelectedProgramme = selectedEnrolledProgram.find((f) => f.id === selectedData.id);
                        changeVitalViewType(selectedView, curSelectedProgramme, key, 'SCHEDULED', false);
                      } else {
                        getLatestOTT(selectedData, key, false);
                      }
                      dialogData.dialogProgrammeId = dialogData.dialogProgrammeIdSelected;
                      dialogData.dialogValue = dialogData.dialogValueSelected;
                      this.setState({ showDialog: false, dialogData });
                    }}
                    aria-label="Add"
                    className={close}>
                    <Icon fontSize="inherit" style={styles.iconClose}>
                      close
                    </Icon>
                  </Fab>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Grid
                container
                style={{ paddingTop: '24px', paddingLeft: '32px', paddingRight: '32px', paddingBottom: '24px' }}>
                <Typography>
                  There might be multiple Programme(s) with different threshold settings prescribed to Participants.
                  One-Time Task(s) might have their own threshold setting(s) too. You may select which threshold setting
                  to apply in this report for your meaningful comparisons. Only one setting can be selected at any time.
                </Typography>
              </Grid>
              <Grid
                container
                direction="row"
                style={{
                  paddingTop: '24px',
                  paddingLeft: '32px',
                  paddingRight: '32px',
                  paddingBottom: '24px',
                  width: '100%',
                }}>
                <Grid item xs={6} style={{ paddingRight: '10px' }}>
                  <Typography
                    variant="caption"
                    style={{
                      fontSize: '12px',
                      fontWeight: '400',
                      paddingLeft: '10px',
                      color: this.state.epSelectActive ? '#3f51b5' : '#9E9E9E',
                    }}>
                    Programme(s)<sup>*</sup>
                  </Typography>
                  <Select
                    style={{ width: '100%' }}
                    value={this.state.dialogData.dialogProgrammeId}
                    placeholder="Programme(s)*"
                    renderValue={(val) => {
                      const data = programmeList.find((f) => f.value === val);
                      return (
                        <Typography style={styles.menuItemTypograph}>
                          {commonUtil.truncateWithEllipsis(data.label, 44)}
                        </Typography>
                      );
                    }}
                    onChange={(e) => {
                      this.setState((prevState) => {
                        const dialogData = Object.assign({}, prevState.dialogData);
                        dialogData.dialogProgrammeId = e.target.value;
                        return { dialogData };
                      });
                    }}
                    onOpen={() =>
                      this.setState({
                        epSelectActive: true,
                      })
                    }
                    onBlur={() => this.setState({ epSelectActive: false })}>
                    {programmeList.map((p) => (
                      <MenuItem value={p.value} key={p.value}>
                        <Grid container direction="row" style={{ justifyContent: 'space-between' }}>
                          <Typography style={styles.menuItemTypograph}>{p.label}</Typography>
                          {this.state.dialogData.dialogProgrammeId === p.value && (
                            <Check style={{ color: '#3fd762', marginTop: '-5px' }} />
                          )}
                        </Grid>
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="caption"
                    style={{
                      fontSize: '12px',
                      color: this.state.thresholdSelectActive ? '#3f51b5' : '#9E9E9E',
                      fontWeight: '400',
                      paddingLeft: '10px',
                    }}>
                    Threshold Settings<sup>*</sup>
                  </Typography>
                  <Select
                    style={{ width: '100%' }}
                    value={this.state.dialogData.dialogValue}
                    placeholder="Threshold Settings*"
                    renderValue={(val) => {
                      const data = thresholdSettingsData.find((f) => f.value === val);
                      return <Typography style={styles.menuItemTypograph}>{data.label}</Typography>;
                    }}
                    onChange={(e) => {
                      if (e.target.value === 'SCHEDULED') {
                        const curSelectedProgramme = selectedEnrolledProgram.find((f) => f.id === selectedData.id);
                        changeVitalViewType(selectedView, curSelectedProgramme, key, 'SCHEDULED', false);
                      } else {
                        getLatestOTT(selectedData, key, false);
                      }
                      this.setState((prevState) => {
                        const dialogData = Object.assign({}, prevState.dialogData);
                        dialogData.dialogValue = e.target.value;
                        return { dialogData };
                      });
                    }}
                    onOpen={() =>
                      this.setState({
                        thresholdSelectActive: true,
                      })
                    }
                    onBlur={() => this.setState({ thresholdSelectActive: false })}>
                    {thresholdSettingsData.map((p) => {
                      return (
                        <MenuItem value={p.value} key={p.value}>
                          <Grid container direction="row" justify="space-between">
                            <Typography style={styles.menuItemTypograph}>{p.label}</Typography>
                            {this.state.dialogData.dialogValue === p.value && (
                              <Check style={{ color: '#3fd762', marginTop: '-5px' }} />
                            )}
                          </Grid>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              </Grid>
              <Grid
                container
                style={{ paddingTop: '24px', paddingLeft: '32px', paddingRight: '32px', paddingBottom: '24px' }}>
                {this.renderRow(
                  {
                    row:
                      selectedEnrolledProgram.find((f) => f.id === this.state.dialogData.dialogProgrammeId) ||
                      selectedData,
                  },
                  key,
                )}
              </Grid>
            </DialogContent>
            <DialogActions style={styles.dialogAction}>
              <Button
                style={{ width: '180px' }}
                variant="contained"
                className="milo-btn-white"
                disabled={disablePopupReset}
                onClick={() => {
                  let { dialogData } = this.state;
                  const {
                    mode,
                    data: { key },
                    reportData,
                  } = this.props;
                  const curSelectedProgramme = selectedEnrolledProgram[0];
                  if (mode === 'create') {
                    // changeVitalViewType(selectedView, curSelectedProgramme, key, 'SCHEDULED', false);
                    dialogData = {
                      ...dialogData,
                      dialogProgrammeId: curSelectedProgramme.id,
                      dialogValue: 'SCHEDULED',
                    };
                  } else {
                    let defaultId = 0;
                    let defaultValue = 'SCHEDULED';
                    const module = reportData.modules.find((f) => f.key === 'vitals');
                    if (module) {
                      const vital = module.components.find((f) => f.key === key);
                      if (vital) {
                        defaultId = vital.thresholdEpId;
                        if (vital.thresholdSettings) defaultValue = vital.thresholdSettings;
                      }
                    }
                    // reset();
                    dialogData = {
                      ...dialogData,
                      dialogProgrammeId: defaultId,
                      dialogValue: defaultValue,
                    };
                  }
                  this.setState({ dialogData });
                }}>
                Reset
              </Button>
              <Button
                className="milo-btn-orange"
                style={{ width: '180px' }}
                variant="contained"
                disabled={disablePopup}
                onClick={() => {
                  const { dialogData } = this.state;
                  if (dialogData.dialogValue === 'SCHEDULED') {
                    const curSelectedProgramme = selectedEnrolledProgram.find(
                      (f) => f.id === dialogData.dialogProgrammeId,
                    );
                    changeVitalViewType(selectedView, curSelectedProgramme, key, 'SCHEDULED');
                  } else {
                    // One time
                    getLatestOTT(selectedData, key);
                  }
                  dialogData.dialogValueSelected = dialogData.dialogValue;
                  this.setState({ showDialog: false, dialogData });
                }}>
                Apply
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </Grid>
    );
  }
}
