import * as R from 'ramda';
import { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { withResource, resourceTypes, gettersOf } from 'dataModule/store/resources';
import { actionCreators as messageActionCreators } from 'appModule/message/ducks/message';
import { Loading } from 'utilsModule/components';
import SelectCountry from '../components/SelectCountry';

@withResource([
  { resourceType: resourceTypes.COUNTRIES, method: 'retrieveMany', options: { runOnDidMount: true } },
])
@connect(null, { notify: messageActionCreators.show })
@withRouter
class SelectCountryContainer extends PureComponent {
  selectCountry = ({ country: { value = '' } = {} }) => {
    const countryList = gettersOf(resourceTypes.COUNTRIES).getCountries(this.props.data, false);
    const selectedCountry = countryList.filter(c => c.value === value);
    const { location: { query: { type } = {} } = {} } = this.props;

    if (selectedCountry.length >= 1) {
      if (type === 'business') {
        window.location.href = `${selectedCountry[0].loginUrl}/covid-19-business-reg?country=${value}`;
      } else if (type === 'individual') {
        window.location.href = `${selectedCountry[0].loginUrl}/covid-19-individual-reg?country=${value}`;
      }
    } else {
      this.props.notify({ message: 'The selected country is not supported.', type: 'error' });
    }
  }

  render() {
    const { data } = this.props;
    if (data.status.loading) return <Loading />;

    // Sanitize data
    const countryList = gettersOf(resourceTypes.COUNTRIES).getStatus('retrieveMany')(data, false).success
      ? gettersOf(resourceTypes.COUNTRIES).getCountries(data, false)
      : [];

    // console.log('%crender SelectCountryContainer', 'font-size: 12px; color: #00b3b3', this.props);

    if (!this.inited) {
      this.initialValues = {
        country: {
          options: R.map(R.pick(['id', 'value', 'label']), countryList),
        },
      };

      this.inited = true;
    }

    return <SelectCountry
      initialValues={this.initialValues}
      runtimeProps={this.runtimeProps}
      handleSubmit={this.selectCountry}
    />;
  }
}

export default SelectCountryContainer;
