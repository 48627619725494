import React from 'react';
import { withResource, resourceTypes } from 'dataModule/store/resources';
import { PrivateRoute, withLoading } from 'utilsModule/components';
import * as R from 'ramda';
import LoadableApp from './loadables';

@withResource([
  {
    resourceType: resourceTypes.USERS,
    method: 'retrieveCurrent',
    options: { runOnDidMount: true },
  },
])
@withLoading()
class PrivilegedApp extends React.PureComponent {
  render() {
    const passedThroughProps = R.omit(['data', 'actionCreators'])(this.props);
    return <PrivateRoute component={LoadableApp} {...passedThroughProps} />;
  }
}

export default PrivilegedApp;
