import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import moment from 'moment';
import * as R from 'ramda';
import update from 'immutability-helper';
import commonUtil from 'utilsModule/common';
import { withResource, resourceTypes, gettersOf } from 'dataModule/store/resources';
import { actionCreators as messageActionCreators } from 'appModule/message/ducks/message';
import { findBy, updateEnvConfig } from 'utilsModule';
import { OTP } from '../components';

const OTP_TYPES = {
  AUTH_TFA: 'AUTH_TFA',
  ONBOARD_INVITATION: 'ONBOARD_INVITATION',
  ONBOARD_REGISTER: 'ONBOARD_REGISTER',
  ONBOARD_TFA: 'ONBOARD_TFA',
};

const otpData = {
  [OTP_TYPES.ONBOARD_TFA]: {
    title: 'OTP Verification',
    codeName: 'OTP',
  },
  [OTP_TYPES.ONBOARD_INVITATION]: {
    title: 'Registration',
    codeName: 'invitation code',
    buttonName: 'Verify Invitation Code',
    textForm: 'If you require a new invitation code, please click on your e-mail link again',
  },
  [OTP_TYPES.ONBOARD_REGISTER]: {
    title: 'OTP Verification',
    codeName: 'OTP',
  },
  [OTP_TYPES.ONBOARD_TFA]: {
    title: 'OTP Verification',
    codeName: 'OTP',
  },
};

@withResource([{ resourceType: resourceTypes.USERS }])
@connect(null, { notify: messageActionCreators.show })
@withRouter
class OTPContainer extends PureComponent {
  static propTypes = {};

  state = {
    maintenanceMessage: null,
  };

  async UNSAFE_componentWillMount() {
    await this.fetchMaintenanceData();
    const { location: { query: { api_url: API_URL, gothumb_url: GOTHUMB_URL } } } = this.props;

    if (!API_URL || !GOTHUMB_URL) return;
    updateEnvConfig(API_URL, GOTHUMB_URL);
  }

  componentDidMount() {
    this.mounted = true;
    const { match: { params: { type } = {} } } = this.props;
    type === OTP_TYPES.ONBOARD_INVITATION && this.sendOTP();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  sendOTP = () => {
    const {
      match: { params: { type } = {} },
      location: { state },
      data, actionCreators, notify,
    } = this.props;

    let method;
    let credentials;
    let codeName;
    switch (type) {
      case OTP_TYPES.AUTH_TFA: {
        const { loginId, password } = state;
        method = 'request2FA';
        credentials = { loginId, password };
        codeName = 'OTP';
        break;
      }
      case OTP_TYPES.ONBOARD_INVITATION: {
        const invitationToken = gettersOf(resourceTypes.USERS).getInvitationToken()(data);
        method = 'requestInvitationCode';
        credentials = { invitationToken };
        codeName = 'Invitation Code';
        break;
      }
      case OTP_TYPES.ONBOARD_TFA: {
        const invitationToken = gettersOf(resourceTypes.USERS).getInvitationToken()(data);
        const { username, password } = state;
        method = 'request2FAForOnboard';
        credentials = { invitationToken, username, password };
        codeName = 'OTP';
        break;
      }
      case OTP_TYPES.ONBOARD_REGISTER: {
        const invitationToken = gettersOf(resourceTypes.USERS).getInvitationToken()(data);
        const { countryCode, value } = gettersOf(resourceTypes.USERS).getOnboardMobile()(data);
        const username = `${countryCode} ${value}`;
        const { password } = state;
        method = 'request2FAForOnboard';
        credentials = { invitationToken, username, password };
        codeName = 'OTP';
        break;
      }
      default:
        if (process.env.DEBUG) throw new TypeError(`Unexpected OTP type ${type}`);
    }

    actionCreators[resourceTypes.USERS].ajax({
      cargo: { method, input: { content: credentials } },
      onSuccess: () => {
        notify({ message: `${codeName} sent successfully `, type: 'success', customClass : ['expanded'] });
      },
      onFailure: ({ error }) => {
        if (this.mounted) {
          const reportedError = JSON.stringify(error, ['code', 'message']);
          notify({ message: `Failed to request ${codeName}: ${reportedError}`, type: 'error', customClass : ['expanded'] });
        }
      },
    });
  }

  verifyOTP = ({ otp }) => {
    const {
      match: { params: { type } = {} },
      location: { state: { entryLocation } = {}, query: { token } = {} },
      history,
      data, actionCreators, notify,
    } = this.props;

    return new Promise((resolve, reject) => {
      let method;
      let credentials;
      let next;
      switch (type) {
        case OTP_TYPES.AUTH_TFA: {
          method = 'verify2FA';
          credentials = {
            token: gettersOf(resourceTypes.USERS).get2FAToken()(data) || token,
            code: R.pipe(R.values, R.join(''))(otp),
          };
          next = (verify2FAData) => {
            const { sessionId, profiles = [] } = R.path(['verify2FA'], verify2FAData);
            // NOTE: This is necessary for SelectProfile to query BE
            sessionStorage.setItem('session-token', sessionId);
            history.push('/auth/selectProfile', { profiles, entryLocation });
          };
          break;
        }
        case OTP_TYPES.ONBOARD_INVITATION: {
          method = 'verifyInvitationCode';
          credentials = {
            invitationToken: gettersOf(resourceTypes.USERS).getInvitationToken()(data),
            invitationCode: R.pipe(R.values, R.join(''))(otp),
          };
          next = (verifyInvitationCodeData) => {
            const { nextAction } = R.path(['verifyInvitationCode'], verifyInvitationCodeData);
            if (!['REGISTER', 'LOGIN'].includes(nextAction)) throw new TypeError(`Unexpected next action ${nextAction}`);
            (nextAction === 'REGISTER') && history.push('/onboard/setPassword');
            // (nextAction === 'LOGIN') && history.push('/onboard/accountRegistered');
            (nextAction === 'LOGIN') && history.push('/auth/login/ONBOARD');
          };
          break;
        }
        case OTP_TYPES.ONBOARD_TFA: {
          method = 'verify2FAForOnboard';
          credentials = {
            otpToken: gettersOf(resourceTypes.USERS).getOtpToken('request2FAForOnboard')()(data),
            otp: R.pipe(R.values, R.join(''))(otp),
          };
          next = (verify2FAForOnboardData) => {
            const { sessionId } = R.path(['verify2FAForOnboard'], verify2FAForOnboardData);
            // NOTE: This is necessary for UpdateDemographic to query BE
            sessionStorage.setItem('session-token', sessionId);
            history.push('/onboard/updateDemographic');
          };
          break;
        }
        case OTP_TYPES.ONBOARD_REGISTER: {
          method = 'verify2FAForOnboard';
          credentials = {
            otpToken: gettersOf(resourceTypes.USERS).getOtpToken('request2FAForOnboard')()(data) ||
              gettersOf(resourceTypes.USERS).getOtpToken('registerAuth')()(data),
            otp: R.pipe(R.values, R.join(''))(otp),
          };
          next = (verify2FAForOnboardData) => {
            const { sessionId, profile: { id: profileId } = {}, profiles = [] } = R.path(['verify2FAForOnboard'], verify2FAForOnboardData);
            // NOTE: This is necessary for SelectProfile to query BE
            sessionStorage.setItem('session-token', sessionId);
            history.push('/auth/selectProfile', { profiles: [findBy('id', profileId, profiles)] });
          };
          break;
        }
        default:
          if (process.env.DEBUG) throw new TypeError(`Unknown OTP type ${type}`);
      }

      actionCreators[resourceTypes.USERS].ajax({
        cargo: { method, input: { content: credentials } },
        onSuccess: ({ data: result }) => {
          if (this.mounted) {
            resolve(result);
            next(result);
          }
        },
        onFailure: ({ error }) => {
          if (this.mounted) {
            const reportedError = JSON.stringify(error, ['message']);
            reject(new SubmissionError({ _error: reportedError }));
            notify({ message: error.message, type: 'error', customClass : ['expanded'] });
          }
        },
      });
    });
  }

  getNearestMaintenanceSchedule = (data) => {
    let returnValue = null;
    let tempData = null;
    const curDateTime = moment(new Date()).toISOString();

    data.forEach((item) => {
      const maintenanceStart = moment(item.maintenanceStart).toISOString();
      if (maintenanceStart >= curDateTime) {
        if (tempData === null) {
          tempData = maintenanceStart;
          returnValue = item;
        } else if (maintenanceStart <= tempData) {
          tempData = maintenanceStart;
          returnValue = item;
        }
      }
    });
    return returnValue;
  }

  handleScheduleData = async (data = {}) => {
    if (data && Object.entries(data).length > 0) {
      const start = data.maintenanceStart;
      const end = data.maintenanceEnd;
      const timezone =
        moment()
          .tz(data.timezone)
          .utcOffset() / 60;
      const timezoneInGMT =
        timezone >= 0 ? `(GMT +${timezone})` : `(GMT ${timezone})`;
      const curDate = moment(new Date()).toISOString();
      const startFormatted = moment(start).toISOString();

      // default value for reminder date is 1 week before maintenance
      const startMaintenance = new Date(start);
      let startReminderDate;

      if (
        data.maintenanceRemainder === null ||
        data.maintenanceRemainder === ''
      ) {
        // if reminder is empty then set toast to show from 1 week before maintenance
        startMaintenance.setDate(startMaintenance.getDate() - 7);
        startReminderDate = moment(startMaintenance).toISOString();
      } else if (data.maintenanceRemainder === 'MONTH') {
        startMaintenance.setDate(startMaintenance.getDate() - (data.counterTime * 30));
        startReminderDate = moment(startMaintenance).toISOString();
      } else if (data.maintenanceRemainder === 'WEEK') {
        startMaintenance.setDate(startMaintenance.getDate() - (data.counterTime * 7));
        startReminderDate = moment(startMaintenance).toISOString();
      } else if (data.maintenanceRemainder === 'DAY') {
        startMaintenance.setDate(startMaintenance.getDate() - data.counterTime);
        startReminderDate = moment(startMaintenance).toISOString();
      } else if (data.maintenanceRemainder === 'HOUR') {
        startMaintenance.setDate(startMaintenance.getDate() - (data.counterTime / 24));
        startReminderDate = moment(startMaintenance).toISOString();
      }

      // Before Maintenance
      if (curDate >= startReminderDate && curDate < startFormatted) {
        const txt = `System will be scheduled for maintenance: From ${moment(start).format('D MMM YY')}, ${moment(start).format('HHmm')}hrs to ${moment(end).format('D MMM YY')}, ${moment(end).format('HHmm')}hrs ${timezoneInGMT}`;
        return txt;
      }
    }
    return '';
  };

  fetchMaintenanceData = async () => {
    const { actionCreators } = this.props;
    await actionCreators[resourceTypes.USERS].ajax({
      cargo: { method: 'maintenanceSchedule' },
      onSuccess: async ({ data: result }) => {
        const getNearestSchedule = await this.getNearestMaintenanceSchedule(result.maintenanceSchedule);
        const resultMessage = await this.handleScheduleData(getNearestSchedule);
        this.setState({ maintenanceMessage: resultMessage });
      },
      onFailure: ({ error }) => {
        console.log(error);
      },
    });
  };

  convertToPhoneObject = (phone) => {
    if (!phone) return {};

    const phoneObject = phone.split(' ');
    return {
      countryCode: phoneObject[0],
      value: phoneObject[1],
    };
  }

  render() {
    const { maintenanceMessage } = this.state;
    const { match: { params: { type } = {} }, data, location: { query: { phone } } } = this.props;
    process.env.DEBUG && console.log('%crender OTPContainer', 'font-size: 12px; color: #00b3b3', this.props);

    // Sanitize data
    const { countryCode = '65', value = 'xxxxxxxx', email = null } = (() => {
      switch (type) {
        case OTP_TYPES.AUTH_TFA: {
          const otpVia = gettersOf(resourceTypes.USERS).get2FAOtpVia()(data);
          if (otpVia && otpVia.method === 'SMS') {
            return this.convertToPhoneObject(otpVia.via);
          } else if (otpVia && otpVia.method === 'EMAIL') {
            return {
              email: otpVia.via,
            };
          }
          return this.convertToPhoneObject(phone);
        }
        case OTP_TYPES.ONBOARD_INVITATION:
        case OTP_TYPES.ONBOARD_TFA:
        case OTP_TYPES.ONBOARD_REGISTER:
          return gettersOf(resourceTypes.USERS).getOnboardMobile()(data);
        default: return {};
      }
    })();
    const title = R.pathOr('OTP Verification', [type, 'title'], otpData);
    const codeName = R.pathOr('OTP', [type, 'codeName'], otpData);
    const textForm = R.pathOr('If you require a new OTP, please log in again', [type, 'textForm'], otpData);
    const buttonName = R.pathOr('Verify OTP', [type, 'buttonName'], otpData);
    const timeoutMins = 5; // BE: Configurable
    const sendViaMessage = email ? `email ${commonUtil.maskEmail(email)}` : `mobile ending with +${countryCode} ${value.toString().replace(/\d(?=\d{4})/g, '*')}`;

    if (!this.inited || maintenanceMessage !== null) {
      this.initialValues = {};

      this.runtimeProps = {
        header: {
          banner: {
            title,
            maintenanceMessage,
            buttonName,
          },
        },
      };

      this.inited = true;
    }

    this.runtimeProps = update(this.runtimeProps, {
      header: {
        banner: {
          subtitle: {
            $set: `Please enter the ${codeName} sent to your ${sendViaMessage}.
            This code is only valid for ${timeoutMins} minutes.

            ${textForm}.`,
          },
        },
      },
    });

    return <OTP
      initialValues={this.initialValues}
      runtimeProps={this.runtimeProps}
      verifyOTP={this.verifyOTP}
      sendOTP={this.sendOTP} />;
  }
}

export { OTP_TYPES };
export default OTPContainer;
