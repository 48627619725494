import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { withRouter } from 'react-router';
import { actionCreators as listActionCreators } from './ducks/list';
import { actionCreators as chatsActionCreators } from './ducks/chats';

const mapState = state => ({
  list: state.chat.list,
  user: R.path(['resources', 'users', 'retrieveCurrent'], state),
});

@withRouter
@connect(
  mapState,
  {
    fetchList: listActionCreators.fetchList,
    initChats: chatsActionCreators.initChats,
    logout: chatsActionCreators.logout,
  },
)
class Notification extends React.Component {
  static displayName = 'ChatNotification';
  state = {
    key: null,
  };

  UNSAFE_componentWillMount() {
    const {
      fetchList,
      user: { profile, demographics },
    } = this.props;
    const params = [
      { name: 'profileId', value: profile.id },
      { name: 'page', value: 1 },
      { name: 'pageSize', value: 100 },
    ];
    if (window.MILO_CONFIG && window.MILO_CONFIG.CHAT_FEATURE) {
      fetchList(params);
    }
  }

  componentWillUnmount() {
    const { logout } = this.props;

    logout();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { key } = this.state;
    const newKey = R.path(['list', 'value', 'key'], nextProps);
    const rooms = R.path(['list', 'value', 'rooms'], nextProps);
    const user = R.path(['user'], nextProps);
    const { initChats } = this.props;
    if (key === null && newKey && user) {
      this.setState({ key: newKey });
      // When CHAT_FEATURE is false then the chat will not be inited and all the chat function will be disabled.
      if (window.MILO_CONFIG && window.MILO_CONFIG.CHAT_FEATURE) {
        initChats({ key: newKey, user, rooms });
      }
    }
  }

  render() {
    return <div style={{ display: 'none' }} />;
  }
}

export default Notification;
