import { Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/styles';
import logo from 'dataModule/assets/img/HealthBeatsDialogLogo.svg';
import React from 'react';
const disclaimerPopUpImg = require('../assets/DisclaimerPopUp.png');

const styles = (theme) => ({
  dialogPaper: {
    minHeight: '600px',
    minWidth: '654px',
    borderRadius: '5px',
  },
  topHeader: {
    margin: 0,
    padding: 2,
    height: 64,
    backgroundColor: '#fff',
  },
  primaryColor: {
    color: '#ff5100',
  },
  listItem: {
    height: 'auto !important',
  },
  dialogLogo: {
    maxWidth: '40px',
  },
  dialogTitle: {
    color: '#212121',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '19px',
    letterSpacing: '0.15000000596046448px',
  },
  dialogCloseButton: {
    position: 'relative',
    left: '10px',
    color: 'black',
    maxHeight: '40px',
    maxWidth: '40px',
  },
  dialogDisplayFlex: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: '40px',
    textIndent: 0,
  },
  dialogListItem: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '19px',
    color: '#212121',
    letterSpacing: '0.5px',
  },
  link: {
    marginLeft: 30,
  },
  icon: {
    marginRight: 8,
  },
  button: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '1.25px',
    textAlign: 'center',
    minWidth: 161,
  },
  tabRoot: {
    [theme.breakpoints.up('md')]: {
      minWidth: 80,
    },
    backgroundColor: '#fff',
    opacity: 'unset',
    height: 60,
    '&:hover': {
      color: '#2979ff',
      opacity: 1,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  tabLabelContainer: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(),
    },
  },
  label: {
    textTransform: 'capitalize',
    color: '#212121',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: 1.2,
    fontStretch: 'normal',
    letterSpacing: '0.5px',
    '&:hover': {
      color: '#212121',
    },
  },
  indicator: { backgroundColor: '#ff5100' },
});

@withStyles(styles, { index: 1 })
export default class DialogDisclaimer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
    };
  }

  handleClose = async () => {
    this.props.toggleDisclaimerDialog(false);
  };

  render() {
    const {
      open,
      classes: { dialogPaper, dialogLogo, dialogDisplayFlex, button, dialogTitle, dialogCloseButton },
    } = this.props;

    return (
      <Dialog open={open} classes={{ paper: dialogPaper }} aria-labelledby="ihealth-customer-care-info">
        <DialogTitle id="ihealth-customer-care-info" style={{ padding: '12px 16px' }}>
          <Grid container direction="row" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid item className={dialogDisplayFlex}>
              <img src={logo} className={dialogLogo} />
              <span style={{ width: '16px' }} />
              <Typography variant="body" className={dialogTitle}>
                Disclaimer
              </Typography>
            </Grid>
            <Grid item className={dialogDisplayFlex}>
              <IconButton aria-label="close" className={dialogCloseButton} onClick={this.handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ padding: '0' }}>
          <img src={disclaimerPopUpImg} style={{ width: '100%', height: '100%' }} />
          <div style={{ padding: 30 }}>
            <Typography style={{ lineHeight: '20px' }}>
              HealthBeats® Offline Module now allows Participants to continue taking their vitals measurements when they
              are offline, and for these data to be synchronized when they get back online.
              <br />
              <br />
              What you can see here is generated based on data available to HealthBeats® platform at this moment. As
              some data may not be updated when a Participant is offline, this may result in the same report downloaded
              at different date and time to generate different results.
              <br />
              <br />
              Please refer to Participant Listing Page for Last Sync Date of Participants.
            </Typography>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
