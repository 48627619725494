import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Container, Grid } from '@material-ui/core';
import { resourceTypes, gettersOf } from 'dataModule/store/resources';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import logo from 'dataModule/assets/img/HealthBeatsDialogLogo.svg';
import { connect } from 'react-redux';
import { actionCreators as sessionActionCreators } from '../../globalSession/ducks';
import * as R from 'ramda';
import moment from 'moment-timezone';

const styles = () => ({
  primaryColor: {
    color: '#ff5100',
  },
  listItem: {
    height: 'auto !important',
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: '40px',
  },
  title: {
    //styleName: w7. Subtitle 1/Medium;
    color: '#212121',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '19px',
    letterSpacing: '0.15000000596046448px',
  },
  content: {
    color: '#212121',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.25px',
  },
  customDialogPaper: {
    maxWidth: '654px',
    // top: '192px',
    borderRadius: '8px',
  },
  logo: {
    maxWidth: '40px',
  },
  closeButton: {
    position: 'relative',
    left: '10px',
    color: 'black',
    maxHeight: '40px',
    maxWidth: '40px',
  },
  button: {
    //styleName: w11. Button/Medium;
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '1.25px',
    textAlign: 'center',
  },
});

@connect(
  (state) => ({
    currentOrganization: gettersOf(resourceTypes.USERS).getLoggedInOrganization({ root: true })(state),
    sessionDialogOpen: R.path(['globalSession', 'sessionDialogOpen'], state),
  }),
  { ...sessionActionCreators },
)
@withStyles(styles, { index: 1 })
export default class DialogSessionTimeoutComponent extends React.Component {
  redirectToLoginPage = async () => {
    window.location.href = 'https://www.healthbeats.co';
  };

  render() {
    const { sessionDialogOpen, classes, currentOrganization } = this.props;
    const offsetTZ =
      moment()
        .tz(currentOrganization.timezone)
        .utcOffset() / 60;

    return (
      <Dialog
        open={sessionDialogOpen}
        style={{ zIndex: 9999 }}
        PaperProps={{ classes: { root: classes.customDialogPaper } }}
        // onClose={this.handleClose}
        // scroll={this.state.scroll}
        aria-labelledby="ihealth-customer-care-info">
        <DialogTitle id="ihealth-customer-care-info" style={{ padding: '12px 16px' }}>
          <Grid container direction="row" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid item className={classes.displayFlex}>
              <img src={logo} className={classes.logo} />
              <span style={{ width: '16px' }} />
              <Typography variant="body" className={classes.title}>
                Session Expired
              </Typography>
            </Grid>
            <Grid item className={classes.displayFlex}>
              <IconButton aria-label="close" className={classes.closeButton} onClick={this.redirectToLoginPage}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ padding: '24px 24px 40px' }}>
          <Typography variant="body2" className={classes.content}>
            To prevent unauthorised access to your account, you are automatically logged out by the system. Please log in again.
          </Typography>
        </DialogContent>
        <DialogActions style={{ padding: '12px' }}>
          <Button
            className={['milo-btn-orange', classes.button]}
            variant="contained"
            onClick={this.redirectToLoginPage}>
            Proceed to log in
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
