/* Constants */

/* Action Creators */

/* Epics */
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/catch';

import DM from 'dataModule';

/* Reducer */
/*
**************************************************
  Action Types
**************************************************
*/
const prefix = 'PROVIDER/TASK';

const actionTypes = {
  FETCH_LIST: Symbol(`${prefix}/FETCH_LIST`),
  FETCH_LIST_SUCCESS: Symbol(`${prefix}/FETCH_LIST_SUCCESS`),
  FETCH_LIST_FAILURE: Symbol(`${prefix}/FETCH_LIST_FAILURE`),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  fetchList: (payload) => ({ type: actionTypes.FETCH_LIST, payload }),
  fetchListSuccess: (payload) => ({ type: actionTypes.FETCH_LIST_SUCCESS, payload }),
  fetchListFailure: (payload) => ({ type: actionTypes.FETCH_LIST_FAILURE, payload }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/
const defaultBody = {
  timezone: 'Asia/Singapore',
  page: 1,
  pageSize: 1000,
};

const epics = [
  // fetchList
  (action$) =>
    action$.ofType(actionTypes.FETCH_LIST).mergeMap(({ payload }) =>
      Observable.fromPromise(
        DM.task.fetchList({
          ...defaultBody,
          patientId: payload.patientId,
          enrolledProgramId: payload.enrolledProgramId || 0,
          status: payload.status || null,
          startDate: payload.startDate || null,
          endDate: payload.endDate || null,
          timezone: payload.timezone,
          page: payload.page,
          pageSize: payload.pageSize,
          getCount: payload.getCount || null,
        }),
      )
        .map((result) => actionCreators.fetchListSuccess({ result }))
        .catch((error) => Observable.of(actionCreators.fetchListFailure({ error }))),
    ),
];

export { epics };
/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
  code: null,
  value: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    // case actionTypes.FETCH_LIST: {
    //   const { info } = action.payload;
    //   return { ...state, info };
    // }
    case actionTypes.FETCH_LIST_SUCCESS: {
      const { result } = action.payload;
      return { ...state, ...result };
    }
    case actionTypes.FETCH_LIST_FAILURE: {
      const { error } = action.payload;
      return { ...state, result: { ok: false, error } };
    }
    default: {
      return state;
    }
  }
};
