/* Constants */

/* Action Creators */

/* Epics */
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/catch';

import DM from 'dataModule';
import { eqProps, findIndex, lensPath, pathOr, propOr, set } from 'ramda';
/* Reducer */
/*
**************************************************
  Action Types
**************************************************
*/
const actionTypes = {
  FETCH_LIST: Symbol('FETCH_LIST'),
  REVIEW_ALERT: Symbol('REVIEW_ALERT'),
  CLOSE_ALERT: Symbol('CLOSE_ALERT'),
  UPDATE_ALERT: Symbol('UPDATE_ALERT'),
  FETCH_LIST_SUCCESS: Symbol('FETCH_LIST_SUCCESS'),
  FETCH_LIST_FAILURE: Symbol('FETCH_LIST_FAILURE'),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  fetchList: payload => ({ type: actionTypes.FETCH_LIST, payload }),
  reviewAlert: ({ id }) => ({ type: actionTypes.REVIEW_ALERT, payload: { id } }),
  closeAlert: ({ id }) => ({ type: actionTypes.CLOSE_ALERT, payload: { id } }),
  updateAlert: payload => ({ type: actionTypes.UPDATE_ALERT, payload }),
  fetchListSuccess: payload => ({ type: actionTypes.FETCH_LIST_SUCCESS, payload }),
  fetchListFailure: payload => ({ type: actionTypes.FETCH_LIST_FAILURE, payload }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/

// allowed params: enrolledProgramId, patientId, status, page, pageSize

const epics = [
  // fetchList
  action$ =>
    action$.ofType(actionTypes.FETCH_LIST).mergeMap(({ payload }) => {
      return Observable.fromPromise(DM.alert.fetchList(payload))
        .map(result => actionCreators.fetchListSuccess({ result }))
        .catch(error => Observable.of(actionCreators.fetchListFailure({ error })));
    }),
  action$ =>
    action$.ofType(actionTypes.REVIEW_ALERT).mergeMap(({ payload: { id } }) =>
      Observable.fromPromise(DM.alert.reviewAlert({ id }))
        .map(result => actionCreators.updateAlert(result.value))
        .catch(error => Observable.of(actionCreators.fetchListFailure({ error })))),
  action$ =>
    action$.ofType(actionTypes.CLOSE_ALERT).mergeMap(({ payload: { id } }) =>
      Observable.fromPromise(DM.alert.closeAlert({ id }))
        .map(result => actionCreators.updateAlert(result.value))
        .catch(error => Observable.of(actionCreators.fetchListFailure({ error })))),
];

export { epics };
/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
  data: [],
  loading: true,
  page: 0,
  pageSize: 10,
  total: 0,
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LIST: {
      return { ...state, loading: true };
    }
    case actionTypes.UPDATE_ALERT: {
      const newAlert = action.payload;
      const idx = findIndex(eqProps('_id', newAlert), state.data);
      const lens = lensPath(['data', idx]);
      return idx === -1 ? state : set(lens, newAlert, state);
    }
    case actionTypes.FETCH_LIST_SUCCESS: {
      const result = pathOr({}, ['result', 'value'], action.payload);
      const data = propOr([], 'data', result);
      const total = propOr(0, 'total', result);
      return {
        ...state,
        data,
        total,
        loading: false,
      };
    }
    case actionTypes.FETCH_LIST_FAILURE: {
      const { error } = action.payload;
      return { ...state, loading: false };
    }
    default: {
      return state;
    }
  }
};
