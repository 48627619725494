/* eslint-disable */
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/catch';

/* Libs */
import DM from 'dataModule';

/*
**************************************************
  Action Types
**************************************************
*/
const prefix = 'CONSUMABLESALES';

const actionTypes = {
  CONSUMABLE_SALES: Symbol(`${prefix}/CONSUMABLES_SALE`),
  CONSUMABLE_SALES_SUCCESS: Symbol(`${prefix}/CONSUMABLE_SALES_SUCCESS`),
  CONSUMABLE_SALES_FAILURE: Symbol(`${prefix}/CONSUMABLE_SALES_FAILURE`),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  consumableSales: payload => ({ type: actionTypes.CONSUMABLE_SALES, payload }),
  consumableSalesSuccess: payload => ({ type: actionTypes.CONSUMABLE_SALES_SUCCESS, payload }),
  consumableSalesFailure: payload => ({ type: actionTypes.CONSUMABLE_SALES_FAILURE, payload }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/
const epics = [
  action$ => action$.ofType(actionTypes.CONSUMABLE_SALES)
    .mergeMap(({ payload: { info, onConsumableSalesSuccess, onConsumableSalesFailure } }) => {
      return DM.consumableSales.consumableSales(info)
        .map((res) => {
          onConsumableSalesSuccess(res.value);
          return actionCreators.consumableSalesSuccess({ res });
        })
        .catch((error) => {
          onConsumableSalesFailure(error);
          return Observable.of(actionCreators.consumableSalesFailure({ error: error.message }));
        });
    }),
];

export { epics };

/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
  info: null,
  result: {
    ok: null,
    info: null,
    error: null,
  },
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.CONSUMABLE_SALES: {
      const { info } = action.payload;
      return { ...state, info };
    }
    case actionTypes.CONSUMABLE_SALES_SUCCESS: {
      const { info } = action.payload;
      return { ...state, result: { ok: true, info } };
    }
    case actionTypes.CONSUMABLE_SALES_FAILURE: {
      const { error } = action.payload;
      return { ...state, result: { ok: false, error } };
    }
    default: {
      return state;
    }
  }
};
