import ReactDOM from 'react-dom/client';
import { AppContainer } from 'react-hot-loader';
import theme from 'stylesModule/theme';
import history from 'dataModule/store/history';
import store from 'dataModule/store/reduxStore';
import App from './app';
import commonUtil from 'utilsModule/common';

const render = (Component) => {

	const root = ReactDOM.createRoot(document.getElementById('root'));
	root.render(
		<AppContainer>
			<Component theme={theme} store={store} history={history} />
		</AppContainer>
	);
};



render(App);

const role = sessionStorage.getItem('user-role');
const orgType = sessionStorage.getItem('org-type');
if (role) {
	commonUtil.renameDocumentTitle(role, orgType);
}

if (window.MILO_CONFIG && window.MILO_CONFIG.ENABLE_HOT_RELOADING_JS && module.hot) {
	module.hot.accept('./app', () => {
		// console.log('%cHot reloading app...', 'font-size: 15px; font-weight: bold; color: #00b3b3');
		const AppNew = require('./app').default; // eslint-disable-line global-require
		render(AppNew);
		// NOTE: This still works but won't preserve react component state
		// import('./app').then(AppNew => render(AppNew.default));
	});
}
