import { combineReducers } from 'redux';
import list, { epics as listEpics } from './list';
import fetchLatestMeasurements, { epics as latestMeasurementsEpics } from './fetchLatestMeasurements';
import programs, { epics as progEpics } from './programSelect';

export const epics = [...listEpics, ...progEpics, ...latestMeasurementsEpics];

export default combineReducers({
  list,
  programs,
  fetchLatestMeasurements,
});
