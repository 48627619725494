import React, { PureComponent } from 'react';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Grid, Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import uuidv4 from 'uuid/v4';
import { withReduxForm, ShowHidePasswordTextField, Phone } from 'utilsModule/form/reduxFormMui';
import { Version } from 'utilsModule/components';
import { Back } from 'utilsModule/components';
import HeaderComponent from 'appModule/main/layouts/Header';
import Logo from 'appModule/logo/logoNoText';

const styles = theme => ({
  pageContainer: {
    height: '84vh',
    width: '100vw',
    margin: 0,
    backgroundColor: '#fff',
    paddingBottom: 100
  },
  back: {
    height: '16vh',
    padding: 20,
    backgroundColor: '#fff',
  },
  banner: {
    textAlign: 'center'
  },
  title: {
    letterSpacing: '0.4px',
  },
  subTitle: {
    whiteSpace: 'pre-line',
    textAlign: 'center',
    letterSpacing: '0.5px',
    fontStretch: 'normal',
    lineHeight: 1.5,
    fontSize: '15px',
  },
  card: {
    maxWidth: 350,
    margin: '0 auto',
    borderRadius: 20,
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
  },
  policy: {
    marginTop: 20,
    fontSize: 15,
    textAlign: 'center',
    lineHeight: 1.5,
    display: 'inline-block',
  },
  link: {
    color: '#2c7df6',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  right: {
    textAlign: 'right',
    cursor: 'pointer',
  },
});

const layout = {
  header: {
    containerProps: {
      justify: 'center',
    },
    components: [
      {
        id: uuidv4(),
        name: 'banner',
        component: () => (
            <Grid container direction="column" alignItems="center">
                <Grid item xs={12} style={{ paddingBottom: '10px' }}><Logo /></Grid>
                <Grid item xs={12} style={{ paddingBottom: '15px' }}>
                    <Typography variant='h4' style={styles.title}>Login Verification</Typography>
                </Grid>
                <Grid item xs={12}>
                <Typography
                    variant='body1'
                    align='center'
                    style={styles.subTitle}>Your mobile number has been previously registered and activated for another role. Proceed to log in to complete the activation of your new role.</Typography>
                </Grid>
            </Grid>
        ),
        colSpans: { xs: 12 },
      },
    ],
  },
  body: {
    itemProps: {
        style: {
            marginTop: 10,
        },
    },
    sections: [
      {
        id: uuidv4(),
        containerProps: {
          overall: {
            spacing: 0,
          },
          body: {
            spacing: 0,
          },
        },
        rows: [
          {
            id: uuidv4(),
            cols: [
              {
                name: 'mobile',
                label: 'Mobile Number',
                component: Phone,
                colSpans: { xs: 8 },
                required: true,
                inject: ({ runtimeProps: { t } }) => ({ label: t('Mobile No') }),
              },
            ],
          },
          {
            id: uuidv4(),
            cols: [
              {
                name: 'encryptPassword',
                label: 'Password',
                colSpans: { xs: 8 },
                component: ShowHidePasswordTextField,
                required: true,
                inject: ({ runtimeProps: { t } }) => ({ label: t('Password') }),
              },
            ],
          },
        ],
      },
    ],
  },
  footer: {
    itemProps: {
      style: {
        marginTop: 20,
        alignItems: 'center'
      },
    },
    components: [
      {
        id: uuidv4(),
        component: Button,
        type: 'submit',
        children: 'Verify Log In',
        variant: 'contained',
        color: 'primary',
        style: { width: '40%', left: '28%' },
        className: 'milo-btn-submit-medium',
        colSpans: { xs: 12 },
        inject: ({ submitting, runtimeProps: { t } }) => ({ disabled: submitting, children: t('Log In') }),
      },
    ],
  },
};

const validate = ({ mobile: { value }, password }, { runtimeProps: { t } }) => ({
  value: (value !== undefined || value) ? '' : t('validation:Please enter Mobile No'),
  password: password ? '' : t('validation:Please enter Password'),
});

const config = {
  form: 'login',
  validate,
};
@withNamespaces('auth', { wait: true })
@withRouter
@withStyles(styles, { index: 1 })
@withReduxForm({ layout, config })
class ReverifyLogin extends PureComponent {
  static propTypes = {
    /* container */
    signIn: PropTypes.func.isRequired,
    forgetPassword: PropTypes.func.isRequired,
    /* withStyles */
    classes: PropTypes.object,
    /* createReduxForm */
    Form: PropTypes.func.isRequired,
  };

  render() {
    const {
      classes, Form, initialValues, runtimeProps, signIn, t, forgetPassword, isOnBoard
    } = this.props;

      return(
        <React.Fragment>
          <HeaderComponent isOnBoard={isOnBoard} />
            <div style={{paddingTop: 50}}>
              <Back className={classes.back} label='Log In' backToWebsite={true} path='/auth' />
              <Grid container className={classes.pageContainer} alignItems='center'>
                <Grid item xs />
                <Grid item xs={5}>
                  <Form
                    autocomplete='off'
                    runtimeProps={{
                      ...runtimeProps,
                      t,
                      forgetPassword,
                      classes,
                    }}
                    initialValues={initialValues}
                    className={classes.formContainer}
                    onSubmit={signIn}
                    sections={[]}
                  />
                </Grid>
                <Grid item xs />
              </Grid>
            </div>
        </React.Fragment>
      );
    
  }
}

export default ReverifyLogin;
