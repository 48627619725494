import { httpGet, httpPost } from './utils';

const create = httpPost;
const retrieveMany = httpGet;

const api = {
  create,
  retrieveMany,
};

export default api;
