/* eslint-disable max-len */
import {
  Avatar as MuiAvatar,
  Button,
  Grid,
  Typography
} from '@material-ui/core';
import React from 'react';
import { Assignment } from 'utilsModule/material-ui-icons';
import CollapsibleCard from './CollapsibleCard';
import VitalInterface from './VitalInterface';

const styles = {
  collapsibleCardGrid: {
    backgroundColor: '#212121',
    fontSize: '24px'
  },
  refreshData: {
    fontSize: '12px',
    color: '#FFFFFF'
  },
  avatarTitle: {
    fontSize: '24px',
  },
};

export default class CollapsibleCardWithStatusChartVitals extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getSelectedVitals = () => {
    const { selectedEnrolledProgram } = this.props;
    const returnData = [];
    for (const item of selectedEnrolledProgram) {
      for (const obj of item.activities) {
        for (const value of obj.vitalsDataForActivity.vitalDefs) {
          returnData.push(value.vitalDef.name);
        }
      }
    }
    return returnData;
  }

  render() {
    const { title, data, refresh, modules, getLatestOTT, informationData, changeVitalViewType, mode, pdf = false, reportType, isEmbedded = false, reset, reportData } = this.props;

    // Get Selected vitals
    let selectedVitals = this.getSelectedVitals();

    // Get vital module
    const module = modules.find(f => f.key === 'vitals');
    if (!module) return;

    // Get checked vital
    selectedVitals = selectedVitals.filter(f => module.components.filter(c => c.checked).map(m => m.key).includes(f));

    const vitalData = data.filter(f => selectedVitals.includes(f.key));

    const cardHeaderProps = {
      avatar: (<MuiAvatar aria-label={title} style={{...styles.collapsibleCardGrid, position: 'static'}}><Assignment /></MuiAvatar>),
      title: (<Typography variant="h5" component="h3" style={styles.avatarTitle}>{title}</Typography>),
      action: (
        mode === 'view' ? null :
          <Grid style={{ padding: 15 }}>
            <Button variant="contained" className="milo-btn-blue-2" style={{ fontSize: '12px' }} onClick={() => refresh()}>
              <Typography variant='body1' style={styles.refreshData}>Refresh data</Typography>
            </Button>
          </Grid>
      )
    };

    return (
      <CollapsibleCard
        cardHeaderProps={!pdf ? cardHeaderProps : null}
        collapseProps={{ timeout: 'auto' }}
        noPaddingLeftRight={true}
        withDivider={false}
      >
        {vitalData.map((item, key) => {
          return (
            <VitalInterface
              isEmbedded={isEmbedded}
              pdf={pdf}
              key={key}
              data={item}
              selectedEnrolledProgram={item.selectedEnrolledProgramme}
              informationData={informationData}
              changeVitalViewType={changeVitalViewType}
              getLatestOTT={getLatestOTT}
              reportType={reportType}
              mode={mode}
              reset={reset}
              reportData={reportData}
            />
          );
        })}
      </CollapsibleCard>
    );
  }
}
