import { Observable } from 'rxjs/Observable';
import * as R from 'ramda';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';
import 'rxjs/observable/ArrayObservable';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/merge';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/catch';
import { actionCreators as roomActionCreators, actionTypes as roomActionTypes } from './room';
import ChatCore from '../utils/ChatCore';

let instanceChat = null;

export function getChat(epId) {
  if (epId) {
    return instanceChat.findChat({ epId });
  }
  return instanceChat;
}
/* Reducer */
/*
**************************************************
  Action Types
**************************************************
*/
const prefix = 'PROVIDER/CHAT/NOTIFICATION';

const actionTypes = {
  INIT_CHATCORE: Symbol(`${prefix}/INIT_CHATCORE`),
  INIT_CHATCORE_SUCCESS: Symbol(`${prefix}/INIT_CHATCORE_SUCCESS`),
  CONNECT_CHAT_SUCCESS: Symbol(`${prefix}/CONNECT_CHAT_SUCCESS`),
  RECEIVE_MESSAGES: Symbol(`${prefix}/RECEIVE_MESSAGES`),
  SEARCH_HISTORY: Symbol(`${prefix}/SEARCH_HISTORY`),
  SEARCH_HISTORY_START: Symbol(`${prefix}/SEARCH_HISTORY_START`),
  SEARCH_HISTORY_SUCCESS: Symbol(`${prefix}/SEARCH_HISTORY_SUCCESS`),
  INTO_CHATROOM: Symbol(`${prefix}/INTO_CHATROOM`),
  CONNECT_ONE_CHAT: Symbol(`${prefix}/CONNECT_ONE_CHAT`),
  CONNECT_ONE_CHAT_SUCCESS: Symbol(`${prefix}/CONNECT_ONE_CHAT_SUCCESS`),
  SEND_MESSAGE: Symbol(`${prefix}/SEND_MESSAGE`),
  CATCH_ERROR: Symbol(`${prefix}/CATCH_ERROR`),
  LOGOUT: Symbol(`${prefix}/LOGOUT`),
  CHAT_USER_ONLINE: Symbol(`${prefix}/CHAT_USER_ONLINE`),
  CHAT_USER_OFFLINE: Symbol(`${prefix}/CHAT_USER_OFFLINE`),
  CHATCORE_STATE_CHANGE: Symbol(`${prefix}/CHATCORE_STATE_CHANGE`),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  initChats: payload => ({ type: actionTypes.INIT_CHATCORE, payload }),
  initChatsSuccess: payload => ({
    type: actionTypes.INIT_CHATCORE_SUCCESS,
    payload,
  }),
  connectChatSuccess: payload => ({
    type: actionTypes.CONNECT_CHAT_SUCCESS,
    payload,
  }),
  connectOneChat: payload => ({
    type: actionTypes.CONNECT_ONE_CHAT,
    payload,
  }),
  connectOneChatSuccess: payload => ({
    type: actionTypes.CONNECT_ONE_CHAT_SUCCESS,
    payload,
  }),
  receiveMessages: payload => ({ type: actionTypes.RECEIVE_MESSAGES, payload }),
  searchHistory: payload => ({ type: actionTypes.SEARCH_HISTORY, payload }),
  searchHistoryStart: payload => ({ type: actionTypes.SEARCH_HISTORY_START, payload }),
  searchHistorySuccess: payload => ({
    type: actionTypes.SEARCH_HISTORY_SUCCESS,
    payload,
  }),
  intoChatRoom: payload => ({ type: actionTypes.INTO_CHATROOM, payload }),
  sendMessage: payload => ({ type: actionTypes.SEND_MESSAGE, payload }),
  logout: payload => ({ type: actionTypes.LOGOUT, payload }),
  chatUserOnline: payload => ({ type: actionTypes.CHAT_USER_ONLINE, payload }),
  chatUserOffline: payload => ({ type: actionTypes.CHAT_USER_OFFLINE, payload }),
  chatCoreStateChange: payload => ({ type: actionTypes.CHATCORE_STATE_CHANGE, payload }),
  catchError: payload => ({ type: actionTypes.CATCH_ERROR, payload }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/
const epics = [
  // initChat
  (action$, store) =>
    action$
      .ofType(actionTypes.INIT_CHATCORE)
      .mergeMap(({ payload }) => {
        const chatCore = new ChatCore(payload);
        instanceChat = chatCore;
        window.Chats = chatCore;
        return Observable.fromPromise(chatCore.initChatEngine({
          userStateChange: (newUser) => {
            store.dispatch(actionCreators.chatCoreStateChange(newUser));
          },
        }));
      })
      .map(chatCore => actionCreators.initChatsSuccess({ chatCore }))
      .catch(error => actionCreators.catchError({ error })),
  (action$, store) =>
    action$
      .ofType(actionTypes.INIT_CHATCORE_SUCCESS)
      .mergeMap(({ payload }) => {
        const { chatCore } = payload;
        const chats = chatCore.connectAllChannels();
        return Observable.from(chats);
      })
      .mergeMap(chat => chat)
      .map((chat) => {
        chat.registerEvent({
          receiveMsg: (data) => {
            store.dispatch(actionCreators.receiveMessages(data));
          },
          online: (data) => {
            store.dispatch(actionCreators.chatUserOnline(data));
          },
          offline: (data) => {
            store.dispatch(actionCreators.chatUserOffline(data));
          },
        });
        return actionCreators.connectChatSuccess({ chat });
      }),
  (action$, store) =>
    action$.ofType(actionTypes.SEARCH_HISTORY).mergeMap(({ payload }) => {
      const { epId } = payload;

      const chat = instanceChat.findChat({ epId });

      if (chat && chat.isConnected && chat.hasMoreHistory && !chat.isSearching) {
        store.dispatch(actionCreators.searchHistoryStart({
          searching: true,
          hasMoreHistory: true,
        }));
        chat.searchHistory({
          epId,
          cb: (data) => {
            store.dispatch(actionCreators.searchHistorySuccess(data));
          },
        });
      }

      return [];
    }),
  (action$, store) =>
    action$.ofType(actionTypes.INTO_CHATROOM).map(({ payload }) => {
      const { epId } = payload;
      const chat = instanceChat.findChat({ epId });
      if (chat && chat.messages.length === 0) {
        store.dispatch(actionCreators.searchHistory({ epId: chat.epId }));
      }
      return roomActionCreators.getChatroom(payload.epId);
    }),
  (action$, store) =>
    action$
      .filter(({ type, payload }) => {
        if (type === roomActionTypes.GET_CHATROOM_SUCCESS) {
          const {
            result: { value },
          } = payload;
          // const me = R.path(['resources', 'users', 'retrieveCurrent', 'profile'], store.getState());
          // const timePeriods = R.compose(
          //   R.path(['timePeriods']),
          //   R.find(R.propEq('id', me.id)),
          // )(value.members);
          const chat = instanceChat.findChat({ epId: `${value.epId}` });
          // if (chat) {
          //   chat.setTimePeriods(timePeriods);
          // }
          return R.isNil(chat);
        }
        return false;
      })
      .mergeMap(({ payload }) => {
        const {
          result: { value },
        } = payload;

        return Observable.fromPromise(instanceChat.connectOneChat(value))
          .map((chat) => {
            chat.registerEvent({
              receiveMsg: (data) => {
                store.dispatch(actionCreators.receiveMessages(data));
              },
              online: (data) => {
                store.dispatch(actionCreators.chatUserOnline(data));
              },
              offline: (data) => {
                store.dispatch(actionCreators.chatUserOffline(data));
              },
            });
            return actionCreators.connectChatSuccess({ chat });
          })
          .catch(error => actionCreators.catchError({ error }));
      }),
  action$ =>
    action$.ofType(actionTypes.SEND_MESSAGE).mergeMap(({ payload }) => {
      // const { epId, text } = payload;

      instanceChat.sendMessageToChat(payload);

      return [];
    }),
];

export { epics };
/*
**************************************************
  Reducer
**************************************************
*/
// state.chats demo
// const chats = [
//     {
//         epId: '',
//         epId: '',
//         messages: [],
//         unreadMessages: [],
//     }
// ];
const initState = {
  hasChat: false,
  connectChatEngine: false,
  chats: [],
  chatCoreUsers: {},
  currentChat: {
    epId: '',
    searching: false,
    hasMoreHistory: true,
    isConnected: false,
  },
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_CHATCORE_SUCCESS: {
      return { ...state, connectChatEngine: true };
    }
    case actionTypes.CONNECT_CHAT_SUCCESS: {
      const { chat } = action.payload;
      const { chats } = state;
      const value = chat.getValue();
      value.messages = [...value.messages];
      const newState = {
        ...state,
        chats: [...chats, value],
        hasChat: true,
      };

      if (chat.epId === state.currentChat.epId) {
        newState.currentChat = {
          ...state.currentChat,
          isConnected: chat.isConnected,
          searching: false,
          hasMoreHistory: chat.hasMoreHistory,
        };
      }
      return newState;
    }
    case actionTypes.RECEIVE_MESSAGES: {
      const msg = action.payload;
      const { chats } = state;
      const msgArrName = msg.epId !== state.currentChat.epId ? 'unreadMessages' : 'messages';
      const chat = R.find(R.propEq('epId', msg.epId))(chats);

      // Fix everyone in the team is added twice during enrolment
      const currentMemberChangesMessages = chat.messages.filter(c => c.type === 'memberChanges');
      if (currentMemberChangesMessages.find(m => m.timetoken === msg.message.timetoken)) {
        return { ...state, chats: [...chats] };
      }

      const newMessages = msg.message;
      chat[msgArrName] = [...chat[msgArrName], newMessages];
      return { ...state, chats: [...chats] };
    }
    case actionTypes.INTO_CHATROOM: {
      const { epId } = action.payload;
      const chat = R.find(R.propEq('epId', epId))(state.chats);
      const currentChat = {
        ...state.currentChat,
        epId,
        isConnected: false,
        searching: false,
        hasMoreHistory: true,
      };
      // user has read all messages~
      if (chat) {
        chat.messages = [...chat.messages, ...chat.unreadMessages];
        chat.unreadMessages = [];
        currentChat.hasMoreHistory = chat.hasMoreHistory;
        currentChat.isConnected = chat.isConnected;
        currentChat.hasChat = true;
      }
      return {
        ...state,
        currentChat: {
          ...currentChat,
        },
      };
    }
    case actionTypes.SEARCH_HISTORY_START: {
      const { currentChat } = state;
      const { searching, hasMoreHistory } = action.payload;
      return {
        ...state,
        currentChat: {
          ...currentChat,
          searching,
          hasMoreHistory,
        },
      };
    }
    case actionTypes.SEARCH_HISTORY_SUCCESS: {
      const { epId, messages, hasMoreHistory } = action.payload;
      const chat = R.find(R.propEq('epId', epId))(state.chats);
      const { currentChat } = state;
      chat.messages = [...messages, ...chat.messages];
      chat.hasMoreHistory = hasMoreHistory;
      return {
        ...state,
        currentChat: { ...currentChat, searching: false, hasMoreHistory },
      };
    }
    case actionTypes.CATCH_ERROR: {
      const { error } = action.payload;
      // console.log('========error=======', error);
      return state;
    }
    case actionTypes.CHAT_USER_ONLINE: {
      const { epId, uuid, newState } = action.payload;
      if (state.chats && state.chats.length > 0) {
        const chatIndex = R.findIndex(R.propEq('epId', epId))(state.chats);
        const path = ['chats', chatIndex, 'onlineUsers'];
        let method = 'append';
        const userIndex = R.findIndex(R.propEq('uuid', uuid))(state.chats[chatIndex].onlineUsers);
        if (userIndex >= 0) {
          method = 'always';
          path.push(userIndex);
        }

        const result = R.over(R.lensPath(path), R[method]({ uuid, ...newState }), state);
        path.pop();
        return result;
      }
      return state;
    }
    case actionTypes.CHAT_USER_OFFLINE: {
      const { epId, uuid } = action.payload;
      if (state.chats && state.chats.length > 0) {
        const chatIndex = R.findIndex(R.propEq('epId', epId))(state.chats);
        const path = ['chats', chatIndex, 'onlineUsers'];
        const userIndex = R.findIndex(R.propEq('uuid', uuid))(state.chats[chatIndex].onlineUsers);
        if (userIndex < 0) return state;
        const result = R.over(R.lensPath(path), R.filter(u => u.uuid !== uuid), state);
        return result;
      }
      return state;
    }
    case actionTypes.CHATCORE_STATE_CHANGE: {
      const newUser = action.payload;
      // const chatCoreUsers = Object.assign({}, state.chatCoreUsers);
      // console.log('CHATCORE_STATE_CHANGE', action.payload);
      // chatCoreUsers[id] = chatCoreUsers[id]
      //   ? { ...Object.assign(chatCoreUsers[id], newState) }
      //   : { [id]: newState };

      // return { ...state, chatCoreUsers };
      const { chats } = state;

      const newChats = chats.map((chat) => {
        const userInChatIndex = R.findIndex(R.propEq('uuid', newUser.uuid), chat.onlineUsers);
        if (userInChatIndex < 0) return chat;
        return R.set(
          R.lensPath(['onlineUsers', userInChatIndex]),
          Object.assign({}, chat.onlineUsers[userInChatIndex], newUser),
          chat,
        );
      });
      return { ...state, chats: newChats };
    }
    case actionTypes.LOGOUT: {
      if (instanceChat) {
        try {
          instanceChat.ChatEngine.disconnect();
        } catch (error) {
          console.warn(error);
        }
        instanceChat = null;  
      }
      return initState;
    }
    default: {
      return state;
    }
  }
};
