/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button, Card } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import uuidv4 from 'uuid/v4';
import { withReduxForm, TextField } from 'utilsModule/form/reduxFormMui';
import { normalize, isNumeric } from 'utilsModule/form';
import { Back } from 'utilsModule/components';
import Logo from 'appModule/logo/logoNoText';
import HeaderComponent from 'appModule/main/layouts/Header';

const styles = {
  pageContainer: {
    height: '84vh',
    width: '100vw',
    margin: 0,
    backgroundColor: '#fff',
    paddingBottom: 100
  },
  back: {
    height: '16vh',
    padding: 20,
    backgroundColor: '#fff',
  },
  banner: {
    textAlign: 'center',
  },
  title: {
    letterSpacing: '0.4px',
  },
  subTitle: {
    whiteSpace: 'pre-line',
    textAlign: 'center',
    letterSpacing: '0.5px',
    fontStretch: 'normal',
    lineHeight: 1.5,
    fontSize: '15px',
  },
  maintenanceMessage: {
    color: 'white',
    textAlign: 'center',
    whiteSpace: 'pre-line',
    padding: '0 0 5px',
  },
  cardMaintenace: {
    background: '#464a47',
    padding: 15,
    textAlign: 'center',
    width: '100%',
    marginBottom: '25px',
  },
};

const digitInputs = {};

const layout = {
  header: {
    containerProps: {
      justify: 'center',
    },
    components: [
      {
        id: uuidv4(),
        name: 'banner',
        component: ({ title, maintenanceMessage, subtitle }) => (
          <Grid container style={styles.banner}>
            <Grid item xs={12} style={{ paddingBottom: '20px' }}><Logo /></Grid>
            {
              maintenanceMessage &&
              <React.Fragment>
                <Grid item xs={1} />
                <Grid item xs={10}>
                  <Card style={styles.cardMaintenace}>
                    <Typography style={styles.maintenanceMessage}>{maintenanceMessage.substring(0, maintenanceMessage.indexOf(':') + 1)}</Typography>
                    <Typography style={styles.maintenanceMessage}>{maintenanceMessage.substring(maintenanceMessage.indexOf(':') + 1)}</Typography>
                  </Card>
                </Grid>
                <Grid item xs={1} />
              </React.Fragment>
            }
            <Grid item xs={12} style={{ paddingBottom: '15px' }}>
              <Typography variant='h4' style={styles.title}>{title}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant='body1'
                style={styles.subTitle}>{subtitle}</Typography>
            </Grid>
          </Grid>
        ),
        colSpans: { xs: 12 },
        inject: ({ runtimeProps: { header: { banner } } }) => banner,
      },
    ],
  },
  body: {
    sections: [
      {
        id: uuidv4(),
        name: 'otp',
        rows: [
          {
            id: uuidv4(),
            containerProps: {
              justify: 'center',
              spacing: 1,
            },
            cols: [
              {
                name: 'digit0',
                component: TextField,
                normalize: normalize.toNumber,
                colSpans: { xs: 1 },
                autoFocus: true,
                inputProps: {
                  style: {
                    textAlign: 'center',
                  },
                  maxLength: 1,
                  onClick: e => e.target.select(),
                  onFocus: e => e.target.select(),
                },
                // eslint-disable-next-line
                inputRef: node => digitInputs.digit0 = node,
                inject: ({ submit }) => ({
                  onChange: (event, newValue) =>
                  // eslint-disable-next-line
                  (isNumeric(newValue) ?
                      digitInputs.digit1.value &&
                      digitInputs.digit2.value &&
                      digitInputs.digit3.value &&
                      digitInputs.digit4.value &&
                      digitInputs.digit5.value ?
                        // NOTE: submit in the next tick so that digit's value is captured by redux-form
                        setTimeout(submit, 0) :
                        digitInputs.digit1.focus()
                      : null),
                }),
              },
              {
                name: 'digit1',
                component: TextField,
                normalize: normalize.toNumber,
                colSpans: { xs: 1 },
                inputProps: {
                  style: {
                    textAlign: 'center',
                  },
                  maxLength: 1,
                  onClick: e => e.target.select(),
                  onFocus: e => e.target.select(),
                  onKeyDown: ({ key }) => key === 'Backspace' && digitInputs.digit1.value === '' && digitInputs.digit0.focus(),
                },
                // eslint-disable-next-line
                inputRef: node => digitInputs.digit1 = node,
                inject: ({ submit }) => ({
                  onChange: (event, newValue) =>
                  // eslint-disable-next-line
                  (isNumeric(newValue) ?
                      digitInputs.digit0.value &&
                      digitInputs.digit2.value &&
                      digitInputs.digit3.value &&
                      digitInputs.digit4.value &&
                      digitInputs.digit5.value ?
                        // NOTE: submit in the next tick so that digit's value is captured by redux-form
                        setTimeout(submit, 0) :
                        digitInputs.digit2.focus()
                      : null),
                }),
              },
              {
                name: 'digit2',
                component: TextField,
                normalize: normalize.toNumber,
                colSpans: { xs: 1 },
                inputProps: {
                  style: {
                    textAlign: 'center',
                  },
                  maxLength: 1,
                  onClick: e => e.target.select(),
                  onFocus: e => e.target.select(),
                  onKeyDown: ({ key }) => key === 'Backspace' && digitInputs.digit2.value === '' && digitInputs.digit1.focus(),
                },
                // eslint-disable-next-line
                inputRef: node => digitInputs.digit2 = node,
                inject: ({ submit }) => ({
                  onChange: (event, newValue) =>
                  // eslint-disable-next-line
                  (isNumeric(newValue) ?
                      digitInputs.digit0.value &&
                      digitInputs.digit1.value &&
                      digitInputs.digit3.value &&
                      digitInputs.digit4.value &&
                      digitInputs.digit5.value ?
                        // NOTE: submit in the next tick so that digit's value is captured by redux-form
                        setTimeout(submit, 0) :
                        digitInputs.digit3.focus()
                      : null),
                }),
              },
              {
                name: 'digit3',
                component: TextField,
                normalize: normalize.toNumber,
                colSpans: { xs: 1 },
                inputProps: {
                  style: {
                    textAlign: 'center',
                  },
                  maxLength: 1,
                  onClick: e => e.target.select(),
                  onFocus: e => e.target.select(),
                  onKeyDown: ({ key }) => key === 'Backspace' && digitInputs.digit3.value === '' && digitInputs.digit2.focus(),
                },
                // eslint-disable-next-line
                inputRef: node => digitInputs.digit3 = node,
                inject: ({ submit }) => ({
                  onChange: (event, newValue) =>
                  // eslint-disable-next-line
                  (isNumeric(newValue) ?
                      digitInputs.digit0.value &&
                      digitInputs.digit1.value &&
                      digitInputs.digit2.value &&
                      digitInputs.digit4.value &&
                      digitInputs.digit5.value ?
                        // NOTE: submit in the next tick so that digit's value is captured by redux-form
                        setTimeout(submit, 0) :
                        digitInputs.digit4.focus()
                      : null),
                }),
              },
              {
                name: 'digit4',
                component: TextField,
                normalize: normalize.toNumber,
                colSpans: { xs: 1 },
                inputProps: {
                  style: {
                    textAlign: 'center',
                  },
                  maxLength: 1,
                  onClick: e => e.target.select(),
                  onFocus: e => e.target.select(),
                  onKeyDown: ({ key }) => key === 'Backspace' && digitInputs.digit4.value === '' && digitInputs.digit3.focus(),
                },
                // eslint-disable-next-line
                inputRef: node => digitInputs.digit4 = node,
                inject: ({ submit }) => ({
                  onChange: (event, newValue) =>
                  // eslint-disable-next-line
                  (isNumeric(newValue) ?
                      digitInputs.digit0.value &&
                      digitInputs.digit1.value &&
                      digitInputs.digit2.value &&
                      digitInputs.digit3.value &&
                      digitInputs.digit5.value ?
                        // NOTE: submit in the next tick so that digit's value is captured by redux-form
                        setTimeout(submit, 0) :
                        digitInputs.digit5.focus()
                      : null),
                }),
              },
              {
                name: 'digit5',
                component: TextField,
                normalize: normalize.toNumber,
                colSpans: { xs: 1 },
                inputProps: {
                  style: {
                    textAlign: 'center',
                  },
                  maxLength: 1,
                  onClick: e => e.target.select(),
                  onFocus: e => e.target.select(),
                  onKeyDown: ({ key }) => key === 'Backspace' && digitInputs.digit5.value === '' && digitInputs.digit4.focus(),
                },
                // eslint-disable-next-line
                inputRef: node => digitInputs.digit5 = node,
                inject: ({ submit }) => ({
                  onChange: (event, newValue) =>
                    isNumeric(newValue) &&
                    digitInputs.digit0.value &&
                    digitInputs.digit1.value &&
                    digitInputs.digit2.value &&
                    digitInputs.digit3.value &&
                    digitInputs.digit4.value &&
                    // NOTE: submit in the next tick so that digit's value is captured by redux-form
                    setTimeout(submit, 0),
                }),
              },
            ],
          },
        ],
      },
    ],
  },
  footer: {
    itemProps: {
      style: {
        marginTop: 50,
      },
    },
    containerProps: {
      justify: 'center',
    },
    components: [
      {
        id: uuidv4(),
        name: 'OTPInfo',
        colSpans: {
          xs: 7,
        },
        component: ({ submitting, sendOTP, buttonName }) =>
          <Grid container direction='column' style={{ paddingBottom: 70 }}>
            <Grid key={uuidv4()} item >
              <Button type='submit' variant='contained' color='primary' className='milo-btn-submit-medium' style={{ width: '100%' }} disabled={submitting} >{buttonName}</Button>
            </Grid>
            {/* <Grid key={uuidv4()} item >
                <Button variant="contained" color='primary' className='milo-btn-grey' style={{ width: '100%' }} disabled={submitting} onClick={sendOTP}>Resend OTP</Button>
            </Grid> */}
          </Grid>,
        inject: ({
          submitting,
          onSendOTP: sendOTP,
          runtimeProps: { header: { banner: { buttonName } } },
        }) => ({ submitting, sendOTP, buttonName }),
      },
    ],
  },
};

const validate = ({
  otp: {
    digit0, digit1, digit2, digit3, digit4, digit5,
  } = {},
}) => {
  const errors = { otp: {} };
  digit0 === '' && (errors.otp.digit0 = 'Oops');
  digit1 === '' && (errors.otp.digit1 = 'Oops');
  digit2 === '' && (errors.otp.digit2 = 'Oops');
  digit3 === '' && (errors.otp.digit3 = 'Oops');
  digit4 === '' && (errors.otp.digit4 = 'Oops');
  digit5 === '' && (errors.otp.digit5 = 'Oops');
  return errors;
};

const config = {
  form: 'verifyOTP',
  validate,
};

@withStyles(styles, { index: 1 })
@withReduxForm({ layout, config })
class OTP extends PureComponent {
  static propTypes = {
    /* container */
    verifyOTP: PropTypes.func.isRequired,
    sendOTP: PropTypes.func.isRequired,
    /* withStyles */
    classes: PropTypes.object,
    /* createReduxForm */
    Form: PropTypes.func.isRequired,
  };

  render() {
    const {
      classes,
      Form, initialValues, runtimeProps,
      verifyOTP, sendOTP,
    } = this.props;

    // console.log('%crender OTP', 'font-size: 12px; color: #00b3b3', this.props);

    return (
      <React.Fragment>
        <HeaderComponent isOnBoard={true} />
        <div style={{paddingTop: 50}}>
        <Back className={classes.back} label='Log In' backToWebsite={true} path='/auth' />
        <Grid container className={classes.pageContainer} alignItems='center'>
          <Grid item xs />
          <Grid item xs={5}>
            <Form
              autocomplete='off'
              runtimeProps={runtimeProps}
              initialValues={initialValues}
              onSubmit={verifyOTP}
              onSendOTP={sendOTP}
              sections={[]}
            />
          </Grid>
          <Grid item xs />
        </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default OTP;
