import { combineReducers } from 'redux';
import list, { epics as listEpics } from './list';
import profileCard, { epics as profileCardEpics } from './profileCard';

export const epics = [
  ...profileCardEpics,
  ...listEpics,
];

export default combineReducers({
  profileCard,
  list,
});
