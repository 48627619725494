/* Epics */
import { Observable } from 'rxjs/Observable';
import { replace } from 'react-router-redux';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/delay';

/* Libs */
import DM from 'dataModule';
import { allPass, compose, propEq, complement, isNil, path } from 'ramda';

/*
**************************************************
  Action Types
**************************************************
*/
const DEFAULT_CURRENT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 9e6;

const actionTypes = {
  FETCH_PAGE: Symbol('FETCH_PAGE'),
  FETCH_DISCHARGED_PAGE: Symbol('FETCH_DISCHARGED_PAGE'),
  FETCH_PAGE_SUCCESS: Symbol('FETCH_PAGE_SUCCESS'),
  FETCH_PAGE_FAILURE: Symbol('FETCH_PAGE_FAILURE'),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  fetchPage: payload => ({ type: actionTypes.FETCH_PAGE, payload }),
  fetchDischargedPage: payload => ({ type: actionTypes.FETCH_DISCHARGED_PAGE, payload }),
  fetchPageSuccess: payload => ({ type: actionTypes.FETCH_PAGE_SUCCESS, payload }),
  fetchPageFailure: payload => ({ type: actionTypes.FETCH_PAGE_FAILURE, payload }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/
const epics = [
  // fetchPage
  action$ =>
    action$.ofType(actionTypes.FETCH_PAGE).mergeMap(({ payload: { patientId, page, pageSize, epId = 0 } }) =>
      Observable.fromPromise(DM.program.provider.fetchEnrolledProgramListNoPagination({
        patientId,
        page: page || DEFAULT_CURRENT_PAGE,
        pageSize: pageSize || DEFAULT_PAGE_SIZE,
      }))
        .map(payload => ({ type: actionTypes.FETCH_PAGE_SUCCESS, payload, epId }))
        .catch(error => Observable.of(actionCreators.fetchPageFailure({ error })))),
  (action$, store) =>
    action$
      .filter(allPass([
        propEq('type', actionTypes.FETCH_PAGE_SUCCESS),
        compose(complement(isNil), path(['payload', 'list', 0])),
      ]))
      .map(({ payload: { list }, epId }) =>
        replace(`${store.getState().router.location.pathname}?id=${!epId ? path([0, 'id'], list) : epId}`)
      ),

  // fetchDischargedPage
  action$ =>
    action$.ofType(actionTypes.FETCH_DISCHARGED_PAGE).mergeMap(({ payload: { patientId, page, pageSize, epId = 0 } }) =>
      Observable.fromPromise(DM.program.provider.fetchDischargedEnrolledProgramListNoPagination({
        patientId,
        page: page || DEFAULT_CURRENT_PAGE,
        pageSize: pageSize || DEFAULT_PAGE_SIZE,
      }))
        .map(payload => ({ type: actionTypes.FETCH_PAGE_SUCCESS, payload, epId }))
        .catch(error => Observable.of(actionCreators.fetchPageFailure({ error })))),
  (action$, store) =>
    action$
      .filter(allPass([
        propEq('type', actionTypes.FETCH_PAGE_SUCCESS),
        compose(complement(isNil), path(['payload', 'list', 0])),
      ]))
      .map(({ payload: { list }, epId }) =>
        replace(`${store.getState().router.location.pathname}?id=${!epId ? path([0, 'id'], list) : epId}`)
      ),
];

export { epics };

/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
  // prevList: [],
  list: [],
  pagination: { currentPage: 0, pageSize: 10, totalCount: 0 },
  loading: true,
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PAGE: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.FETCH_DISCHARGED_PAGE: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.FETCH_PAGE_SUCCESS: {
      const { list, pagination } = action.payload;
      return {
        ...state,
        list,
        pagination,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};
