/* eslint-disable arrow-body-style */
/* eslint-disable max-len */
import React from 'react';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import * as _ from 'lodash';
import { roundFloatToFixedDecimal } from 'utilsModule/common';
import BigNumber from 'bignumber.js';

const invoiceHeader = (patientInvoice) => {
  const billingCountry = _.get(patientInvoice, 'recipientInfo.address.country', null);
  return (
    <Grid style={{ padding: '15px' }}>
      <Grid container>
        <Grid item xs={4} style={{ textAlign: 'left' }}>
          <table>
            <tr>
              <td>{patientInvoice.issuerOrgName}</td>
            </tr>
            <tr>
              <td>{patientInvoice.issuerOrgInfo.address[0].line_1}</td>
            </tr>
            {
              patientInvoice.issuerOrgInfo.address[0].line_2 ?
                <tr>
                  <td> {patientInvoice.issuerOrgInfo.address[0].line_2} {patientInvoice.issuerOrgInfo.address[0].line_2}</td>
                </tr> : ''
            }
            <tr>
              <td> {patientInvoice.issuerOrgInfo.address[0].country} {patientInvoice.issuerOrgInfo.address[0].postalCode}</td>
            </tr>
            <tr>
              <td>
                {
                  (patientInvoice.issuerOrgInfo.taxRegistrationNumber && billingCountry === 'Singapore') ?
                    `GST Reg. No. ${patientInvoice.issuerOrgInfo.taxRegistrationNumber}` : ''
                }
              </td>
            </tr>
          </table>
        </Grid>

        <Grid item xs={4} style={{ textAlign: 'center' }}>
          <div style={{ textAlign: 'center' }}>
            {
              patientInvoice.isCovidInvoice
                ? <img src={patientInvoice.issuerOrgInfo.logoUrl} alt='' style={{ width: '150px' }} />
                : <img src={patientInvoice.issuerOrgInfo.signedlogoUrl} alt='' style={{ width: '150px' }} />
            }
          </div>
          <h2 style={{ textAlign: 'center' }}>
            {
              patientInvoice.isRefund ? 'Credit Note' : 'Tax Invoice'
            }
          </h2>
        </Grid>

        <Grid item xs={4}>
          {/* Place Holder */}
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={4} style={{ textAlign: 'left' }}>
          <table>
            <tr>
              <td><b>INVOICE TO:</b></td>
            </tr>
            <tr>
              <b>{patientInvoice.recipientInfo.lastname}, {patientInvoice.recipientInfo.firstname}</b>
            </tr>
            <tr>
              <td>{patientInvoice.recipientInfo.address.line}</td>
            </tr>
            <tr>
              <td>
                {patientInvoice.recipientInfo.address.country} {patientInvoice.recipientInfo.address.postalCode}
              </td>
            </tr>
          </table>
        </Grid>

        <Grid item xs={4} style={{ textAlign: 'center' }}>
          {/* Placeholder */}
        </Grid>

        <Grid item xs={4}>
          <Grid container justify='flex-end'>
            <Grid item>
              <table>
                <tr>
                  <td style={{ textAlign: 'right' }}>
                    {
                      patientInvoice.isRefund ? 'Credit Note No:' :
                        'Invoice No:'
                    }
                  </td>
                  <td>{patientInvoice.invoiceNo}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'right' }}>
                    {
                      patientInvoice.isRefund ? 'Credit Note Date:' :
                        'Invoice Date:'
                    }
                  </td>
                  <td>{moment.tz(patientInvoice.statementDate, patientInvoice.timezone).format('DD MMM YYYY')}</td>
                </tr>
                {
                  patientInvoice.type === 'SUBSCRIPTION' && !patientInvoice.isCovidInvoice ?
                    <React.Fragment>
                      <tr>
                        <td style={{ textAlign: 'right' }}>Due Date:</td>
                        <td>
                          {
                            patientInvoice.isRefund ? 'Not Applicable' :
                              moment.tz(patientInvoice.paymentDueDate, patientInvoice.timezone).format('DD MMM YYYY')
                          }
                        </td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: 'right' }}>Terms:</td>
                        <td>
                          {
                            patientInvoice.isRefund ? 'Not Applicable' :
                              `${patientInvoice.paymentTerm} Days`
                          }
                        </td>
                      </tr>
                    </React.Fragment>
                    : ''
                }
                <tr>
                  <td style={{ textAlign: 'right' }}>Currency:</td>
                  <td>{patientInvoice.currency}</td>
                </tr>
              </table>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  );
};

const invoiceTableHeader = () => {
  return (
    <thead style={{ borderBottom: '1px solid black' }}>
      <tr>
        <th
          style={{
            width: '30%',
            height: 50,
            fontSize: 14,
            lineHeight: '17px',
          }}>Description</th>
        <th
          style={{
            width: '20%',
            height: 50,
            fontSize: 14,
            lineHeight: '17px',
          }}>Charge <br /> Code</th>
        <th
          style={{
            width: '5%',
            height: 50,
            fontSize: 14,
            lineHeight: '17px',
          }}>Qty</th>
        <th style={{
          width: '10%',
          height: 50,
          fontSize: 14,
          lineHeight: '17px',
        }}>Unit Price</th>
        <th
          style={{
            width: '10%',
            height: 50,
            fontSize: 14,
            lineHeight: '17px',
          }}>Amount<br />Exclude<br />Tax</th>
        <th style={{
          width: '5%',
          height: 50,
          fontSize: 14,
          lineHeight: '17px',
        }}>Tax</th>
        <th style={{
          width: '10%',
          height: 50,
          fontSize: 14,
          lineHeight: '17px',
        }}>Amount<br />Include<br />Tax</th>
      </tr>
    </thead>
  );
};

const invoiceTableBody = (patientInvoice) => {
  return (
    <tbody>
      {patientInvoice.patientInvoiceLines.map((line, i) => {
        const background = line.sequence === 0 ? '#8080803b' : '#fff';
        return (
          <tr key={line.id} style={{ verticalAlign: 'text-top', background }}>
            <td style={{
              padding: '5px',
              paddingLeft: '10px',
              paddingBottom: (line.sequence === 0) ? '5px' : '0px',
              paddingTop: (line.sequence !== 0 && line.itemCodeCode === null && line.sequence !== 1) ? '15px' : '5px',
            }}>
              {
                line.itemCodeCode === null ?
                  <b> {line.description} </b> :
                  line.description
              }
            </td>
            <td style={{ textAlign: 'center' }}> {line.itemCodeCode} </td>
            <td style={{ textAlign: 'center' }}> {line.quantity} </td>
            { line.priceBeforeTax ? <td style={{ textAlign: 'right' }}> {roundFloatToFixedDecimal(line.priceBeforeTax, 2).toFixed(2)} </td> : <td />}
            { line.taxAmount ? <td style={{ textAlign: 'right' }}> {roundFloatToFixedDecimal(line.priceBeforeTax, 2).toFixed(2)} </td> : <td />}
            { line.taxAmount ? <td style={{ textAlign: 'right' }}> {roundFloatToFixedDecimal(line.taxAmount, 2).toFixed(2)} </td> : <td />}
            { line.amount ? <td style={{ textAlign: 'right' }}> {roundFloatToFixedDecimal(line.amount, 2).toFixed(2)} </td> : <td />}
          </tr>
        );
      })}

      <tr>
        <td colSpan='7' style={{ height: '1px', borderBottom: '1px solid black' }}>
        </td>
      </tr>
      <tr>
        <td colSpan='7' style={{ height: '10px' }}>
        </td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td style={{ textAlign: 'right' }} colSpan={2}>Subtotal</td>
        <td style={{ textAlign: 'right' }}>{roundFloatToFixedDecimal(patientInvoice.subTotal, 2).toFixed(2)}</td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td style={{ textAlign: 'right' }} colSpan={2}>Tax Total @ {roundFloatToFixedDecimal(Number(patientInvoice.issuerOrgInfo.taxRate, 0)).toFixed()}%</td>
        <td style={{ textAlign: 'right' }}>{roundFloatToFixedDecimal(patientInvoice.taxTotal, 2).toFixed(2)}</td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td style={{ textAlign: 'right' }} colSpan={2}><b>TOTAL </b></td>
        <td style={{ textAlign: 'right' }}><b>{roundFloatToFixedDecimal(patientInvoice.total, 2).toFixed(2)}</b></td>
      </tr>
      <tr>
        <td colSpan='7' style={{ height: '10px' }}>
        </td>
      </tr>
    </tbody>
  );
};

const invoiceSummary = (invoiceData) => {
  const carryForwardBalance = invoiceData.additionalData.previousInvoice ?
  roundFloatToFixedDecimal(Number(invoiceData.additionalData.previousInvoice.balanceDue, 2).toFixed(2)) : 0;
  const payment = invoiceData.additionalData.latestPayment;
  const paymentDue = (new BigNumber(carryForwardBalance).minus(new BigNumber(payment))).plus(new BigNumber(invoiceData.patientInvoice.total));

  return (
    <Grid style={{ padding: '15px', paddingBottom: 0 }}>
      <Grid container>
        <Grid item xs={12}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead style={{ borderTop: '1px solid black', borderBottom: '1px solid black' }}>
              <tr>
                <td style={{ textAlign: 'center', padding: 10 }}>
                  <b>Summary</b>
                </td>
                <td style={{ textAlign: 'center', padding: 10 }}>
                  <b>Amount</b>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Carry Forward Balance</td>
                <td style={{ textAlign: 'right' }}>{carryForwardBalance}</td>
              </tr>
              <tr>
                <td>Payment Received</td>
                <td style={{ textAlign: 'right' }}>
                  {invoiceData.additionalData.latestPayment ? roundFloatToFixedDecimal(invoiceData.additionalData.latestPayment, 2).toFixed(2) : '-'}
                </td>
              </tr>
              <tr>
                <td>Current Charges</td>
                <td style={{ textAlign: 'right' }}>
                  {roundFloatToFixedDecimal(invoiceData.patientInvoice.total, 2).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Payment Due</b>
                </td>
                <td style={{ textAlign: 'right' }}>
                  <b>{roundFloatToFixedDecimal(paymentDue, 2).toFixed(2)}</b>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr style={{ height: 15, borderTop: '1px solid black', borderBottom: '1px solid black' }}>
                <td>
                </td>
                <td >
                </td>
              </tr>
            </tfoot>
          </table>
        </Grid>
      </Grid>
    </Grid>
  );
};

const invoiceBody = (invoiceData) => {
  return (
    <Grid style={{ padding: '15px', paddingTop: 0 }}>
      <Grid container>
        <Grid item xs={12}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            {invoiceTableHeader(invoiceData.patientInvoice.currency)}
            {invoiceTableBody(invoiceData.patientInvoice, invoiceData.groupNames)}
          </table>
        </Grid>
      </Grid>
    </Grid>
  );
};

const invoiceFooter = () => {
  return (
    <Grid style={{ padding: '15px', paddingTop: 0 }}>
      <Grid container>
        <Grid item xs={12}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead style={{ borderTop: '1px solid black', borderBottom: '1px solid black' }}>
              <tr>
                <td colSpan='2' style={{ textAlign: 'center' }}><b>PAYMENT METHODS</b></td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: '60%' }}>
                  <b>Pay through HealthBeats®</b><br />
                  Payments can be made through HealthBeats® app<br />using credit or debit cards.
                </td>
                <td style={{ width: '30%' }}>
                  <b>Pay over Counter</b><br />
                  Please visit our clinic for payment.
                </td>
              </tr>
            </tbody>
          </table>
        </Grid>
      </Grid>
    </Grid>
  );
};

const generateInvoiceHTML = (invoiceData) => {
  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: '#FFF',
        fontSize: '14px',
      }}>
      { invoiceHeader(invoiceData.patientInvoice)}
      { invoiceSummary(invoiceData)}
      { invoiceBody(invoiceData)}
      { invoiceFooter()}
    </div>
  );
};

export default generateInvoiceHTML;
