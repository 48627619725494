/* eslint-disable */
/* Constants */

/* Action Creators */

/* Epics */
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/catch';

/* Reducer */
import vital from 'appModule/vital/ducks';
/* Libs */
import { combineReducers } from 'redux';
import DM from 'dataModule';
import { push } from 'react-router-redux';
import get from 'lodash/get';

/*
**************************************************
  Action Types
**************************************************
*/
const prefix = "program/enroll";

const actionTypes = {
  SHOW_PAGE: Symbol(`${prefix}/SHOW_PAGE`),
  SHOW_ENROLL_ADDON_PAGE: Symbol(`${prefix}/SHOW_ENROLL_ADDON_PAGE`),
  SELECT_BASE: Symbol(`${prefix}/SELECT_BASE`),
  SAVE: Symbol(`${prefix}/SAVE`),
  SAVE_SUCCESS: Symbol(`${prefix}/SAVE_SUCCESS`),
  SAVE_FAILURE: Symbol(`${prefix}/SAVE_FAILURE`),
  ENROLL: Symbol(`${prefix}/ENROLL`),
  ENROLL_SUCCESS: Symbol(`${prefix}/ENROLL_SUCCESS`),
  ENROLL_FAILURE: Symbol(`${prefix}/ENROLL_FAILURE`),
  SAVE_FORM_URL: Symbol(`${prefix}/SAVE_FORM_URL`),
  GET_CONTRACT_ID: Symbol(`${prefix}/GET_CONTRACT_ID`),
  SET_CONTRACT_ID: Symbol(`${prefix}/SET_CONTRACT_ID`),
  REGISTER_BASE_EP: 'REGISTER_BASE_EP',
  UNREGISTER_BASE_EP: 'UNREGISTER_BASE_EP'
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  showPage: payload => ({ type: actionTypes.SHOW_PAGE, payload }),
  showEnrollAddonPage: payload => ({ type: actionTypes.SHOW_ENROLL_ADDON_PAGE, payload }),
  selectBase: payload => ({ type: actionTypes.SELECT_BASE, payload }),
  registerBaseEp: payload => ({ type: actionTypes.REGISTER_BASE_EP, payload }),
  unregisterBaseEp: payload => ({ type: actionTypes.UNREGISTER_BASE_EP, payload }),
  save: payload => ({ type: actionTypes.SAVE, payload }),
  saveSuccess: payload => ({ type: actionTypes.SAVE_SUCCESS, payload }),
  saveFailure: payload => ({ type: actionTypes.SAVE_FAILURE, payload }),
  enroll: payload => ({ type: actionTypes.ENROLL, payload }),
  enrollSuccess: payload => ({ type: actionTypes.ENROLL_SUCCESS, payload }),
  enrollFailure: payload => ({ type: actionTypes.ENROLL_FAILURE, payload }),
  saveFormURL: payload => ({ type: actionTypes.SAVE_FORM_URL, payload }),
  // getConsentId: payload => ({ type: actionTypes.GET_CONSENT_ID, payload }),
  // getLoanId: payload => ({ type: actionTypes.GET_LOAN_ID, payload }),
  getContractIds: payload => ({ type: actionTypes.GET_CONTRACT_ID, payload }),
  // setConsentId: payload => ({ type: actionTypes.SET_CONSENT_ID, payload }),
  // setLoanId: payload => ({ type: actionTypes.SET_LOAN_ID, payload }),
  setContractIds: payload => ({ type: actionTypes.SET_CONTRACT_ID, payload }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/
const epics = [
  action$ => action$.ofType(actionTypes.SHOW_PAGE)
    .map(({ payload: { patientId, apId } }) => push(`/app/participants/${patientId}/enroll/${apId}`)),

  action$ => action$.ofType(actionTypes.SHOW_ENROLL_ADDON_PAGE)
    .map(({ payload: { patientId, apId } }) => push(`/app/participants/${patientId}/enroll/addon/${apId}`)),

  action$ => action$.ofType(actionTypes.SELECT_BASE)
    .map(({ payload: { patientId } }) => push(`/app/participants/${patientId}/enroll/addon`)),
    
  // Enroll
  action$ => action$.ofType(actionTypes.ENROLL)
    .mergeMap(({ payload: { patientId, program, onEnrollSuccess, onEnrollFailure } }) =>
      Observable.fromPromise(DM.program.provider.enrollProgram({ patientId, program }))
        .map(program => {
          onEnrollSuccess(program);
          return actionCreators.enrollSuccess({ patientId });
        })
        .catch(error => {
          onEnrollFailure(error);
          return Observable.of(actionCreators.enrollFailure({ error }));
        })),
  action$ => action$.ofType(actionTypes.ENROLL_SUCCESS).map(({ payload: { patientId } }) => push(`/app/participants/${patientId}/program`)),

  // Save as draft
  action$ => action$.ofType(actionTypes.SAVE)
    .mergeMap(({ payload: { patientId, program, onSaveSuccess, onSaveFailure } }) =>
      Observable.fromPromise(DM.program.provider.saveDraftEnrolledProgram({ patientId, program }))
        .map(program => {
          console.log('program saved: ', program);
          onSaveSuccess(program);
          return actionCreators.saveSuccess({ patientId });
        })
        .catch(error => {
          console.log('%cerror', 'font-size: 12px; color: #00b3b3', error);
          onSaveFailure(error);
          return Observable.of(actionCreators.saveFailure({ error }));
        })),
  action$ => action$.ofType(actionTypes.SAVE_SUCCESS).map(({ payload: { patientId } }) => push(`/app/participants/${patientId}/program`)),
  action$ => action$.ofType(actionTypes.SAVE_FORM_URL).map(({ payload: { new_forms, patientId, apId, enrollPP } }) => push(`/app/participants/${patientId}/enroll/${apId}/form/loan`, enrollPP)),
  action$ => action$.ofType(actionTypes.GET_CONTRACT_ID)
    .mergeMap(({ payload: { patientId } }) =>
      Observable.fromPromise(Promise.all([DM.program.provider.getConsentId({ patientId }), DM.program.provider.getLoanId({ patientId })]))
        .map(ids => {
          return actionCreators.setContractIds({
            consentId: get(ids[0], 'value.contractNo', null),
            loanId: get(ids[1], 'value.contractNo', null)
          });
        })
        .catch(error => {
          console.log('%cerror', 'font-size: 12px; color: #00b3b3', error);
          onEnrollFailure(error);
          return Observable.of(actionCreators.enrollFailure({ error }));
        })),
];

export { epics };
/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
  patientId: null,
  apId: null,
  epId: null,
  forms: [{ type: 'consent', url: null, contractId: null }, { type: 'loan', url: null, contractId: null }],
  consentId: null,
  loanId: null,
  baseEp: {
    isAddOn: false,
    baseEnrolledProgram: null
  }
};

const params = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_PAGE: {
      const { patientId, apId } = action.payload;
      return { ...state, patientId, apId };
    }
    case actionTypes.SHOW_ENROLL_ADDON_PAGE: {
      const { patientId, apId, epId } = action.payload;
      return { ...state, patientId, apId, epId };
    }
    case actionTypes.SELECT_BASE: {
      const { patientId, epId } = action.payload;
      return { ...state, patientId, epId };
    }
    case actionTypes.REGISTER_BASE_EP:
      return { ...state, baseEp: { ...action.payload } };
    case actionTypes.UNREGISTER_BASE_EP:
      return { ...state, baseEp: initState.baseEp };
    case actionTypes.SAVE_FORM_URL: {
      const { new_forms } = action.payload;
      const forms = state.forms.map(f => new_forms.find(n => n.type === f.type) || f);;
      return { ...state, forms };
    }
    case actionTypes.SET_CONTRACT_ID: {
      const { consentId, loanId } = action.payload;
      return { ...state, consentId, loanId };
    }

    default: {
      return state;
    }
  }
};
/*
**************************************************
  Reducer
**************************************************
*/
export default combineReducers({
  vital,
  params,
});