import React from 'react';
import { Loading } from 'utilsModule/components';
import { withResource, resourceTypes, gettersOf } from 'dataModule/store/resources';
import generateInvoiceHTML from '../components/PatientInvoice';

@withResource([
  {
    resourceType: resourceTypes.BILLING,
    method: 'retrievePatientInvoiceByUuid',
    input: ({ location: { query: params } }) => ({
      params: {
        queries: [
          { name: 'type', value: 'PATIENT' }, 
          { name: 'session-token', value: params['session-token'] 
      },],
      ids: [{ name: 'uuid', value: params.uuid }],
      },
    }),
    options: { runOnDidMount: true },
  },
])
class PatientInvoice extends React.Component {
  render() {
    const { data } = this.props;
    if (data.status.loading) return <Loading />;

    const { value: invoiceData } = gettersOf(resourceTypes.BILLING).getPatientInvoiceByUuid()(data);
    return generateInvoiceHTML(invoiceData);
  }
}

export default PatientInvoice;
