/* eslint-disable */
import get from 'lodash/get';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/catch';
import DM from 'dataModule';

/*
**************************************************
  Action Types
**************************************************
*/
const prefix = 'DEVICE';

const actionTypes = {
  DEVICE_AVAILABLE_LIST: Symbol(`${prefix}/DEVICE_AVAILABLE_LIST`),
  DEVICE_AVAILABLE_LIST_SUCCESS: Symbol(`${prefix}/DEVICE_AVAILABLE_LIST_SUCCESS`),
  DEVICE_AVAILABLE_LIST_FAILURE: Symbol(`${prefix}/DEVICE_AVAILABLE_LIST_FAILURE`),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  deviceAvailableList: payload => ({ type: actionTypes.DEVICE_AVAILABLE_LIST, payload }),
  deviceAvailableListSuccess: payload => ({ type: actionTypes.DEVICE_AVAILABLE_LIST_SUCCESS, payload }),
  deviceAvailableListFailure: payload => ({ type: actionTypes.DEVICE_AVAILABLE_LIST_FAILURE, payload }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/
const epics = [
  // List all serialized item (devices) 
  action$ =>
    action$.ofType(actionTypes.DEVICE_AVAILABLE_LIST).mergeMap(({ payload: { info, onDeviceAvailableSuccess, onDeviceAvailableFailure } }) =>
      DM.device
        .deviceAvailableList(info)
        .map(res => {
          onDeviceAvailableSuccess(get(res, 'value.devices', []));
          return actionCreators.deviceAvailableListSuccess({
            availableDevices: get(res, 'value.devices', []),
          });
        })
        .catch(error => {
          onDeviceAvailableFailure(res);
          return Observable.of(actionCreators.deviceAvailableListFailure({ error: error.message }));
        })
    ),
];

export { epics };

/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
  list: {
    availableDevices: []
  },
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.DEVICE_AVAILABLE_LIST: {
      return { ...state, list: { availableDevices: [] } };
    }
    case actionTypes.DEVICE_AVAILABLE_LIST_SUCCESS: {
      const { availableDevices } = action.payload;
      return { ...state, list: { availableDevices } };
    }

    default: {
      return state;
    }
  }
};
