import moment from 'moment';
import * as R from 'ramda';
import React from 'react';
import { Paper, Grid, Button, Typography } from '@material-ui/core';
import SignaturePad from 'appModule/signature/containers/signaturePad';
import commonUtil from 'utilsModule/common';
import { connect } from 'react-redux';

const style = {
  trLeftColumn: {
    background: '#8080803b',
    width: '30%',
    fontWeight: '500',
  },
  lineInput: {
    borderStyle: 'groove',
    borderWidth: '0px 0px 2px 0px',
    fontSize: '16px',
    marginBottom: '15px',
  },
  signaturePadInput: {
    borderWidth: '0px 0px 2px 0px',
    marginLeft: '10px',
    borderStyle: 'groove',
    fontWeight: '400',
    fontSize: '16px',
  },
  sticky: {
    position: 'sticky',
    bottom: 0,
    background: 'inherit',
    zIndex: 9,
  },
  bold: {
    fontWeight: '500',
  },
};

@connect(state => ({
  currentOrganization: R.path(['resources', 'users', 'retrieveCurrent', 'organization'], state),
}), null)
class CRForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      target_signature_ref: null,
      awaitPerformAction: false,
    };
  }

  _handleClose = () => {
    this.setState({ open: false });
  }

  _signatureInput = (id, w) => {
    let _signatureRef = null;
    return (
      <div onClick={() => this.setState({ open: true, target_signature_ref: _signatureRef })} style={{ width: '250px', marginBottom: '15px' }}>
        <img id={id} style={{ maxWidth: '250px', maxHeight: '200px' }} ref={r => _signatureRef = r}
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACWCAYAAABkW7XSAAAEaElEQVR4Xu3UgQkAMAwCwXb/oS10i4fLBHIG77YdR4AAgYDANViBlkQkQOALGCyPQIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiBgsPwAAQIZAYOVqUpQAgQMlh8gQCAjYLAyVQlKgIDB8gMECGQEDFamKkEJEDBYfoAAgYyAwcpUJSgBAgbLDxAgkBEwWJmqBCVAwGD5AQIEMgIGK1OVoAQIGCw/QIBARsBgZaoSlAABg+UHCBDICBisTFWCEiDwAIGUVl1ZhH69AAAAAElFTkSuQmCC" />
        <div style={R.merge(style.signaturePadInput, { width: w || 'auto' })} />
      </div>
    );
  }

  getiHealthCareRegisteredText = () => (
    <React.Fragment>
      iHealth<sup style={{ display: 'inline-block' }}><span style={{ fontSize: '12px' }}>&reg;</span></sup>
    </React.Fragment>
  )

  renderParticipantDetails = (crFormData, isReadOnly) => {
    const { participantDetails } = crFormData;
    return (
      <React.Fragment>
        <tr>
          <td style={style.trLeftColumn} rowSpan='2'>Participant Details</td>
          <td>
            <p style={{ margin: '20px auto 20px auto' }}>
              <strong style={style.bold}>Participant Name (in full):</strong>&nbsp;&nbsp;
              {participantDetails.participantName}
            </p>
            <p style={{ margin: '20px auto 20px auto' }}>
              <strong style={style.bold}>ID Type:</strong>&nbsp;&nbsp;
              {participantDetails.participantIDType}
            </p>
            <p style={{ margin: '20px auto 20px auto' }}>
              <strong style={style.bold}>ID No.:</strong>&nbsp;&nbsp;
              {participantDetails.participantIDNumber}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p style={{ margin: '20px auto 20px auto' }}>
              <strong style={style.bold}>Guardian Name (in full) (mandatory when Participant is below 18 years old):</strong>&nbsp;&nbsp;
            {!isReadOnly ?
                <input ref={ref => this.guardianName = ref} style={R.merge(style.lineInput, { width: '80%', marginTop: '5px' })} type='text' /> :
                crFormData && crFormData.guardianName
              }
            </p>
            <p style={{ margin: '20px auto 20px auto' }}>
              <strong style={style.bold}>ID Type:</strong>&nbsp;&nbsp;
            {!isReadOnly ?
                <input ref={ref => this.guardianIDType = ref} style={R.merge(style.lineInput, { width: '50%' })} type='text' /> :
                crFormData && crFormData.guardianIDType
              }
            </p>
            <p style={{ margin: '20px auto 20px auto' }}>
              <strong style={style.bold}>ID No.:</strong>&nbsp;&nbsp;
            {!isReadOnly ?
                <input ref={ref => this.guardianIDNumber = ref} style={R.merge(style.lineInput, { width: '40%' })} type='text' /> :
                crFormData && crFormData.guardianIDNumber
              }
            </p>
          </td>
        </tr>
      </React.Fragment>
    );
  }

  renderExtendDuration = () => (
    <tr>
      <td style={style.trLeftColumn}>Description of Change Request </td>
      <td>
        <div>
          <p>To <strong style={style.bold}><u>extend</u></strong> the Subscription Period: </p>
          <span>Extended Subscription Period for <input style={R.merge(style.lineInput, { width: '7%', textAlign: 'center' })} readOnly type='text' /> Months </span><br />
          <br />
          <span>
            From
            <input style={R.merge(style.lineInput, { width: '28%', textAlign: 'center' })} readOnly type='text' />
            to
            <input style={R.merge(style.lineInput, { width: '28%', textAlign: 'center' })} readOnly type='text' />
          </span>
          <br />
        </div>
        <br />
      </td>
    </tr>
  )

  renderAddDevice = () => (
    <tr>
      <td style={style.trLeftColumn}>Description of Change Request </td>
      <td>
        <div>
          <p>To <strong style={style.bold}><u>add</u></strong> the following new {this.getiHealthCareRegisteredText()} Device(s): </p>
          <p>Device/Accessory:</p>
          <p>Model: </p>
          <p>Serial No:</p>
        </div>
      </td>
    </tr>
  )

  renderReplaceDevice = (crFormData) => {
    const { deviceInfo: { existingDevice, newDevice } } = crFormData;
    return (
      <tr>
        <td style={style.trLeftColumn}>Description of Change Request </td>
        <td>
          <div>
            <p style={{ margin: '20px auto 20px auto' }}>To <strong style={style.bold}><u>replace</u></strong> the following existing {this.getiHealthCareRegisteredText()} Device(s) </p>
            <p><u><strong style={style.bold}>Existing/Current </strong>iHealth<sup style={{ display: 'inline-block', borderBottom: 'solid 1px black', lineHeight: '21px' }}><span style={{ fontSize: '12px' }}>&reg;</span></sup> Device</u></p>
            <p>
              Device/Accessory:&nbsp;&nbsp;
              {existingDevice && existingDevice.deviceName}
            </p>
            <p>
              Model:&nbsp;&nbsp;
              {existingDevice && existingDevice.deviceModel}
            </p>
            <p>
              Serial No:&nbsp;&nbsp;
              {existingDevice && existingDevice.serialNo}
            </p>

            <p><u><strong style={style.bold}>New<sup style={{ display: 'inline-block', borderBottom: 'solid 1px black', lineHeight: '22px' }}>* </sup>
            </strong> iHealth<sup style={{ display: 'inline-block', borderBottom: 'solid 1px black', lineHeight: '21px' }}>
                <span style={{ fontSize: '12px' }}>&reg;</span></sup> Device</u></p>
            <p>
              Device/Accessory:&nbsp;&nbsp;
              {newDevice && newDevice.deviceName}
            </p>
            <p>
              Model:&nbsp;&nbsp;
              {newDevice && newDevice.deviceModel}
            </p>
            <p style={{ margin: '20px auto 20px auto' }}>
              Serial No:&nbsp;&nbsp;
              {newDevice && newDevice.serialNo}
            </p>
          </div>
        </td>
      </tr>
    );
  }

  renderReturnDevice = (crFormData) => {
    const { deviceInfo: { returnedDevices } } = crFormData;
    return (
      <tr>
        <td style={style.trLeftColumn}>Description of Change Request </td>
        <td>
          <div>
            <p>To <strong style={style.bold}><u>return</u></strong> the following existing {this.getiHealthCareRegisteredText()} Device(s): </p>
            {
              returnedDevices.map(d => (
                <React.Fragment>
                  <p>
                    Device/Accessory:&nbsp;&nbsp;
                    {d.deviceName}
                  </p>
                  <p>
                    Model:&nbsp;&nbsp;
                    {d.deviceModel}
                  </p>
                  <p>
                    Serial No:&nbsp;&nbsp;
                    {d.serialNo}
                  </p>
                  <br />
                </React.Fragment>
              ))
            }
          </div>
        </td>
      </tr>
    );
  }

  renderOthers = isReadOnly => (
    <tr>
      <td style={style.trLeftColumn}>Description of Change Request </td>
      <td>
        <div>
          <p>Others (please provide details): </p>
          {!isReadOnly &&
            <input ref={ref => this.others = ref} style={R.merge(style.lineInput, { width: '100%' })} type='text' />
          }
        </div>
        <br />
      </td>
    </tr>
  )

  renderTableBody = (crFormData, isReadOnly) => {
    const { action, currentUser } = crFormData;

    return (
      <React.Fragment>
        <tbody>
          {this.renderParticipantDetails(crFormData, isReadOnly)}

          <tr>
            <td style={style.trLeftColumn}>Change Request No.</td>
            <td>{!isReadOnly ? this.props.contractNo : crFormData.changeRequestNo} </td>
          </tr>

          <tr>
            <td style={style.trLeftColumn}>Date of Change Request</td>
            <td>{!isReadOnly ? moment().tz(this.props.currentOrganization.timezone).format('DD-MMM-YYYY') : crFormData && crFormData.dateOfChangeRequest} </td>
          </tr>

          {action === 'EXTEND' && this.renderExtendDuration()}
          {action === 'ADD' && this.renderAddDevice()}
          {action === 'REPLACE' && this.renderReplaceDevice(crFormData)}
          {action === 'RETURN' && this.renderReturnDevice(crFormData)}
          {action === 'OTHERS' && this.renderOthers(isReadOnly)}

          <tr>
            <td style={style.trLeftColumn}>Revision of Subscription Fees, if application</td>
            {!isReadOnly && action !== 'RETURN' && action !== 'REPLACE' ?
              <td>SGD <input ref={ref => this.revisedSubscriptionFees = ref} style={R.merge(style.lineInput, { width: '15%', textAlign: 'center' })} type='number' /></td> :
              <td> {crFormData && crFormData.revisedSubscriptionFees && `SGD ${crFormData.revisedSubscriptionFees}`} </td>
            }
          </tr>

          <tr>
            <td style={style.trLeftColumn}>Effective Date of Change Request</td>
            <td>
              {!isReadOnly ?
                moment().tz(this.props.currentOrganization.timezone).format('DD-MMM-YYYY') :
                crFormData && crFormData.effectiveDateOfChangeRequest
              }
            </td>
          </tr>

          <tr>
            <td style={style.trLeftColumn}>Name of Doctor or Hospital / Clinic Authorised Officer (in full)</td>
            <td>{currentUser.name} </td>
          </tr>

        </tbody>
      </React.Fragment>
    );
  }

  renderSignatureText = (crFormData) => {
    const { action } = crFormData;
    return (
      <React.Fragment>
        <p><strong style={style.bold}>AGREEMENT BY PARTICIPANT</strong></p>
        <p><strong style={style.bold}>I acknowledge and agree that:</strong></p>

        <div style={{ display: 'inline-flex', paddingBottom: '10px' }}>
          <span style={{ paddingRight: '20px' }}>1. </span>
          <p style={{ margin: '0px' }}>
            I agree to the changes set out in this Change Request Form.
        </p>
        </div>
        <br />

        <div style={{ display: 'inline-flex', paddingBottom: '10px' }}>
          <span style={{ paddingRight: '20px' }}>2. </span>
          <p style={{ margin: '0px' }}>
            Save and except for the changes set out in this Change Request Form, all other terms and conditions of my subscription shall continue to apply
            and be valid and enforceable.
        </p>
        </div>
        <br />

        {
          action === 'ADD' || action === 'REPLACE' ?
            <div style={{ display: 'inline-flex', paddingBottom: '10px' }}>
              <span style={{ paddingRight: '20px' }}>3. </span>
              <p style={{ margin: '0px' }}>
                <sup>*</sup> For New {this.getiHealthCareRegisteredText()} Devices - I have received and inspected the {this.getiHealthCareRegisteredText()}&nbsp;
            Devices provided under this Change Request Form and I confirm that the {this.getiHealthCareRegisteredText()} Devices are in good and working condition.
          </p>
            </div> :
            <div />
        }
      </React.Fragment>
    );
  }

  renderSignature = (crFormData, isReadOnly) => (
    <React.Fragment>
      <div style={{ width: '40%', marginLeft: '40px', marginTop: '50px' }}>
        {!isReadOnly ?
          this._signatureInput('participantSignature') :
          <React.Fragment>
            <img style={{ maxWidth: '200px', maxHeight: '200px' }} src={crFormData.participantSignature} alt='' />
            <hr />
          </React.Fragment>
        }
        <span style={{ marginLeft: '10px' }}>Signature of Participant </span>
      </div>

      <div style={{
        width: '20%', float: 'right', marginTop: '-60px', marginRight: '40px',
      }}>
        {!isReadOnly ?
          <input type='text' style={R.merge(style.lineInput, { width: '100%' })} value={moment().tz(this.props.currentOrganization.timezone).format('DD-MMM-YYYY')} /> :
          <input type='text' readOnly style={R.merge(style.lineInput, { width: '100%' })} value={crFormData && crFormData.dateOfChangeRequest} />
        }
        <span>Date </span>
      </div>

      <div style={{ width: '40%', marginLeft: '40px' }}>
        {!isReadOnly ?
          this._signatureInput('guardianSignature') :
          <React.Fragment>
            <img style={{ maxWidth: '200px', maxHeight: '200px' }} src={crFormData.guardianSignature} alt='' />
            <hr />
          </React.Fragment>
        }
        <span style={{ marginLeft: '10px' }}>Signature of Guardian, where applicable </span>
      </div>

      <div style={{
        width: '20%', float: 'right', marginTop: '-60px', marginRight: '40px',
      }}>
        {!isReadOnly ?
          <input type='text' style={R.merge(style.lineInput, { width: '100%' })} value={moment().tz(this.props.currentOrganization.timezone).format('DD-MMM-YYYY')} /> :
          <input type='text' readOnly style={R.merge(style.lineInput, { width: '100%' })} value={crFormData && crFormData.dateOfChangeRequest} />
        }
        <span>Date </span>
      </div>
      <br /><br /><br />

      <span style={{ marginLeft: '50px' }}><strong style={style.bold}>Witnessed by:</strong></span><br />
      <div style={{ width: '40%', marginLeft: '40px' }}>
        {!isReadOnly ?
          this._signatureInput('witnessSignature') :
          <React.Fragment>
            <img style={{ maxWidth: '200px', maxHeight: '200px' }} src={crFormData.witnessSignature} alt='' />
            <hr />
          </React.Fragment>
        }
        <span style={{ marginLeft: '10px' }}>Signature of Doctor or Hospital /<br />&nbsp;&nbsp;Clinic Authorised Officer </span>
      </div>

      <div style={{
        width: '20%', float: 'right', marginTop: '-60px', marginRight: '40px',
      }}>
        {!isReadOnly ?
          <input type='text' style={R.merge(style.lineInput, { width: '100%' })} value={moment().tz(this.props.currentOrganization.timezone).format('DD-MMM-YYYY')} /> :
          <input type='text' readOnly style={R.merge(style.lineInput, { width: '100%' })} value={crFormData && crFormData.dateOfChangeRequest} />
        }
        <span>Date </span>
      </div>
    </React.Fragment>
  )

  handlePerformAction = () => {
    const { crFormData, executeMethod, contractNo } = this.props;
    this.setState({ awaitPerformAction: true });

    const crFormCompleteData = R.merge(crFormData, {
      guardianName: this.guardianName.value || null,
      guardianIDType: this.guardianIDType.value || null,
      guardianIDNumber: this.guardianIDNumber.value || null,
      changeRequestNo: contractNo,
      dateOfChangeRequest: moment().tz(this.props.currentOrganization.timezone).format('DD-MMM-YYYY'),
      revisedSubscriptionFees: this.revisedSubscriptionFees ? this.revisedSubscriptionFees.value : null,
      effectiveDateOfChangeRequest: moment().tz(this.props.currentOrganization.timezone).format('DD-MMM-YYYY'),
      participantSignature: document.getElementById('participantSignature').src || null,
      guardianSignature: document.getElementById('guardianSignature').src || null,
      witnessSignature: document.getElementById('witnessSignature').src || null,
    });


    executeMethod(crFormCompleteData);
  }

  renderFooter = (action) => {
    const { closeCRForm } = this.props;
    const { awaitPerformAction } = this.state;

    return (
      <Grid container style={R.merge(style.sticky, { marginTop: '100px', marginBottom: '100px' })} justify='center'>
        <Grid item xs={5} style={{ textAlign: 'left' }}>
          <Button className='milo-btn-white' variant='contained' style={{ width: '50%' }} disabled={awaitPerformAction} onClick={closeCRForm}>Previous </Button>
        </Grid>
        <Grid item xs={5} style={{ textAlign: 'right' }}>
          <Button className='milo-btn-blue' variant='contained' style={{ width: '50%' }} disabled={awaitPerformAction} onClick={this.handlePerformAction}>Proceed to {commonUtil.toTitleCase(action)} </Button>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { crFormData, isReadOnly } = this.props;
    return (
      <Paper style={{ boxShadow: 'unset' }}>
        <Grid container justify='center' style={{ padding: '10px' }}>
          <Grid style={{ minWidth: '66%' }} item xs={isReadOnly ? 12 : 8}>
            <SignaturePad {...this.state} close={this._handleClose} />
            <table className='change-request-form-table' style={{ borderCollapse: 'collapse', width: '100%' }} align='center'>
              <thead>
                <tr style={{ textAlign: 'center' }}>
                  <th colSpan='2'><Typography variant='h6'>CHANGE REQUEST FORM </Typography></th>
                </tr>
              </thead>
              {this.renderTableBody(crFormData, isReadOnly)}
            </table>
            <br />
            {this.renderSignatureText(crFormData)}
            {this.renderSignature(crFormData, isReadOnly)}
            {!isReadOnly && this.renderFooter(crFormData.action)}
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default CRForm;
