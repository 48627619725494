/* Epics */
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/catch';
/* Libs */
import { path } from 'ramda';

/*
**************************************************
  Action Types
**************************************************
*/
const prefix = 'MESSAGE';

const actionTypes = {
  SHOW: Symbol(`${prefix}/SHOW`),
  SET_MESSAGE: Symbol(`${prefix}/SET_MESSAGE`),
  SET_STATUS: Symbol(`${prefix}/SET_STATUS`),
  SET_TYPE: Symbol(`${prefix}/SET_TYPE`),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  show: payload => ({ type: actionTypes.SHOW, payload }),
  setMessage: payload => ({ type: actionTypes.SET_MESSAGE, payload }),
  setStatus: payload => ({ type: actionTypes.SET_STATUS, payload }),
  setType: payload => ({ type: actionTypes.SET_TYPE, payload }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/
const epics = [];

export { epics };

/*
**************************************************
  State Getters
**************************************************
*/
const getState = path(['message']);

const getters = {
  getState,
};

export { getters };

/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
  message: null,
  status: 'closed',
  type: 'info',
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.SHOW: {
      const { message, type, customClass } = action.payload;
      return {
        ...state, message, type, status: 'opened', customClass
      };
    }
    case actionTypes.SET_MESSAGE: {
      const { message } = action.payload;
      return { ...state, message };
    }
    case actionTypes.SET_STATUS: {
      const { status } = action.payload;
      return { ...state, status };
    }
    case actionTypes.SET_TYPE: {
      const { type } = action.payload;
      return { ...state, type };
    }
    default: {
      return state;
    }
  }
};
