/* Epics */
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/delay';

/* Libs */
import DM from 'dataModule';

/*
**************************************************
  Action Types
**************************************************
*/
const DEFAULT_CURRENT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 10;

const prefix = 'app/program/apList';

const actionTypes = {
  FETCH_PAGE: Symbol(`${prefix}/FETCH_PAGE`),
  FETCH_PAGE_SUCCESS: Symbol(`${prefix}/FETCH_PAGE_SUCCESS`),
  FETCH_PAGE_FAILURE: Symbol(`${prefix}/FETCH_PAGE_FAILURE`),
};

export { actionTypes };

/*
**************************************************
  Action Creators
**************************************************
*/
const actionCreators = {
  fetchPage: payload => ({ type: actionTypes.FETCH_PAGE, payload }),
  fetchPageSuccess: payload => ({ type: actionTypes.FETCH_PAGE_SUCCESS, payload }),
  fetchPageFailure: payload => ({ type: actionTypes.FETCH_PAGE_FAILURE, payload }),
};

export { actionCreators };

/*
**************************************************
  Epics
**************************************************
*/
const epics = [
  // fetchPage
  action$ => action$.ofType(actionTypes.FETCH_PAGE)
    // TODO: Use options later
    .mergeMap(({ payload: { page, pageSize } }) =>
      Observable.fromPromise(DM.program.admin.fetchAdminProgramList({
        page: page || DEFAULT_CURRENT_PAGE, pageSize: pageSize || DEFAULT_PAGE_SIZE,
      }))
        .map(actionCreators.fetchPageSuccess)
        .catch(error => Observable.of(actionCreators.fetchPageFailure({ error })))),
];

export { epics };
/*
**************************************************
  Reducer
**************************************************
*/
const initState = {
  prevList: [],
  list: [],
  options: {
    pagination: { currentPage: 0, pageSize: 10, totalCount: 0 },
  },
  loading: true,
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PAGE: {
      return {
        ...state, prevList: state.list, /*list: [], */loading: true,
      };
    }
    case actionTypes.FETCH_PAGE_SUCCESS: {
      // TODO: Modify dataManager to return options later
      const { list, pagination } = action.payload;
      return {
        ...state, list, options: { pagination }, loading: false,
      };
    }
    default: {
      return state;
    }
  }
};
