import { combineReducers } from 'redux';
import patientMinList, { epics as patientMinListEpics } from './patientMinList';
import consumableAvailableList, { epics as consumableAvailableListEpics } from './consumableAvailableList';
import addedConsumablesList, { epics as manageConsumablesEpics } from './manageConsumables';
import consumableSale, { epics as consumableSaleEpics } from './consumableSales';

export const epics = [
  ...patientMinListEpics,
  ...consumableAvailableListEpics,
  ...manageConsumablesEpics,
  ...consumableSaleEpics,
];

export default combineReducers({
  patientMinList,
  consumableAvailableList,
  addedConsumablesList,
  consumableSale,
});
