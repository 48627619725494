/* eslint-disable consistent-return */
/* eslint-disable object-curly-newline */
import React from 'react';
import * as momentTz from 'moment-timezone';
import { withStyles } from '@material-ui/styles';
const footer = require('../../../../public/hb_powered_by_int.png');

const styles = (theme) => ({
  container: {
    backgroundColor: 'white',
    width: '21cm',
    height: '29.7cm',
    overflow: 'hidden',
  },
  metadataTitle: {
    width: '15%',
    fontSize: 10,
  },
  metadataValue: {
    width: '60%',
    fontSize: 10,
  },
  tdHeader: {
    border: '1px solid black',
    borderCollapse: 'collapse',
    textAlign: 'left',
    fontSize: 8,
    fontWeight: 'bold',
    padding: 8,
  },
  td: {
    border: '1px solid black',
    borderCollapse: 'collapse',
    textAlign: 'left',
    fontSize: 8,
    padding: 8,
    overflowWrap: 'anywhere',
  },
  tdTotal: {
    border: '1px solid black',
    borderCollapse: 'collapse',
    fontWeight: 'bold',
    fontSize: 12,
    padding: 8,
  },
  metadataSpace: {
    height: 10,
  },
});

@withStyles(styles, { index: 1 })
class AdherenceReportComponent extends React.Component {
  render = () => {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <div style={{ padding: 20 }}>
          {' '}
          {this._header()} <div style={{ height: 20 }}> </div> {this._table()} <div style={{ height: 80 }}> </div>{' '}
          {this._footer()}{' '}
        </div>{' '}
      </div>
    );
  };

  _header = () => {
    const { reportMetaData, classes } = this.props;
    if (!reportMetaData) return;
    const dateTimeReport = momentTz(new Date(reportMetaData.reportDate))
      .tz(reportMetaData.timezone)
      .format('DD MMM YYYY, hh:mm A');
    return (
      <div
        style={{
          width: '100%',
        }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={reportMetaData.hospitalLogo} style={{ width: 110, height: 110, marginTop: 12, marginBottom: 12 }} />{' '}
        </div>{' '}
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            fontSize: 16,
            fontWeight: 'bold',
            fontFamily: 'Roboto',
            marginTop: 12,
            marginBottom: 12,
          }}>
          Participants ' Adherence Report: {reportMetaData.monthYear}{' '}
        </div>{' '}
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td className={classes.metadataTitle}> Hospital / Clinic Name </td>{' '}
              <td className={classes.metadataValue}>: {reportMetaData.organizationName} </td>{' '}
            </tr>{' '}
            <tr>
              <td className={classes.metadataTitle}> Period of Report </td>{' '}
              <td className={classes.metadataValue}>: {reportMetaData.periodReportDate} </td>{' '}
            </tr>{' '}
            <tr>
              {' '}
              <td /> <td />{' '}
            </tr>{' '}
            <tr>
              {' '}
              <td /> <td />{' '}
            </tr>{' '}
            <tr>
              {' '}
              <td /> <td />{' '}
            </tr>{' '}
            <tr>
              {' '}
              <td /> <td />{' '}
            </tr>{' '}
            <tr>
              {' '}
              <td /> <td />{' '}
            </tr>{' '}
            <tr>
              <td className={classes.metadataTitle}> Date and Time of Report </td>{' '}
              <td className={classes.metadataValue}>: {dateTimeReport} </td>{' '}
            </tr>{' '}
            <tr>
              <td className={classes.metadataTitle}> Downloaded By </td>{' '}
              <td className={classes.metadataValue}>
                : {`${reportMetaData.name.firstName} ${reportMetaData.name.lastName}`}{' '}
              </td>{' '}
            </tr>{' '}
          </tbody>{' '}
        </table>
      </div>
    );
  };

  _table = () => {
    const { adherences, classes, pages, totalPages } = this.props;
    return (
      <div style={{ height: '609px' }}>
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
          <thead>
            <tr>
              <th className={classes.tdHeader}> S / N </th> <th className={classes.tdHeader}> Participant Name </th>{' '}
              <th className={classes.tdHeader}> Programme </th> <th className={classes.tdHeader}> Vital Types </th>{' '}
              <th className={classes.tdHeader}> Adherence Rate( % ) </th>{' '}
              <th className={classes.tdHeader}> No.of Completed Tasks </th>{' '}
              <th className={classes.tdHeader}> No.of Missed Tasks </th>{' '}
              <th className={classes.tdHeader}> Mobile Number </th>{' '}
              <th className={classes.tdHeader}> Email Address </th>{' '}
            </tr>{' '}
          </thead>{' '}
          <tbody> {adherences.map((n, i) => this._tableCell(n, i))} </tbody>{' '}
        </table>{' '}
      </div>
    );
  };

  _tableCell = (data, index) => {
    const { classes, pageStartFrom } = this.props;
    let missed = data.total - data.done;
    let completionRate = `${data.completionRate}%`;
    let adherence = `${data.done}/${data.total}`;
    if (data.total === 0) {
      missed = 'N.A';
      completionRate = 'N.A';
      adherence = 'N.A';
    }
    const pageNo = pageStartFrom + index;
    return (
      <tr key={data.id}>
        <td className={classes.td}> {pageNo} </td>{' '}
        <td className={classes.td} style={{ width: '20%' }}>
          {' '}
          {`${data.name.firstName} ${data.name.lastName}`}{' '}
        </td>{' '}
        <td className={classes.td}> {data.enrolledProgramTitle} </td> <td className={classes.td}> {data.vitals} </td>{' '}
        <td className={classes.td} style={{ width: '5%' }}>
          {' '}
          {completionRate}{' '}
        </td>{' '}
        <td className={classes.td}> {adherence} </td> <td className={classes.td}> {missed} </td>{' '}
        <td className={classes.td}> {`+${data.mobile.countryCode} ${data.mobile.value}`} </td>{' '}
        <td className={classes.td}> {data.email} </td>{' '}
      </tr>
    );
  };

  _footer = () => {
    const { page, totalPages } = this.props;
    return (
      <div>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
          <div style={{ flex: 1 }}>
            <img src={footer} style={{ width: '50%', float: 'right', marginRight: '-30px' }} />
          </div>
          <div style={{ flex: 1 }}>
            <span style={{ float: 'right', fontSize: 12 }}>
              Page {page} of {totalPages}
            </span>
          </div>
        </div>
      </div>
    );
  };
}

export default AdherenceReportComponent;
