import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { SubmissionError } from 'redux-form';
import update from 'immutability-helper';
import commonUtil from 'utilsModule/common';
import { withResource, resourceTypes, gettersOf } from 'dataModule/store/resources';
import { actionCreators as messageActionCreators } from 'appModule/message/ducks/message';
import { ForgetPasswordVerify } from '../components';

@withResource([{ resourceType: resourceTypes.USERS }])
@connect(null, { notify: messageActionCreators.show })
@withRouter
class ForgetPasswordVerifyContainer extends PureComponent {
  static propTypes = {};

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  sendOTP = () => {
    const { actionCreators, notify } = this.props;
    const mobileNumber = sessionStorage.getItem('verifyPhone');
    actionCreators[resourceTypes.USERS].ajax({
      cargo: { method: 'forgetPassword', input: { content: { phone: mobileNumber } } },
      onSuccess: () => {
        if (this.mounted) {
          notify({ message: 'New One Time Password (OTP) has been sent successfully.', type: 'success', customClass : ['expanded'] });
        }
      },
      onFailure: ({ error, reject }) => {
        if (this.mounted) {
          const reportedError = JSON.stringify(error, ['code', 'message']);
          reject(new SubmissionError({ _error: reportedError }));
          notify({ message: `${error.message}`, type: 'error', customClass : ['expanded'] });
        }
      },
    });
  }

  verifyOTP = ({ otp }) => {
    const {
      actionCreators, notify, history, location: { state: { changePassword = false } = {} } = {},
    } = this.props;
    const credentials = { code: R.pipe(R.values, R.join(''))(otp) };
    const mobileNumber = sessionStorage.getItem('verifyPhone');
    actionCreators[resourceTypes.USERS].ajax({
      cargo: { method: 'passwordVerifyOTP', input: { content: { phone: mobileNumber, otp: credentials.code } } },
      onSuccess: () => {
        if (changePassword) {
          history.push('/changepassword/setnewpassword', { changePassword: true });
        } else {
          history.push('/forgetpassword/setnewpassword');
        }
      },
      onFailure: ({ error }) => {
        notify({ message: `${error.message}`, type: 'error', customClass : ['expanded'] });
      },
    });
  }

  convertToPhoneObject = (phone) => {
    if (!phone) return {};

    const phoneObject = phone.split(' ');
    return {
      countryCode: phoneObject[0],
      value: phoneObject[1],
    };
  }

  render() {
    // process.env.DEBUG && console.log('%crender ForgetPasswordVerifyContainer', 'font-size: 12px; color: #00b3b3', this.props);

    // Sanitize data
    const timeoutMins = 5; // BE: Configurable
    const { data, location: { state: { changePassword = false } = {} } = {} } = this.props;

    if (!this.inited) {
      this.initialValues = {};
      this.inited = true;
    }

    let sendViaMessage = '';
    const otpVia = gettersOf(resourceTypes.USERS).getForgotPassword2FAOtpVia()(data);
    if (otpVia && otpVia.method === 'SMS') {
      const mobileObj = this.convertToPhoneObject(otpVia.via);
      sendViaMessage = `mobile ending with +${mobileObj.countryCode} ${mobileObj.value.toString().replace(/\d(?=\d{4})/g, '*').replace(mobileObj.value.slice(-4), '-'+mobileObj.value.slice(-4))}`;
    } else if (otpVia && otpVia.method === 'EMAIL') {
      sendViaMessage = `email ${commonUtil.maskEmail(otpVia.via)}`;
    }

    this.runtimeProps = {
      header: {
        banner: {
          title: 'Enter One-Time Password (OTP)',
        },
      },
    };

    this.runtimeProps = update(this.runtimeProps, {
      header: {
        banner: {
          subtitle: {
            $set: `The OTP sent to your ${sendViaMessage} is only valid for ${timeoutMins} minutes.`,
          },
        },
      },
    });

    return <ForgetPasswordVerify
      initialValues={this.initialValues}
      runtimeProps={this.runtimeProps}
      verifyOTP={this.verifyOTP}
      sendOTP={this.sendOTP}
      changePassword={changePassword}
    />;
  }
}

export default ForgetPasswordVerifyContainer;
