import React from 'react';
import { withStyles } from '@material-ui/styles';
import ReportFooter from '../../component/ReportPDF/ReportFooter';

const styles = () => ({
  container: {
    backgroundColor: 'white',
    width: '21cm',
    height: '29.7cm',
    overflow: 'hidden',
  },
  embeddedContainer: {
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
  },
  content: { padding: 20, height: '89%' },
  embeddedContent: { paddingTop: 20, paddingBottom: 20, height: '80%' },
});

@withStyles(styles, { index: 1 })
class ReportPage extends React.Component {
  render() {
    const { page, totalPages, classes, children, isEmbedded = false } = this.props;

    return (
      <React.Fragment>
        <div className={isEmbedded ? classes.embeddedContainer : classes.container}>
          <div className={isEmbedded ? classes.embeddedContent : classes.content}>{children}</div>
          {!isEmbedded && <ReportFooter page={page} totalPages={totalPages} />}
        </div>
      </React.Fragment>
    );
  }
}

export default ReportPage;
