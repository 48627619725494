import React from 'react';
import { Loading } from 'utilsModule/components';
import { withResource, resourceTypes, gettersOf } from 'dataModule/store/resources';
import generateInvoiceHTML from '../components/OrgInvoice';

@withResource([
  {
    resourceType: resourceTypes.BILLING,
    method: 'retrieveOrgInvoiceByUuid',
    input: ({ location: { query: params } }) => ({
      params: {
        queries: [
          { name: 'session-token', value: params['session-token']}
        ],
        ids: [{ name: 'uuid', value: params.uuid }],
      },
    }),
    options: { runOnDidMount: true },
  },
])
class OrgInvoice extends React.Component {
  render() {
    const { data } = this.props;
    if (data.status.loading) return <Loading />;
    const { value: invoiceData } = gettersOf(resourceTypes.BILLING).getOrgInvoiceByUuid()(data);
    return generateInvoiceHTML(invoiceData);
  }
}

export default OrgInvoice;
